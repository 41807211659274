import {Body2} from '@ui/atoms/typography';
import React, {FC, ReactNode, useState} from 'react';

interface HoverTooltipProps {
  children: ReactNode;
  tooltipText: string;
  show?: boolean;
  className?: string;
}
export const HoverTooltip: FC<HoverTooltipProps> = ({
  children,
  show,
  tooltipText,
  className,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className="relative"
      onClick={() => setIsHovering(false)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      <div className="z-10 ">{children}</div>
      {isHovering && (
        <div
          style={{zIndex: -4}}
          className={
            'bg-[#F6F6F8] -top-[50%] -left-[50%] z-0 w-full absolute px-4 py-4 rounded-full '
          }
        />
      )}
      {(isHovering || show) && (
        <div
          className={`absolute top-9 -right-6 bg-gray-700 text-white py-1 px-2 rounded-[5px] ${className}`}>
          <Body2 kind="white" size="12px" style={{whiteSpace: 'nowrap'}}>
            {tooltipText}
          </Body2>
        </div>
      )}
    </div>
  );
};
