import React, {FC, useMemo} from 'react';
import {AnalyticsActionItemsOverviewPage} from '../../reporting-pages/admin-analytics/analytics-pages/analytics-action-items/overview';
import {Body1, Body2} from '@ui/atoms/typography';
import {Insight} from '../interface';
import {FlexRow, FlexRowEnd} from '@ui/style/styles';
import {formatConfigDate, comparisonConfigDate} from '../insight-options';
import {useInsightActionItemHook} from './action-items-insight-hook';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {PercentageProgress} from '../../dashboard/insight-card';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {getInitialAdditionalFilters} from '../edit-insight/edit-insight-filter';
import {VerticalInsightConfig} from '../../dashboard/insight-config';

interface ActionItemsDistributionProps extends Insight {
  showRangeType?: boolean;
  handleEditLayout?: (id: string) => void;
}

type OriginalSummary = {
  [key: string]: {
    current: number;
    previous: number;
    change: number;
  };
};

type AnalyticsPageSummary = {
  [K in keyof OriginalSummary]: K extends 'completionRate' ? string : number;
} & {total: string};

function prepareAnalyticsSummary(
  summary: OriginalSummary,
): AnalyticsPageSummary {
  const prepared = Object.entries(summary).reduce((acc, [key, value]) => {
    if (key === 'completionRate') {
      acc.total = `${value.current}%`;
    } else {
      acc[key] = value.current;
    }
    return acc;
  }, {} as AnalyticsPageSummary);

  return prepared;
}

type SummaryItem = {
  current: number;
  previous: number;
  change: number;
};

type Summary = {
  [key: string]: SummaryItem;
};

type FormattedSummaryItem = {
  label: string;
  current: string | number;
  color: string;
  previous: string | number;
  change: string;
};

function formatActionItemsSummary(summary: Summary): FormattedSummaryItem[] {
  const labels = {
    active: {
      label: 'Open',
      color: '#E4E5FB',
    },
    completed: {
      label: 'Completed',
      color: '#585ADF',
    },
    overdue: {
      label: 'Overdue',
      color: '#28284D',
    },
    undated: {
      label: 'Undated',
      color: '#5E5E73',
    },
    completionRate: {
      label: 'Completion rate',
      color: '',
    },
  };

  return Object.entries(summary)
    .filter(([key]) => !!labels[key as keyof typeof labels])
    .map(([key, item]) => {
      const isPercentage = key === 'completionRate';
      const formatValue = (value: number) =>
        isPercentage ? `${value}%` : value;
      const changeDirection = item.change >= 0 ? '↑' : '↓';

      return {
        label: labels[key as keyof typeof labels].label || key,
        color: labels[key as keyof typeof labels].color,
        current: formatValue(item.current),
        previous: formatValue(item.previous),
        change: `${Math.abs(item.change).toFixed(1)}% ${changeDirection}`,
      };
    });
}

export const ActionItemsDistribution: FC<ActionItemsDistributionProps> = ({
  showRangeType,
  handleEditLayout,
  name,
  ...rest
}) => {
  const config = rest.config;

  const additionalFilters = getInitialAdditionalFilters(
    config.list,
    config.users,
  );

  const formatDate = useMemo(() => formatConfigDate(config), [config]);

  const comparisonDate = useMemo(
    () => comparisonConfigDate(config, formatDate.startDate),
    [config, formatDate.startDate],
  );

  const {summary} = useInsightActionItemHook({
    members: config.users || [],
    sections: config.list || [],
    date: formatDate,
    comparisonDate: comparisonDate,
  });

  const hasComparison = !!config.comparison.value;

  const preparedSummary = prepareAnalyticsSummary(summary);

  const formattedSummary = formatActionItemsSummary(summary);

  return (
    <div className="bg-white shadow-sm p-6 border rounded-[10px] border-borderLight  min-h-[150px] w-full h-full ">
      <Body1 weight="bold" className="mb-2">
        {name}
      </Body1>

      {showRangeType && (
        <div>
          <VerticalInsightConfig
            config={config}
            additionalFilters={additionalFilters}
          />
        </div>
      )}

      {hasComparison ? (
        formattedSummary.map((item, index) => (
          <div
            key={item.label}
            className={`flex flex-row justify-between items-center py-4 ${
              index === formattedSummary.length - 1 ? '' : 'border-b'
            }  border-b-borderLight `}>
            <div>
              <FlexRow className="gap-2">
                {item.color && (
                  <CustomColorIcon
                    color={item.color}
                    height="10px"
                    width="10px"
                    margin={0}
                  />
                )}
                <Body2
                  weight={
                    item.label.toLowerCase() === 'completion rate'
                      ? 'semibold'
                      : 'default'
                  }>
                  {item.label}
                </Body2>
              </FlexRow>
              <Body2
                kind="textBody"
                style={{fontSize: '12px'}}
                className="mt-3">
                Change
              </Body2>
            </div>
            <div>
              <FlexRow>
                <Body2 kind="textBody" weight="semibold">
                  <span className="text-black">{item.current}</span> vs.{' '}
                  {item.previous}
                </Body2>
              </FlexRow>
              <div className="mt-3 flex flex-row justify-end items-center">
                <PercentageProgress value={item.change} />
              </div>
            </div>
          </div>
        ))
      ) : (
        <AnalyticsActionItemsOverviewPage
          summary={preparedSummary as any}
          pieTitle="Completion rate"
          extraPieSection={[
            {
              title: 'Undated',
              name: 'undated',
              key: 'undated',
              value: summary.undated.current,
              color: '#5E5E73',
              percent: 'undatedPercent',
            },
          ]}
          excludeCompletionRate
        />
      )}

      {showRangeType && (
        <FlexRowEnd className="mt-6">
          <button
            onClick={() => {
              if (rest.firebaseId && handleEditLayout)
                handleEditLayout(rest.firebaseId);
            }}>
            <HoverTooltip tooltipText="Edit insight">
              <CandleVIcon />
            </HoverTooltip>
          </button>
        </FlexRowEnd>
      )}
    </div>
  );
};
