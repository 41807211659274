import styled from 'styled-components';
import {useMemo} from 'react';
import {ReviewCycles} from './review-cycles/review-cycles';
import {CultureAndCompetenceResult} from './results/cc-results';
import {YourReview} from './your-review/your-review';
import {Routes, Route, useNavigate} from 'react-router';
import {useIsRouteActive} from '@utils/route-href';
import {observer} from 'mobx-react-lite';
import {useStoreContext} from '@store/store-context';
import {authStore} from '@store/stores/auth-store';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Headline2} from '@ui/atoms/typography';
import {Button} from '@ui/atoms/button';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {VerticalSpacer} from '@ui/atoms/spacer';

const Wrapper = styled.div`
  width: 100%;
`;

const CC_ROUTES = {
  REVIEW_CYCLES: `review-cycles`,
  YOUR_REVIEWS: `your-reviews`,
  RESULTS: `results`,
};

export const CultureAndCompetence = observer(() => {
  const {
    storeDashboardRoutes: {activateCultureAndCompetenceTab},
  } = useStoreContext();

  const isRouteActive = useIsRouteActive();

  const isReviewCyclesActive = useMemo(
    () => isRouteActive(CC_ROUTES.REVIEW_CYCLES),
    [isRouteActive],
  );

  const isYourReviewsActive = useMemo(
    () => isRouteActive(CC_ROUTES.YOUR_REVIEWS),
    [isRouteActive],
  );
  const isResultsActive = useMemo(() => isRouteActive(CC_ROUTES.RESULTS), [
    isRouteActive,
  ]);

  const navigate = useNavigate();

  const navigation: any = useMemo(() => {
    const nav = [
      {
        active: isYourReviewsActive,
        disable: false,
        href: CC_ROUTES.YOUR_REVIEWS,
        id: '1',
        onClick: () => {
          activateCultureAndCompetenceTab('reviewCycle');
        },
        title: `Your reviews`,
        icon: <></>,
      },
    ];

    if (authStore.auth.user.isReviewer) {
      nav.unshift({
        active: isReviewCyclesActive,
        disable: false,
        href: CC_ROUTES.REVIEW_CYCLES,
        id: '1',
        onClick: () => {
          activateCultureAndCompetenceTab('yourReview');
        },
        icon: <></>,

        title: `Manage cycles`,
      });
    }
    nav.push({
      active: isResultsActive,
      disable: false,
      href: CC_ROUTES.RESULTS,
      id: '1',
      onClick: () => {
        activateCultureAndCompetenceTab('result');
      },
      title: `Results`,
      icon: <></>,
    });

    return nav;
  }, [
    isReviewCyclesActive,
    isYourReviewsActive,
    isResultsActive,
    activateCultureAndCompetenceTab,
  ]);

  return (
    <Wrapper>
      <FlexRowSpaceBetween className="mb-10">
        <div className="">
          <Headline2>C&C review cycles</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Participate, manage and track periodic performance reviews.
            </Body1>
          </div>
        </div>

        <Button
          onClick={() => {
            navigate('/create-review-cycle');
          }}
          width="sm"
          style={{padding: '11px 24px'}}
          className="buttonDiv">
          Create a new cycle
        </Button>
      </FlexRowSpaceBetween>

      <TabNavigation navigations={navigation} />
      <VerticalSpacer size="24px" />

      <Routes>
        <Route path={CC_ROUTES.REVIEW_CYCLES} element={<ReviewCycles />} />

        <Route path={CC_ROUTES.YOUR_REVIEWS} element={<YourReview />} />

        <Route
          path={CC_ROUTES.RESULTS}
          element={<CultureAndCompetenceResult />}
        />
      </Routes>
    </Wrapper>
  );
});
