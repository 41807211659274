import {useMemo, useState} from 'react';
import {useFirebaseFetch} from './query-hook';
import {useStoreContext} from '@store/store-context';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import dayjs from 'dayjs';

export const useSections = () => {
  const {data: sections} = useFirebaseFetch(`sections`);
  const [showRenameSectionModal, setRenameSectionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    authStore: {auth},
  } = useStoreContext();

  const computeSections = useMemo(() => {
    if (sections) {
      return Object.entries(sections).map(([key, section]: any) => ({
        ...section,
        key,
      }));
    }
    return [];
  }, [sections]);

  const canCreateSection = auth.user.role === 'admin' || auth.user.isReviewer;

  const [sectionTitle, setSectionTitle] = useState('');

  const findSectionSlug = (key: string) => {
    return computeSections.find((section) => section.name === key)?.slug;
  };

  const findSection = (key: string) => {
    return computeSections.find((section) => section.key === key);
  };

  const handleCreateSection = async () => {
    await writeFirebaseData('sections', {
      createdBy: auth.user.id,
      createdAt: dayjs().format(),
      name: sectionTitle,
      slug: sectionTitle,
    });
  };

  const handleEditSection = async (id: string, name: string) => {
    const editedSection = findSection(id);

    await updateFirebaseData(`sections/${id}`, {
      ...editedSection,
      name,
    });
  };

  const handleDeleteSection = async (id: string) => {
    await removeFirebaseData(`sections/${id}`);
  };

  return {
    handleCreateSection,
    setSectionTitle,
    findSectionSlug,
    findSection,
    canCreateSection,
    handleEditSection,
    handleDeleteSection,
    computeSections,
    showRenameSectionModal,
    setShowDeleteModal,
    showDeleteModal,
    setRenameSectionModal,
    sectionTitle,
  };
};
