import {useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {authStore} from '@store/stores/auth-store';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {
  writeFirebaseData,
  removeFirebaseData,
  setFirebaseData,
} from '@utils/firebase-handler';
import {useFirebaseFetch} from './query-hook';
import {FIREBASE_BASE_URL} from '@utils/firebase-request';
import {getDatabase} from 'firebase/database';
import {get, ref} from 'firebase/database';
import {DEFAULT_KPI_CONFIG} from '@pages/dashboard/reporting/insights/edit-insight/edit-insight-hook';

interface Goal {
  id: string;
  name: string;
  isKpi: boolean;
  // Add other properties as needed
}

interface InsightConfig {
  type: 'dynamic';
  comparison: {
    type: string;
    value: number;
  };
  range: {
    type: string;
    value: number;
  };
}

interface Insight {
  name: string;
  id: string;
  createdBy: string;
  updatedAt: string;
  createdAt: string;
  updatedBy: string;
  config: InsightConfig;
  type: 'kpi';
}

interface Filters {
  startDate: string;
  endDate: string;
  filterBy: string;
  removeKpiFilter: boolean;
  isKpi: boolean;
}

export const useKpiInsightHook = () => {
  const [isUpdateNeeded, setIsUpdateNeeded] = useState(false);
  const [cleanUpComplete, setCleanUpComplete] = useState(false);

  const {data: insights} = useFirebaseFetch('insights');

  const fetchSpecifiedGoals = useCallback(
    async (filters: Filters, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const response: {goals: Goal[]} = await controller.fetchSpecifiedGoals(
        filters,
        view,
        true,
      );

      return response?.goals || [];
    },
    [],
  );

  const cleanupNonKpiInsights = useCallback(
    async (goals: Goal[]) => {
      if (!insights) return;

      const insightsArray = Object.entries(insights) as [string, Insight][];
      const kpiGoalIds = new Set(
        goals.filter((goal) => goal.isKpi).map((goal) => goal.id),
      );

      for (const [insightId, insight] of insightsArray) {
        if (insight.type === 'kpi' && !kpiGoalIds.has(insight.id)) {
          removeFirebaseData(`insights/${insightId}`);
        }
      }
      setCleanUpComplete(true);
    },
    [insights],
  );

  const updateKpiInsights = useCallback(async (goals: Goal[]) => {
    const kpiGoals = goals.filter((goal) => goal.isKpi);

    for (const goal of kpiGoals) {
      const insight: Insight = {
        name: goal.name,
        id: goal.id,
        createdBy: '',
        updatedAt: dayjs().format(),
        createdAt: dayjs().format(),
        updatedBy: '',
        config: DEFAULT_KPI_CONFIG as any,
        type: 'kpi',
      };

      await writeFirebaseData(`insights`, insight);
    }

    setIsUpdateNeeded(false);
    setFirebaseData(`users/${authStore.auth.user.id}/sync-data/insights`, {
      isKpi: true,
    });
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const actionItemsRef = ref(
      db,
      `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/users/${authStore.auth.user.id}/sync-data/insights`,
    );

    get(actionItemsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          if (!data?.isKpi) {
            setIsUpdateNeeded(true);
          }
        } else {
          setIsUpdateNeeded(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching action item insights:', error);
      });
  }, []);

  useEffect(() => {
    const fetchAndUpdateKpiInsights = async () => {
      const filters: Filters = {
        startDate: dayjs().subtract(1, 'year').format('MM/DD/YYYY'),
        endDate: dayjs().format('MM/DD/YYYY'),
        filterBy: 'weeks',
        removeKpiFilter: true,
        isKpi: true,
      };

      try {
        const goals = await fetchSpecifiedGoals(filters);
        if (!cleanUpComplete) {
          await cleanupNonKpiInsights(goals);
        }

        if (isUpdateNeeded) await updateKpiInsights(goals);
      } catch (error) {}
    };

    if (isUpdateNeeded || !cleanUpComplete) {
      fetchAndUpdateKpiInsights();
    }
  }, [
    fetchSpecifiedGoals,
    cleanupNonKpiInsights,
    updateKpiInsights,
    insights,
    isUpdateNeeded,
    cleanUpComplete,
  ]);

  return {isUpdateNeeded};
};
