import UserDateRangeFilter from '@pages/dashboard/goals/objective-filter';
import {useAnalyticsQuestionHook} from './question-hook';
import {Body1, Body2, Headline3} from '@ui/atoms/typography';
import {useQuery} from 'react-query';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {ItemLoader} from '@ui/organisms/item-loader';
import {cloudinary} from '@assets/images/cloudinary';
import {Overview, Wrapper, LoadingWrapper} from './styles';
import {FC, useCallback, useMemo, useState} from 'react';
import {SelectField} from '@ui/molecules/select/selectfield';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {UserListCard} from '@ui/molecules/user/list-card';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {Button} from '@ui/atoms/button';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {useStoreContext} from '@store/store-context';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {CancelIcon} from '@ui/atoms/icons';
import dayjs from 'dayjs';
const {emptyGoals} = cloudinary;

type Option = {label: string; value: string};

interface AnalyticsMeetingProps {
  selectedGroups: Option[];
  selectedTeams: Option[];
  selectedMembers: Option[];
}

export const AnalyticsQuestions: FC<AnalyticsMeetingProps> = ({
  selectedGroups,
  selectedMembers,
  selectedTeams,
}) => {
  const selectedGroupsId = selectedGroups.map((group) => group.value);

  const selectedTeamsId = selectedTeams.map((team) => team.value);

  const selectedMembersId = selectedMembers.map((member) => member.value);

  const computeFilters = useMemo(() => {
    return {
      manager: selectedTeamsId.length ? selectedTeamsId.join(',') : '',

      user: selectedMembersId.length ? selectedMembersId.join(',') : '',
      group: selectedGroupsId.length ? selectedGroupsId.join(',') : '',
    };
  }, [selectedGroupsId, selectedMembersId, selectedTeamsId]);

  const checkinsRequest = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const checkinsController = useMemo(
    () => new CompanyCheckinsController(checkinsRequest),
    [checkinsRequest],
  );

  const [selectedType, setSelectedType] = useState('');

  const [showApply, setShowApply] = useState(true);

  const [questionGroups, setQuestionsGroups] = useState<string[]>([]);

  const getCheckinsQuestions = useCallback(
    async (filter) => {
      const response = await checkinsController.getCheckinsQuestions(filter);

      if (response) {
        return response
          .filter((question) =>
            questionGroups.length > 0
              ? question.target.ids.some((id) => questionGroups.includes(id))
              : true,
          )
          .flatMap((question) =>
            question.questions.map((question) => ({
              value: question.id,
              label: question.text,
            })),
          );
      }
      return [];
    },
    [checkinsController, questionGroups],
  );
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const computeGroups = useMemo(() => {
    return groupType.map((grpType) => {
      return {
        name: grpType.name,
        data: groups
          .filter((group) => group.groupType === grpType.id)
          .map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          }),
      };
    });
  }, [groupType, groups]);

  const {data: questions, isLoading: questionLoading} = useQuery(
    ['questions-reporting', selectedType, questionGroups],
    () => getCheckinsQuestions({type: selectedType}),
  );

  const {
    dateRange,
    setDateRange,
    filters,
    fetchReportCheckinsQuestions,
  } = useAnalyticsQuestionHook(computeFilters);

  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);

  const {data, isLoading} = useQuery(
    ['questions-reporting', filters, selectedQuestions],
    () =>
      fetchReportCheckinsQuestions({
        ...filters,
        questionId: selectedQuestions.join(','),
      }),
    {
      enabled: !!selectedQuestions.length && !showApply,
    },
  );

  const questionTypeOptions = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Individual',
      value: 'individual',
    },
    {
      label: 'Group',
      value: 'group',
    },
    {
      label: 'Company-wide',
      value: 'general',
    },
  ];

  const QuestionFilter = (
    <MultiOptionsSelectField
      options={questions}
      performance
      searchPlaceholder={`Search question`}
      groupDisplayLength={3}
      margin
      showSearchIcon
      isLoading={questionLoading}
      defaultValue={questions?.filter((question) =>
        selectedQuestions.includes(question.value),
      )}
      noOptionsMessage={`Select question not found`}
      placeholder={`Select question`}
      onChange={(data: {value: string}[]) => {
        if (isDisabled) setIsDisabled(false);
        setSelectedQuestions(data.map((option) => option.value));
      }}
    />
  );
  const ActionButton = (
    <div>
      {showApply ? (
        <Button
          style={{padding: '12px 24px'}}
          disabled={isDisabled}
          onClick={() => {
            setShowApply(false);
          }}>
          Apply
        </Button>
      ) : (
        <Button
          style={{padding: '12px 24px'}}
          className="flex items-center"
          onClick={() => {
            setSelectedQuestions([]);
            setSelectedType('');
            setShowApply(true);
            setIsDisabled(true);
            setQuestionsGroups([]);
          }}
          kind="secondary">
          Clear <CancelIcon style={{strokeWidth: 1.5}} />
        </Button>
      )}
    </div>
  );
  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: 'Try tweaking the date range, action/event, or breakdown.',
    title: 'No results yet.',
  };

  const typeIsGroup = selectedType === 'group';

  if (isLoading) {
    return (
      <LoadingWrapper>
        <ItemLoader />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper className="">
      <div className="body">
        <Overview>
          <div className="header flex flex-row justify-between items-center border-b pb-4  border-b-[#ededf2] mb-4">
            <Body2 weight="bold">Overview</Body2>
            <div>
              <UserDateRangeFilter
                showDateRange
                removeUserFilter
                dateRange={dateRange}
                _height="36px"
                canFilterAll
                setDateRange={setDateRange}
              />
            </div>
          </div>

          <div className="flex flex-row w-full flex-wrap gap-2">
            <div className="w-[30%]">
              <SelectField
                placeholder="Select question type"
                options={questionTypeOptions}
                defaultValue={selectedType}
                onChange={(data: {value: string}) => {
                  if (!showApply) setShowApply(true);

                  setSelectedType(data?.value || '');
                }}
                margin
                reportingStyling
              />
            </div>
            <div className="sm:mx-0  w-full">
              {typeIsGroup ? (
                <MultiOptionsSelectFieldGroup
                  options={computeGroups}
                  performance
                  searchPlaceholder={`Search groups`}
                  groupDisplayLength={3}
                  margin
                  showSearchIcon
                  isLoading={questionLoading}
                  noOptionsMessage={`groups not found`}
                  placeholder={`Select groups`}
                  onChange={(data: {value: string}[]) => {
                    setQuestionsGroups(data.map((option) => option.value));
                  }}
                />
              ) : (
                QuestionFilter
              )}
            </div>
            {!typeIsGroup && ActionButton}
          </div>
          {typeIsGroup && (
            <FlexRow className="mt-4">
              <div className=" w-full mr-4">{QuestionFilter}</div>
              {ActionButton}
            </FlexRow>
          )}
        </Overview>
        <div className="table">
          <div className="table-desc">
            <Body2 weight="semibold">Summary ({data?.length || 0})</Body2>
          </div>
          <div className="border-t border-t-[#ededf2] rounded-t-[10px] p-6">
            {!data?.length && !isLoading && (
              <EmptyPlaceholder {...emptyPlaceholder} />
            )}
            {isLoading && (
              <FlexRowCenter className="h-[200px]">
                <ItemLoader />
              </FlexRowCenter>
            )}
            {data &&
              data.map((option, idx, allData) => (
                <div
                  key={option.questionId}
                  className={
                    idx !== allData.length - 1
                      ? 'border-b border-b-[#ededf2] mb-5 pb-3'
                      : ''
                  }>
                  <Headline3 className="mb-3">
                    <span className="text-[#5F5F8C]">Q. </span>
                    {option.question}
                  </Headline3>
                  <div className="mx-5">
                    {option.responses.length ? (
                      option.responses.map((response, index, allAnswers) => (
                        <div
                          key={response.id}
                          className={
                            index !== allAnswers.length - 1
                              ? 'border-b border-b-[#ededf2] mb-5'
                              : ''
                          }>
                          <FlexRow>
                            <div className="mr-4">
                              <UserListCard
                                tooltip={true}
                                userId={response?.id || ''}
                                type="primary"
                                textStyle={{fontWeight: 500}}
                                textKind="textDark"
                                avatar={response.avatar?.url}
                                name={
                                  response?.firstName && response?.lastName
                                    ? `${response.firstName} ${response.lastName}`
                                    : response?.email || ''
                                }
                              />
                            </div>

                            <CustomColorIcon
                              color="#D9D9D9"
                              width="8px"
                              height={'8px'}
                              margin={5}
                            />

                            <Body2 kind="textBody" className="date-body">
                              {dayjs(response.createdAt).format('DD MMM. YYYY')}
                            </Body2>
                          </FlexRow>
                          <Body1 className="my-3">{response.response}</Body1>
                        </div>
                      ))
                    ) : (
                      <div>
                        <Body1 kind="textBody">Hmm, no answers found.</Body1>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
