import {IEmoji, emojiList} from '@ui/assets/emojis';
import {Button} from '@ui/atoms/button';
import {Image} from '@ui/atoms/image';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, CTA} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {TextField} from '@ui/molecules/field/textfield';
import {AnalyticsSection} from '@ui/molecules/home-analytics-section';
import {AnalyticsSectionNavProps} from '@ui/molecules/home-analytics-section/analytics-section';
import {FlexRowCenter} from '@ui/style/styles';
import {getColorFromTheme} from '@ui/ui-utils';
import {FC, memo, useState} from 'react';
import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {ItemLoader} from '../item-loader';
import {NextSubmission} from './next-submission';
import {PulseSummary} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-checkins/pulse-summary';

export const MoodDiv = styled.div<{
  activated?: boolean;
  disabled?: boolean;
  error?: boolean;
}>`
 
  div.titleDiv {
    padding: 18px 24px 16px;
  }

  div.contentDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 2px;
   
    ${down('sm')} {
      padding: 24px 3px;
    }
 .inner-content {
      width: 65%;

      ${down('lg')} {
        width: 75%;
      }
      ${down('md')} {
        width: 100%;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      .emoji {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
       
        .halo {
          padding: 4px;
           border-radius: 50%;
      
         
          &:hover {
            background: ${({disabled}) =>
              disabled ? '' : getColorFromTheme('purple300')};
        
          };
         
          .img {
            width: 56px;
          height: 56px;
          ${down('sm')} {
         width: 46px;
          height: 46px;
          }

            img {
          width: 56px;
          height: 56px;
           ${down('sm')} {
         width: 46px;
          height: 46px;
      }
        }


          }
        }
      }
    }
    .second-inner-content {
      width: 58.8%;
      ${down('md')} {
        width: 100%;
      }
      }
    }
  }

   
  div.footerDiv {
    display: flex;
    align-items: center;
    padding: 12px 40px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

interface MeetingAnalyticsProps extends AnalyticsSectionNavProps {
  isPulseSubmitted: boolean;
  dueDay: number;
  showSummary?: boolean;
  isSubmitting?: boolean;
  onSubmit?: (data: {mood: string; feedback?: string}) => void;
  summary?: any;
}

export const PulseCard: FC<MeetingAnalyticsProps> = memo(
  ({
    isPulseSubmitted,
    dueDay,
    onSubmit,
    isLoading,
    showSummary,
    isSubmitting,
    summary,
    ...rest
  }) => {
    const [mood, setMood] = useState<null | string>('');

    const [feedback, setFeedback] = useState('');

    const moods = [
      {
        emoji: emojiList.drained,
        text: 'Drained',
      },
      {
        emoji: emojiList.sad,
        text: 'Sad',
      },
      {
        emoji: emojiList.meh,
        text: 'Meh!',
      },
      {
        emoji: emojiList.happy,
        text: 'Happy',
      },
      {
        emoji: emojiList.elated,
        text: 'Elated',
      },
    ];

    const handleSubmit = () => {
      if (mood) onSubmit && onSubmit({mood, feedback});
    };
    const handleMood = (mood_: string, pulseId: string) => {
      setMood(mood_.toLowerCase());
    };

    return (
      <AnalyticsSection title="Pulse" {...rest}>
        {showSummary && !isLoading && (
          <div className="">
            <PulseSummary
              view="home"
              pulseSubmissionCount={{
                count: summary?.submittedPulse,
                percent: summary?.submittedPulsePercent,
              }}
              avgPulse={summary?.averagePulse}
            />
          </div>
        )}
        {!showSummary && isPulseSubmitted && !isLoading && (
          <NextSubmission dueDay={dueDay} />
        )}
        {isLoading && (
          <FlexRowCenter>
            <ItemLoader />
          </FlexRowCenter>
        )}
        {!showSummary && !isPulseSubmitted && !isLoading && (
          <SectionCard
            CustomHeaderTitle={
              <div>
                <Body1 kind="textDark" weight="bold">
                  Taking a quick mood check! How are you feeling?
                </Body1>
              </div>
            }>
            <MoodDiv>
              <div className="contentDiv">
                <div className="inner-content">
                  {moods.map((mood__: {emoji: IEmoji; text: string}, index) => (
                    <div
                      key={index + mood__.text}
                      className="emoji"
                      onClick={() => handleMood(mood__.text, '')}>
                      <div
                        className="halo"
                        style={{
                          background:
                            mood !== null && mood__.text.toLowerCase() === mood
                              ? '#585ADF'
                              : undefined,
                        }}>
                        <div className="img">
                          <Image {...mood__.emoji} />
                        </div>
                      </div>
                      <CTA kind="textMedium">{mood__.text}</CTA>
                    </div>
                  ))}
                </div>
                {mood && (
                  <>
                    <VerticalSpacer size="24px" />
                    <div className="second-inner-content">
                      <TextField
                        optional
                        margin
                        placeholder="Share you more information about your mood here."
                        label={`${
                          ['elated', 'happy'].includes(mood as any)
                            ? 'Yay 🎉 '
                            : ''
                        }What made you ${mood} this week?`}
                        onChange={(e) => {
                          setFeedback(e.target.value);
                        }}
                        name="pulse.feedback"
                        key="feedback"
                        type="text"
                      />
                      <Button
                        className="mt-4"
                        isLoading={isSubmitting}
                        width="full"
                        onClick={handleSubmit}>
                        Submit
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </MoodDiv>
          </SectionCard>
        )}
      </AnalyticsSection>
    );
  },
);
