import {Body2, Headline2} from '@ui/atoms/typography';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {FC, memo, ReactNode} from 'react';
import {Insight} from '../insights/interface';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ArrowDownIcon} from '@ui/atoms/icons';
import {ArrowPointingUpIcon} from '@ui/atoms/icons/arrow-pointing-up';
import styled from 'styled-components';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {getInitialAdditionalFilters} from '../insights/edit-insight/edit-insight-filter';
import {VerticalInsightConfig} from './insight-config';

interface InsightProps extends Insight {
  showRangeType?: boolean;
  percentProgress?: number;
  showPercentProgress?: boolean;
  handleEditLayout?: (id: string) => void;
  value: number | string | ReactNode;
}

export const InsightCard: FC<InsightProps> = memo(
  ({
    config,
    name,
    value = 0,
    percentProgress,
    showPercentProgress = false,
    showRangeType,
    handleEditLayout,
    ...rest
  }) => {
    const additionalFilters = getInitialAdditionalFilters(
      config.list,
      config.users,
    );

    return (
      <>
        <div className="bg-white flex flex-col items-start shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] py-6 px-4 border h-full rounded-[10px] border-borderLight  min-h-[150px]">
          {showRangeType && (
            <>
              <VerticalInsightConfig
                config={config}
                additionalFilters={additionalFilters}
              />
              <VerticalSpacer size="16px" />
            </>
          )}
          {typeof value === 'string' || typeof value === 'number' ? (
            <Headline2>{value}</Headline2>
          ) : (
            value
          )}

          <Body2 kind="textBody" className="mt-4">
            {name}
          </Body2>
          <FlexRowSpaceBetween className="mt-6">
            <FlexRow>
              {showPercentProgress ? (
                <PercentageProgress value={percentProgress} />
              ) : null}
            </FlexRow>

            {showRangeType && (
              <button
                onClick={() => {
                  if (rest.firebaseId && handleEditLayout)
                    handleEditLayout(rest.firebaseId);
                }}>
                <HoverTooltip tooltipText="Edit insight">
                  <CandleVIcon />
                </HoverTooltip>
              </button>
            )}
          </FlexRowSpaceBetween>
        </div>
      </>
    );
  },
);

const PercentageChangeWrapper = styled.div<{color: string}>`
  margin-left: 2px;

  svg {
    stroke: ${({color}) => color};
    width: 16px;
    height: 16px;
  }
`;

export const PercentageProgress = ({value}: {value?: number | string}) => {
  if (Number(value) >= 0) {
    return (
      <FlexRow>
        <Body2 weight="semibold" kind={'green300'}>
          +{value}%
        </Body2>
        <PercentageChangeWrapper color="#47B881">
          <ArrowPointingUpIcon />
        </PercentageChangeWrapper>
      </FlexRow>
    );
  } else if (Number(value) < 0) {
    return (
      <FlexRow>
        <Body2 weight="semibold" kind={'red400'}>
          {value}%
        </Body2>
        <PercentageChangeWrapper color="#D52A2A">
          <ArrowDownIcon />
        </PercentageChangeWrapper>
      </FlexRow>
    );
  }
  return (
    <FlexRow>
      <Body2 weight="semibold" kind="textMuted">
        -
      </Body2>
    </FlexRow>
  );
};
