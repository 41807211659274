import {IAPIUserFormat, IGroup} from '@hooks';
import dayjs from 'dayjs';
import {userName} from './user-name';

interface Task {
  assignee?: string;
  createdBy?: string;
  user?: string;
  completed?: boolean;
  dueDate?: string;
}

// Utility function to get action items for a user
export const getUserActionItems = (userId: string, tasks: Task[]): Task[] => {
  return tasks.filter((task) =>
    [task?.assignee, task?.createdBy, task?.user].includes(userId),
  );
};

// Utility function to get task stats for a user or group
export const getTaskStats = (tasks: Task[], userId?: string) => {
  const completedOnDueDateActionItems =
    tasks.filter((task) => task?.completed && dayjs(task.dueDate).isValid())
      .length || 0;

  return {
    assigned: tasks.filter((task) => task.assignee === userId).length,
    active: tasks.filter((task) => !task.completed).length,
    completionRate: Math.round(
      (completedOnDueDateActionItems / tasks.length) * 100,
    ),
    completedonDueDate: completedOnDueDateActionItems,

    total: tasks.length,
    overdue: tasks.filter((task) => dayjs().isAfter(dayjs(task?.dueDate)))
      .length,
  };
};

// Function to get stats for managers
export const getManagerStats = (
  managers: IAPIUserFormat[],
  users: IAPIUserFormat[],
  tasks: Task[],
) => {
  return managers.map((manager) => {
    const directReports = users.filter(
      (user) => user.reviewer?.id === manager.id,
    );

    let managerDirectReportTasks: Task[] = [];

    directReports.forEach((directReport) => {
      const userActionItems = getUserActionItems(directReport.id, tasks);
      managerDirectReportTasks.push(...userActionItems);
    });

    return {
      name: userName(manager),
      avatar: manager?.avatar?.url || '',
      ...getTaskStats(managerDirectReportTasks, manager.id),
    };
  });
};

// Function to get stats for groups
export const getGroupStats = (
  groups: IGroup[],
  users: IAPIUserFormat[],
  tasks: Task[],
) => {
  return groups.map((group) => {
    const groupUsers = users.filter((user) =>
      user.groups?.some((_group) => group.id === _group.group),
    );

    let groupTasks: Task[] = [];

    groupUsers.forEach((user) => {
      const userActionItems = getUserActionItems(user.id, tasks);
      groupTasks.push(...userActionItems);
    });

    return {
      name: group.name,
      avatar: '',
      ...getTaskStats(groupTasks),
    };
  });
};
