import styled from 'styled-components';
import {down, up} from 'styled-breakpoints';
import {ReactNode} from 'react';
import {VerticalSpacer} from '../../atoms/spacer';
import styledMap from 'styled-map';

const Wrapper = styled.section<{variant?: string}>`
  display: grid;
  grid-template-columns: ${styledMap('variant', {
    youcheckins: '18% 82%',
    goals: '15% 87%',
    reporting: '24% 75%',
    default: '17% 80%',
  })};

  width: 100%;

  grid-column-gap: ${styledMap('variant', {
    youcheckins: '24px',
    reporting: '24px',
    goals: '20px',
    default: '34px',
  })};

  div {
    ${up('md')} {
      &::-webkit-scrollbar {
        width: 2em;
        border-top: 1px solid #ededf2;
        height: 2.1em;
      }

      &::-webkit-scrollbar-track {
        background: #f6f8fa;
        border: 0.8em solid rgba(0, 0, 0, 0);
        background-clip: padding-box;

        -webkit-border-radius: 1.5em;

        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        height: 10em;
        border: 0.8em solid rgba(0, 0, 0, 0);
        background: #e5ebf1;
        border-radius: 10px;
        background-clip: padding-box;
        -webkit-border-radius: 1.5em;

        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }
  }
  ${down('sm')} {
    display: block;
  }
`;

export interface TPageSideLayoutProps {
  content: ReactNode;
  navigation: ReactNode;
  variant?: string;
  className?: string;
  emptyTreeGoalState?: boolean;
  sideNavSecondLevelItem?: ReactNode;
  sideNavSecondLevelItemSpacing?: string;
}

export const PageSideLayout = function ({
  content,
  navigation,
  className,
  variant,
  emptyTreeGoalState,
  sideNavSecondLevelItem,
  sideNavSecondLevelItemSpacing,
}: TPageSideLayoutProps) {
  return (
    <Wrapper variant={variant} className={className}>
      <div className="h-full ">
        <div>{navigation}</div>
        {!!sideNavSecondLevelItem && (
          <div>
            {!!sideNavSecondLevelItemSpacing && (
              <VerticalSpacer size={sideNavSecondLevelItemSpacing} />
            )}
            {sideNavSecondLevelItem}
          </div>
        )}
      </div>
      <div
        style={
          variant === 'tree' && !emptyTreeGoalState
            ? {overflowX: 'scroll'}
            : variant === 'tree' && emptyTreeGoalState
            ? {}
            : {}
        }>
        {content}
      </div>
    </Wrapper>
  );
};
