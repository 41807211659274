import {ListGoals} from '@utils/goal-owners';
import {SecondWrapperChildGoals} from './analytics-status.styles';
import {Avatars} from '../../../../../../../ui/molecules/avatars';
import {StatusType} from '@hooks';
import {goalDueDate} from '../../../../../../../utils/date';
import {useViewInsight} from '@hooks/insights';
import {FlexRow} from '@ui/style/styles';
import dayjs from 'dayjs';
import {GoalViewInsights} from '@ui/atoms/view-icon/view-icon';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {EditableGoalTitle} from '../../../../../../../ui/molecules/goal/editable-title';
import {Body2, CTA} from '@ui/atoms/typography';
import {UserListCard} from '@ui/molecules/user/list-card';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {observer} from 'mobx-react';

export const ObjectiveAnalytics = observer(
  ({
    goal,
    open,
    setOpen,
    showProgress,
    style,
    groupBy,
    removeInsight,
    removeAvatar,
  }: any) => {
    const viewGoalInsight = useViewInsight();

    const isGoalEnded =
      goal.status === StatusType.COMPLETED ||
      goal.status === StatusType.INCOMPLETED;
    const goalIsPastDue = goalDueDate(goal.endDate) === 'Past due';

    const formatGoalDateRange =
      dayjs(goal.startDate).format('DD. MMM YY') +
      '  -  ' +
      dayjs(goal.endDate).format('DD. MMM YY');

    const iconColor = () => {
      switch (goal.performance) {
        case 'on_track':
          return '#47B881';
        case 'no_status':
          return '#5F5F8C';
        case 'at_risk':
          return '#D52A2A';
        case 'behind':
          return '#E6821D';

        default:
          return '';
      }
    };
    const goalIsKpi = goal.isKpi;

    const goalIsBrokenDown = ['group', 'team'].includes(groupBy);

    return (
      <div style={{display: 'flex'}}>
        <SecondWrapperChildGoals
          removeBorder={open}
          style={style}
          groupBy={groupBy}
          includeProgress={showProgress}>
          <div>
            {goalIsBrokenDown ? (
              <Body2 weight="semibold">{goal?.firstName}</Body2>
            ) : (
              <EditableGoalTitle
                // kind="admin"
                title={goal.name}
                goalGroups={goal.group}
                goalType={
                  goal.goalType === 'group'
                    ? `-`
                    : goal.goalType === 'company'
                    ? 'Company-wide'
                    : goal.goalType
                }
                weight={500}
                lineHeight={'20px'}
                subTitle={
                  <FlexRow style={{marginLeft: '8px', marginTop: '3px'}}>
                    {(isGoalEnded || goalIsPastDue) && (
                      <FlexRow>
                        <Body2
                          kind={
                            goalIsPastDue && !isGoalEnded
                              ? 'red400'
                              : 'textBody'
                          }
                          weight="regular"
                          style={{fontSize: '10px'}}>
                          {isGoalEnded ? 'Ended' : 'Past due'}{' '}
                        </Body2>
                        <HorizontalSideRule size={'15px'} />
                      </FlexRow>
                    )}
                    <Body2
                      kind="textBody"
                      style={{fontSize: '10px'}}
                      weight="regular">
                      {formatGoalDateRange}
                    </Body2>
                  </FlexRow>
                }
                hasKeyResults={false}
                isOpen={open}
                onClick={() => {
                  setOpen(open === goal.id ? '' : goal.id);
                }}
              />
            )}
          </div>
          {goalIsBrokenDown ? (
            <UserListCard
              tooltip={true}
              userId={goal.groupAdmin?.id || goal.manager?.id}
              type="secondary"
              reviewer={''}
              avatar={
                goal?.groupAdmin?.avatar?.url || goal.manager?.avatar?.url
              }
              name={
                goal.groupAdmin?.firstName ||
                goal.manager?.firstName +
                  ' ' +
                  (goal?.groupAdmin?.lastName || goal.manager?.lastName) ||
                ''
              }
              TextComponent={CTA}
              textKind="textDark"
              tooltipText
            />
          ) : (
            !removeAvatar && (
              <div className="tooltip_container">
                <div>
                  {!!goal?.assignee && (
                    <Avatars
                      items={ListGoals(goal)}
                      total={
                        goal.keyResults?.length > 0 ? ListGoals(goal).length : 1
                      }
                    />
                  )}
                </div>
              </div>
            )
          )}

          {!goalIsBrokenDown && (
            <>
              {!removeInsight && (
                <div className=" w-fit">
                  <GoalViewInsights
                    onClick={() => viewGoalInsight(goal.id)}
                    onHover
                  />
                </div>
              )}
              {showProgress && (
                <div className="mr-9 flex items-center ">
                  <CustomColorIcon
                    color={goal.status === 'past_due' ? '#5f5f8c' : iconColor()}
                    margin={8}
                    width="10px"
                    height="10px"
                  />
                  <Body2 weight="semibold">
                    {goalIsKpi
                      ? goal.currentValue
                      : `${goal.percentageProgress}%`}
                  </Body2>
                </div>
              )}
            </>
          )}
          {goalIsBrokenDown && (
            <div className="mr-9">
              <Body2 weight="semibold">{goal.fulfilment}%</Body2>
            </div>
          )}
        </SecondWrapperChildGoals>
      </div>
    );
  },
);
