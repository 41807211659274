import {useMemo, useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router';
import {
  IGoalsFrameworkData,
  WorkspaceFormData,
} from './admin-workspace-interface';
import {
  GoalsFrameworkValidator,
  WorkspaceValidator,
} from './admin-workspace-validator';
import {generateRandomDigits} from '@utils/generate-random-numbers';

import {WorkspaceController} from './admin-workspace-controller';
import {WorkspaceRequestImpl} from './admin-workspace-request';
import {useStoreContext} from '../../../../../store/store-context';
import {resizeFile} from '../../../../../utils/compress-images';
import {BaseURL} from '../../../../../configs/request';
import {authStore} from '../../../../../store/stores/auth-store';
import {usersStore} from '../../../../../store/stores/users-store';
import {activateNotification} from '../../../../../ui/molecules/notification/activate-notification';
import {SetupWorkspaceRequestImpl} from '../../../../admin/setup-workspace/setup-workspace-request';
import {SetupWorkspaceController} from '../../../../admin/setup-workspace/setup-workspace-controller';
import {WorkspaceUseCase} from '@hooks';
import {capitalize} from '@utils';
import {setFirebaseData} from '@utils/firebase-handler';

export function useAdminWorkspaceHook() {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
  } = useForm<WorkspaceFormData>({
    resolver: yupResolver(WorkspaceValidator) as Resolver<
      WorkspaceFormData,
      object
    >,
    mode: 'onSubmit',
  });

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const hasError = useMemo(() => {
    return formState.submitCount > 0 && formState.errors;
  }, [formState.errors, formState.submitCount]);

  const handleSelectChange = (value: string) => {
    setValue('timezone', value);
  };

  const {
    authStore: {
      setInitResponseAsAuth,
      setFrameworkInAuth,
      setNameInAuth,
      featureLimitStatus,
      setWorkspaceNameInStore,
      auth,
    },
    usersStore: {getUsers},
  } = useStoreContext();
  const request = new WorkspaceRequestImpl();
  const controller = new WorkspaceController(request);
  const navigate = useNavigate();
  const {search} = useLocation();
  const accessToken = search.slice(search.indexOf('=') + 1);

  const submitForm = async (data: WorkspaceFormData) => {
    await controller.newWorkspace(data, accessToken);
    setWorkspaceNameInStore(data.name);
    if (localStorage && localStorage.auth && !!localStorage.auth) {
      const storedAuthObject = JSON.parse(localStorage.auth);
      storedAuthObject.user.workspace.name = data.name;
      localStorage.setItem('auth', JSON.stringify(storedAuthObject));
    }
    setInitResponseAsAuth();
    navigate('/', {replace: true});
    navigate('/workspace-settings/profile', {replace: true});
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const {name, timezone, contactEmail} = watch();

  const [image, setImage] = useState({preview: '', raw: '', disable: false});

  const [base64Upload, setbase64Upload] = useState<any | null>(null);

  const handleChange = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await resizeFile(file);
    setbase64Upload(base64);
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        disable: true,
      });
    }
  };

  // Fetch Users
  const getUsersFunc = useCallback(async () => await getUsers(), [getUsers]);
  useEffect(() => {
    getUsersFunc();
  }, [getUsersFunc]);

  //fetch workspace data

  const [users, setUsers] = useState({
    Name: '',
    Timezone: '',
    email: '',
    avatar: '',
  });

  useMemo(() => {
    (name && name !== users.Name) ||
    (timezone && timezone !== users.Timezone) ||
    (contactEmail && contactEmail !== users.email)
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [name, timezone, contactEmail, users.Name, users.Timezone, users.email]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${BaseURL}/workspaces/${authStore.auth?.user.workspace.id}`, {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      })
      .then((response) => {
        let userData = response.data;
        setUsers({
          Name: userData.name,
          Timezone: userData.timezone,
          email: userData.contactEmail,
          avatar: userData.avatar ? userData.avatar.url : '',
        });
        setIsLoading(true);
      })
      .catch((error) => {
        setIsLoading(true);
        activateNotification({
          title: 'Error',
          content: 'failed to fetch, please reload',
          kind: 'error',
        });
      });
  }, []);

  let noOfMembers = usersStore.confirmedUsers.totalResults - 1;

  useEffect(() => {
    usersStore.getConfirmedUsers();
  }, []);

  // Admin Goals Framework
  const [activeGoalsFrameworkTab, setActiveGoalsFrameworkTab] = useState<
    'name' | 'framework' | 'import' | ''
  >('');
  const [selectedFramework, setSelectedFramework] = useState<
    string | undefined
  >(auth?.user?.workspace?.framework);

  const handleGoalsFrameworkTabSwitch = (
    tab: 'name' | 'framework' | 'import' | '',
  ) => {
    if (activeGoalsFrameworkTab === tab) {
      setActiveGoalsFrameworkTab('');
    } else {
      setActiveGoalsFrameworkTab(tab);
    }
  };
  const handleKpiSelection = async (allowKpi: boolean) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        config: {
          ...auth?.user?.workspace?.config,
          allowKpi: allowKpi,
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      allowKpi &&
      activateNotification({
        title: 'KPI enabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response &&
      !allowKpi &&
      activateNotification({
        title: 'KPI disabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const handleUseCaseUpdate = async (
    type: 'performance' | 'engagement',
    value: boolean,
  ) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const computeUseCaseValue = () => {
      if (auth?.user?.workspace?.settings.useCase === WorkspaceUseCase.ALL) {
        if (type === 'performance' && !value) return 'engagement';
        if (type === 'engagement' && !value) return 'performance';
        return 'all';
      }

      if (
        auth?.user?.workspace?.settings.useCase === WorkspaceUseCase.ENGAGEMENT
      ) {
        if (type === 'performance' && value) return 'all';
        return '';
      }
      if (
        auth?.user?.workspace?.settings.useCase === WorkspaceUseCase.PERFORMANCE
      ) {
        if (type === 'engagement' && value) return 'all';
        return '';
      }
      return 'all';
    };

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          goalStatus: undefined,
          useCase: computeUseCaseValue(),
        },
      },
      auth?.tokens?.access?.token,
    );

    response &&
      activateNotification({
        title: `${capitalize(type)} ${value ? 'enabled' : 'disabled'} `,
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const handleAlignmentSelection = async (enableAlignment: boolean) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          goalAlignment: enableAlignment,
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      enableAlignment &&
      activateNotification({
        title: 'Goal alignment enabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response &&
      !enableAlignment &&
      activateNotification({
        title: 'Goal alignment disabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };
  const handleCascadingSelection = async (enableCascading: boolean) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          keyResultCascading: enableCascading,
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      enableCascading &&
      activateNotification({
        title: 'Key result cascading enabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response &&
      !enableCascading &&
      activateNotification({
        title: 'Key result cascading disabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const handleAutoEndGoal = async (autoEndGoals: boolean) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          autoEndGoals: autoEndGoals ? 'enabled' : 'disabled',
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      autoEndGoals &&
      activateNotification({
        title: 'Goal auto-end enabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response &&
      !autoEndGoals &&
      activateNotification({
        title: 'Goal auto-clean disabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };
  const handleOneOnOneMeetings = async (enabled: boolean) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          oneOnOne: enabled ? 'enabled' : 'disabled',
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      enabled &&
      activateNotification({
        title: '1:1 meetings enabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response &&
      !enabled &&
      activateNotification({
        title: '1:1 meetings disabled',
        content: `Settings updated`,
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };
  const [editMemberGoal, setEditMemberGoal] = useState(false);
  const [editMemberGoalLoader, setEditMemberGoalLoader] = useState(false);

  const handleCanMemberEdit = async (value: string) => {
    const request = new SetupWorkspaceRequestImpl();
    const controller = new SetupWorkspaceController(request);
    setEditMemberGoalLoader(true);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          allowGoalUpdate: value,
        },
      },
      auth?.tokens?.access?.token,
    );
    response &&
      activateNotification({
        title: '',
        content: `Settings updated`,
        kind: 'success',
      });
    setEditMemberGoalLoader(false);
    setEditMemberGoal(false);
    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));
  };

  const [displayNames, setDisplayNames] = useState({
    goal: '',
    keyResults: '',
  });
  const handleDisplayGoal = (value: string) => {
    setDisplayNames({...displayNames, goal: value});
  };

  const handleDisplayKeyResults = (value: any) => {
    setDisplayNames({...displayNames, keyResults: value});
  };
  const {
    errors: errorsGoalFramework,
    setValue: setValueGoalsFramework,
    control: controlGoalsFramework,
  } = useForm<IGoalsFrameworkData>({
    resolver: yupResolver(GoalsFrameworkValidator) as Resolver<
      IGoalsFrameworkData,
      object
    >,
    mode: 'onBlur',
    defaultValues: {
      framework: selectedFramework,
    },
  });
  const frameworks = [
    {label: 'OKRs', value: 'okr'},
    {
      label: 'Focus areas & KPIs',
      value: 'kpi',
    },
  ];
  const getGoalFrameworkLabel = (frameworkValue: any) => {
    switch (frameworkValue) {
      case 'okr':
        return 'OKRs';
      case 'kpi':
        return 'Focus areas & KPIs';
      default:
        return 'OKRs';
    }
  };
  const getGoalFrameworkModalText = (frameworkValue: any) => {
    switch (frameworkValue) {
      case 'okr':
        return 'You’re about to change your workspace goal framework to Objectives & Key results (OKRs). This will remove the good and stretch KPI targets of all goals. Base KPIs will become target value for key results. ';
      case 'kpi':
        return 'You’re about to change your workspace goal framework to Focus areas and KPIs. This will add base, good and stretch KPI targets. Target value of key results will become base KPIs. ';
      default:
        return 'You’re about to change your workspace goal framework to Objectives & Key results (OKRs). This will remove the good and stretch KPI targets of all goals. Base KPIs will become target value for key results. ';
    }
  };

  const [
    goalFrameworkModalIsOpen,
    setGoalFrameworkModalIsOpen,
  ] = useState<boolean>(false);
  const [displayNamesModal, setDisplayNamesModal] = useState(false);
  const [modalData, setModalData] = useState<{framework?: string}>({});
  const [loader, setLoader] = useState<any>({});
  const handleSelectFramework = (data: {label: string; value: string}) => {
    if (data?.value) {
      setModalData({...modalData, framework: data?.value});
      setValueGoalsFramework('framework', data?.value);
      setSelectedFramework(data?.value);
    }
  };
  const handleClickSave = () => {
    if (activeGoalsFrameworkTab === 'name') {
      setDisplayNamesModal(true);
    } else {
      setGoalFrameworkModalIsOpen(true);
    }
  };
  const handleConfirmModal = async () => {
    setGoalFrameworkModalIsOpen(false);
    await submitSelectedFramework();
  };
  const handleConfirmNameModal = async () => {
    setDisplayNamesModal(false);
    await submitDisplayName();
  };

  const closeGoalFrameworkModal = () => {
    setGoalFrameworkModalIsOpen(false);
    setSelectedFramework(selectedFramework);
  };
  const closeGoalNameModal = () => {
    setDisplayNamesModal(false);
  };

  const selectFrameworkRequest = new SetupWorkspaceRequestImpl();
  const selectFrameworkController = new SetupWorkspaceController(
    selectFrameworkRequest,
  );
  const submitDisplayName = async () => {
    try {
      const id = auth?.user?.workspace?.id;
      const response = await selectFrameworkController.workspaceEdit(
        id,
        {
          config: {
            goalName: displayNames.goal,
            behaviour: 'custom',
            keyResultName: displayNames.keyResults,
          },
        },
        auth?.tokens?.access?.token,
      );
      if (response) {
        setLoader({...loader, displayName: false});
        if (localStorage && localStorage.auth && !!localStorage.auth) {
          const storedAuthObject = JSON.parse(localStorage.auth);
          storedAuthObject.user.workspace.config = {
            goalName: displayNames.goal,
            keyResultName: displayNames.keyResults,
          };
          localStorage.setItem('auth', JSON.stringify(storedAuthObject));
        }
        setNameInAuth(displayNames.goal, displayNames.keyResults);
        activateNotification({
          title: 'Display name changed',
          content: `Your workspace name has been successfully changed.`,
          kind: 'success',
        });
        setFirebaseData(`updates/settings`, generateRandomDigits(16));

        navigate('/', {replace: true});
        navigate('../workspace-settings', {replace: true});
      }
    } catch (e) {
      setLoader({...loader, displayName: false});
    }
  };
  const submitSelectedFramework = async () => {
    try {
      setLoader({...loader, goalFramework: true});
      const id = auth?.user?.workspace?.id;
      const response: any = await selectFrameworkController.workspaceEdit(
        id,
        {framework: selectedFramework},
        auth?.tokens?.access?.token,
        true,
      );
      if (response) {
        setLoader({...loader, goalFramework: false});
        if (localStorage && localStorage.auth && !!localStorage.auth) {
          const storedAuthObject = JSON.parse(localStorage.auth);
          storedAuthObject.user.workspace.framework = selectedFramework;

          storedAuthObject.user.workspace.config = {
            goalName: response?.config?.goalName,
            keyResultName: response?.config?.keyResultName,
          };
          setNameInAuth(
            response?.config?.goalName,
            response?.config?.keyResultName,
          );

          localStorage.setItem('auth', JSON.stringify(storedAuthObject));
          setFrameworkInAuth(selectedFramework as any);
        }

        navigate('/', {replace: true});
        navigate('../workspace-settings', {replace: true});

        activateNotification({
          title: 'Success',
          content: `Framework has been successfully updated`,
          kind: 'success',
        });
      }
    } catch (e: any) {
      setLoader({...loader, goalFramework: false});
    }
  };
  const isGoalsFrameworkSaveDisabled =
    selectedFramework === auth?.user?.workspace?.framework;
  const nameSaveDisabled =
    displayNames.goal === '' || displayNames.keyResults === '';

  return {
    register,
    errors,
    isSubmitting,
    submitForm,
    handleSelectChange,
    handleSubmit,
    control,
    image,
    base64Upload,
    handleChange,
    hasError,
    isDisabled,
    users,
    isLoading,
    noOfMembers,
    activeGoalsFrameworkTab,
    setActiveGoalsFrameworkTab,
    handleGoalsFrameworkTabSwitch,
    submitDisplayName,
    controlGoalsFramework,
    errorsGoalFramework,
    setValueGoalsFramework,
    frameworks,
    auth,
    handleConfirmNameModal,
    handleAlignmentSelection,
    closeGoalNameModal,
    displayNamesModal,
    handleSelectFramework,
    goalFrameworkModalIsOpen,
    closeGoalFrameworkModal,
    editMemberGoal,
    setEditMemberGoal,
    editMemberGoalLoader,
    handleCascadingSelection,
    featureLimitStatus,
    handleDisplayKeyResults,
    handleDisplayGoal,
    handleCanMemberEdit,
    handleConfirmModal,
    nameSaveDisabled,
    submitSelectedFramework,
    isGoalsFrameworkSaveDisabled,
    handleAutoEndGoal,
    handleKpiSelection,
    loader,
    handleOneOnOneMeetings,
    modalData,
    getGoalFrameworkLabel,
    getGoalFrameworkModalText,
    handleClickSave,
    handleUseCaseUpdate,
  };
}
