import {Button} from '@ui/atoms/button';
import {CalendarIcon, CancelIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import {FlexRowEnd} from '@ui/style/styles';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

export const CalendarIconWrapper = styled.span`
  stroke: white;
`;

export const AddMeetingToCalender = observer(() => {
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(true);

  if (!showBanner) return null;

  return (
    <div className="border-t-4 border-[#585ADF] w-full   z-10 fixed pr-4">
      <FlexRowEnd>
        <div className="px-4 pt-2 pb-3 bg-[#585ADF] absolute top-[0px] right-28 sm:right-4 md:right-12 w-[380px] flex items-center justify-between column-6 rounded-b-2xl">
          <CalendarIconWrapper className="">
            <CalendarIcon />
          </CalendarIconWrapper>
          <Body2 weight="semibold" kind="white" className=" whitespace-nowrap">
            Sync with your calendar
          </Body2>
          <Button
            style={{padding: '4px 16px'}}
            kind="secondary"
            onClick={() => navigate('/apps')}>
            Connect
          </Button>
          <button
            onClick={() => {
              setShowBanner(false);
            }}>
            <CancelIcon color="white" />
          </button>
        </div>
      </FlexRowEnd>
    </div>
  );
});
