import {useStoreContext} from '@store/store-context';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {database} from '@utils/firebase-request';
import dayjs from 'dayjs';
import {ActionType} from '@api/action-items-api/action-items-request';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import {equalTo, onValue, orderByChild, query, ref} from 'firebase/database';

interface ActionItemsHookProps {
  itemId: string;
  source: string;
  userId: string;
  callback?: any;
  orderBy?: {
    key: string;
    value: string;
  };
}

export const useActionItems = ({
  itemId,
  source,
  userId,
  orderBy,
  callback,
}: ActionItemsHookProps) => {
  const {
    authStore,
    usersStore: {users},
  } = useStoreContext();

  const actionItemsPath = `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/action_items/`;

  const [firebaseTasks, setTasks] = useState<any>();

  const databaseRef = ref(database, actionItemsPath);

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    let tasks: any = {};

    const handleSnapshot = (snapshot: any) => {
      if (snapshot.exists()) {
        tasks = {...tasks, ...snapshot.val()};

        setTasks(tasks);
      } else {
        setTasks(tasks);
      }
    };

    const fetchFilteredData = (orderByField: string, equalToValue: any) => {
      const filteredQuery = query(
        databaseRef,
        orderByChild(orderByField),
        equalTo(equalToValue),
      );
      onValue(filteredQuery, handleSnapshot);
    };

    if (orderBy) {
      fetchFilteredData(orderBy.key, orderBy.value);
    }

    if (userId) {
      fetchFilteredData('assignee', userId);

      fetchFilteredData('user', userId);
    }

    setIsLoading(false);
  }, [databaseRef, orderBy, userId]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, userId]);

  const findField = (data: any[], key: string) => {
    return data.find((field) => field.key === key);
  };

  const computeActionItem = (data: any, removeSource?: boolean) => {
    const assignee = data?.assignee;
    const id = data?.id || data?.key || generateRandomDigits(24);

    const computedItem = {
      completed: data.completed,
      id,
      meta: removeSource
        ? null
        : data?.meta || findField(tasks, id)?.meta || null,
      text: data.text,
      source: removeSource
        ? ''
        : data.source || findField(tasks, id)?.source || source,
      completedAt:
        findField(tasks, id)?.completedAt || data.completed
          ? dayjs().format()
          : '',
      sourceId: removeSource
        ? ''
        : data.sourceId || findField(tasks, id)?.sourceId || itemId,
      section: data?.section ?? findField(tasks, id)?.section ?? '',
      updatedBy: data.updatedBy || authStore.auth.user.id,
      createdBy:
        findField(tasks, id)?.createdBy ||
        data.createdBy ||
        authStore.auth.user.id,
      order: data?.order || findField(tasks, data.key)?.order || null,
      dueDate: data?.dueDate || null,
      createdAt:
        findField(tasks, id)?.createdAt || data?.createdAt || dayjs().format(),
      user: data?.user || findField(tasks, id)?.userId || userId,
      assignee: typeof assignee === 'string' ? assignee : assignee?.id || null,
    };

    return computedItem;
  };

  const handleChange = async (
    data: ActionType[],
    action?: 're-sort' | 'user' | 'dueDate' | '' | 'remove' | 'remove-link',
    fieldId?: string,
    item?: ActionType,
  ) => {
    if (action === 're-sort') {
      const formattedData = data
        .filter((value) => !!value.text)
        .map((value, idx) => {
          const computedValue = computeActionItem({...value, order: idx + 1});

          const taskExists = findField(tasks, value.key)?.firebaseId;

          if (!!taskExists) {
            updateFirebaseData(`action_items/${taskExists}`, computedValue);
          } else {
            writeFirebaseData(`action_items`, computedValue);
          }

          return computedValue;
        });

      callback && callback(formattedData);
      return;
    }

    const taskExists = findField(tasks, fieldId || '')?.firebaseId;

    if (action === 'remove' && taskExists) {
      removeFirebaseData(`action_items/${taskExists}`);

      const allTasks = firebaseTasks;

      delete allTasks[taskExists];
      setTasks(allTasks);
      return;
    }

    if (!!item) {
      if (!!taskExists) {
        updateFirebaseData(
          `action_items/${taskExists}`,
          computeActionItem(item, action === 'remove-link'),
        );
      } else {
        writeFirebaseData(
          `action_items`,
          computeActionItem(item, action === 'remove-link'),
        );
      }
    }
  };

  const getFirebaseTasks = useCallback(() => {
    const convertAndFilterTasks = (tasks: any) => {
      if (!tasks) return [];
      if (!Array.isArray(tasks)) {
        return Object.entries(tasks).map(([firebaseId, task]: any) => ({
          ...task,
          firebaseId,
        }));
      }
      return tasks;
    };

    const convertedTasks = convertAndFilterTasks(firebaseTasks);

    const _computedTasks = convertedTasks
      .map((task) => ({
        ...task,
        assignee: users.find((user) => user.id === task.assignee),
        key: task.id || task._id || task.key || '',
      }))
      .sort((a, b) => a.order - b.order);

    return _computedTasks;
  }, [firebaseTasks, users]);

  const tasks = useMemo(() => {
    return getFirebaseTasks()?.length
      ? getFirebaseTasks()
      : [{text: '', completed: false}];
  }, [getFirebaseTasks]);

  return {
    handleChange,
    isLoading,
    tasks,
  };
};
