import {AnalyticsController} from '../../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {useCallback, useState} from 'react';
import {toJS as proxyToJS} from 'mobx';

import dayjs from 'dayjs';
import {useStoreContext} from '@store/store-context';
import {useQuery} from 'react-query';

interface Activity {
  endDate: string;
  prevValue?: number;
  percentageProgress?: number;
  performance?: 'on_track' | 'at_risk' | 'behind';
  isGoalEndDate?: boolean;
  isTodayDate?: boolean;
  [key: string]: any;
}

export function processActivityData(
  activities: Activity[],
  todayDate: string,
  goalEndDate?: string,
): Activity[] {
  const formattedGoalEndDate = dayjs(goalEndDate).format('MM/DD/YYYY');
  const formattedTodayDate = dayjs(todayDate).format();

  // Create a new array with all activities, goal end date, and today date
  const allDates = () => {
    const options = [
      ...activities,
      {endDate: formattedTodayDate, isTodayDate: true},
    ];
    if (goalEndDate) {
      options.push({endDate: formattedGoalEndDate, isGoalEndDate: true} as any);
    }
    return options;
  };

  // Sort the array by date
  const sortedDates = allDates().sort(
    (a, b) => dayjs(a.endDate).valueOf() - dayjs(b.endDate).valueOf(),
  );

  // Function to carry over values from the previous activity
  const carryOverValues = (index: number) => {
    if (index > 0) {
      const previousActivity: any = sortedDates[index - 1];

      return {
        prevValue: previousActivity.prevValue || previousActivity.currentValue,
        currentValue: previousActivity.currentValue,
        percentageProgress: previousActivity.percentageProgress,
      };
    }
    return {};
  };

  // Process goal end date and today date
  let lastRegularActivityIndex = -1;
  sortedDates.forEach((item, index) => {
    if (!item.isGoalEndDate && !item.isTodayDate) {
      lastRegularActivityIndex = index;
    }
    if (item.isTodayDate) {
      Object.assign(item, carryOverValues(lastRegularActivityIndex + 1));
    }
    if (item.isGoalEndDate) {
      Object.assign(item, {});
    }
  });

  // Remove any duplicate dates, keeping the one with more information
  const uniqueDates = sortedDates.reduce((acc: Activity[], current: any) => {
    const existingIndex = acc.findIndex(
      (item) =>
        dayjs(item.endDate).format() === dayjs(current.endDate).format(),
    );
    if (existingIndex === -1) {
      acc.push(current);
    } else {
      // Merge properties, prioritizing non-undefined values
      acc[existingIndex] = Object.entries(current).reduce(
        (merged, [key, value]) => {
          if (value !== undefined) {
            merged[key] = value;
          }
          return merged;
        },
        acc[existingIndex],
      );
    }
    return acc;
  }, []);

  return uniqueDates;
}
export const useFetchGoalById = () => {
  const {
    groupStore: {groups},
  } = useStoreContext();

  const [graphTimeRange, setGraphTimeRange] = useState('28');

  const formatGraphTimeRange = useCallback(() => {
    const startDate = dayjs()
      .subtract(Number(graphTimeRange), 'day')
      .format('YYYY-MM-DD');

    const endDate = dayjs().format('YYYY-MM-DD');
    return {startDate, endDate};
  }, [graphTimeRange]);

  const getGoalDetails = useCallback(
    async (id: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const response: any = await controller.fetchReportGoalById(
        id,
        graphTimeRange ? formatGraphTimeRange() : undefined,
      );

      if (response) {
        const includeGroups = async (goalsResponse: any) => {
          if (goalsResponse.goalAlignment) {
            const alignment: any = await controller.fetchReportGoalById(
              goalsResponse.goalAlignment.id,
            );

            goalsResponse.goalAlignment =
              alignment || goalsResponse.goalAlignment;
          }
          if (goalsResponse.goalType === 'group') {
            const group = goalsResponse.group.map((grp: string) => {
              return proxyToJS(groups.find((_group) => _group.id === grp));
            });

            return {
              ...goalsResponse,
              group: group,
            };
          } else {
            return {
              ...goalsResponse,
            };
          }
        };

        const unfurledData = await includeGroups(response);

        if (unfurledData) {
          const res = [
            ...processActivityData(
              unfurledData?.activities,
              dayjs().format(),
              !unfurledData?.isKpi
                ? dayjs(unfurledData?.endDate).format()
                : undefined,
            ),
          ];

          const keyResultActivities = unfurledData.keyResults.map((kr: any) => {
            return {
              ...kr,
              activities: [
                ...processActivityData(
                  kr.activities,
                  dayjs().format(),
                  dayjs(unfurledData?.endDate).format(),
                ),
              ],
            };
          });

          return {
            ...unfurledData,
            activities: res,
            keyResults: keyResultActivities,
          };
        }
      }
    },
    [formatGraphTimeRange, graphTimeRange, groups],
  );

  return {
    getGoalDetails,
    updateTimeRange: setGraphTimeRange,
    timeRange: graphTimeRange,
  };
};
export const useInsightHook = (goalId?: string) => {
  const {
    getGoalDetails,
    updateTimeRange: setGraphTimeRange,
    timeRange: graphTimeRange,
  } = useFetchGoalById();

  const {data, isLoading} = useQuery(
    ['report-goal', goalId, graphTimeRange],
    () => getGoalDetails(goalId || ''),
    {
      enabled: !!goalId,
    },
  );

  return {
    getGoalDetails,
    goal: data,
    updateTimeRange: setGraphTimeRange,
    timeRange: graphTimeRange,
    loading: isLoading,
  };
};
