import {yupResolver} from '@hookform/resolvers/yup';
import {CardElement} from '@stripe/react-stripe-js';
import {Token} from '@stripe/stripe-js/types/api';
import {
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
  StripeElements,
} from '@stripe/stripe-js/types/stripe-js';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Resolver, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router';
import {WorkspaceAPIController} from '../../../../../../api/workspaces-api/workspaces-api-controller';
import {WorkspaceAPIRequestImpl} from '../../../../../../api/workspaces-api/workspaces-api-request';
import {useStoreContext} from '../../../../../../store/store-context';
import {AdminBillingController} from './admin-billing.controller';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {
  IAdminBillingDetailsFormData,
  IAdminBillingFormData,
} from './admin-billing.interface';
import {AdminBillingRequestImpl} from './admin-billing.request';
import {
  AdminBillingBillingDetailsFormValidator,
  AdminBillingSubscriptionFormValidator,
} from './admin-billing.validator';
import {useQuery} from 'react-query';
import {setFirebaseData} from '@utils/firebase-handler';

export function useBillingHook() {
  const request = useMemo(() => new AdminBillingRequestImpl(), []);
  const controller = useMemo(() => new AdminBillingController(request), [
    request,
  ]);

  const {
    billingsStore: {
      setBillingPlans,
      setBillingSubscriptions,
      setLoader: setBillingsLoader,
      numSeats,
      selectedBillingPlan,
      chosenBillingPlanType,
      setChosenBillingPlanType,
      setSelectedBillingPlan,
      setBillingMethods,
      updateShowNonActiveBilling,
      setValidCoupons,
      setNextCycle,
    },
    storeDashboardRoutes: {activateWorkspaceTab},
    authStore: {setBillingInAuth, setFeatureLimitStatus, auth},
  } = useStoreContext();

  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');

  const [addressDetails, setAddressDetails] = useState({
    line1: '',
    country: '',
    state: '',
    city: '',
  });
  const [addressSaved, setSaveAddress] = useState(false);
  const updateAddressDetails = (
    field: keyof typeof addressDetails,
    value: string | null,
  ) => {
    setAddressDetails((prev) => ({...prev, [field]: value}));
  };

  // Billing Subscription Form
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
  } = useForm<IAdminBillingFormData>({
    resolver: yupResolver(AdminBillingSubscriptionFormValidator) as Resolver<
      IAdminBillingFormData,
      object
    >,
    mode: 'all',
  });

  // Stripe Billing Card Details Collection Form
  const {
    register: registerCard,
    handleSubmit: handleSubmitCard,
    errors: errorsCard,
    formState: formsStateCard,
    setValue: setValueCard,
    control: controlCard,
    watch: watchCard,
  } = useForm<IAdminBillingDetailsFormData>({
    resolver: yupResolver(AdminBillingBillingDetailsFormValidator) as Resolver<
      IAdminBillingDetailsFormData,
      object
    >,
    mode: 'all',
    defaultValues: {
      type: 'card',
      billing_details: {
        name: '',
        phone: '',
        email: '',
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postal_code: null,
          state: null,
        },
      },
    },
  });

  const isSubmittingCard = useMemo(() => {
    return formsStateCard.isSubmitting;
  }, [formsStateCard.isSubmitting]);

  const handleFormValueChangeCard = useCallback(
    (value: any, field: string) => {
      if (value) {
        setValueCard(field, value);
      } else {
        setValueCard(field, null);
      }
    },
    [setValueCard],
  );

  // ON MOUNT ===
  // Fetch Feature Limit Status
  const fetchFeatureLimitStatus = useCallback(async () => {
    const request = new WorkspaceAPIRequestImpl();
    const controller = new WorkspaceAPIController(request);
    const response: any = await controller.fetchFeatureLimitStatus();

    response && setFeatureLimitStatus(response);
  }, [setFeatureLimitStatus]);

  // Load Billing Plans
  const loadBillingPlans = useCallback(async () => {
    setBillingsLoader('billingPlans', true);
    const response = await controller.fetchAllBillingPlans();
    response && setBillingsLoader('billingPlans', false);
    response && setBillingPlans(response);
  }, [controller, setBillingPlans, setBillingsLoader]);

  useEffect(() => {
    loadBillingPlans();
  }, [loadBillingPlans]);

  // Load Billing Subscriptions
  const loadBillingSubscriptions = useCallback(async () => {
    setBillingsLoader('billingSubscriptions', true);
    const response = await controller.fetchAllBillingSubscriptions();
    response && setBillingsLoader('billingSubscriptions', false);
    response && setBillingSubscriptions(response);
  }, [controller, setBillingSubscriptions, setBillingsLoader]);

  useEffect(() => {
    loadBillingSubscriptions();
  }, [loadBillingSubscriptions]);

  // Load Billing Methods
  const loadBillingMethods = useCallback(async () => {
    setBillingsLoader('billingMethods', true);
    const response: any = await controller.getAllBillingMethods();
    response && setBillingsLoader('billingMethods', false);
    response && setBillingMethods(response);
    if (response) {
      const address = response?.data[0]?.billing_details.address;
      if (address?.line1 || address?.country)
        setAddressDetails({
          line1: address.line1 || '',
          city: address.city || '',
          country: address.country || '',
          state: address.state || '',
        });
      if (address?.country) {
        setSelectedCountry(address.country);
      }
      if (address?.state) setSelectedState(address.state);
      if (address?.country && address.state && address.line1) {
        setSaveAddress(true);
      }
    }
  }, [controller, setBillingMethods, setBillingsLoader]);

  useEffect(() => {
    loadBillingMethods();
  }, [loadBillingMethods]);

  // Load Valid Coupons -  Ensure to Uncomment after test
  const loadValidCoupons = useCallback(async () => {
    setBillingsLoader('validCoupons', true);
    const response = await controller.getAllValidCoupons();
    response && setBillingsLoader('validCoupons', false);
    response && setValidCoupons(response);
  }, [controller, setBillingsLoader, setValidCoupons]);

  useEffect(() => {
    loadValidCoupons();
  }, [loadValidCoupons]);

  // Fetch Next Cycle
  const fetchNextCycle = useCallback(async () => {
    setBillingsLoader('nextCycle', true);
    const response = await controller.fetchNextCycle();
    response && setBillingsLoader('nextCycle', false);
    response && setNextCycle(response);
  }, [controller, setBillingsLoader, setNextCycle]);

  const {refetch: nextCycleRefetch} = useQuery(['next-cycle'], () =>
    fetchNextCycle(),
  );

  // Validate coupon
  const [validCoupon, setValidCoupon] = useState<string>('');
  const [couponDiscount, setCouponDiscount] = useState<number>(0);
  const validateCoupon = useCallback(
    async (code: string) => {
      try {
        setBillingsLoader('validateCoupon', true);
        const response = await controller.validateCouponCode({
          code,
        });
        response && setBillingsLoader('validateCoupon', false);
        response && setValidCoupon(code);
        response && setCouponDiscount((response as any)?.percent_off);
      } catch (err: any) {
        setBillingsLoader('validateCoupon', false);
      }
    },
    [controller, setBillingsLoader],
  );

  // Choose a billing plan and route to reserve seats
  const chooseBillingPlan = (plan: string, type: string) => () => {
    setChosenBillingPlanType(plan);

    setSelectedBillingPlan(type === 'annually' ? 'yearly' : type);

    navigate(`/workspace-settings/billing-cycle`);
  };

  // Stripe payment method submission logic

  const [stripeError, setStripeError] = useState<undefined | null | string>(
    null,
  );
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<Token | null>(null);
  const [completedSubscription, setCompletedSubscription] = useState<boolean>(
    false,
  );

  const isTrialActive = auth.user.workspace.billing.activeTrial;

  const handleCreateBillingSubscription = async (billingPlan: string) => {
    const subscribeResponse = await controller.createBillingSubscription({
      seats: String(numSeats),
      plan: billingPlan,
      isTrial: false,
      coupon: validCoupon?.length > 0 ? validCoupon : undefined,
    });

    setCompletedSubscription(true);
    if (localStorage?.auth && !!subscribeResponse) {
      const newAuth = {
        ...JSON.parse(localStorage?.auth),
        user: {
          ...JSON.parse(localStorage?.auth)?.user,
          workspace: {
            ...JSON.parse(localStorage?.auth)?.user?.workspace,
            billing: subscribeResponse,
          },
        },
      };
      localStorage.setItem('auth', JSON.stringify(newAuth));
      setBillingInAuth(subscribeResponse);
    }

    if (!!subscribeResponse) {
      navigate('/workspace-settings/billing');

      activateWorkspaceTab('billing');

      setFirebaseData(`updates/settings`, generateRandomDigits(16));

      updateShowNonActiveBilling(false);
      fetchFeatureLimitStatus();
    }
    setCompletedSubscription(true);
  };

  const handleSubmitPaymentMethod = (
    stripe: Stripe | null,
    elements: StripeElements | null,
    cardExists?: boolean,
  ) => async (data: any) => {
    // const {...billingDetails} = watchCard();

    if (!stripe || !elements) {
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const billing_details = {
      address_line1: addressDetails.line1,
      address_city: addressDetails.city,
      address_state: addressDetails.state,
      address_country: addressDetails.country,
      email: auth.user.email,
    };

    const billingPlanTypePrefix = (plan: string) => {
      switch (plan) {
        case 'growth':
          return '';

        default:
          return 'standard_';
      }
    };

    const planType = selectedBillingPlan === 'monthly' ? 'monthly' : 'yearly';

    const billingPlan = billingPlanTypePrefix(chosenBillingPlanType) + planType;

    if (cardExists) {
      return handleCreateBillingSubscription(billingPlan);
    }

    const payload = await stripe.createToken(
      elements.getElement(CardElement) as
        | StripeCardElement
        | StripeCardNumberElement,
      billing_details,
    );

    if (payload.error) {
      setStripeError(payload.error.message);
    } else {
      setPaymentMethod(payload.token);
    }

    if (payload && payload?.token?.id) {
      const response = await controller.createBillingMethod(
        {
          paymentId: payload?.token?.id,
        },
        true,
      );

      if (response) {
        if (!isTrialActive) {
          handleCreateBillingSubscription(billingPlan);
        }
      }
    }
  };

  const [updatingCard, setUpdatingCard] = useState(false);

  const handleUpdatePaymentMethod = async (
    stripe: Stripe | null,
    elements: StripeElements | null,
    onClose?: () => void,
  ) => {
    // const {...billingDetails} = watchCard();

    setUpdatingCard(true);

    if (!stripe || !elements) {
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }
    const billing_details = {
      address_line1: addressDetails.line1,
      address_city: addressDetails.city,
      address_state: addressDetails.state,
      address_country: addressDetails.country,
      email: auth.user.email,
    };

    const payload = await stripe.createToken(
      elements.getElement(CardElement) as
        | StripeCardElement
        | StripeCardNumberElement,
      billing_details,
    );

    if (payload.error) {
      setStripeError(payload.error.message);
    } else {
      setPaymentMethod(payload.token);
    }

    if (payload && payload?.token?.id) {
      const response = await controller.createBillingMethod(
        {
          paymentId: payload?.token?.id,
        },
        true,
      );

      if (response) {
        !!response &&
          setFirebaseData(`updates/settings`, generateRandomDigits(16));

        await loadBillingMethods();
        onClose && onClose();

        activateNotification({
          title: 'Success',
          content: 'Your card has been updated',
          kind: 'success',
        });
      }
    }
    setUpdatingCard(false);
  };

  return {
    chooseBillingPlan,
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    registerCard,
    handleSubmitCard,
    errorsCard,
    formsStateCard,
    setValueCard,
    updateAddressDetails,
    addressDetails,
    controlCard,
    watchCard,
    isSubmittingCard,
    handleFormValueChangeCard,
    loadBillingSubscriptions,
    stripeError,
    setStripeError,
    cardComplete,
    setCardComplete,
    setSelectedCountry,
    selectedCountry,
    setSelectedState,
    selectedState,
    updatingCard,
    processing,
    setProcessing,
    paymentMethod,
    nextCycleRefetch,
    setPaymentMethod,
    addressSaved,
    setSaveAddress,
    handleSubmitPaymentMethod,
    completedSubscription,
    handleUpdatePaymentMethod,
    validateCoupon,
    couponDiscount,
    setCouponDiscount,
    validCoupon,
    setValidCoupon,
  };
}
