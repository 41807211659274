import {
  ComponentPropsWithRef,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import styledMap from 'styled-map';
import {Status, useImage} from '../../../hooks';
import {userInitials} from '../../../utils';
import {capitalize} from '../../../utils';
import {Typography} from '../typography/typography';
import {getColorFromTheme} from '../../ui-utils';
import {useLayer} from 'react-laag';
import {TextTag2} from '../tag';
import {motion, AnimatePresence} from 'framer-motion';
import {HorizontalSpacer, VerticalSpacer} from '../spacer';
import {Body2, CTA, Headline4} from '../typography';
import {useStoreContext} from '../../../store/store-context';
import {Groups} from '../../molecules/groups';
import {ItemLoaderLine} from '../../organisms/item-loader-line';
import {ifProp} from 'styled-tools';

export type AvatarProps = ComponentPropsWithRef<'img'> & {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xs';
  name: string;
  optional?: string;
  userId: string;
  isUserDeleted?: boolean;
  customUsers?: any[];
  avatarIcon?: ReactNode;
  active?: boolean;
  tooltip: boolean;
};

const size = styledMap('size', {
  default: '32px',
  sm: '24px',
  xs: '16px',
  md: '32px',
  lg: '40px',
  xl: '100px',
});

const fontSize = styledMap('size', {
  default: '10px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  xl: '24px',
});

const AvatarWrapper = styled.div`
  position: relative;
`;
const DefaultWrapper = styled.div<
  Pick<AvatarProps, 'size'> & Pick<AvatarProps, 'active'>
>`
  width: ${size};
  height: ${size};
  border-radius: 100%;
  box-shadow: ${(props) =>
    props.active ? '0 0 0 2px #585ADF;' : '0 0 0 2px #fff'};

  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${getColorFromTheme('backgroundDark')};

  .tooltip {
    background: #ffffff;
    border: 1px solid #ededf2;
    box-sizing: border-box;
    box-shadow: 0px 9px 38px rgba(0, 0, 0, 0.05),
      0px 6.53341px 11.4559px rgba(0, 0, 0, 0.0325794);
    border-radius: 10px;
    padding: 16px;
  }
`;
const TooltipWrapper = styled.div`
  padding: 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
  box-shadow: 0px 9px 38px rgba(0, 0, 0, 0.05),
    0px 6.53341px 11.4559px rgba(0, 0, 0, 0.0325794);
  zindex: 999999999999999999999999;
  background: #ffffff;
  width: 230px;
`;

const AvatarImg = styled.img<Pick<AvatarProps, 'size'>>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
`;

const AvatarIcon = styled.div<{avatarIsText?: boolean}>`
  position: absolute;
  left: ${ifProp('avatarIsText', '0px', '-3px')};
  bottom: ${ifProp('avatarIsText', '-4px', '0px')};
  border: 2px solid white;
  border-radius: 100%;
  z-index: 9999;
  width: fit-content;
  height: min-content;
`;

const AvatarText = styled.span<Pick<AvatarProps, 'size'>>`
  ${Typography};
  background-color: ${getColorFromTheme('backgroundDark')};
  color: ${getColorFromTheme('textDark')};
  font-weight: 500;
  line-height: 0;
  font-size: ${fontSize};
`;

const MotionDiv = styled(motion.div)`
  z-index: 9999999999999999999;
`;

export const Avatar = memo(function ({
  name,
  size,
  userId,
  active,
  tooltip,
  isUserDeleted,
  customUsers,
  avatarIcon,
  optional,
  ...props
}: AvatarProps) {
  const [status] = useImage(props.src || '');
  const [isOpen, setOpen] = useState(false);

  // helper function to close the menu
  function close() {
    setOpen(false);
  }
  const {
    usersStore: {users},
    groupStore: {groups: group},
  } = useStoreContext();
  const {renderLayer, triggerProps, layerProps} = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });
  const [groups, setGroups] = useState([]);
  const [reviewer, setReviewer] = useState<any>({});
  const [jobTitle, setJobTitle] = useState<string>('');
  const [isFetchingGroups, setIsFetchingGroups] = useState(false);
  const [isFetchingReviewerData, setIsFetchingReviewerData] = useState(false);
  const userDetails: any = users?.find((user: any) => user?.id === userId);

  const fetchGroup = useCallback(
    async (id: string) => {
      setIsFetchingGroups(true);
      setIsFetchingReviewerData(true);
      const getUsers = customUsers || users;
      const userDetails: any = getUsers?.find(
        (user: any) => user?.id === userId,
      );

      const reviewerResponses: any = userDetails?.reviewer;

      setIsFetchingGroups(false);

      if (userDetails && userDetails.groups) {
        const getGroupIds = userDetails.groups.map((grp: any) => grp.group);
        const getUserGroups =
          group.length > 0
            ? group
                ?.filter((grp: any) => getGroupIds.includes(grp.id))
                .map((grp: any) => ({
                  name: grp.name,
                  value: grp.id,
                }))
            : [];
        setGroups(getUserGroups as any);
      }
      setJobTitle(userDetails?.jobTitle);

      if (reviewerResponses) {
        const reviewerResponse = reviewerResponses;
        reviewerResponse && setIsFetchingReviewerData(false);

        reviewerResponse &&
          reviewerResponse.email &&
          setReviewer({
            name:
              reviewerResponse &&
              reviewerResponse.firstName &&
              reviewerResponse.lastName
                ? `${reviewerResponse.firstName} ${reviewerResponse.lastName}`
                : reviewerResponse.email,
            avatar:
              reviewerResponse &&
              reviewerResponse.avatar &&
              reviewerResponse.avatar.url
                ? reviewerResponse.avatar.url
                : '',
          });
      }
      setIsFetchingReviewerData(false);
    },
    [users, userId, group, customUsers],
  );

  useEffect(() => {
    if (
      !!userId &&
      userId !== 'members' &&
      userId !== 'undefined' &&
      userId !== 'managers'
    ) {
      fetchGroup(userId);
    }
  }, [fetchGroup, userId]);

  const AvatarName = isUserDeleted ? 'Deleted user' : optional || name;
  if (status !== Status.LOADED || !props.src) {
    return (
      <AvatarWrapper>
        <DefaultWrapper
          active={active}
          size={size}
          {...triggerProps}
          onMouseEnter={() => setOpen(tooltip && true)}
          onMouseLeave={() => setOpen(false)}>
          <AvatarText size={size}>{userInitials(AvatarName)}</AvatarText>

          {renderLayer(
            <AnimatePresence>
              {isOpen && (
                <div style={{zIndex: 99999999999}}>
                  <MotionDiv
                    {...layerProps}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}>
                    <TooltipWrapper>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div>
                          <DefaultWrapper size="lg" active={active}>
                            <AvatarText size="lg">
                              {userInitials(AvatarName)}
                            </AvatarText>
                          </DefaultWrapper>
                        </div>

                        <HorizontalSpacer size="10px" />
                        <div>
                          <div style={{display: 'flex', marginBottom: '4px'}}>
                            {userDetails?.role === 'admin' && (
                              <TextTag2>
                                {capitalize(userDetails.role)}
                              </TextTag2>
                            )}
                            {active && (
                              <TextTag2
                                style={{
                                  marginLeft:
                                    userDetails?.role === 'admin' ? '6px' : '',
                                }}>
                                Goal owner
                              </TextTag2>
                            )}
                          </div>
                          <Headline4
                            kind="textDark"
                            style={{
                              wordBreak: 'break-word',
                            }}>
                            {name} {isUserDeleted ? '(Deleted user)' : ''}
                          </Headline4>
                          <Body2 kind="textBody">
                            {isFetchingReviewerData
                              ? 'Loading'
                              : jobTitle || '--'}
                          </Body2>
                        </div>
                      </div>
                      <VerticalSpacer size="16px" />
                      <div>
                        <CTA kind="textDark" weight="regular">
                          Group(s)
                        </CTA>
                        <VerticalSpacer size="6px" />
                        {isFetchingGroups ? (
                          <ItemLoaderLine />
                        ) : !isFetchingGroups && groups.length === 0 ? (
                          <p>No groups</p>
                        ) : (
                          <Groups items={groups} total={groups.length} />
                        )}
                        <VerticalSpacer size="16px" />
                      </div>
                      <div
                        style={{
                          height: '1px',
                          borderTop: '1px solid #ededf2',
                        }}></div>
                      <VerticalSpacer size="16px" />
                      {reviewer.name && (
                        <div>
                          <CTA kind="textDark">Manager</CTA>
                          <VerticalSpacer size="6px" />
                          {isFetchingReviewerData ? (
                            'Loading ...'
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              <Body2
                                kind="textBody"
                                style={{
                                  overflowWrap: 'break-word',
                                  wordWrap: 'break-word',
                                  msWordBreak: 'break-all',
                                  wordBreak: 'break-word',
                                }}>
                                {reviewer.name || '--'}
                              </Body2>
                              <DefaultWrapper size="sm">
                                {reviewer && reviewer.avatar === '' ? (
                                  <AvatarText size="sm">
                                    {' '}
                                    {userInitials(reviewer.name)}
                                  </AvatarText>
                                ) : (
                                  <AvatarImg
                                    {...props}
                                    src={reviewer.avatar}
                                    size="sm"
                                  />
                                )}
                              </DefaultWrapper>
                            </div>
                          )}
                        </div>
                      )}
                    </TooltipWrapper>
                  </MotionDiv>
                </div>
              )}
            </AnimatePresence>,
          )}
        </DefaultWrapper>
        {avatarIcon && <AvatarIcon avatarIsText>{avatarIcon}</AvatarIcon>}
      </AvatarWrapper>
    );
  }

  return (
    <AvatarWrapper>
      <DefaultWrapper
        size={size}
        active={active}
        {...triggerProps}
        onMouseEnter={() => setOpen(tooltip && true)}
        onMouseLeave={() => setOpen(false)}>
        <AvatarImg {...props} size={size} />
        {avatarIcon && <AvatarIcon>{avatarIcon}</AvatarIcon>}

        {renderLayer(
          <AnimatePresence>
            {isOpen && (
              <div style={{zIndex: 99999999999}}>
                <MotionDiv
                  {...layerProps}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}>
                  <TooltipWrapper>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <DefaultWrapper size="lg" active={active}>
                        <AvatarImg {...props} size="lg" />
                      </DefaultWrapper>
                      <HorizontalSpacer size="10px" />
                      <div>
                        <div style={{display: 'flex', marginBottom: '4px'}}>
                          {userDetails?.role === 'admin' && (
                            <TextTag2>{capitalize(userDetails.role)}</TextTag2>
                          )}
                          {active && (
                            <TextTag2
                              style={{
                                marginLeft:
                                  userDetails?.role === 'admin' ? '6px' : '',
                              }}>
                              Goal owner
                            </TextTag2>
                          )}
                        </div>
                        <Headline4
                          kind="textDark"
                          style={{
                            wordBreak: 'break-word',
                          }}>
                          {name} {isUserDeleted ? '(Deleted user)' : ''}
                        </Headline4>
                        <Body2 kind="textBody">
                          {' '}
                          {isFetchingReviewerData
                            ? 'Loading'
                            : jobTitle || '--'}
                        </Body2>
                      </div>
                    </div>
                    <VerticalSpacer size="16px" />
                    <div>
                      <CTA kind="textDark" weight="regular">
                        Group(s)
                      </CTA>
                      <VerticalSpacer size="6px" />
                      {isFetchingGroups ? (
                        <ItemLoaderLine />
                      ) : !isFetchingGroups && groups.length === 0 ? (
                        <p>No groups</p>
                      ) : (
                        <Groups items={groups} total={groups.length} />
                      )}
                      <VerticalSpacer size="16px" />
                    </div>
                    <div
                      style={{
                        height: '1px',
                        borderTop: '1px solid #ededf2',
                      }}></div>
                    <VerticalSpacer size="16px" />
                    <div>
                      <CTA kind="textDark">Manager</CTA>
                      <VerticalSpacer size="6px" />
                      {isFetchingReviewerData ? (
                        'Loading ...'
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <Body2
                            kind="textBody"
                            style={{
                              overflowWrap: 'break-word',
                              wordWrap: 'break-word',
                              msWordBreak: 'break-all',
                              wordBreak: 'break-word',
                            }}>
                            {reviewer.name || '--'}
                          </Body2>
                          <DefaultWrapper size="sm">
                            {reviewer && reviewer.avatar === '' ? (
                              <AvatarText size="sm">
                                {' '}
                                {userInitials(reviewer.name)}
                              </AvatarText>
                            ) : (
                              <AvatarImg
                                {...props}
                                src={reviewer.avatar}
                                size="sm"
                              />
                            )}
                          </DefaultWrapper>
                        </div>
                      )}
                    </div>
                  </TooltipWrapper>
                </MotionDiv>
              </div>
            )}
          </AnimatePresence>,
        )}
      </DefaultWrapper>
      {avatarIcon && <AvatarIcon>{avatarIcon}</AvatarIcon>}
    </AvatarWrapper>
  );
});
Avatar.displayName = 'Avatar';
