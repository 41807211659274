import {Button} from '@ui/atoms/button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2, Headline3} from '@ui/atoms/typography';
import {SecondaryPlayIcon} from '@ui/atoms/icons/play-icon';
import PageLayout from '@ui/templates/page-template/page-layout';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import {FlexRow, FlexRowEnd, FlexRowSpaceBetween} from '@ui/style/styles';
import {Modal} from '@ui/molecules/modal';
import ReactPlayer from 'react-player';
import {ModalCard} from '@ui/layouts/modal-card';
import {useFirebaseFetch} from '@hooks/query-hook';
import {CancelIcon} from '@ui/atoms/icons';
import {useStoreContext} from '@store/store-context';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {QuestionIconWrapper} from '../you/you-page.styles';
import Overlay from '@assets/images/video-overlay.png';
import {masteryTasks} from './mastery-tasks';
import {useMemo, useState} from 'react';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {SubmitCheckIcon} from '@ui/atoms/icons/check';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {useFeaturesHook} from '@hooks/features';

const VideoWrapper = styled.div`
  border-radius: 10px;
  position: relative;
  background-image: url(${Overlay});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 268px;
  height: 148px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const ModalWrapper = styled.div`
  max-width: 750px;
  margin: auto;

  @media (max-width: 768px) {
    margin: 0px 20px;
    max-width: 100%;
  }
`;
const WatchCard = styled.button`
  background: white;
  display: flex;
  position: absolute;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px 16px;
  svg {
    padding-top: 3px;
  }
  width: fit-content;
  right: 30%;
  top: 40%;
`;

const SortedTaskWrapper = styled.div`
  height: 60vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 1em;

    height: 2.1em;
  }

  &::-webkit-scrollbar-track {
    background: #f6f8fa;
    border: 0.3em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;

    -webkit-border-radius: 0.5em;

    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10em;
    border: 0.4em solid rgba(0, 0, 0, 0);
    background: #cecede;
    border-radius: 10px;
    background-clip: padding-box;
    -webkit-border-radius: 0.5em;

    -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 57%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 75%;
    }

    @media (min-width: 768px) and (max-width: 1100px) {
      width: 90%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const MasteryWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 16px;
  .tier {
    display: flex;
    align-items: center;
    border: 1px solid #ededf2;
    border-radius: 10px;
  }
  .level {
    padding: 8px 16px;
    width: 100%;
    border-left: 1px solid #ededf2;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .active {
    background: #585adf;
  }
`;
const Task = styled.div`
  padding: 16px 24px;
  border: 1px solid #ededf2;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .video {
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
      margin-top: 16px;
      width: 100%;
    }
  }
  .desc {
  }
  button {
    padding: 8px 16px;
  }
`;
export const MasteryPage = () => {
  const navigate = useNavigate();
  const {
    authStore: {auth},
  } = useStoreContext();

  const {data} = useFirebaseFetch(`users/${auth.user.id}/onboarding/mastery`);

  const completedTasks = data?.tasks ? Object.keys(data?.tasks) : [];

  const masteryStatus = data?.tier || 'novice';

  const masteryOptions = [
    {
      title: 'Novice',
      active: masteryStatus === 'novice',
      radius: '6px 0px 0px 6px',
    },
    {
      title: 'Adept',
      active: masteryStatus === 'adept',
      radius: '0px',
    },
    {
      title: 'Master',
      active: masteryStatus === 'master',
      radius: '0px 6px 6px 0px',
    },
  ];

  const [activeTab, setActiveTab] = useState('');

  const {
    isKpiAllowed,
    isFeedBackEnabled,
    isEngagementActive,
    isPerformanceActive,
    isCheckinsEnabled,
    isCcEnabled,
  } = useFeaturesHook();

  const masteryTabs = useMemo(() => {
    const options = [
      {
        active: activeTab === '',
        href: '#',
        id: '1',
        onClick: () => setActiveTab(''),
        title: 'All',
      },
      {
        active: activeTab === 'members',
        href: '#',
        id: '1',
        onClick: () => setActiveTab('members'),
        title: 'Add/manage members',
      },

      {
        active: activeTab === 'reporting',
        href: '#',
        id: '1',
        onClick: () => setActiveTab('reporting'),
        title: 'Reporting',
      },
    ];

    if (isPerformanceActive) {
      options.splice(1, 0, {
        active: activeTab === 'okrs',
        href: '#',
        id: '1',
        onClick: () => setActiveTab('okrs'),
        title: 'Objectives (OKRs)',
      });

      if (isCcEnabled) {
        options.splice(7, 0, {
          active: activeTab === 'cc-reviews',
          href: '#',
          id: '1',
          onClick: () => setActiveTab('cc-reviews'),
          title: 'C&C Reviews',
        });
      }
      if (isKpiAllowed) {
        options.splice(2, 0, {
          active: activeTab === 'kpis',
          href: '#',
          id: '1',
          onClick: () => setActiveTab('kpis'),
          title: 'KPIs',
        });
      }
    }

    if (isEngagementActive) {
      if (isCheckinsEnabled) {
        options.splice(5, 0, {
          active: activeTab === 'updates',
          href: '#',
          id: '1',
          onClick: () => setActiveTab('updates'),
          title: 'Updates',
        });
      }

      if (isFeedBackEnabled) {
        options.splice(6, 0, {
          active: activeTab === 'feedback',
          href: '#',
          id: '1',
          onClick: () => setActiveTab('feedback'),
          title: 'Feedback',
        });
      }
    }
    return options;
  }, [
    activeTab,
    isCcEnabled,
    isCheckinsEnabled,
    isEngagementActive,
    isFeedBackEnabled,
    isKpiAllowed,
    isPerformanceActive,
  ]);

  const sortedTasks = masteryTasks()
    .filter((task) => (activeTab ? task.tab === activeTab : !activeTab))
    .sort((taskA, taskB) => {
      const isTaskACompleted = completedTasks.includes(taskA.id);
      const isTaskBCompleted = completedTasks.includes(taskB.id);

      if (isTaskACompleted && !isTaskBCompleted) {
        return 1;
      } else if (!isTaskACompleted && isTaskBCompleted) {
        return -1;
      } else {
        return 0; // no change in order
      }
    });

  return (
    <div>
      <PageLayout title="Beam mastery videos" onClick={() => navigate(-1)}>
        <FormWrapper>
          <div className="form">
            <MasteryWrapper>
              <Body1 weight="bold">
                Beam mastery{' '}
                <QuestionIconWrapper tooltip={true}>
                  <AlertBadge />
                  <span
                    className="tooltip"
                    style={{
                      width: '300px',
                      left: '575px',
                      zIndex: 9999,
                      padding: '16px',
                    }}>
                    Your tier depends on trying Beam features. Experiment with
                    new ones to level up.
                  </span>
                </QuestionIconWrapper>
              </Body1>
              <VerticalSpacer size="10px" />
              <Body2 kind="textBody">
                Become an expert on our platform with ease through our
                comprehensive video collection covering all features.
              </Body2>
              <VerticalSpacer size="24px" />
              <div className="tier">
                {masteryOptions.map((option, idx) => (
                  <div
                    key={idx}
                    className={`level ${option.active ? 'active' : ''} ${
                      idx === 0 ? 'no-b' : ''
                    }`}
                    style={{borderRadius: option.radius}}>
                    <Body2
                      kind={option.active ? 'white' : 'textMuted'}
                      weight={option.active ? 'semibold' : 'default'}>
                      {' '}
                      {option.title}
                    </Body2>
                  </div>
                ))}
              </div>
              <VerticalSpacer size="10px" />

              <Body2 kind="textBody">
                {masteryStatus === 'novice'
                  ? `You are ${
                      3 - completedTasks.length
                    } actions away from reaching Adept level.`
                  : masteryStatus === 'adept'
                  ? `You are ${
                      masteryTasks().length - completedTasks.length
                    } actions away from reaching Master level.`
                  : ''}
              </Body2>
            </MasteryWrapper>
            <VerticalSpacer size="24px" />
            <TabNavigation
              navigations={masteryTabs}
              type="button"
              enableScroll
            />
            <VerticalSpacer size="24px" />

            <SortedTaskWrapper>
              {sortedTasks.map((task, index) => (
                <MasteryTask
                  key={index}
                  task={task}
                  completedTasks={completedTasks}
                />
              ))}
            </SortedTaskWrapper>
          </div>
        </FormWrapper>
      </PageLayout>
    </div>
  );
};

interface ITask {
  task: {
    title: string;
    description: string;
    id: string;
    duration: string;
    video: string;
    url: string;
  };
  completedTasks: string[];
}

const MasteryTask = ({task, completedTasks}: ITask) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Task>
        <div className="desc">
          <FlexRow>
            <Body1 weight="bold">{task.title} </Body1>
            {completedTasks.includes(task.id) ? (
              <div style={{marginLeft: '5px', marginTop: '3px'}}>
                {' '}
                <SubmitCheckIcon status />
              </div>
            ) : null}{' '}
          </FlexRow>
          <VerticalSpacer size="10px" />

          <Body2 kind="textBody">{task.description}</Body2>
          <VerticalSpacer size="16px" />

          {task.id === 'help-center' ? (
            <Button kind="secondary">Visit help center</Button>
          ) : (
            <FlexRow>
              <Button
                onClick={() =>
                  window.open(`${window.location.origin}${task.url}`)
                }>
                Try {completedTasks.includes(task.id) ? 'again' : 'this'}
              </Button>
              {/* DO NOT DELETE */}
              {/* <Button kind="secondary">Learn more</Button> */}
            </FlexRow>
          )}
        </div>
        <div className="video">
          <VideoWrapper>
            <WatchCard>
              <div className="flex items-center gap-1">
                <SecondaryPlayIcon />
                <Body2 weight="bold" className="flex items-center">
                  {' '}
                  Play video
                </Body2>
              </div>
            </WatchCard>
          </VideoWrapper>
        </div>
      </Task>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalWrapper>
          <ModalCard title="" getStarted="getStarted">
            <div style={{padding: '16px'}}>
              <FlexRowSpaceBetween>
                <FlexRow>
                  <Headline3>{task.title}</Headline3>
                  {completedTasks.includes(task.id) ? (
                    <div style={{marginLeft: '5px', marginTop: '3px'}}>
                      {' '}
                      <SubmitCheckIcon status />
                    </div>
                  ) : null}{' '}
                </FlexRow>
                <PlainButton onClick={() => setOpen(false)}>
                  <CancelIcon />
                </PlainButton>
              </FlexRowSpaceBetween>
              <VerticalSpacer size="16px" />
              <div>
                <ReactPlayer
                  // ref={ref}
                  url={`${task.video}?rel=0`}
                  controls
                  width={'100%'}
                  style={{
                    borderRadius: '10px',
                    overflow: 'hidden',
                  }}
                  playing
                />
              </div>
              <VerticalSpacer size="16px" />
              <FlexRowEnd>
                {task.id === 'help-center' ? (
                  <Button kind="secondary">Visit help center</Button>
                ) : (
                  <FlexRow>
                    <Button
                      onClick={() =>
                        window.open(`${window.location.origin}${task.url}`)
                      }>
                      Try {completedTasks.includes(task.id) ? 'again' : 'this'}
                    </Button>
                    {/* DO NOT DELETE */}
                    {/* <Button kind="secondary">Learn more</Button> */}
                  </FlexRow>
                )}
              </FlexRowEnd>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </>
  );
};
