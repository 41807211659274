import {HubSpot, Slack} from '.';
import {Body2, Body1, Headline2} from '@ui/atoms/typography';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {useAppHook} from './apps.hooks';
import {cloudinary} from '@assets/images/cloudinary';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react-lite';
import {SearchBox2} from '@ui/molecules/search-box';
import {ItemLoaderWrapper} from '../../admin-performance/performance-framework/review-cycle/review-cycle-style';
import {down} from 'styled-breakpoints';
import {useBreakpoints} from '@utils/use-breakpoints';
import styled from 'styled-components';
import {GoogleCalender} from './google-calender/gcalender';
import {authStore} from '@store/stores/auth-store';
const {emptyGoals: emptyGoal} = cloudinary;

const Wrapper = styled.div`
  .support {
    background: #f6f6fe;
    border-radius: 8px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AppWrapper {
    border: 1px solid #ededf2;
    border-radius: 10px;

    padding: 24px 16px;

    ${down('sm')} {
      border: 1px solid #ededf2;
      border-radius: 10px;
      padding: 16px 10px;
    }
  }
  ${down('sm')} {
    border: none;
  }
`;

const integrations = [
  'hubspot',
  'slack',
  'linear',
  'jira',
  'google analytics',
  'google calender',
  'zoho crm',
  'google sheets',
  'asana',
];

const EmptySearch = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoal,
    style: {height: '30%', width: '30%'},
  },

  subtitle: '',
  title: `No app found.`,
};

export const AppIntegrationContainer = observer(() => {
  const {search, handleSearch, loading} = useAppHook();
  const {
    integrationStore: {activeIntegrations, setActiveIntegrations},
  } = useStoreContext();

  const handleSearchInput = (e: string) => {
    return e.includes(search.toLowerCase().trim()) || search === '';
  };

  const {isXs} = useBreakpoints();

  const defaultData = {
    app: '',
    id: '',
    lastSync: '',
    status: 'connect',
  };

  const findApp = (_app: string) =>
    activeIntegrations?.find(
      (app: {app: string}) => app.app.toLowerCase() === _app,
    );

  const isHubspotActive = findApp('hubspot');

  const isSlackActive = findApp('slack');

  const userIsAdmin = authStore.auth.user.role === 'admin';
  return (
    <>
      <Wrapper className="bg-white">
        {isXs ? (
          <Body1
            weight="semibold"
            style={{padding: '16px 5px', marginBottom: '10px'}}>
            Manage apps
          </Body1>
        ) : (
          <Headline2 weight="bold" style={{padding: '16px 0px'}}>
            Manage apps
          </Headline2>
        )}

        <div className="AppWrapper">
          <div className="support">
            <Body2 align="center" kind="textBody">
              {isXs
                ? 'To add a custom app'
                : '  To add a custom integration or request an app'}
              ,{' '}
              <span style={{color: '#585ADF', fontWeight: 500}}>
                <a href="mailto:Hi@peopebeam.co">contact Beam support</a>
              </span>
              .
            </Body2>
          </div>
          {!loading && (
            <>
              <div style={{margin: '10px 0px 20px 0px'}}>
                <SearchBox2
                  placeholder="Search here"
                  margin
                  value={search}
                  handleCancel={() => handleSearch('')}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {userIsAdmin && (
                <>
                  {handleSearchInput('hubspot') && (
                    <HubSpot
                      setActiveIntegrations={setActiveIntegrations}
                      data={isHubspotActive || defaultData}
                    />
                  )}

                  {handleSearchInput('slack') && (
                    <Slack
                      setActiveIntegrations={setActiveIntegrations}
                      data={isSlackActive || defaultData}
                    />
                  )}
                </>
              )}

              {handleSearchInput('Google calender') && (
                <GoogleCalender
                // setActiveIntegrations={setActiveIntegrations}
                // data={isSlackActive || defaultData}
                />
              )}
              {search !== '' &&
                !integrations.filter((app) =>
                  app.includes(search.toLowerCase().trim()),
                )?.[0] && <EmptyPlaceholder {...EmptySearch} />}
            </>
          )}
          {loading && (
            <ItemLoaderWrapper>
              <ItemLoaderLine />
            </ItemLoaderWrapper>
          )}
        </div>
      </Wrapper>
    </>
  );
});
