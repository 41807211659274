import {observer} from 'mobx-react';
import {Button} from '../../../../../../../ui/atoms/button';
import {Body1} from '../../../../../../../ui/atoms/typography';
import {useAdminWorkspaceHook} from '../../admin-workspace-hooks';
import {ContentBox, FeaturesLayout} from '../../admin-workspace-styled';
import {FlexRowEnd} from '@ui/style/styles';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {EditAdminPulse} from './edit-admin-pulse';
import {Routes, Route} from 'react-router';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {useAdminPulseHook} from './admin-pulse-hook';
import {useEffect, useState} from 'react';

export const AdminPulse = observer(({subTab}: {subTab?: string}) => {
  useAdminWorkspaceHook();

  const {isPulseEnabled, editPulse, handleTogglePulse} = useAdminPulseHook();

  const {isEngagementActive} = useWorkspaceUseCase();

  const [pulseChecked, setPulseChecked] = useState(isPulseEnabled);

  useEffect(() => {
    if (subTab && subTab === 'configure') {
      editPulse();
    }
  }, [editPulse, subTab]);

  return (
    <>
      <Routes>
        <Route path="">
          <ContentBox>
            <div className="flex">
              <div className="column">
                <Body1 kind="textDark" weight="bold">
                  Pulse
                </Body1>
              </div>
              <FlexRowEnd className="">
                <SwitchToggle
                  disabled={!isEngagementActive}
                  onChange={(e: any) => {
                    if (isPulseEnabled) {
                      setPulseChecked(false);
                    } else {
                      setPulseChecked(true);
                    }
                    handleTogglePulse(!isPulseEnabled);
                  }}
                  checked={pulseChecked && isEngagementActive}
                  defaultChecked={isPulseEnabled}
                />
                <div style={{margin: '0px 10px'}}>
                  <HorizontalSideRule size="32px" />
                </div>
                <Button
                  width="sm"
                  kind="secondary"
                  style={{padding: '8px 16px'}}
                  disabled={!isPulseEnabled || !isEngagementActive}
                  onClick={editPulse}>
                  View
                </Button>
              </FlexRowEnd>
            </div>
          </ContentBox>
        </Route>
        <Route path="pulse-configure">
          <FeaturesLayout>
            <EditAdminPulse />
          </FeaturesLayout>
        </Route>
      </Routes>
    </>
  );
});
