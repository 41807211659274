import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {Body2} from '@ui/atoms/typography';
import styled from 'styled-components';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {Button} from '@ui/atoms/button';
import {Table, TableHeader, TableRow, TableWrapper} from '../cc-styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {cloudinary} from '@assets/images/cloudinary';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {CcController} from '@api/cc-api/cc-controller';
import {ItemLoader} from '@ui/organisms/item-loader';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {observer} from 'mobx-react';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {useMemo, useState} from 'react';
import {UserListCard} from '@ui/molecules/user/list-card';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useCountdown} from '@utils/countdown';
import {ccFirebaseReminderTracker} from '@utils/firebase-request';

const {emptyGoals} = cloudinary;

const NominationCount = styled.div`
  border-radius: 6px;
  background: var(--main-colours-purple-100, #f6f6fe);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  min-height: 30px;
  justify-content: center;
  width: 100%;
`;

export const NominatePeers = observer(() => {
  const [searchParams] = useSearchParams();

  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const userId = searchParams.get('userId');

  const reviewId = searchParams.get('reviewId');

  const navigate = useNavigate();

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const fetchUserNomination = async () => {
    const response = await controller.fetchReviewNomination(
      userId ?? '',
      reviewId ?? '',
    );

    if (response) {
      return response;
    }

    return [];
  };

  const {data, isLoading, refetch} = useQuery(['nomination', reviewId], () =>
    fetchUserNomination(),
  );

  const [loadingNomination, setLoadingNomination] = useState(false);

  const handleNominate = async () => {
    setLoadingNomination(true);

    const response = await controller.updateReviewNomination({
      reviewers: selectedUsers,
      reviewCycle: reviewId || '',
      user: userId || '',
    });
    response && refetch();

    response && setSelectedUsers([]);

    setLoadingNomination(false);
  };

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    variant: 'default' as 'default',

    title: 'Choose at least 1 peer (Max 3.)',
  };

  const MAX_NOMINATIONS = 3;

  const getReviewersId = useMemo(
    () =>
      data?.length
        ? data.map((nomination: {reviewer: string}) => nomination.reviewer)
        : [],
    [data],
  );

  const computeUsers = useMemo(() => {
    const options = data?.length
      ? users.filter((user) => !getReviewersId.includes(user.id))
      : users;

    return options.filter(
      (option) => option.id !== auth.user.id && option.firstName,
    );
  }, [users, getReviewersId, data, auth.user.id]);

  const user = users.find((_user) => _user.id === userId);

  return (
    <div>
      <DrawerModal
        open
        onClose={() => navigate({search: ''})}
        title={
          userId !== auth.user.id
            ? `Nominate Peers for ${userName(user)}`
            : 'Nominate Peers'
        }>
        <div>
          {isLoading && (
            <FlexRowCenter style={{height: '100vh'}}>
              <ItemLoader />
            </FlexRowCenter>
          )}
          {!isLoading && (
            <>
              <NominationCount>
                <Body2 kind="textBody">
                  {MAX_NOMINATIONS - data.length} peer nomination left
                </Body2>
              </NominationCount>
              <VerticalSpacer size="8px" />
              <FlexRowSpaceBetween>
                <div className="w-full min-h-full  ">
                  <MultiOptionsSelectField
                    margin
                    placeholder="Add peers"
                    type="dropdownOnly"
                    excludeCheckBox
                    disabled={data.length === MAX_NOMINATIONS}
                    defaultValue={selectedUsers.map((user) => {
                      return {
                        value: user,
                      };
                    })}
                    noOptionMessage={'No option Found'}
                    borderRadius="10px"
                    maxSelection={MAX_NOMINATIONS - data.length}
                    performance
                    groupDisplayLength={3}
                    options={computeUsers.map((user) => {
                      return {
                        value: user.id,
                        label: userName(user),
                      };
                    })}
                    onChange={(data: {value: string; label: any | null}[]) => {
                      setSelectedUsers(data?.map((user) => user.value));
                    }}
                  />
                </div>
                <div className="w-[17%] ml-4">
                  <Button
                    style={{padding: '12px 16px'}}
                    disabled={!selectedUsers.length}
                    onClick={handleNominate}
                    isLoading={loadingNomination}>
                    Add Peers
                  </Button>
                </div>
              </FlexRowSpaceBetween>
              <VerticalSpacer size="24px" />
              <TableWrapper>
                <Table>
                  <TableHeader variant="you" gridTemplate="30% 32% 20% 15%">
                    <Body2 weight="bold">Peers</Body2>

                    <Body2 weight="bold">Invited by</Body2>

                    <Body2 weight="bold">Reminder</Body2>
                    <Body2 weight="bold">Action</Body2>
                  </TableHeader>
                  {!data.length ? (
                    <EmptyPlaceholder {...emptyPlaceholder} />
                  ) : (
                    data.map(
                      (nomination: {
                        reviewer: string;
                        createdBy: string;
                        reviewWritten: boolean;
                        id: string;
                      }) => (
                        <Nomination
                          nomination={nomination}
                          refetch={refetch}
                          userId={userId}
                          reviewId={reviewId}
                        />
                      ),
                    )
                  )}
                </Table>
              </TableWrapper>
            </>
          )}
        </div>
      </DrawerModal>
    </div>
  );
});

type NominationProps = {
  nomination: {
    reviewer: string;
    createdBy: string;
    id: string;
    reviewWritten: boolean;
  };
  refetch: () => void;
  userId: string | null;
  reviewId: string | null;
};

const Nomination = ({
  nomination,
  refetch,
  reviewId,
  userId,
}: NominationProps) => {
  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const deleteNomination = async (nominationId: string) => {
    const response = await controller.deleteNomination(nominationId);

    if (response) {
      refetch();
    }
  };

  const reminderId = `users/${auth.user.id}/review_reminder/${reviewId}/${userId}`;

  const {data: reminderExists} = useFirebaseFetch(`${reminderId}`);

  const {minutes} = useCountdown(reminderExists || 0);

  const isDisabled = nomination.reviewWritten || minutes > 0;

  const sendReminder = async (nominationId: string) => {
    const response = await controller.sendNominationReminder(nominationId);

    if (response) {
      ccFirebaseReminderTracker(userId || '', reviewId || '');
    }
  };

  return (
    <TableRow variant="you" gridTemplate="28% 30% 16% 15%">
      <div style={{display: 'flex', alignItems: 'center'}}>
        {users
          .filter((user) => user.id === nomination.reviewer)
          .map((user) => (
            <UserListCard
              kind="admin"
              type="secondary"
              textStyle={{fontWeight: 600}}
              tooltip={true}
              avatar={user.firstName ? user.firstName : ''}
              reviewer={user.reviewer}
              name={
                user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : ` ... (pending)`
              }
              userId={user.id}
            />
          ))}
      </div>
      <div>
        {users
          .filter((user) => user.id === nomination.createdBy)
          .map((user) => (
            <UserListCard
              kind="admin"
              type="secondary"
              textStyle={{fontWeight: 600}}
              tooltip={true}
              avatar={user.firstName ? user.firstName : ''}
              reviewer={user.reviewer}
              name={
                user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : ` ... (pending)`
              }
              userId={user.id}
            />
          ))}
      </div>
      <div>
        <Button
          disabled={isDisabled}
          onClick={() => sendReminder(nomination.id)}
          kind="secondary"
          style={{
            padding: '8px 16px',
          }}>
          Send
        </Button>
      </div>

      <div>
        <Button
          disabled={nomination.reviewWritten}
          onClick={() => deleteNomination(nomination.id)}
          kind="secondary"
          style={{
            padding: '8px 16px',
          }}>
          Remove
        </Button>
      </div>
    </TableRow>
  );
};
