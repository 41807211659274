import {SectionCard} from '@ui/layouts/section-card';
import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {FC, useState} from 'react';
import {useUpcomingMeetingHook} from '../individual-hook';
import {cloudinary} from '@assets/images/cloudinary';
import {Body1, Body2} from '@ui/atoms/typography';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {MeetingCard} from '@ui/organisms/meetings/meeting-card/meeting-card';
import {authStore} from '@store/stores/auth-store';
import UserDateRangeFilter from '@pages/dashboard/goals/objective-filter';
import {useNavigate} from 'react-router';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {TextField} from '@ui/molecules/field/textfield';
import {RescheduleMeeting} from '@pages/1:1s/reschedule-meeting/reschedule-meeting';
import {Pagination} from '@ui/molecules/pagination/pagination';
import {IMeetingResponse} from '@hooks';
import {calcPages} from '@utils/pagination';
import {useStoreContext} from '@store/store-context';

const {emptyGoals} = cloudinary;

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle: 'Add a new 1:1 meeting or document notes.',
  title: 'No records found',
};

interface MeetingTabsProps {
  data: {
    limit: number;
    results: IMeetingResponse[];
    totalPages: number;
    totalResults: number;
    page: number;
  };
  pages: {
    maxPageNumberLimit: number;
    minPageNumberLimit: number;
    limit: number;
    page: number;
  };
  handlePrevBtn: () => void;
  goToPage: (page: number) => void;
  nextPageBtn: () => void;
  updateTotalPages: (totalPages: number) => void;
  updateLimit: (limit: number) => void;
  dateRange: {starts: string; ends: string};

  refetch: any;
  isLoading: boolean;
  updateDateRange: (value: any) => void;
}
export const MeetingsTab: FC<MeetingTabsProps> = ({
  data,
  dateRange,
  updateDateRange,
  handlePrevBtn,
  nextPageBtn,
  updateLimit,
  pages,
  goToPage,

  isLoading,
  refetch,
}) => {
  const navigate = useNavigate();

  const {
    usersStore: {users},
  } = useStoreContext();

  const {deleteMeeting, onCancel} = useUpcomingMeetingHook();

  const [cancelReason, setCancelReason] = useState('');

  const [showCancelModal, setCancelModal] = useState('');

  const [openDrawer, setOpenDrawer] = useState(false);

  const [modalLoader, setModalLoader] = useState(false);

  const [selectedMeeting, setSelectedMeeting] = useState<any>();

  return (
    <div>
      <SectionCard
        // headerPadding={isXs ? 'small' : undefined}
        CustomHeaderTitle={
          <FlexRowSpaceBetween>
            <Body2 weight="bold" data-testid="summary-text">
              Summary ({data.totalResults})
            </Body2>
            <div>
              <UserDateRangeFilter
                removeUserFilter
                showDateRange
                _height="36px"
                disablePeriods
                dateRange={dateRange}
                canFilterAll
                setDateRange={updateDateRange}
              />
            </div>
          </FlexRowSpaceBetween>
        }
        variant="columnStart"
        action={<div></div>}>
        <div>
          {!data.totalResults && !isLoading ? (
            <EmptyPlaceholder {...emptyPlaceholder} />
          ) : null}
          {isLoading && (
            <FlexRowCenter>
              <ItemLoader />
            </FlexRowCenter>
          )}

          {!isLoading && data.totalResults ? (
            <div>
              {data.results.map((meeting) => (
                <MeetingCard
                  key={meeting.id}
                  excludeAddToCalendar
                  onCancel={() => {
                    setCancelModal(meeting.id);
                  }}
                  startMeeting={() => {
                    navigate(
                      `/view-meetings/${meeting.id}?view=${
                        meeting.type === 'team' ? 'teams' : 'individual'
                      }${'&start=true'}${
                        [meeting.user.reviewer].includes(authStore.auth.user.id)
                          ? '&manager=true'
                          : ''
                      }`,
                    );
                  }}
                  viewMeeting={() => {
                    navigate(
                      `/view-meetings/${meeting.id}?view=${
                        meeting.type === 'team' ? 'teams' : 'individual'
                      }${
                        [meeting.user.reviewer].includes(authStore.auth.user.id)
                          ? '&manager=true'
                          : ''
                      }`,
                    );
                  }}
                  {...meeting}
                  label={[]}
                  onDelete={() =>
                    deleteMeeting(meeting.id).then((meeting) => refetch())
                  }
                  participant={
                    Array.isArray(meeting.participant)
                      ? users.filter((user) =>
                          meeting.participant
                            .map((participant: {id: any}) =>
                              typeof participant === 'string'
                                ? participant
                                : participant.id,
                            )
                            .includes(user.id),
                        )
                      : users.filter((user) => user.id === meeting.participant)
                  }
                  onReschedule={() => {
                    setSelectedMeeting(meeting);
                    setOpenDrawer(true);
                  }}
                />
              ))}
            </div>
          ) : null}
        </div>
      </SectionCard>
      {openDrawer && (
        <RescheduleMeeting
          open={openDrawer}
          onUpdated={refetch}
          meeting={selectedMeeting}
          onClose={() => setOpenDrawer(false)}
        />
      )}

      <CustomIconModal
        open={!!showCancelModal}
        onClose={() => setCancelModal('')}
        disabled={!cancelReason}
        loading={modalLoader}
        body={
          <div className="mt-4">
            <TextField
              multiple
              inputStyle={{minHeight: '20px'}}
              placeholder="Add reason"
              value={cancelReason}
              onChange={(event) => setCancelReason(event.target.value)}
            />
          </div>
        }
        onClick={() => {
          setModalLoader(true);
          onCancel(showCancelModal, cancelReason).then((meeting) => {
            setCancelModal('');
            setModalLoader(false);

            refetch();
          });
        }}
        title={'Cancel Meeting'}
        description={
          <Body1 kind="textBody" align="center">
            Provide reason, click{' '}
            <span style={{color: 'black', fontWeight: 600}}>“confirm”</span> to
            continue. The attendee will be notified.
          </Body1>
        }
      />
      {pages.limit < data.totalResults && (
        <Pagination
          totalPages={data.totalPages}
          page={pages.page}
          totalResults={data.totalResults}
          maxPageNumberLimit={pages.maxPageNumberLimit}
          limit={pages.limit}
          updateLimit={(value) => updateLimit(value)}
          minPageNumberLimit={pages.minPageNumberLimit}
          goToPage={goToPage}
          pages={calcPages(data.totalResults, pages.limit)}
          nextBtn={nextPageBtn}
          prevBtn={handlePrevBtn}
        />
      )}
    </div>
  );
};
