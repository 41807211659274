import {observer} from 'mobx-react';
import {useStoreContext} from '../../../store/store-context';
import PageLayout from '@ui/templates/page-template/page-layout';
import {SideNavigation} from '../../../ui/molecules/side-nav';
import {
  WorkspaceWrapper,
  WorkspaceWrapperChild,
} from '../admin/admin-components/admin-workspace/admin-workspace-styled';
import {Personal} from './member-profile-component/personal';
import {NotificationCard} from '@ui/molecules/notification/notification';
import {Security} from './member-profile-component/security';
import NotificationSettings from './member-profile-component/notification';
import {Wrapper} from './member-profile-style';
import {useNavigate} from 'react-router-dom';

export const MemberProfilePageElement = observer(() => {
  const {storeDashboardRoutes} = useStoreContext();
  const {userProfileTabs, activateUserProfileTab} = storeDashboardRoutes;

  const userProfileNavigations = [
    {
      active: userProfileTabs.personal,
      href: '/edit/',
      id: '1',
      type: 'workspace',
      onClick: () => activateUserProfileTab('personal'),
      title: 'Personal',
    },

    {
      active: userProfileTabs.notification,
      href: '/edit/',
      id: '1',
      type: 'workspace',
      onClick: () => activateUserProfileTab('notification'),
      title: 'Notifications',
    },
    {
      active: userProfileTabs.security,
      href: '/edit/',
      id: '2',
      type: 'workspace',
      onClick: () => activateUserProfileTab('security'),
      title: 'Security',
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <PageLayout
        bodyStyle={{margin: 0}}
        leftAction={<></>}
        titleMargin={'17%'}
        title={
          userProfileTabs.personal
            ? 'Edit Profile'
            : userProfileTabs.notification
            ? 'Notifications'
            : 'Security Settings'
        }
        onClick={() => navigate(-1)}>
        <Wrapper>
          <WorkspaceWrapper>
            <WorkspaceWrapperChild>
              <SideNavigation
                navigations={userProfileNavigations}
                type="workspace"
              />
              <div>
                <NotificationCard />
                {userProfileTabs.personal && <Personal />}
                {userProfileTabs.notification && <NotificationSettings />}
                {userProfileTabs.security && <Security />}
              </div>
            </WorkspaceWrapperChild>
          </WorkspaceWrapper>
        </Wrapper>
      </PageLayout>
    </>
  );
});

export const memberprofilePage = () => {
  const Page = observer(() => <MemberProfilePageElement />);
  return {
    Page,
  };
};
