import {Dispatch, SetStateAction} from 'react';
import {Button} from '../../../../../../../../../ui/atoms/button';
import {CheckIcon} from '../../../../../../../../../ui/atoms/icons';
import {
  VerticalSpacer,
  HorizontalSpacer,
} from '../../../../../../../../../ui/atoms/spacer';
import {Body2} from '../../../../../../../../../ui/atoms/typography';
import {ItemLoader} from '../../../../../../../../../ui/organisms/item-loader';
import {useBillingHook} from '../../../admin-billing.hooks';
import {IBillingPlan} from '../../../admin-billing.interface';
import {PlanDiv, PlansDiv} from '../billing-not-active.styles';

interface IBillingPlansComponent {
  loader: boolean;
  plans: IBillingPlan[];
  billingCycle: 'annually' | 'monthly';
  setShowStarterPlanCTA: Dispatch<SetStateAction<boolean>>;
}
export const BillingPlans = ({
  loader,
  plans,
  billingCycle,
  setShowStarterPlanCTA,
}: IBillingPlansComponent) => {
  const {chooseBillingPlan} = useBillingHook();

  return (
    <PlansDiv>
      {!loader &&
        // eslint-disable-next-line array-callback-return
        plans.map((plan: IBillingPlan) => {
          if (plan.isVisible) {
            return (
              <PlanDiv>
                <div>
                  <p className="paragraphMicroscopy">
                    {plan.name} {plan.name === 'Free' && 'forever'}
                  </p>
                  <VerticalSpacer size="16px" />
                  <Body2 kind="textBody">
                    <span className="monthlyCostSpan">
                      {`$${
                        billingCycle === 'annually'
                          ? plan.yearly.unitCost
                          : plan.monthly.unitCost
                      }`}
                    </span>{' '}
                    per user month, billed {billingCycle}
                  </Body2>

                  <VerticalSpacer size="20px" />
                  <div className="divider" />
                  <VerticalSpacer size="20px" />

                  {plan.features.map((feature: string) => (
                    <div className="featureLine">
                      <div>
                        <CheckIcon style={{stroke: '#585ADF'}} />
                      </div>
                      <HorizontalSpacer size="10px" />
                      <Body2 kind="textBody">{feature}</Body2>
                    </div>
                  ))}
                  <VerticalSpacer size="14px" />
                </div>
                <Button
                  width="full"
                  onClick={chooseBillingPlan(plan.plan, billingCycle)}>
                  Select plan
                </Button>
              </PlanDiv>
            );
          }
        })}
      {loader && <ItemLoader />}
    </PlansDiv>
  );
};
