import {makeAutoObservable, observable, action} from 'mobx';

export class IntegrationStore {
  @observable activeIntegrations = [];
  @observable googleTokenLoader = true;
  @observable isGapiLoaded = false;
  @observable googleCalenderIsSigned = true;

  constructor() {
    makeAutoObservable(this);
  }
  @action setActiveIntegrations = (data: any) => {
    this.activeIntegrations = data;
  };

  @action setLoader = (data: boolean) => {
    this.googleTokenLoader = data;
  };

  @action setSignStatus = (data: boolean) => {
    this.googleCalenderIsSigned = data;
  };
  @action updateGapiStatus = () => {
    this.isGapiLoaded = true;
  };
}

export const integrationStore = new IntegrationStore();
