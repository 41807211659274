import {VerticalSpacer} from '../../ui/atoms/spacer';
import {Body2} from '../../ui/atoms/typography';
import {Button} from '../../ui/atoms/button';
import Clap from '../../assets/images/clap.png';
import {down, up} from 'styled-breakpoints';
import styled from 'styled-components';
import {SuccessOuterWrapper} from '../dashboard/admin/admin-components/invite/workspace-invite.style';
import {SuccessWrapper} from '../dashboard/admin/admin-components/invite/workspace-invite.style';

import {BackAction} from '../../ui/molecules/back-action';
import {CancelIcon} from '../../ui/atoms/icons';
import {useNavigate} from 'react-router';
import {Image} from '../../ui/atoms/image';

export const BackWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;

export default function OnBoardingModal({title, subtitle}: any) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <SuccessOuterWrapper>
        <BackWrapper>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            title="Close"
            onClick={() => navigate('/you')}
          />
        </BackWrapper>
        <VerticalSpacer size="20px" />
        <SuccessWrapper>
          <div>
            <VerticalSpacer size="16px" />
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '10px 15%',
                  justifyContent: 'center',
                }}>
                <VerticalSpacer size="16px" />
                <Image src={Clap} />
                <VerticalSpacer size="16px" />
                <Body2
                  kind="textDark"
                  style={{
                    fontWeight: 600,
                    fontSize: '25px',
                    lineHeight: '32.5px',
                    textAlign: 'center',
                  }}>
                  {title}
                </Body2>
                <VerticalSpacer size="16px" />
                <Body2
                  kind="textBody"
                  style={{
                    textAlign: 'center',
                  }}>
                  {subtitle}
                </Body2>
                <VerticalSpacer size="16px" />
                <Button
                  width="full"
                  onClick={() => navigate('/you/get-started')}>
                  Continue
                </Button>
                <VerticalSpacer size="32px" />
              </div>
            </>
          </div>
        </SuccessWrapper>
      </SuccessOuterWrapper>
    </div>
  );
}
