import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {integrationRequest} from './integrations-request';
import {parseErrorContent} from '@utils/error-handling';

export class IntegrationsController {
  async getIntegrations() {
    try {
      const response = await integrationRequest.getIntegrations();
      return response;
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(error),
        kind: 'error',
      });
    }
  }

  async postIntegrations(data: {app: string; token: string}) {
    try {
      const response = await integrationRequest.postIntegrations(data);
      return response;
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(error),
        kind: 'error',
      });
    }
  }
  async getAppReport(app: string) {
    try {
      const response = await integrationRequest.getAppReport(app);
      return response;
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(error),
        kind: 'error',
      });
    }
  }

  async patchIntegrations(data: {app: string; token: string}) {
    try {
      await integrationRequest.patchIntegrations(data);
      return true;
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(error),
        kind: 'error',
      });
    }
  }
  async deleteIntegrations(app: string) {
    try {
      await integrationRequest.deleteIntegrations(app);
      return true;
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(error),
        kind: 'error',
      });
    }
  }
}
