import {useCallback, useEffect, useState} from 'react';
import {MeetingRequestImpl} from '../meeting.request';
import {useNavigate} from 'react-router';
import {MeetingController} from '../meeting-controller';
import {useStoreContext} from '@store/store-context';
import nextId from 'react-id-generator';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {userName} from '@utils/user-name';
import {writeFirebaseData} from '@utils/firebase-handler';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';
import {generateRandomDigits} from '@utils/generate-random-numbers';

export interface CreateMeetingNoteData {
  participant: string[];
  hostAgendaPrivate: boolean;
  hostAgenda: {text: string; completed: boolean; _id?: string}[];
  hostNote: string;
  shareHostNote: boolean;
  label: string[];
  nextSteps: any[];
  shareHostAgenda: boolean;
  title: string;
  startDate: string;
  frequency: string;
  time?: string;
  reminder: string[];
}

export const useCreateMeetingNoteHook = () => {
  const [searchParams] = useSearchParams();

  const participant = searchParams.get('participant');

  const allParticipant = participant ? participant?.split(',') : [];

  const [values, setValues] = useState<CreateMeetingNoteData>({
    participant: allParticipant,
    shareHostAgenda: true,
    nextSteps: [
      {
        text: '',
        completed: false,
        id: generateRandomDigits(24),
      },
    ],
    title: '',
    label: [],
    hostAgendaPrivate: false,
    hostNote: '',
    shareHostNote: true,
    hostAgenda: [
      {
        text: '',
        completed: false,
        _id: nextId('meeting-'),
      },
    ],
    startDate: participant ? dayjs().format() : '',
    frequency: 'once',
    reminder: [],
  });

  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleFormValueChange = useCallback(
    async (value: string | null | string[] | boolean, name: string) => {
      setValues((prev) => ({...prev, [name]: value}));
    },
    [],
  );

  const isTeamsMeeting = true;

  const markAsCompleted = useCallback(
    async (
      id: string,
      name: string,
      feedbackValues: {
        feedbackValue: string;
        impression: string;
        feedbackSharingType: string;
        values: string[];
      },
      userId: string,
      hostNote?: string,
    ) => {
      const request = new FeedbackRequestImpl();
      const controller = new FeedbackController(request);
      const {
        feedbackValue,
        impression,
        feedbackSharingType,
        values: _values,
      } = feedbackValues;

      const submitMeeting = async (feedbackId?: string) => {
        const _data: any = {
          status: 'completed',
          shareHostNote: values.shareHostNote,
          shareParticipantAgenda: values.participant,
          feedback: feedbackId,
        };

        if (hostNote) {
          _data.hostNote = hostNote;
        }

        if (values.nextSteps.length > 0) {
          values.nextSteps.forEach((item) => {
            writeFirebaseData(`action_items`, {...item, sourceId: id});
          });
        }

        const meetingRequest = new MeetingRequestImpl();
        const meetingController = new MeetingController(meetingRequest);

        const meetingResponse = await meetingController.patchMeeting(_data, id);
        if (meetingResponse) {
          navigate(`/view-meetings/${id}?type=meeting-notes`);
          setLoading(false);
        }
      };

      if (feedbackValue && impression && feedbackSharingType) {
        const response = await controller.postFeedback(
          {
            feedback: feedbackValue,
            impression: impression,
            subjectType: '1:1',
            subjectName: `1:1 with ${name}`,
            subjectId: id,
            visibility: feedbackSharingType,
            values: _values,
          },
          userId,
        );
        if (response) {
          return submitMeeting(response.id);
        }
      }
      return submitMeeting();
    },
    [values.shareHostNote, values.participant, values.nextSteps, navigate],
  );
  const onSubmit = useCallback(
    async (feedbackValues?: {
      feedbackValue: string;
      impression: string;
      feedbackSharingType: string;
      values: string[];
    }) => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);
      setLoading(true);
      const {
        hostAgendaPrivate,
        nextSteps,
        hostNote,
        shareHostNote,
        ...otherValues
      } = values;

      const participantData = users.find(
        (user) => user.id === values.participant[0],
      );

      const _data: any = {
        ...otherValues,

        title: values.title || `Notes with ${userName(participantData)}`,
      };

      if (values.hostAgenda.some((agenda) => agenda.text.length > 0)) {
        _data.hostAgenda = values.hostAgenda
          .filter((agenda) => !!agenda.text)
          .map((agenda) => {
            return {
              text: agenda.text,
              completed: agenda.completed,
            };
          });
      } else {
        _data.hostAgenda = undefined;
      }

      const response = await controller.postMeeting(_data);

      if (response) {
        markAsCompleted(
          response.id,
          userName(participantData),
          feedbackValues || {
            feedbackValue: '',
            impression: '',
            feedbackSharingType: '',
            values: [],
          },
          auth.user.id,
          values.hostNote,
        );
      }
      setIsLoading(false);
      setLoading(false);
    },
    [auth.user.id, markAsCompleted, users, values],
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isTeamsMeeting && !!participant) {
      setIsLoading(true);
      onSubmit();
    }
  }, [isTeamsMeeting, onSubmit, participant]);

  return {
    handleFormValueChange,
    loading,
    isLoading,
    onSubmit,
    participant,
    values,
  };
};
