import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2, CTA, Headline4} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {useBreakpoints} from '@utils/use-breakpoints';
import {observer} from 'mobx-react';
import {useMemo} from 'react';
import {
  EditCheckinsInnerWrapper,
  EditCheckinsOuterWrapper,
  BreadcrumbWrapper,
  SectionComponentWrapper,
} from '../admin-checkins/admin-checkins.styles';
import {useNavigate} from 'react-router';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {SelectField} from '@ui/molecules/select/selectfield';
import {ItemLoader} from '@ui/organisms/item-loader';
import {useAdminPulseHook} from './admin-pulse-hook';

export const EditAdminPulse = observer(() => {
  const {
    isEditingPulseFrequency,
    isDisabled,
    handleFormChange,
    formData,
    isLoading,
    handleSubmit,
    setIsEditingPulseFrequency,
  } = useAdminPulseHook();

  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const computeGroups = useMemo(() => {
    return groupType.map((grpType) => {
      return {
        name: grpType.name,
        data: groups
          .filter((group) => group.groupType === grpType.id)
          .map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          }),
      };
    });
  }, [groupType, groups]);

  const navigate = useNavigate();

  const breadcrumbNav = useMemo(() => {
    const nav = [
      {
        title: 'Engagement',
        href: '#',
        id: '1',
        onClick: () => {
          navigate(-1);
        },
      },
      {
        title: 'Pulse',
        href: '#',
        id: '2',
        isCurrent: true,
        onClick: () => {},
      },
    ];
    return nav;
  }, [navigate]);

  const frequencies = [
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Bi-weekly',
      value: 'bi-weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  const loading = false;

  const {isXs} = useBreakpoints();
  return (
    <EditCheckinsOuterWrapper
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}>
      <BreadcrumbWrapper>
        <SecondaryBreadcrumb
          wrapperStyles={{padding: '16px 32px 16px', marginBottom: 0}}
          navigation={breadcrumbNav}
        />
      </BreadcrumbWrapper>
      <EditCheckinsInnerWrapper>
        {
          <>
            {loading ? (
              <ItemLoader />
            ) : (
              <>
                <SectionCard
                  CustomHeaderTitle={
                    <div>
                      <Headline4 kind="textDark">
                        Mood check frequency
                      </Headline4>
                    </div>
                  }
                  contentStyling={{
                    padding: isXs ? '0px' : '0px 0px 10px 0px',
                  }}
                  action={
                    <Button
                      kind={isEditingPulseFrequency ? 'primary' : 'secondary'}
                      style={{padding: '8px 16px'}}
                      onClick={
                        isEditingPulseFrequency
                          ? () => setIsEditingPulseFrequency(false)
                          : () => setIsEditingPulseFrequency(true)
                      }
                      type="button">
                      {isEditingPulseFrequency ? 'Save' : 'Edit'}{' '}
                    </Button>
                  }>
                  <div>
                    <SectionComponentWrapper>
                      <CTA kind="textDark">
                        How often should users submit mood checks?
                      </CTA>
                      <SelectField
                        name="user"
                        disabled={!isEditingPulseFrequency}
                        defaultValue={formData.frequency}
                        excludeSearchBox
                        onChange={(data: {value: string}) => {
                          handleFormChange('frequency', data?.value);
                        }}
                        borderRadius="10px"
                        margin
                        placeholder="Select frequency"
                        options={frequencies}
                        fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                          `Oops! Seems there is no ${searchTerm} found in this workspace`
                        }
                      />
                    </SectionComponentWrapper>
                  </div>
                </SectionCard>{' '}
                <VerticalSpacer size="24px" />
                <SectionCard
                  title="Exclude groups"
                  headerPadding={isXs ? 'small' : undefined}
                  contentStyling={{
                    padding: '0px',
                  }}
                  CustomHeaderTitle={
                    <div>
                      <Headline4 kind="textDark">
                        Exclude groups from pulse submission
                      </Headline4>
                      <Body2 kind="textBody" className="mt-1">
                        Select which group shouldn't submit pulse
                      </Body2>
                    </div>
                  }>
                  <SectionComponentWrapper>
                    <>
                      <CTA kind="textDark">Groups</CTA>

                      <MultiOptionsSelectFieldGroup
                        options={computeGroups}
                        performance={true}
                        searchPlaceholder="Search group"
                        margin
                        groupDisplayLength={5}
                        noOptionsMessage="Group not found"
                        placeholder="Select Groups"
                        defaultValue={formData.excludedGroups?.map(
                          (val?: string) => ({
                            label: groups?.find((value) => value?.id === val)
                              ?.name,
                            value: val,
                          }),
                        )}
                        onChange={(data: any) => {
                          handleFormChange(
                            data.map(
                              (group: {label: string; value: string}) =>
                                group.value,
                            ),
                            'excludedGroups',
                          );
                        }}
                      />
                    </>
                  </SectionComponentWrapper>
                </SectionCard>
                <VerticalSpacer size="16px" />
                <Button
                  width="full"
                  type="submit"
                  data-form-action={true}
                  isLoading={isLoading}
                  disabled={isEditingPulseFrequency || isDisabled}
                  //   aria-busy={isSubmitting}
                >
                  Save settings
                </Button>
              </>
            )}
          </>
        }
      </EditCheckinsInnerWrapper>
    </EditCheckinsOuterWrapper>
  );
});
