import {activateNotification} from '../../../../../ui/molecules/notification/activate-notification';
import {
  ECheckinsFrequencyType,
  EQuestionType,
  ICheckinsPrioritiesResponseData,
  ICheckinsPulseResponseData,
  ICheckinsQuestionResponseData,
  ICreateQuestionForm,
  ISpecificCheckinsWithoutResponseData,
} from './company-checkins.interface';
import {removeEmptyStringFromObject} from '@utils/data-structure-algos';
import {CompanyCheckinsRequest} from './company-checkins.request';
import {checkinName} from '@utils/checkin-name';

export class CompanyCheckinsController {
  constructor(private readonly request: CompanyCheckinsRequest) {}

  async createCheckinsQuestion(data: ICreateQuestionForm) {
    try {
      await this.request.createCheckinsQuestion(data);
      activateNotification({
        title: 'Success',
        content: `Question created`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinsQuestions(queryFilters?: {
    type?: EQuestionType;
    frequency?: ECheckinsFrequencyType;
    userQuestions?: boolean;
  }) {
    try {
      const response = await this.request.getCheckinsQuestions(queryFilters);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getSpecificCheckinsQuestion(questionId: string) {
    try {
      const response = await this.request.getSpecificCheckinsQuestion(
        questionId,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async updateCheckinQuestion(data: ICreateQuestionForm, questionId: string) {
    try {
      await this.request.updateCheckinQuestion(data, questionId);
      activateNotification({
        title: 'Success',
        content: `Question updated`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async deleteCheckinsQuestion(questionId: string) {
    try {
      await this.request.deleteCheckinsQuestion(questionId);
      activateNotification({
        title: 'Success',
        content: `Question deleted`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getSpecificCheckinsBatch(batchId: string) {
    try {
      const response = await this.request.getSpecificCheckinsBatch(batchId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async updateCheckinBatch(data: ICreateQuestionForm, batchId: string) {
    try {
      await this.request.updateCheckinBatch(data, batchId);
      activateNotification({
        title: 'Success',
        content: `Questions updated`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async deleteCheckinsBatch(batchId: string) {
    try {
      await this.request.deleteCheckinsBatch(batchId);
      activateNotification({
        title: 'Success',
        content: `Questions deleted`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCurrentCheckinsInfo(
    filter?: {weekOfYear?: number; year?: number},
    hideError?: boolean,
  ) {
    try {
      const response = await this.request.getCurrentCheckinsInfo(filter);
      return response;
    } catch (e: any) {
      !hideError &&
        activateNotification({
          title: 'Error',
          content: e?.response?.data?.message,
          kind: 'error',
        });
      return;
    }
  }
  async getCurrentPulseInfo(
    filter?: {weekOfYear?: number; year?: number},
    hideError?: boolean,
  ) {
    try {
      const response = await this.request.getCurrentPulseInfo(filter);
      return response;
    } catch (e: any) {
      !hideError &&
        activateNotification({
          title: 'Error',
          content: e?.response?.data?.message,
          kind: 'error',
        });
      return;
    }
  }

  async getCheckinsQuestionsResponse(questionId: string) {
    try {
      const response = await this.request.getCheckinsQuestionsResponse(
        questionId,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async updateCheckinsQuestionsResponse(
    data: ICheckinsQuestionResponseData,
    questionId: string,
  ) {
    try {
      await this.request.updateCheckinsQuestionsResponse(data, questionId);

      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinsPulseResponse(pulseId: string) {
    try {
      const response = await this.request.getCheckinsPulseResponse(pulseId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async updateCheckinsPulseResponse(
    data: ICheckinsPulseResponseData,
    pulseId: string,
  ) {
    try {
      await this.request.updateCheckinsPulseResponse(data, pulseId);

      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinsPrioritiesResponse(prioritiesId: string) {
    try {
      const response = await this.request.getCheckinsPrioritiesResponse(
        prioritiesId,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async updateCheckinsPrioritiesResponse(
    data: ICheckinsPrioritiesResponseData,
    prioritiesId: string,
  ) {
    try {
      await this.request.updateCheckinsPrioritiesResponse(data, prioritiesId);

      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getListOfCheckins(filter?: {
    weekOfYear?: number;
    year?: number;
    isActive?: boolean;
    isSubmitted?: boolean;
    isReviewed?: boolean;
    reviewer?: string;
    manager?: string;
    user?: string;
    page?: number;
  }) {
    try {
      const response = await this.request.getListOfCheckins(filter);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
  async getPulses(filter?: {
    weekOfYear?: number;
    year?: number;
    isActive?: boolean;
    isSubmitted?: boolean;
    isReviewed?: boolean;
    reviewer?: string;
    manager?: string;
    user?: string;
    page?: number;
  }) {
    try {
      const response = await this.request.getPulses(filter);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinById(checkinId: string) {
    try {
      const response = await this.request.getCheckinById(checkinId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async updateCheckinById(
    data: ISpecificCheckinsWithoutResponseData,
    checkinId: string,
  ) {
    try {
      await this.request.updateCheckinById(
        removeEmptyStringFromObject(data),
        checkinId,
      );
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinByIdAndFetchResponses(checkinId: string) {
    try {
      const response = await this.request.getCheckinByIdAndFetchResponses(
        checkinId,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async submitSpecificCheckin(
    data: ISpecificCheckinsWithoutResponseData,
    checkinId: string,
    type?: 'update' | 'submit' | 'report',
  ) {
    try {
      await this.request.submitSpecificCheckin(data, checkinId);
      activateNotification({
        title: 'Success',
        content: ` ${checkinName()} ${
          type === 'update'
            ? 'updated'
            : type === 'report'
            ? 'reviewed'
            : 'submitted'
        }`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinsCalendarWeekList(filter?: {
    manager?: string;
    user?: string;
  }) {
    try {
      const response = await this.request.getCheckinsCalendarWeekList(filter);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinsQuestionsTemplates(filter?: {
    type?: string;
    getTypes?: string;
  }) {
    try {
      const response = await this.request.getCheckinsQuestionsTemplates(filter);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }
}
