import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactSelect, {ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  Item,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
  OptionsWrapper,
} from './styled';
import {FlexRow} from '@ui/style/styles';
import {GreenWhitePlusIcon} from '@ui/atoms/icons/greenWhitePlus';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import {MagnifyingGlassIcon} from '../../../atoms/icons';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {SearchBox} from '../../search-box';
import {SelectFieldProps} from '../user-multi-select/user-multi-select';
import {CTA, Body2, Body1} from '../../../atoms/typography';

export const GroupsSelectField = forwardRef(
  (
    {
      label,
      state,
      helper,
      reviewer,
      placeholder,
      options,
      groupType,
      selectAllOption,
      showCancelIcon,
      setValue,
      type,
      height,
      margin,
      onClickCustomOption,
      showSearchIcon,
      performance,
      groupDisplayLength,
      isCheck,
      setClearAll,
      removeSelectedFromDropdown,
      cancelSelection,
      handleCheckboxcheck,
      onChange,
      theme,
      value,
      handleGroupSelect,
      clearOptions,
      reportingStyling,
      customOption,
      noOptionMessage,
      defaultValue,
      disabled,
      clearManagers,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<any>>,
  ) => {
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const getValue = useMemo(() => {
      let selectedOption = null;

      options?.map((option: any) => {
        if (defaultValue) {
          const getGroup = option.groups?.find(
            (grp: any) => grp.id === defaultValue,
          );
          if (getGroup) {
            selectedOption = {
              label: getGroup.name,
              value: getGroup.id,
              groupType: option?.id,
            };
          }
          return null;
        }
        return null;
      });
      return selectedOption;
    }, [defaultValue, options]);

    const [selectedOptions, setSelectedOptions] = useState<any>({
      label: '',
      value: '',
      groupType: '',
    });

    const [defaultValueUse, setDefaultValueUse] = useState(false);

    useEffect(() => {
      if (defaultValue && !defaultValueUse) {
        setSelectedOptions(getValue);
        setDefaultValueUse(true);
      }
    }, [getValue, defaultValue, defaultValueUse, options]);

    const [groupTypeName, setGroupTypeName] = useState<any>([]);

    const [search, setSearch] = useState('');

    const handleChange = useCallback(
      (opts: any, action: ActionMeta<any>) => {
        setSelectedOptions(opts);
        if (onChange) {
          onChange(opts);
        }
        handleClose();
      },
      [onChange, handleClose],
    );

    const handleSearch = (e: any) => {
      setSearch(e.target.value);
    };

    const handleCancelFx = useCallback(
      (value: string, clearAll?: any) => {
        const options = selectedOptions.filter(
          (option: any) => option.value !== value,
        );
        if (clearAll) {
          setSelectedOptions([]);
          if (onChange) onChange([]);
        } else {
          setSelectedOptions(options);
          if (onChange) onChange(options);
          if (cancelSelection) cancelSelection(options);
        }
      },
      [selectedOptions, onChange, cancelSelection],
    );

    const handleGroupTypeName = (name: any) => {
      if (groupTypeName?.includes(name)) {
      } else {
        setGroupTypeName([...groupTypeName, name]);
      }
    };
    const filterOption = useMemo(() => {
      if (search) {
        return options
          .filter(
            (option) =>
              option.groups.filter((d: any) =>
                d.name.toLowerCase().includes(search.toLowerCase()),
              )?.length > 0,
          )
          .map((option) => {
            return {
              ...option,
              groups: option.groups.filter((d: any) =>
                d.name.toLowerCase().includes(search.toLowerCase()),
              ),
            };
          });
      } else if (!options) return [];
      else return options;
    }, [options, search]);

    useEffect(() => {
      if (clearOptions) {
        handleCancelFx('', true);
      }
    }, [clearOptions, handleCancelFx]);

    return (
      <FieldWrapper margin={margin}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              performance={performance}
              state={state}
              height={height}
              reportingStyling={reportingStyling}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {selectedOptions?.value ? (
                    <>
                      <>
                        {groupTypeName?.length > 0 ? (
                          <Body2
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            {groupTypeName[0]}{' '}
                            {groupTypeName?.length === 1 && ':'}
                          </Body2>
                        ) : null}
                      </>

                      <Body1>{selectedOptions?.label}</Body1>
                    </>
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {!disabled ? (
                    <>
                      {showSearchIcon ? (
                        <MagnifyingGlassIcon />
                      ) : (
                        <motion.span
                          initial={{rotate: 0}}
                          animate={{
                            rotate: open ? 180 : 0,
                            marginTop: open ? '-6px' : '0px',
                          }}
                          transition={{
                            duration: 0.3,
                          }}
                          key="user-select-toggled">
                          <ArrowHeadDownIcon />
                        </motion.span>
                      )}
                    </>
                  ) : (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: 0,
                        marginTop: '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      {showSearchIcon ? (
                        <MagnifyingGlassIcon />
                      ) : (
                        <ArrowHeadDownIcon />
                      )}
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>

            {open && (
              <>
                <DropdownWrapper>
                  <div style={{padding: '5px 8px'}}>
                    <SearchBox
                      placeholder="Search group"
                      onChange={handleSearch}
                      style={{height: '38px', marginBottom: '16px'}}
                    />
                    <div>
                      {filterOption?.map((grp: any) => (
                        <GroupsSelect
                          groups={grp}
                          disabled={disabled}
                          theme={theme}
                          selectedOptions={selectedOptions}
                          search={search}
                          handleGroupTypeName={handleGroupTypeName}
                          handleChange={handleChange}
                          isCheck={isCheck}
                          handleCheckboxcheck={handleCheckboxcheck}
                        />
                      ))}
                    </div>
                    {customOption && (
                      <FlexRow
                        onClick={() => {
                          onClickCustomOption();
                          handleClose();
                        }}
                        style={{marginTop: '6px', cursor: 'pointer'}}>
                        <GreenWhitePlusIcon /> <HorizontalSpacer size="12px" />{' '}
                        <CTA kind="textDark" style={{fontWeight: 600}}>
                          Manage groups
                        </CTA>
                      </FlexRow>
                    )}
                  </div>
                </DropdownWrapper>
              </>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
export const GroupsSelect = ({
  groups,
  disabled,
  search,
  handleChange,
  theme,
}: any) => {
  const isUsers = theme === 'users';
  const [openGroupType, setOpenGroupType] = useState(isUsers);

  const filterGroups = groups?.groups?.filter((item: any) =>
    search ? item?.name.toLowerCase().includes(search?.toLowerCase()) : item,
  );

  return (
    <>
      {isUsers && (
        <div className="bg-[#FAFAFA] py-2 px-2 rounded-lg ">
          <Body2 kind={!openGroupType ? 'textBody' : 'textDark'} weight="bold">
            {groups?.name}
          </Body2>
        </div>
      )}

      {!isUsers && (
        <div
          onClick={() => setOpenGroupType(!openGroupType)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            padding: '8px 8px',
          }}>
          <Body2
            kind={!openGroupType ? 'textBody' : 'textDark'}
            style={openGroupType ? {fontWeight: 500} : {fontWeight: 400}}>
            {groups?.name}
          </Body2>
          <ArrowIconWrapper disabled={disabled}>
            {!disabled ? (
              <motion.span
                initial={{rotate: 0}}
                animate={{
                  rotate: openGroupType ? 180 : 0,
                  marginTop: openGroupType ? '-6px' : '0px',
                }}
                transition={{
                  duration: 0.3,
                }}
                key="user-select-toggled">
                <ArrowHeadDownIcon />
              </motion.span>
            ) : (
              <motion.span
                initial={{rotate: 0}}
                animate={{
                  rotate: 0,
                  marginTop: '0px',
                }}
                transition={{
                  duration: 0.3,
                }}
                key="user-select-toggled">
                <ArrowHeadDownIcon />
              </motion.span>
            )}
          </ArrowIconWrapper>
        </div>
      )}

      {openGroupType && (
        <>
          {groups?.groups?.length > 0 ? (
            filterGroups.map((item: any) => (
              <Item
                style={{cursor: 'pointer'}}
                onClick={(e: any) => {
                  handleChange(
                    {
                      label: item?.name,
                      value: item.id,
                      groupType: groups?.id,
                    },
                    e,
                  );
                }}>
                <Body2 kind="textBody" style={{marginLeft: '8px'}}>
                  {item?.name}
                </Body2>
              </Item>
            ))
          ) : (
            <Item>
              <Body2 kind="textBody">Not option available</Body2>
            </Item>
          )}
        </>
      )}
    </>
  );
};
