import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {capitalizeWords} from '@utils';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {Headline3, Body2} from '@ui/atoms/typography';
import {SubmitCheckIcon} from '@ui/atoms/icons/check';
import styled from 'styled-components';
import {GoalsSelectField} from '@ui/molecules/select/goal-select-field/goal-select-field';
import {usePeriodHook} from '@hooks/period-hook';
import {MeetingSelectField} from '@ui/organisms/meetings/meeting-select-field/meeting-select-field';
import {LockIcon} from '@ui/atoms/icons/lock';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {ItemLoader} from '@ui/organisms/item-loader';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {DateRangeInput} from '@ui/molecules/date-range-input';
import dayjs from 'dayjs';
import {SectionCard} from '@ui/layouts/section-card';
import {IAPIUserFormat} from '@hooks';
import {get} from 'idb-keyval';
import {authStore} from '@store/stores/auth-store';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {Note, DateWrapper} from '../styles';
import {MemberActivity} from '@pages/dashboard/you/you-components/insights/activity/activity';
import {GoalSection} from '@pages/dashboard/you/you-components/insights/goal/goal-card';
import {KeyResultSection} from '@pages/dashboard/you/you-components/insights/keyresults/key-results-cards';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {DateSelectField} from '@ui/molecules/select/date-input/date-input';
import {useCompanyReportingHook} from '@pages/dashboard/reporting/company-reporting.hooks';
import {cloudinary} from '@assets/images/cloudinary';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {useCallback, useEffect, useState} from 'react';
import {FeedbackItem} from '@pages/feedback/feedback-item';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {StatusWrapper} from '../styles';
import {ProgressOnObjectives} from '@pages/dashboard/checkins/my_checkins/components/objectives-progress';
import {Priorities} from '@pages/dashboard/checkins/my_checkins/components/priorities';
import {MoodBox} from '@pages/dashboard/checkins/my_checkins/components/mood-box';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {IMeetingResponse, TGoalResponse} from '@hooks';
import {useCheckinsSearch} from './view-user-hooks';
import {SelectField} from '@ui/molecules/select/selectfield';
import {Feedback} from '@pages/feedback/feedback-items';
import {MoreAboutWorkLife} from '@pages/dashboard/checkins/my_checkins/components/work-life';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {checkinName} from '@utils/checkin-name';
import {MeetingAgenda} from './agenda';
import {NextSteps} from './next-steps';

const {emptyGoals} = cloudinary;

const BorderWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 16px;
`;

interface ViewUserProps {
  open: boolean;
  onClose: () => void;
  name: string;
  user: string;
  type: 'check-in' | 'objective' | 'feedback' | '1:1';
}

export const ViewUser = ({open, type, onClose, name, user}: ViewUserProps) => {
  const [date, setDate] = useState({
    starts: '',
    ends: '',
  });

  const {periods} = usePeriodHook();

  const title = {
    objective: 'goals',
    'check-in': 'updates',
    feedback: 'feedback',
    '1:1': '1:1s',
  };

  const [showDateRange, setShowDateRange] = useState(false);

  const onDateError = new Date(date.ends) < new Date(date.starts);

  return (
    <div>
      <DrawerModal open={open} onClose={onClose}>
        <div>
          <Headline3>
            View {capitalizeWords(name)}’s {title[type]}
          </Headline3>
          <VerticalSpacer size="16px" />
          <DateSelectField
            options={periods}
            inputStyle={{height: '44px', borderRadius: '9px'}}
            placeholder={'Select option'}
            margin
            name="questionType"
            customOptionName="Choose custom timeline"
            maxHeight={400}
            disabledIcon={<LockIcon />}
            onChange={(data: {
              value: string;
              label?: boolean;
              starts: string;
              ends: string;
            }) => {
              if (data.value === 'custom') {
                return setShowDateRange(true);
              }
              setDate({
                starts: data.starts,
                ends: data.ends,
              });
              showDateRange && setShowDateRange(false);
            }}
            fieldNotFoundPlaceHolder={(searchTerm?: string) =>
              `Oops! this period does not exist`
            }
            optionsSelectType="period"
          />
          {showDateRange && (
            <DateWrapper>
              <DateRangeInput
                name="date"
                value={{starts: date.starts, ends: date.ends}}
                helper={onDateError ? 'Start date must be before end date' : ''}
                state={onDateError ? 'error' : 'default'}
                admin={true}
                placeholder="Select Date"
                inputStyle={{height: '44px'}}
                setValue={(e) => {
                  if (
                    e.starts &&
                    e.ends &&
                    new Date(e.ends) > new Date(e.starts)
                  ) {
                    setDate({
                      starts: dayjs(e.starts).format('MM/DD/YYYY'),
                      ends: dayjs(e.ends).format('MM/DD/YYYY'),
                    });
                  }
                }}
              />
            </DateWrapper>
          )}
          <VerticalSpacer size="10px" />
          {type === 'objective' && (
            <SearchObjective date={date} user={user} name={name} />
          )}
          {type === 'check-in' && (
            <SearchCheckin date={date} user={user} name={name} />
          )}
          {type === 'feedback' && (
            <SearchFeedback date={date} user={user} name={name} />
          )}
          {type === '1:1' && (
            <SearchMeetings date={date} user={user} name={name} />
          )}
        </div>
      </DrawerModal>
    </div>
  );
};

const DateInput = ({
  onChange,
  date,
}: {
  onChange?: (date: any) => void;
  date: {starts: string; ends: string};
}) => {
  const {periods} = usePeriodHook();

  const [showDateRange, setShowDateRange] = useState(false);

  const onDateError = new Date(date.ends) < new Date(date.starts);

  return (
    <div>
      <DateSelectField
        options={periods}
        inputStyle={{height: '44px', borderRadius: '9px'}}
        placeholder={'Select option'}
        margin
        name="questionType"
        customOptionName="Choose custom timeline"
        maxHeight={400}
        disabledIcon={<LockIcon />}
        onChange={(data: {
          value: string;
          label?: boolean;
          starts: string;
          ends: string;
        }) => {
          if (data.value === 'custom') {
            return setShowDateRange(true);
          }
          onChange &&
            onChange({
              starts: data.starts,
              ends: data.ends,
            });
          showDateRange && setShowDateRange(false);
        }}
        fieldNotFoundPlaceHolder={(searchTerm?: string) =>
          `Oops! this period does not exist`
        }
        optionsSelectType="period"
      />
      {showDateRange && (
        <DateWrapper>
          <DateRangeInput
            name="date"
            value={{starts: date.starts, ends: date.ends}}
            helper={onDateError ? 'Start date must be before end date' : ''}
            state={onDateError ? 'error' : 'default'}
            admin={true}
            placeholder="Select Date"
            inputStyle={{height: '44px'}}
            setValue={(e) => {
              if (e.starts && e.ends && new Date(e.ends) > new Date(e.starts)) {
                onChange &&
                  onChange({
                    starts: dayjs(e.starts).format('MM/DD/YYYY'),
                    ends: dayjs(e.ends).format('MM/DD/YYYY'),
                  });
              }
            }}
          />
        </DateWrapper>
      )}
    </div>
  );
};
interface SearchProps {
  date?: {starts: string; ends: string};
  user: string;
  name: string;
  useInnerDate?: boolean;
}
const useDateHook = (
  _date?: {starts: string; ends: string},
  useInnerDate?: boolean,
) => {
  const [customDate, setDate] = useState({
    starts: '',
    ends: '',
  });

  const date = useInnerDate
    ? customDate
    : _date
    ? _date
    : {starts: '', ends: ''};

  return {
    date,
    setDate,
  };
};
export const SearchObjective = ({
  date: _date,
  user,
  useInnerDate,
}: SearchProps) => {
  const {getGoals, objectiveOptions = [], loading} = useCompanyReportingHook();

  const {date, setDate} = useDateHook(_date, useInnerDate);

  const EmptySearch = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    title: `Search for a
      goal
       above. `,
  };

  // Objective
  useEffect(() => {
    if (date.starts && date.ends) {
      getGoals(
        {
          goalPage: 1,
          startDate: date.starts,
          endDate: date.ends,
          removeKpiFilter: true,
          member: user,
        },
        '',
      );
    }
  }, [getGoals, date, user]);

  const [
    selectedObjectiveData,
    setSelectedObjective,
  ] = useState<TGoalResponse>();

  const [loadingGoal, setLoadingGoal] = useState(false);

  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
  } = useStoreContext();

  const fetchSpecificObjective = useCallback(
    async (id: string) => {
      setLoadingGoal(true);

      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const response = await controller.fetchReportGoalById(id);
      const includeGroups = async (goalsResponse: any) => {
        if (goalsResponse.goalType === 'group') {
          const getGroup = getGroupTypeAndGroup(
            groups,
            groupType,
            goalsResponse.group,
          );

          return {
            ...goalsResponse,
            ...getGroup,
            groupType: {name: getGroup?.groupType},
          };
        } else {
          return {
            ...goalsResponse,
          };
        }
      };

      const unfurledData = await includeGroups(response);

      response && setSelectedObjective(unfurledData);
      setLoadingGoal(false);

      return response;
    },
    [groups, groupType],
  );

  return (
    <>
      <>
        <FlexRow>
          {useInnerDate && (
            <div style={{width: '55%', marginRight: '10px'}}>
              <DateInput onChange={setDate} date={date} />
            </div>
          )}

          <div style={{width: '100%'}}>
            {loading ? (
              <FlexRowCenter>
                <ItemLoaderLine />
              </FlexRowCenter>
            ) : (
              <GoalsSelectField
                options={objectiveOptions}
                margin
                inputStyle={{height: '44px', borderRadius: '9px'}}
                useProgress
                onChange={(data: {value: string}) => {
                  fetchSpecificObjective(data.value);
                }}
                placeholder={`Search goals`}
              />
            )}
          </div>
        </FlexRow>
      </>

      <VerticalSpacer size="24px" />

      <BorderWrapper>
        {loadingGoal ? (
          <FlexRowCenter>
            <ItemLoader />
          </FlexRowCenter>
        ) : selectedObjectiveData ? (
          <>
            <GoalSection goal={selectedObjectiveData} excludeCopyLink />

            <VerticalSpacer size="24px" />

            {selectedObjectiveData.keyResults.length > 0 && (
              <KeyResultSection keyResult={selectedObjectiveData.keyResults} />
            )}
            <VerticalSpacer size="34px" />
            <MemberActivity goal={selectedObjectiveData as any} isReadOnly />
          </>
        ) : (
          <EmptyPlaceholder {...EmptySearch} />
        )}
      </BorderWrapper>
    </>
  );
};

export const SearchFeedback = ({
  date: _date,
  user,
  useInnerDate,
}: SearchProps) => {
  const EmptySearch = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    title: `Select a period to view feedback`,
  };
  const {date} = useDateHook(_date, useInnerDate);

  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchUserFeedback = useCallback(async (): Promise<any[]> => {
    setLoading(true);
    const request = new FeedbackRequestImpl();
    const controller = new FeedbackController(request);
    const response = await controller.getFeedback(
      {
        startDate: date.starts,
        endDate: date.ends,
      },
      user,
    );
    if (response) {
      response && setFeedbacks(response);
      setLoading(false);
      return response;
    }
    setLoading(false);

    return [];
  }, [date.starts, date.ends, user]);

  useEffect(() => {
    if (date.starts && date.ends) {
      fetchUserFeedback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <>
      {loading ? (
        <BorderWrapper>
          <FlexRowCenter>
            <ItemLoader />
          </FlexRowCenter>
        </BorderWrapper>
      ) : feedbacks.length ? (
        feedbacks.map((feedback) => (
          <>
            <FeedbackItem {...feedback} showReviewer />
            <VerticalSpacer size="16px" />
          </>
        ))
      ) : (
        <BorderWrapper>
          <EmptyPlaceholder {...EmptySearch} />
        </BorderWrapper>
      )}
    </>
  );
};

export const SearchMeetings = ({
  date: _date,
  user,
  useInnerDate,
}: SearchProps) => {
  const {date} = useDateHook(_date, useInnerDate);
  const EmptySearch = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    title: `Select a period to view 1:1s`,
  };

  const [loading, setLoading] = useState(false);
  const [meetingOptions, setMeetingOptions] = useState([]);

  const fetchMeetings = useCallback(async (): Promise<any[]> => {
    setLoading(true);
    const request = new MeetingRequestImpl();
    const controller = new MeetingController(request);
    const response = await controller.getMeetings({
      startDate: date.starts,
      endDate: date.ends,
      status: 'completed',
      participant: user,
    });

    if (response) {
      const transformOptions = response.results.map(
        (meeting: IMeetingResponse) => {
          const formatName = userName(meeting.participant);

          return {
            label: {
              name: `1:1 with ${formatName} `,
              startDate: dayjs(meeting.startDate).format('ddd. D MMM. `YY'),
              time: meeting.time,
            },

            value: meeting.id,
          };
        },
      );

      setMeetingOptions(transformOptions);

      setLoading(false);
      return response;
    }
    setLoading(false);

    return [];
  }, [date.starts, date.ends, user]);

  useEffect(() => {
    if (date.starts && date.ends) {
      fetchMeetings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const [loadingMeeting, setLoadingMeeting] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<IMeetingResponse>();

  const fetchSpecificMeeting = useCallback(
    async (id: string) => {
      setLoadingMeeting(true);

      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);
      const response = await controller.getMeeting(id);
      const users = await get(`${authStore.auth.user.workspace.id}/users`);

      if (response) {
        const _response = {
          ...response,
          user: users.find((user: IAPIUserFormat) => user.id === response.user),
          participant: users.find(
            (user: IAPIUserFormat) => user.id === response.participant,
          ),
        };
        if (response.feedback) {
          const request = new FeedbackRequestImpl();
          const controller = new FeedbackController(request);

          const feedbackResponse = await controller.getFeedback(
            {subjectId: id, subjectType: '1:1'},
            user,
          );

          if (feedbackResponse) _response.feedback = feedbackResponse[0];
        }

        setSelectedMeeting(_response);
        setLoadingMeeting(false);

        return _response;
      }

      setLoadingMeeting(false);

      return response;
    },
    [user],
  );

  const ShowHostNote = () => {
    if (
      selectedMeeting &&
      (selectedMeeting.hostNote ||
        authStore.auth.user.id === selectedMeeting.user.id)
    ) {
      return (
        <>
          <SectionCard
            contentStyling={{padding: '24px'}}
            CustomHeaderTitle={
              <Headline3>{userName(selectedMeeting.user)}'s notes</Headline3>
            }>
            <div>
              <Note>{selectedMeeting.hostNote}</Note>
            </div>
          </SectionCard>
          <VerticalSpacer size="16px" />
        </>
      );
    }
    return null;
  };

  return (
    <>
      {loading ? (
        <FlexRowCenter>
          <ItemLoaderLine />
        </FlexRowCenter>
      ) : (
        <MeetingSelectField
          options={meetingOptions}
          margin
          inputStyle={{height: '44px', borderRadius: '9px'}}
          onChange={(data: {value: string}) => {
            // updateOption('objective', data.value);
            fetchSpecificMeeting(data.value);
          }}
          placeholder={`Search 1:1s`}
        />
      )}

      <VerticalSpacer size="24px" />
      {loadingMeeting ? (
        <BorderWrapper>
          <FlexRowCenter>
            <ItemLoader />
          </FlexRowCenter>
        </BorderWrapper>
      ) : selectedMeeting ? (
        <>
          <MeetingAgenda
            isReadOnly
            meetingId={selectedMeeting.id}
            isHostSharing={selectedMeeting.shareHostAgenda}
            users={{
              user: selectedMeeting.user.id,
              participant: [selectedMeeting.participant.id],
            }}
            participantSharing={selectedMeeting.shareParticipantAgenda}
            updateMeeting={async (value, field) => {}}
            agendas={{
              host: {
                user: selectedMeeting.user,
                agenda: selectedMeeting.hostAgenda,
              },
              participant: {
                user: selectedMeeting.participant,
                agenda: selectedMeeting.participantAgenda,
              },
            }}
          />

          <div>
            <VerticalSpacer size="24px" />
            <NextSteps
              isReadOnly
              steps={selectedMeeting.nextSteps}
              users={[selectedMeeting.user.id, selectedMeeting.participant]}
              updateMeeting={async (data) => {}}
            />
            <VerticalSpacer size="24px" />

            <ShowHostNote />

            {selectedMeeting.feedback && (
              <SectionCard
                contentStyling={{padding: '24px'}}
                CustomHeaderTitle={
                  <Headline3>Write feedback/praise</Headline3>
                }>
                <div>
                  <FeedbackItem
                    {...(selectedMeeting.feedback as any)}
                    excludeSubject
                  />
                </div>
              </SectionCard>
            )}
          </div>
        </>
      ) : (
        <BorderWrapper>
          <EmptyPlaceholder {...EmptySearch} />
        </BorderWrapper>
      )}
    </>
  );
};
export const SearchCheckin = ({
  date: _date,
  user,
  name,
  useInnerDate,
}: SearchProps) => {
  const EmptySearch = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    imports: true,
    subtitle: '',
    title: `Search for an
        ${checkinName()}
       above. `,
  };
  const {date, setDate} = useDateHook(_date, useInnerDate);
  const {
    initWorkspaceCheckins,
    checkinInfo,
    loadingCheckins,
    checkinsOptions,
    checkinGoals,
    loadingGoals,
    initCheckinGoals,
    fetchCheckinWeekList,
  } = useCheckinsSearch(date, user);

  useEffect(() => {
    if (date.starts && date.ends) {
      fetchCheckinWeekList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const checkinsStatus = useCallback(
    (specificUserCheckins: {
      isReviewed?: boolean;
      submittedAt?: string;
      isSubmitted?: boolean;
    }) => {
      if (specificUserCheckins?.isReviewed) {
        return (
          <StatusWrapper>
            <FlexRowSpaceBetween>
              <Body2 kind="textBody" weight="semibold">
                Submitted{' '}
                {specificUserCheckins?.submittedAt
                  ? dayjs(specificUserCheckins?.submittedAt).format('MMMM DD')
                  : null}
              </Body2>{' '}
              <HorizontalSideRule
                size="19px"
                color="#cecede"
                style={{margin: '0px 8px'}}
              />
              <Body2
                kind="textBody"
                weight="semibold"
                style={{display: 'flex', alignItems: 'center'}}>
                Reviewed{' '}
                <span style={{marginLeft: '6px', marginTop: '4px'}}>
                  <SubmitCheckIcon status={true} />
                </span>
              </Body2>
            </FlexRowSpaceBetween>
          </StatusWrapper>
        );
      } else if (specificUserCheckins?.isSubmitted) {
        return (
          <StatusWrapper>
            <Body2 kind="textBody" weight="semibold">
              Submitted{' '}
              {specificUserCheckins?.submittedAt
                ? dayjs(specificUserCheckins?.submittedAt).format('MMMM DD')
                : null}
            </Body2>
          </StatusWrapper>
        );
      } else {
        return (
          <StatusWrapper>
            <Body2 kind="textBody" weight="semibold">
              Not submitted
            </Body2>
          </StatusWrapper>
        );
      }
    },
    [],
  );

  return (
    <>
      <FlexRow>
        {useInnerDate && (
          <div style={{width: '50%', marginRight: '10px'}}>
            <DateInput onChange={setDate} date={date} />
          </div>
        )}
        <div style={{width: '100%'}}>
          <SelectField
            options={checkinsOptions}
            margin
            onChange={(data: {
              value: string;
              year: string;
              dateStart: string;
              dateEnd: string;
            }) => {
              initWorkspaceCheckins({
                weekOfYear: Number(data.value),
                year: Number(data.year),
              });
              initCheckinGoals({
                startDate: data.dateStart,
                endDate: data.dateEnd,
                member: user,
                removeKpiFilter: true,
              });
            }}
            borderRadius={10}
            reportingStatus
            state="performance"
            placeholder={`Search updates`}
          />
        </div>
      </FlexRow>
      <VerticalSpacer size="16px" />
      {loadingCheckins ? (
        <FlexRowCenter>
          <ItemLoader />
        </FlexRowCenter>
      ) : checkinInfo ? (
        <>
          {' '}
          <MoodBox
            isReadOnly
            value={checkinInfo.responses?.pulse as any}
            header={
              <FlexRowSpaceBetween>
                <Headline3> How {capitalizeWords(name)} is feeling </Headline3>
                {checkinsStatus(checkinInfo)}
              </FlexRowSpaceBetween>
            }
          />
          <VerticalSpacer size="24px" />
          <ProgressOnObjectives
            loadingGoals={loadingGoals}
            isReadOnly
            goals={checkinGoals}
          />
          <VerticalSpacer size="24px" />
          <Priorities
            isReadOnly
            handleFormValueChange={(value: any, field: string) => null}
            updatePriorities={(value: any) => null}
            debounceUpdatePriorities={(value: any) => null}
            loadingCheckins={false}
            value={checkinInfo.responses?.priorities as any}
          />
          <VerticalSpacer size="24px" />
          <MoreAboutWorkLife
            isReadOnly
            handleFormValueChange={(value: any, field: string) => null}
            debounceUpdateQuestions={(value: any) => null}
            value={checkinInfo.responses?.questions as any}
          />
        </>
      ) : (
        <BorderWrapper>
          <EmptyPlaceholder {...EmptySearch} />
        </BorderWrapper>
      )}
    </>
  );
};
