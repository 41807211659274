import {ItemLoaderWrapper} from '@pages/dashboard/you/you-components/insights/insights';
import {useState} from 'react';
import {Checkbox} from '@ui/atoms/checkbox';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {useFirebaseFetch} from '@hooks/query-hook';
import {Body2, Headline3} from '@ui/atoms/typography';
import {CommentDetails} from '@ui/layouts/users-goals/comments';
import {ItemLoader} from '@ui/organisms/item-loader';
import styled from 'styled-components';
import {capitalize} from '@utils';
import {observer} from 'mobx-react-lite';
import {useStoreContext} from '@store/store-context';
import {VerticalSpacer} from '@ui/atoms/spacer';

const PriorityWrapper = styled.div`
  padding: 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
  .priority-details {
    margin-top: 8px;
    border-top: 1px solid #ededf2;
    .flex-row {
      display: flex;
      align-items: flex-start;
      .checkbox {
        margin: 2px 6px 0px 0;
      }
      margin: 16px 0 5px 0px;
    }
  }
`;

interface CommentsProps {
  id: string;
  onClose: () => void;
  userId?: string;
  meetingId: string;
  content: {
    text: string;
    completed: boolean;
  };
}

export const MeetingComments = observer(
  ({id, onClose, userId, content, meetingId}: CommentsProps) => {
    const {
      authStore: {auth},
    } = useStoreContext();

    const [loading] = useState(false);

    const PATH = `meetings/${meetingId}/agenda/${id}`;

    const {
      data = {
        comments: [],
      },
    } = useFirebaseFetch(PATH);

    const {data: subscribers} = useFirebaseFetch(`${PATH}/subscribers`);

    if (!id) {
      return null;
    }

    if (loading) {
      return (
        <ItemLoaderWrapper>
          <ItemLoader />
        </ItemLoaderWrapper>
      );
    }

    return (
      <DrawerModal onClose={onClose} open={true}>
        <VerticalSpacer size="8px" />
        <PriorityWrapper>
          <Body2 weight="semibold">Agenda</Body2>
          <div className="priority-details">
            <div className="flex-row">
              <div className="checkbox">
                <Checkbox checked={content?.completed} />
              </div>
              <div>
                <Headline3>{capitalize(content?.text)}</Headline3>
              </div>
            </div>
          </div>
        </PriorityWrapper>
        <CommentDetails
          pathId={id}
          allowedMentionedUsers={userId ? [userId] : [auth.user.reviewer]}
          mentionsPath={'meetings'}
          allGoalSubscribers={subscribers}
          defaultSubscribers={[auth.user.id, auth.user.reviewer]}
          path={PATH}
          comments={{comments: data?.comments}}
        />
      </DrawerModal>
    );
  },
);
