import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  forwardRef,
  useEffect,
} from 'react';
import {InputProps} from '../input/input';
import {Colors} from '@ui/interface';
import {TextAreaNoBorder} from '../textArea/textarea';
import nextId from 'react-id-generator';

interface FieldInputProps extends InputProps {
  handleKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onHandleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  useCompletedAsDisabled?: boolean;
  disabled?: boolean;
  fontWeight?: number;
  background?: Colors;
  colorString?: Colors;
  readonly?: boolean;
}

export const FieldInput: FC<FieldInputProps> = forwardRef(
  (
    {
      handleKeyDown,
      onHandleChange,
      readonly,
      checked,
      useCompletedAsDisabled,
      disabled,
      colorString,
      fontWeight,
      background,
      ...props
    },
    textAreaRef: any,
  ) => {
    useEffect(() => {
      resizeTextArea();
    }, []);

    const resizeTextArea = () => {
      if (textAreaRef.current) {
        const scrollHeight = textAreaRef.current.scrollHeight;

        textAreaRef.current!.style.height = 'auto';

        textAreaRef.current!.style.height = `${scrollHeight}px`;
      }
    };

    return (
      <TextAreaNoBorder
        aria-label="input"
        onChange={(event) => {
          onHandleChange && onHandleChange(event as any);
          resizeTextArea();
        }}
        ref={textAreaRef}
        autoFocus={textAreaRef.current === document.activeElement}
        style={{
          minHeight: '10px',
          background: 'transparent',
          padding: '0px 14px',
          maxWidth: '100%',
        }}
        multiple
        fontWeight={fontWeight}
        colorString={
          checked
            ? useCompletedAsDisabled
              ? 'textDark'
              : 'textMuted'
            : disabled
            ? 'textMedium'
            : colorString
        }
        background={
          disabled
            ? 'backgroundDark'
            : useCompletedAsDisabled
            ? 'backgroundLight'
            : background
        }
        {...(props as any)}
        id={nextId('fields')}
        readOnly={readonly || checked}
      />
    );
  },
);
