import {IMeetingResponse} from '@hooks';
import {useActionItems} from '@hooks/features-action-items';
import {useFirebaseFetch} from '@hooks/query-hook';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useStoreContext} from '@store/store-context';
import {authStore} from '@store/stores/auth-store';
import {Button} from '@ui/atoms/button';
import {FilterIcon} from '@ui/atoms/icons/filter-icon';
import {Body2} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {userName} from '@utils/user-name';
import React, {useState} from 'react';

interface MeetingTabsProps {
  userId: string | string[];
  meetings: IMeetingResponse[];
}

export const ActionItemsTab = ({userId, meetings}: MeetingTabsProps) => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const [sortBy, setSortBy] = useState('createdAt');

  const {handleChange, tasks} = useActionItems({
    userId: '',
    itemId: '',
    source: 'meeting',
    orderBy: {
      key: 'source',
      value: 'meeting',
    },
  });

  const userIds = Array.isArray(userId) ? userId : [userId];

  const meetingIds = meetings.map((meeting) => meeting.id);

  const computedTasks = tasks.filter(
    (task) =>
      meetingIds.includes(task.sourceId) ||
      userIds.includes(task.user) ||
      userIds.includes(task.assignee),
  );

  const filterPath = `users/${auth.user.id}/action_items/filter`;

  const {data: actionItemsFilterPath} = useFirebaseFetch(filterPath);

  const isCustomFilter = actionItemsFilterPath === 'custom';

  const actionItems = computedTasks.length
    ? computedTasks.sort((a: any, b: any) => {
        if (isCustomFilter) {
          return a.order - b.order;
        }
        // Handle cases where dueDate is missing or invalid
        const aDate = a?.[sortBy]
          ? new Date(a[sortBy])
          : new Date(8640000000000000); // Largest possible date

        const bDate = b?.[sortBy]
          ? new Date(b[sortBy])
          : new Date(8640000000000000); // Largest possible date

        // Sort in ascending order
        return aDate.getTime() - bDate.getTime();
      })
    : [
        {
          text: '',
          completed: '',
        },
      ];

  return (
    <div>
      <SectionCard
        contentStyling={{padding: '24px'}}
        CustomHeaderTitle={
          <FlexRowSpaceBetween>
            <Body2 weight="bold">Summary ({actionItems?.length || 0})</Body2>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div>
                  <DropdownItem
                    onClick={() => {
                      setSortBy('dueDate');
                      handleClose();
                    }}>
                    Due date
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSortBy('createdAt');
                      handleClose();
                    }}>
                    Oldest to newest
                  </DropdownItem>{' '}
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <Button
                  kind="secondary"
                  disabled={isCustomFilter}
                  onClick={handleOpen}
                  style={{padding: '8px 16px', border: 0}}>
                  <FlexRowSpaceBetween>
                    <Body2
                      weight="bold"
                      kind={isCustomFilter ? 'textMuted' : 'purple300'}
                      style={{marginRight: '5px'}}>
                      Sort by:{' '}
                      {isCustomFilter
                        ? 'Custom'
                        : sortBy === 'dueDate'
                        ? 'Due date'
                        : 'Oldest to newest'}
                    </Body2>
                    <FilterIcon
                      stroke={isCustomFilter ? '#BFBFD4' : undefined}
                    />
                  </FlexRowSpaceBetween>
                </Button>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                width: '160px',
                padding: '8px',
                right: 0,
              }}
            />
          </FlexRowSpaceBetween>
        }>
        <ActionItems
          handleChange={(data, actionType, fieldId, item) =>
            handleChange(
              data,
              actionType,
              fieldId,
              item
                ? {...item, user: userId.length > 1 ? auth.user.id : userId}
                : undefined,
            )
          }
          users={users
            .filter((user) => [auth.user.id, userId].includes(user.id))
            .map((user) => ({
              label: userName(user),
              value: user.id,
              avatar: user.avatar,
            }))}
          source="meeting"
          canDelete={(fieldId) => {
            const task = tasks.find((task) => task.key === fieldId);

            if (!!task) {
              // allow admin delete action item
              if (authStore.auth.user.role === 'admin' && !!task) {
                return true;
              }

              // allow manager of task users delete task
              const usersInvolved = users
                .filter((user) =>
                  [task?.user, task?.updatedBy, task?.assignee].includes(
                    user.id,
                  ),
                )
                .map((user) => user.reviewer.id);

              if (usersInvolved.includes(auth.user.id)) {
                return true;
              }
            }
            return false;
          }}
          sourceId={''}
          value={(actionItems as any) || []}
          userId={auth.user.id}
        />
      </SectionCard>
    </div>
  );
};
