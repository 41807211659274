import {Button} from '@ui/atoms/button';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
import {useFirebaseFetch} from '@hooks/query-hook';
import {dismissBeamMastery} from '@utils/firebase-request';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import styled from 'styled-components';

const MasteryWrapper = styled.div`
  padding: 16px;
`;

const MasteryLevel = styled(FlexRow)`
  border-radius: 6px;
  width: 100%;
  .level {
    padding: 8px 16px;
    border-left: 1px solid #ededf2;
  }
  .active {
    background: #f6f6fe;
  }
  .inactive {
    background: var(--background-light, #fafafa);
  }
  .no-b {
    border-left: 0px;
  }
`;

type Props = {
  mastery: {
    isDismissed?: boolean;
    status: {
      tier: string;
    };
  } | null;
};

export const BeamMastery = ({mastery}: Props) => {
  const {
    authStore: {auth},
    notificationStore: {handleShowBeamMasteryTooltip},
  } = useStoreContext();

  const handleDismiss = () => {
    dismissBeamMastery(auth.user.id, {
      isDismissed: true,
    });
    handleShowBeamMasteryTooltip(true);
    setTimeout(() => handleShowBeamMasteryTooltip(false), 10000);
  };

  const {data} = useFirebaseFetch(`users/${auth.user.id}/onboarding/mastery`);

  const masteryStatus = data?.tier || 'novice';

  const masteryOptions = [
    {
      title: 'Novice',
      active: masteryStatus === 'novice',
      radius: '6px 0px 0px 6px',
    },
    {
      title: 'Adept',
      active: masteryStatus === 'adept',
      radius: '0px',
    },
    {
      title: 'Master',
      active: masteryStatus === 'master',
      radius: '0px 6px 6px 0px',
    },
  ];

  return (
    <MasteryWrapper className="">
      <FlexRowSpaceBetween>
        <Body1 weight="bold">Mastery Videos</Body1>
        {masteryStatus === 'master' && (
          <PlainButton onClick={handleDismiss}>
            <Body2 kind="purple300" weight="semibold">
              Dismiss
            </Body2>
          </PlainButton>
        )}
      </FlexRowSpaceBetween>
      <VerticalSpacer size="16px" />

      <div>
        <Body2 kind="textBody">
          Access our comprehensive video collection covering all features.
        </Body2>

        <VerticalSpacer size="16px" />
        <MasteryLevel>
          {masteryOptions.map((option, idx) => (
            <div
              key={idx}
              className={`level ${option.active ? 'active' : 'inactive'} ${
                idx === 0 ? 'no-b' : ''
              }`}
              style={{borderRadius: option.radius}}>
              <Body2
                kind={option.active ? 'purple300' : 'textMuted'}
                weight={option.active ? 'semibold' : 'default'}>
                {' '}
                {option.title}
              </Body2>
            </div>
          ))}
        </MasteryLevel>
        <Button
          className="mt-3"
          width="full"
          kind="secondary"
          style={{padding: '8px 12px'}}
          onClick={() => window.open(`${window.location.origin}/beam-mastery`)}>
          Become a master
        </Button>
      </div>
    </MasteryWrapper>
  );
};
