import {Body2, CTA} from '@ui/atoms/typography';
import {FC, useCallback, useMemo} from 'react';

import {
  SummaryWrapper,
  TableContainer,
  SecondRowWrapper,
  HeadlineWrapperContainer,
  SubmittedHeadlineWrapper,
  ItemWrapperContainer,
  HeadlineItemWrapper,
} from './styles';
import {AnalyticsRequestImpl} from '../../admin-analytics.request';
import {AnalyticsController} from '../../admin-analytics.controller';
import {useQuery} from 'react-query';
import {FlexRowSpaceBetween} from '@ui/style/styles';

import dayjs from 'dayjs';
import nextId from 'react-id-generator';
import {UserListCard} from '@ui/molecules/user/list-card';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {cloudinary} from '@assets/images/cloudinary';
import {Image} from '@ui/atoms/image';
import {emojiList} from '@ui/assets/emojis';
import {useApiCalls} from '@hooks/init-calls';
import {useStoreContext} from '@store/store-context';
import {Tooltip} from '@ui/molecules/tooltip';
import {ItemLoader} from '@ui/organisms/item-loader';
import {observer} from 'mobx-react';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {PulseSummary} from './pulse-summary';

const {emptyGoals} = cloudinary;

type Option = {label: string; value: string};

interface User {
  id: string;
  firstName: string;
  lastName: string;
  avatar: any;
  groups: any;
  averageMood: null | string;
  reviewedCheckins: number;
  submittedCheckins: number;
  groupAdmin?: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: any;
  };
  manager?: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: any;
  };
}

dayjs.extend(advancedFormat);

interface PulseCheckinsProps {
  breakdownBy: string;
  selectedGroups: Option[];
  selectedTeams: Option[];
  selectedMembers: Option[];
  pulseFilters: string[];
}

export const PulseCheckinsAnalytics: FC<PulseCheckinsProps> = observer(
  ({
    breakdownBy,
    selectedGroups,
    selectedTeams,
    selectedMembers,
    pulseFilters,
  }) => {
    const {
      analyticsStore: {goalsDate: dateRange},
    } = useStoreContext();

    const getPercentage = (count: number, highestValue: number) => {
      return (count / highestValue) * 100;
    };

    const emptyPlaceholder = {
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: emptyGoals,
        style: {height: '30%', width: '30%'},
      },
      imports: true,
      subtitle: 'Refine your search or try something new.',
      title: 'No results found',
    };

    const getPulseColor = (mood: string) => {
      switch (mood) {
        case 'meh!':
          return '#E87F16';
        case 'sad':
          return '#FFBC00';
        case 'drained':
          return '#F39C9A';
        case 'happy':
          return '#47B881';
        case 'elated':
          return '#2485E5';

        default:
          return '';
      }
    };

    const pulse = useMemo(
      () => [
        {
          pulse: 'Submitted',
          key: 'submittedCheckinsPercent',
          color: 'transparent',
        },
        {
          pulse: 'Elated',
          key: 'elated',
          emoji: emojiList.elated,
          color: getPulseColor('elated'),
        },
        {
          pulse: 'Happy',
          key: 'happy',
          emoji: emojiList.happy,
          color: getPulseColor('happy'),
        },
        {
          pulse: 'Meh',
          key: 'meh!',
          emoji: emojiList.meh,
          color: getPulseColor('meh!'),
        },
        {
          pulse: 'Sad',
          emoji: emojiList.sad,
          key: 'sad',
          color: getPulseColor('sad'),
        },
        {
          pulse: 'Drained',
          key: 'drained',
          emoji: emojiList.drained,
          color: getPulseColor('drained'),
        },
      ],
      [],
    );

    const breakdownIsGroup = breakdownBy === 'group';
    const breakdownIsTeam = breakdownBy === 'team';

    const selectedGroupsId = selectedGroups.map((group) => group.value);

    const selectedTeamsId = selectedTeams.map((team) => team.value);

    const selectedMembersId = selectedMembers.map((member) => member.value);

    const moodValues: any = useMemo(
      () => ({
        drained: 0,
        sad: 1,
        'meh!': 2,
        happy: 3,
        elated: 4,
      }),
      [],
    );

    const getPulseReport = useCallback(async () => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      try {
        const response = await controller.fetchReportPulse({
          startDate: dateRange.starts,
          endDate: dateRange.ends,
          member: selectedMembersId.length ? selectedMembersId.join(',') : '',
          group: selectedGroupsId.length ? selectedGroupsId.join(',') : '',
          manager: selectedTeamsId.length ? selectedTeamsId.join(',') : '',
          groupBy: breakdownBy,
        });
        if (response) {
          const result = response.graph.map((d: any) => {
            return {
              ...d,
              label: dayjs(d.startDate).format('DD MMM.'),
              data: d?.status
                ?.filter((stat: any) => pulseFilters.includes(stat.mood))
                .map((stat: any) => {
                  return {
                    ...stat,
                    percentage:
                      selectedMembers.length === 1
                        ? 25 * moodValues[stat.mood] || 5
                        : getPercentage(
                            stat.count,
                            response.summary?.highestValue,
                          ),
                    id: nextId('bar-id'),
                    startDate: d.startDate,
                    endDate: d.endDate,
                    color: getPulseColor(stat.mood),
                    name: stat.mood,
                  };
                }),
            };
          });

          return {
            summary: response.summary,
            users: response.users,
            labels:
              selectedMembers.length === 1
                ? pulse
                    .filter((mood) => mood.key !== 'submittedCheckinsPercent')
                    .map((mood) => ({name: mood.pulse, color: mood.color}))
                : [],
            result: result,
          };
        }
      } catch {}
    }, [
      dateRange.starts,
      dateRange.ends,
      selectedMembersId,
      selectedGroupsId,
      selectedTeamsId,
      moodValues,
      breakdownBy,
      selectedMembers.length,
      pulse,
      pulseFilters,
    ]);

    const {data, isLoading} = useQuery(
      [
        'pulse-summary',
        breakdownBy,
        dateRange.ends,
        dateRange.starts,
        selectedGroupsId,
        selectedMembersId,
        selectedTeamsId,
      ],
      () => getPulseReport(),
    );

    const {
      groupStore: {groups},
    } = useStoreContext();

    const {initGroupMembers} = useApiCalls();

    const {data: groupMembers} = useQuery(['initGroupMembers'], () =>
      initGroupMembers(),
    );

    const getUsersGroup = useCallback(async () => {
      const res = groupMembers;
      if (res && data?.users) {
        const users = data?.users.filter((user: User) =>
          pulseFilters.length > 0
            ? pulseFilters.includes(user.averageMood || '')
            : true,
        );

        const includeGroup = users.map((user: {id: any}) => {
          const userGroup = res.get(user.id);
          const getGroupIds = userGroup?.map((grp: any) => grp.group);
          const getUserGroups =
            groups.length > 0
              ? groups?.filter((grp: any) => getGroupIds?.includes(grp.id))
              : [];

          return {
            ...user,
            groups: getUserGroups,
          };
        });

        return includeGroup;
      }
    }, [data?.users, groups, groupMembers, pulseFilters]);

    const {data: reportUsers, isLoading: usersLoading} = useQuery(
      ['getUsersWithGroups', data?.users, pulseFilters],
      () => getUsersGroup(),
      {
        enabled: !!data?.users,
      },
    );

    return (
      <>
        <div className="bg-white border border-borderLight rounded-[10px] px-6 py-4 ">
          <FlexRowSpaceBetween className="border-b border-b-[#ededf2] pb-3 mb-6 ">
            <Body2 weight="bold">Overview</Body2>
            <div className="flex items-center sm:items-end sm:flex-col flex-row"></div>
          </FlexRowSpaceBetween>
          {isLoading && (
            <div className="h-[150px] flex items-center justify-center">
              <ItemLoader />
            </div>
          )}
          {!isLoading && (
            <PulseSummary
              avgPulse={data?.summary?.averagePulse}
              updatesSubmission={{
                count: data?.summary.submittedCheckins,
                percent: data?.summary.submittedCheckinsPercent,
              }}
              pulseSubmissionCount={{
                count: data?.summary?.submittedPulse,
                percent: data?.summary?.submittedPulsePercent,
              }}
            />
          )}
        </div>
        <div className="bg-white mt-4">
          <SummaryWrapper>
            <div className="table-summary">
              <Body2 weight="bold">
                Summary ({reportUsers?.length || '0'})
              </Body2>
            </div>

            <TableContainer>
              <div style={{overflowX: 'scroll'}}>
                <SecondRowWrapper>
                  <HeadlineWrapperContainer className="header">
                    <SubmittedHeadlineWrapper>
                      <Body2 weight="bold">Name</Body2>
                      <Body2 weight="bold">Manager</Body2>
                      {!breakdownBy && <Body2 weight="bold">Group</Body2>}
                      <Body2 weight="bold">Avg. Pulse</Body2>
                      <Body2 weight="bold">Submitted</Body2>
                      <Body2 weight="bold">Reviewed</Body2>
                    </SubmittedHeadlineWrapper>
                  </HeadlineWrapperContainer>

                  {(usersLoading || isLoading) && (
                    <div className="h-[150px] flex items-center justify-center">
                      <ItemLoader />
                    </div>
                  )}

                  {!isLoading &&
                    !usersLoading &&
                    reportUsers &&
                    reportUsers.map((user: User) => (
                      <TableRow
                        user={user}
                        isGroup={breakdownIsGroup}
                        isTeam={breakdownIsTeam}
                        pulse={pulse}
                      />
                    ))}
                  {!isLoading && !reportUsers?.length && (
                    <EmptyPlaceholder {...emptyPlaceholder} />
                  )}
                </SecondRowWrapper>
              </div>
            </TableContainer>
          </SummaryWrapper>
        </div>
      </>
    );
  },
);

interface RowProps {
  user: User;
  isGroup?: boolean;
  isTeam?: boolean;
  pulse: any[];
}

const TableRow = ({user, isGroup, isTeam, pulse}: RowProps) => {
  const filteredByBreakdown = isGroup || isTeam;

  return (
    <ItemWrapperContainer key={user.id}>
      <HeadlineItemWrapper>
        <div>
          {isGroup || isTeam ? (
            <Body2 weight="semibold">{user.firstName}</Body2>
          ) : (
            <UserListCard
              tooltip={false}
              userId={user?.id}
              type="secondary"
              reviewer={''}
              avatar={user?.avatar?.url}
              name={user.firstName + ' ' + user?.lastName || ''}
              TextComponent={CTA}
              textKind="textDark"
              tooltipText
            />
          )}
        </div>
        <div>
          {isGroup ? (
            <UserListCard
              tooltip={false}
              userId={user.groupAdmin?.id || ''}
              type="secondary"
              reviewer={''}
              avatar={user?.groupAdmin?.avatar?.url}
              name={
                user.groupAdmin?.firstName + ' ' + user?.groupAdmin?.lastName ||
                ''
              }
              TextComponent={CTA}
              textKind="textDark"
              tooltipText
            />
          ) : (
            <UserListCard
              tooltip={false}
              userId={user.manager?.id || ''}
              type="secondary"
              reviewer={''}
              avatar={user.manager?.avatar?.url}
              name={
                user.manager?.firstName + ' ' + user?.manager?.lastName || ''
              }
              TextComponent={CTA}
              textKind="textDark"
              tooltipText
            />
          )}
        </div>
        {!filteredByBreakdown && (
          <div>
            {user.groups?.length > 0 ? (
              <div className="flex flex-row">
                <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px]">
                  <Body2 weight="semibold" kind="textBody">
                    {`${user.groups[0].name}`.length > 6 ? (
                      <>
                        <Tooltip
                          text={`${user.groups[0].name}  `}
                          maxLength={10}
                          tooltipBody={<span>{user.groups[0].name}</span>}
                        />
                      </>
                    ) : (
                      `${user.groups[0].name}`
                    )}
                  </Body2>
                </div>
                {user.groups.length > 1 && (
                  <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px] ml-1">
                    <Body2 weight="semibold" kind="purple300">
                      {' '}
                      +{user.groups.length - 1}
                    </Body2>
                  </div>
                )}
              </div>
            ) : (
              <div className="px-3 py-2 bg-[#F6F6F8] items-center justify-center rounded-[10px] w-fit">
                <Body2 kind="textBody">--- ---</Body2>
              </div>
            )}
          </div>
        )}

        <div>
          {user.averageMood ? (
            <Image
              {...pulse.filter((mood) => mood.key === user.averageMood)[0]
                ?.emoji}
              width="40px"
              height="40px"
            />
          ) : (
            <Body2>-- --</Body2>
          )}
        </div>

        <div>
          <Body2>{user.submittedCheckins || '-'}</Body2>
        </div>

        <div>
          <Body2>{user.reviewedCheckins || '-'}</Body2>
        </div>
      </HeadlineItemWrapper>
    </ItemWrapperContainer>
  );
};
