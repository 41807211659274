import styled from 'styled-components';
import {ActionCard} from '../../layouts/action-card';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';
import {UserDropSelectField} from '../select/user-drop';
import {OptionsSelectField} from '../select/multi-options/multi-options';
import {getColorFromTheme} from '@ui/ui-utils';
import {Inputfield} from '../field/inputfield';

import {MeasurementTypeField} from '../field/measurement-field';
import {Body1, Body2} from '../../atoms/typography';
import {RangeInput} from '../range-input';
import {Radio} from '@ui/atoms/radio';
import {VerticalSpacer} from '../../atoms/spacer';
import {Flex} from '@ui/style/styles';
import {CurrencySelect} from '../select/currency-input';
import {ItemLoader} from '@ui/organisms/item-loader';
import {SelectField} from '../select/selectfield';
import {cfv} from '@utils/framework';
import {MeasurementType} from '../../interface';
import {useHubspotHook} from '@hooks/hubspot-hook';
import {HubspotIcon} from '@ui/atoms/icons/apps/hubspot';
import {memo, useMemo} from 'react';
import {PercentageRangeInput} from '../percentage-range-input';
import {DeepMap, FieldError} from 'react-hook-form';
import {CurrencySymbol} from '../../../constants';
import {observer} from 'mobx-react-lite';
import {Helper, SecondaryHelper} from '@ui/atoms/helper';

export interface TKeyResult {
  id?: string;
  name: string;
  user: string;
  measurement: {unit: string; symbol?: string};
  range?: {
    target?: number;
    start?: number;
    good?: number;
    stretch?: number;
    current?: number;
    currency?: string;
  } | null;
  showSource?: boolean;
  hubspotValue?: number;
  saveSource?: boolean;
  source?: {
    app: string;
    type: string;
    keyId: string;
    value: number;
  } | null;
}

export interface TMemberOption {
  label: {
    avatar: {
      name: string;
      src: string;
    };
    id: string;
    name: string;
  };
  value: string;
}

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RemoveButton = styled.button`
  display: inline-flex;
  border: none;
  background: none;
  appearance: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    svg {
      circle {
        fill: ${getColorFromTheme('backgroundDark')};
      }
      path {
        stroke: ${getColorFromTheme('textMuted')};
      }
    }
  }
`;

export const IntegrationContainer = styled.div<{errors?: any}>`
  border: 1px solid #ededf2;
  border-radius: 10px;

  .heading {
    padding: 16px;
  }

  .openedState {
    border: ${({errors}) => (errors ? '1px solid #D52A2A' : undefined)};
    border-top: ${({errors}) =>
      errors ? '1px solid #D52A2A' : '1px solid #ededf2'};
    border-radius: ${({errors}) => (errors ? '10px' : '10px 10px 0px 0px')};
    padding: 16px 8px;
  }

  .heading2 {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 7px;
    }
  }

  .app {
    padding: 8px 2px 8px 8px;
    height: 40px;
    display: flex;
    align-items: center;

    p {
      color: #5f5f8c;
    }

    div {
      svg {
        width: 16px;
        height: 16px;
        margin-right: 7px;
      }
    }

    &:hover {
      background: #f6f6f8;
      border-radius: 8px;
      cursor: pointer;
      padding: 12px 2px 12px 8px;

      p {
        color: #1e1e2f;
      }
    }
  }
`;

export interface TKeyResultFieldProps {
  onRemove: () => void;
  setValue: (value: TKeyResult) => void;
  name: string;
  size?: number;
  index: number;
  members: TMemberOption[];
  value: TKeyResult;
  errors?: (DeepMap<TKeyResult, FieldError> | undefined)[];
}

export const KeyResultField = memo(function ({
  onRemove,
  name: fieldName,
  setValue,
  index,
  members,
  value,
  size,
  errors,
}: TKeyResultFieldProps) {
  const currencyResponse =
    value && value.measurement.symbol === 'NGN'
      ? '₦ Naira'
      : value?.measurement.symbol === 'USD'
      ? '$ Dollars'
      : value?.measurement.symbol === 'EUR'
      ? '€ Euros'
      : value?.measurement.symbol === 'GBP'
      ? '£ Pounds'
      : '';

  const renderMeasurementValue = useMemo(() => {
    switch (value.measurement.unit) {
      case MeasurementType.PERCENTAGE:
        return (
          <PercentageRangeInput
            name={`${fieldName}-range`}
            // @ts-ignore
            value={value.range}
            onChange={(innerValue: {
              start?: number;
              target?: number;
              good?: number;
              stretch?: number;
            }) =>
              setValue({
                ...value,
                // @ts-ignore
                range: {
                  ...value.range,
                  ...innerValue,
                },
              })
            }
            // @ts-ignore
            state={!!errors?.range ? 'error' : 'default'}
            helper={
              // @ts-ignore
              errors?.range?.start?.message ||
              // @ts-ignore
              errors?.range?.target?.message ||
              ''
            }
          />
        );
      case MeasurementType.CURRENCY:
        return (
          <>
            <CurrencySelect
              options={CurrencySymbol}
              placeholder={currencyResponse}
              onChange={({value: userValue}: {value: string}) =>
                setValue({
                  ...value,
                  measurement: {
                    unit: value.measurement.unit,
                    symbol: userValue,
                  },
                })
              }
              value={
                value.measurement?.symbol ||
                CurrencySymbol.find(
                  (i) => i.value === value.measurement?.symbol,
                )
              }
              // @ts-ignore
              state={!!errors?.range ? 'error' : 'default'}
              helper={
                // @ts-ignore
                errors?.range?.start?.message ||
                // @ts-ignore
                errors?.range?.target?.message ||
                ''
              }
            />
            <RangeInput
              name={`${fieldName}-range`}
              // @ts-ignore
              value={value.range}
              onChange={(innerValue: {
                start?: number;
                target?: number;
                good?: number;
                stretch?: number;
              }) =>
                setValue({
                  ...value,
                  // @ts-ignore
                  range: {
                    ...value.range,
                    ...innerValue,
                  },
                })
              }
              // @ts-ignore
              state={!!errors?.range ? 'error' : 'default'}
              disabled={value?.source?.app ? true : false}
              helper={
                // @ts-ignore
                errors?.range?.start
                  ? // @ts-ignore
                    errors?.range?.start?.message
                  : // @ts-ignore
                  errors?.range?.target
                  ? // @ts-ignore
                    errors?.range?.target?.message
                  : // @ts-ignore
                  errors?.range?.current
                  ? // @ts-ignore
                    `Start value cannot be greater than current value (${
                      value?.source?.value ? value?.source?.value : 0
                    }) from data source`
                  : ''
              }
            />
          </>
        );
      case MeasurementType.NUMERIC:
        return (
          <RangeInput
            name={`${fieldName}-range`}
            // @ts-ignore
            value={value.range}
            onChange={(innerValue: {
              start?: number;
              target?: number;
              good?: number;
              stretch?: number;
            }) =>
              setValue({
                ...value,
                // @ts-ignore
                range: {
                  ...value.range,
                  ...innerValue,
                },
              })
            }
            // @ts-ignore
            state={!!errors?.range ? 'error' : 'default'}
            disabled={value?.source?.app ? true : false}
            helper={
              // @ts-ignore
              errors?.range?.start
                ? // @ts-ignore
                  errors?.range?.start?.message
                : // @ts-ignore
                errors?.range?.target
                ? // @ts-ignore
                  errors?.range?.target?.message
                : // @ts-ignore
                errors?.range?.current
                ? // @ts-ignore
                  `Start value cannot be greater than current value (${
                    value?.source?.value ? value?.source?.value : 0
                  }) from data source`
                : ''
            }
          />
        );
      case MeasurementType.BINARY:
        return <Body1 kind="textBody">Not Complete/Complete</Body1>;
      default:
        return <Body1 kind="textBody"></Body1>;
    }
  }, [value, fieldName, errors, currencyResponse, setValue]);

  const renderFooter = useMemo(() => {
    const disableRemoveButton = size ? size <= 1 : false;
    return (
      <>
        <FooterWrapper>
          <UserDropSelectField
            name={`${fieldName}-manager`}
            onChange={({value: userValue}: {value: string}) =>
              setValue({
                ...value,
                user: userValue,
              })
            }
            value={members.find((i) => i.value === value.user)}
            options={members}
            // @ts-ignore
            helper={errors?.user?.message || ''}
            // @ts-ignore
            state={!!errors?.user ? 'error' : 'default'}
          />
          <RemoveButton
            type="button"
            disabled={disableRemoveButton}
            aria-label={`remove ${cfv(undefined, true).k_r}`}
            onClick={onRemove}>
            <SecondaryMinusSVG />
            <Body2
              weight="bold"
              style={{marginLeft: '5px'}}
              kind={disableRemoveButton ? 'textMuted' : 'red400'}>
              Remove
            </Body2>
          </RemoveButton>
        </FooterWrapper>
      </>
    );
  }, [fieldName, members, onRemove, setValue, value, errors, size]);

  return (
    <>
      <ActionCard footer={renderFooter} errors={errors ? true : false}>
        <div>
          <input type="text" value={value?.id} hidden />
          <Inputfield
            placeholder="500 new signups"
            value={value.name}
            onChange={(e) => {
              setValue({
                ...value,
                name: e.target.value,
              });
            }}
            name={`${fieldName}-name`}
            type="text"
            // @ts-ignore
            helper={errors?.name?.message || ''}
            // @ts-ignore
            state={!!errors?.name ? 'error' : 'default'}
          />
          <VerticalSpacer size="8px" />
          <MeasurementTypeField
            label="What kind of metric unit will be used to measure it?"
            value={value.measurement.unit ? value.measurement.unit : ''}
            name={`${fieldName}-measurement`}
            margin={value.measurement.unit ? false : true}
            remove={value?.source?.app ? ['percentage', 'binary'] : []}
            setValue={(innerValue: string) => {
              setValue({
                ...value,
                measurement: {unit: innerValue},
              });
            }}
          />

          {renderMeasurementValue}
          {value.measurement.unit && (
            <LinkApp setValue={setValue} value={value} errors={errors} />
          )}
        </div>
      </ActionCard>
      <SecondaryHelper
        state={
          // @ts-ignore
          !!errors?.measurement?.unit ? 'error' : 'default'
        }
        weight={600}
        children={
          // @ts-ignore
          errors?.measurement?.unit?.message || ''
        }
      />
    </>
  );
});

const LinkApp = observer(
  ({
    setValue,
    value,
    errors: error,
  }: {
    setValue: (value: TKeyResult) => void;
    value: TKeyResult;
    errors?: (DeepMap<TKeyResult, FieldError> | undefined)[];
  }) => {
    const {
      save,
      setSave,
      apps,
      reports,
      errors,
      expand,
      setErrors,
      setExpand,
      loading,
      options,
    } = useHubspotHook(value, setValue, error);

    const appInt = apps.map((app: any) => {
      switch (app.app) {
        case 'Hubspot':
          return {
            ...app,
            icon: <HubspotIcon />,
          };
        default:
          return app;
      }
    });

    return (
      <>
        {/* @ts-ignore */}
        <IntegrationContainer errors={errors?.source || errors?.saveSource}>
          <Flex
            alignItems="center"
            className="heading"
            justifyContent="space-between"
            style={{cursor: 'pointer'}}
            onClick={() =>
              !value?.source || !value?.source?.app ? setExpand(!expand) : null
            }>
            <Body2 weight={'semibold'}>Automate key result reporting</Body2>
            <Radio
              checked={expand}
              onChange={(e) =>
                !value?.source || !value?.source?.app
                  ? setExpand(e.target.checked)
                  : null
              }
            />
          </Flex>
          {expand && (
            <div className="openedState">
              {apps.length === 0 && !loading && (
                <Body2 kind="textBody" align="center">
                  To connect apps,{' '}
                  <a
                    href="https://peoplebeam.helpscoutdocs.com/article/34-integrations-beam"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#585ADF',
                      textDecoration: 'underline',
                      fontWeight: 500,
                    }}>
                    learn more about integrations
                  </a>
                </Body2>
              )}
              {apps.length > 0 &&
                !loading &&
                // @ts-ignore
                !value?.source?.app &&
                appInt.map((app: any) => (
                  <div
                    className="app"
                    onClick={() => {
                      setValue({
                        ...value,
                        showSource: true,
                        measurement: {unit: MeasurementType.NUMERIC},
                        // @ts-ignore
                        source: {
                          type: '',
                          keyId: '',
                          app: app.app,
                        },
                      });
                      setSave(false);
                    }}>
                    <Flex alignItems="center">
                      {app.icon}
                      <Body1>{app.app}</Body1>
                    </Flex>
                  </div>
                ))}

              {value?.source?.app !== '' && !loading && (
                <div>
                  {appInt
                    .filter((app: any) => app.app === value?.source?.app)
                    .map((app: any) => (
                      <div style={{padding: '5px 10px'}}>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center">
                          <Flex alignItems="center" className="heading2">
                            {app.icon}
                            <Body1>{app.app}</Body1>
                          </Flex>
                          <Flex className="actions-div">
                            {save ? (
                              <Body2
                                weight="semibold"
                                kind={'purple300'}
                                onClick={() => {
                                  setSave(false);
                                  setValue({...value, saveSource: false});
                                  setErrors(true);
                                }}
                                style={{cursor: 'pointer'}}>
                                Edit
                              </Body2>
                            ) : (
                              <Body2
                                weight="semibold"
                                kind={
                                  value?.source?.keyId === ''
                                    ? 'textMuted'
                                    : 'purple300'
                                }
                                onClick={() => {
                                  if (value?.source?.keyId !== '') {
                                    setSave(true);
                                  }
                                  setValue({...value, saveSource: true});
                                  setErrors(false);
                                }}
                                style={{
                                  cursor:
                                    value?.source?.keyId !== ''
                                      ? 'pointer'
                                      : 'not-allowed',
                                }}>
                                Save
                              </Body2>
                            )}
                            <span
                              style={{
                                width: '2px',
                                height: '16px',
                                borderLeft: '1px solid #CECEDE',
                                margin: '0px 9px',
                              }}></span>
                            <Body2
                              weight="semibold"
                              kind="textBody"
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                setValue({
                                  ...value,
                                  showSource: false,
                                  source: null,
                                });
                                setErrors(false);
                              }}>
                              Unlink
                            </Body2>
                          </Flex>
                        </Flex>
                        {!save && (
                          <>
                            <div style={{marginTop: '14px'}}>
                              <SelectField
                                options={options}
                                borderRadius="10px"
                                value={value?.source?.type}
                                margin={true}
                                onChange={(e: any) =>
                                  setValue({
                                    ...value,
                                    source: {
                                      keyId: '',
                                      type: e?.value ?? '',
                                      value: 0,
                                      app: 'Hubspot',
                                    },
                                  })
                                }
                                placeholder="Select Owner or Stage"
                              />
                            </div>
                            <div style={{marginTop: '12px'}}>
                              <OptionsSelectField
                                options={
                                  value?.source?.type === 'owner'
                                    ? reports.owner
                                    : reports.stage
                                }
                                borderRadius="10px"
                                defaultValue={
                                  value?.source?.type === 'owner'
                                    ? reports.owner?.map(
                                        (x: any) =>
                                          x?.data?.filter(
                                            (report: any) =>
                                              report.keyId ===
                                              value?.source?.keyId,
                                          )?.[0],
                                      )?.[0]
                                    : reports.stage?.map(
                                        (x: any) =>
                                          x?.data?.filter(
                                            (report: any) =>
                                              report.keyId ===
                                              value?.source?.keyId,
                                          )?.[0],
                                      )?.[0]
                                }
                                onChange={(e: any) =>
                                  setValue({
                                    ...value,
                                    // @ts-ignore
                                    range: {
                                      ...value?.range,
                                      current: e[e?.type],
                                    },
                                    // @ts-ignore
                                    source: {
                                      ...value?.source,
                                      keyId: e.keyId,
                                      value: e[e?.type],
                                    },
                                  })
                                }
                                disabled={value?.source?.type === ''}
                                placeholder="Select report"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
          {loading && (
            <div style={{marginBottom: '20px'}}>
              <ItemLoader />
            </div>
          )}
        </IntegrationContainer>
        <Helper
          state={
            // @ts-ignore
            errors?.source?.app ||
            // @ts-ignore
            errors?.source?.type ||
            // @ts-ignore
            errors?.source?.keyId ||
            // @ts-ignore
            errors?.saveSource
              ? 'error'
              : 'default'
          }
          children={
            // @ts-ignore

            errors?.source?.app
              ? // @ts-ignore

                errors?.source?.app?.message
              : // @ts-ignore

              errors?.source?.type
              ? // @ts-ignore

                errors?.source?.type?.message
              : // @ts-ignore

              errors?.source?.keyId
              ? // @ts-ignore

                errors?.source?.keyId?.message
              : // @ts-ignore
              errors?.saveSource
              ? // @ts-ignore
                errors?.saveSource?.message
              : ''
          }
        />
      </>
    );
  },
);

KeyResultField.displayName = 'KeyResultField';
