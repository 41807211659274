import {observer} from 'mobx-react';
import {parseCurrencyValue} from '@constants';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {Flex, FlexRowSpaceBetween} from '@ui/style/styles';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {
  Body1,
  CTA,
  Headline2,
  Headline4,
  Body2,
  Headline3,
} from '@ui/atoms/typography';
import dayjs from 'dayjs';
import {BackAction} from '@ui/molecules/back-action';
import {Modal} from '@ui/molecules/modal';
import {Checkbox} from '@ui/atoms/checkbox';
import {FlexRow} from '@ui/style/styles';
import {ModalCard} from '@ui/layouts/modal-card';
import {CancelIcon} from '@ui/atoms/icons';
import {Link} from '@ui/atoms/link';
import {capitalize} from '@utils';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {AdminBillingRequestImpl} from '../../admin-billing.request';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {AdminBillingController} from '../../admin-billing.controller';
import {useState} from 'react';
import {RotatingLines} from 'react-loader-spinner';
import {AlertBadgeErrorSVG} from '@ui/atoms/svg';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {UpdateCard} from './components/update-card';
import {AnnualModal} from './components/annual-modal';
import {useBillingHook} from '../../admin-billing.hooks';
import {ElementsConsumer} from '@stripe/react-stripe-js';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {
  PlanDiv,
  Wrapper,
  CancelReason,
  InfoContainer,
  CardDetailsDiv,
  ModalDiv,
  RetryPaymentNotificationDiv,
  PaymentHistoryDiv,
} from './billing-active.styles';
import {setFirebaseData} from '@utils/firebase-handler';
dayjs.extend(advancedFormat);

export const AdminBillingActive = observer(() => {
  const {
    billingsStore: {
      billingSubscriptions,
      billingMethods,
      nextCycle,
      setBillingSubscriptions,
      billingPlans,
    },
    storeDashboardRoutes: {activateWorkspaceTab},
    billingRoutesStore: {activateInactiveBillingTabs},
    authStore: {auth},
  } = useStoreContext();

  const {loadBillingSubscriptions, nextCycleRefetch} = useBillingHook();

  const plansSwitched = auth.user.workspace.billing.switchPlan;

  const isSubscriptionCanceled = !auth.user.workspace.billing.renew;

  const activeSubscriptionPlan = billingSubscriptions?.results?.find(
    (billingSubscription: any) => billingSubscription?.status === 'active',
  );

  const isCurrentSubscriptionPending =
    billingSubscriptions?.results?.[0]?.status === 'pending';

  const [showPaymentError, setShowPaymentError] = useState(true);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [openUpgradeSub, setOpenUpgradeSub] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState<'free' | '' | 'paid'>(
    '',
  );
  const [cancellationReasons, setCancellationReasons] = useState<string[]>([]);

  const cancelSubscriptionReason = [
    'I don’t understand Beam',
    'Too expensive',
    'Not ready to pay now',
  ];

  const handleUpdate = (idx: number, value: boolean, reason: string) => {
    if (value) {
      setCancellationReasons((prev) => {
        if (prev) {
          return [...prev, cancelSubscriptionReason[idx]];
        }
        return [cancelSubscriptionReason[idx]];
      });
    }

    if (!value) {
      setCancellationReasons((prev) =>
        prev.filter((_reason) => reason !== _reason),
      );
    }
  };

  const isCancelFreeTrial = showCancelModal === 'free';

  const [retrying, setRetrying] = useState(false);

  const cancelSubscription = async () => {
    setLoading(true);
    const request = new AdminBillingRequestImpl();
    const controller = new AdminBillingController(request);

    const response = await controller.cancelSubscription();
    response && setShowCancelModal('');

    activateNotification({
      title: 'Success',
      content: isCancelFreeTrial
        ? 'Your free trial was cancelled.'
        : 'You have canceled your subscription',
      kind: 'success',
    });
    response && response && activateWorkspaceTab('billing');
    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));

    response && nextCycleRefetch();
    response && activateInactiveBillingTabs('plans');
    setLoading(false);
  };

  const computePlanName = (plan: string = '') => {
    if (plan.includes('standard')) return 'Standard';

    return 'Essentials';
  };

  const activePlan = computePlanName(nextCycle?.plan);

  const [modalAction, setModalAction] = useState<'upgrade' | 'downgrade' | ''>(
    '',
  );

  const retrySubscription = async () => {
    setRetrying(true);
    const request = new AdminBillingRequestImpl();
    const controller = new AdminBillingController(request);

    const response = await controller.retrySubscription();

    response &&
      activateNotification({
        title: 'Success',
        content: 'Payment successful.',
        kind: 'success',
      });

    if (response) {
      window.location.reload();

      const request = new AdminBillingRequestImpl();
      const controller = new AdminBillingController(request);

      const sub = await controller.fetchAllBillingSubscriptions();
      sub && setBillingSubscriptions(sub);
    }
    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));

    response && loadBillingSubscriptions();
    response && activateInactiveBillingTabs('plans');

    setRetrying(false);
  };

  return (
    <>
      {showPaymentError && isCurrentSubscriptionPending && (
        <RetryPaymentNotificationDiv>
          <AlertBadgeErrorSVG />
          {'    '}
          <Body2 weight="bold" kind="white" style={{marginLeft: '8px'}}>
            There are issues with your payment method.{' '}
            <span>
              {' '}
              <NoBorderButton disabled={retrying} onClick={retrySubscription}>
                <span style={{textDecoration: 'underline', fontWeight: 600}}>
                  {retrying ? 'Retrying ...' : '  Retry payment'}
                </span>
              </NoBorderButton>
            </span>{' '}
            or{' '}
            <span>
              {' '}
              <NoBorderButton onClick={() => setOpenDrawer(true)}>
                <span style={{textDecoration: 'underline', fontWeight: 600}}>
                  Update your card
                </span>
              </NoBorderButton>
            </span>
          </Body2>
          <NoBorderButton
            style={{marginLeft: '5px', marginTop: '3px'}}
            onClick={() => setShowPaymentError(false)}>
            <CancelIcon color={'white'} />
          </NoBorderButton>
        </RetryPaymentNotificationDiv>
      )}

      <Wrapper>
        <div className="heading">
          <div>
            <Headline3 kind="textDark">Subscription</Headline3>
            <VerticalSpacer size="8px" />
            <Body1 kind="textBody">
              Your current plan and billing information.
            </Body1>
          </div>
        </div>
        <VerticalSpacer size="24px" />
        {/* To be checked */}
        {plansSwitched && !isSubscriptionCanceled && (
          <>
            <InfoContainer>
              Your previous plan is still active. Starting{' '}
              {dayjs(nextCycle?.startDate).format('MMMM Do')}, you will be
              charged for the new plan.
            </InfoContainer>
            <VerticalSpacer size="24px" />
          </>
        )}

        <PlanDiv>
          <div className="greyedOut">
            <FlexRowSpaceBetween>
              <div>
                <FlexRow>
                  <CTA kind="green400" style={{fontSize: 12}}>
                    Active plan - {activePlan}
                  </CTA>
                  <HorizontalSideRule size="12px" />
                  <CTA
                    kind="purple300"
                    style={{fontSize: 12, cursor: 'pointer'}}
                    onClick={() => {
                      setModalAction(
                        activePlan === 'Essentials' ? 'upgrade' : 'downgrade',
                      );
                      setOpenUpgradeSub(true);
                    }}>
                    {activePlan === 'Essentials' ? 'Upgrade' : 'Downgrade'} plan
                  </CTA>
                </FlexRow>

                <VerticalSpacer size="16px" />

                <Headline2 kind="textDark">
                  {parseCurrencyValue(activeSubscriptionPlan?.amount?.currency)}
                  {nextCycle?.amount ||
                    billingSubscriptions?.results?.[0]?.amount?.cost}
                  {nextCycle?.amount ? (
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: '#5F5F8C',
                      }}>
                      /
                      {activeSubscriptionPlan?.plan === 'yearly'
                        ? 'Annually'
                        : 'Monthly'}
                    </span>
                  ) : (
                    ''
                  )}
                </Headline2>
              </div>
              {isSubscriptionCanceled ? (
                <></>
              ) : (
                <div>
                  {activeSubscriptionPlan?.plan === 'monthly' && (
                    <div>
                      <Button
                        style={{padding: '8px 16px '}}
                        kind="secondary"
                        disabled={plansSwitched}
                        onClick={() => setOpenUpgradeSub(true)}>
                        Switch to annual
                      </Button>
                      <VerticalSpacer size="16px" />
                    </div>
                  )}
                  <NoBorderButton onClick={() => setShowCancelModal('paid')}>
                    <Body2 weight="bold" kind="purple300">
                      Cancel Subscription
                    </Body2>
                  </NoBorderButton>
                </div>
              )}
            </FlexRowSpaceBetween>
          </div>
          {!isCurrentSubscriptionPending && (
            <div className="activePlanDetails bg-white rounded-b-[10px]">
              <Flex justifyContent="space-between" alignItems="center">
                <Body2 kind="textBody">Seats </Body2>
                <Body2 weight="semibold"> {nextCycle?.seats}</Body2>
              </Flex>
              <VerticalSpacer size="24px" />
              {!isSubscriptionCanceled && (
                <Flex justifyContent="space-between" alignItems="center">
                  <Body2 kind="textBody">Next payment date</Body2>
                  <Body2 weight="semibold">
                    {dayjs(auth.user.workspace.billing.cycle.endDate).format(
                      'MMMM Do, YYYY',
                    )}{' '}
                  </Body2>
                </Flex>
              )}
            </div>
          )}
        </PlanDiv>
        <VerticalSpacer size="24px" />
        {!isSubscriptionCanceled && (
          <>
            <CardDetailsDiv>
              <div className="title">
                <Headline4 kind="textDark">Payment method</Headline4>
              </div>
              <div className="table-body">
                <div className="header">
                  <Body2 kind="textBody">Card</Body2>
                  <Body2 kind="textBody">Expiry </Body2>
                  <Body2 kind="textBody">Actions </Body2>
                </div>
                {billingMethods?.data?.map((billingMethod: any) => (
                  <div className="content" key={billingMethod?.id}>
                    <Body2 kind="textBody">
                      {capitalize(billingMethod?.card?.brand)} ending in{' '}
                      {billingMethod?.card?.last4}
                    </Body2>
                    <Body2 kind="textBody">
                      {dayjs(
                        `2000-${billingMethod?.card?.exp_month}-01`,
                      ).format('MMMM')}
                      , {billingMethod?.card?.exp_year}
                    </Body2>
                    <Link
                      kind="blue"
                      style={{
                        color: '#585ADF',
                        textDecoration: 'none',
                        fontWeight: 500,
                      }}
                      onClick={() => setOpenDrawer(true)}
                      to="#">
                      Update
                    </Link>
                  </div>
                ))}
              </div>
            </CardDetailsDiv>
            <VerticalSpacer size="24px" />
          </>
        )}

        <PaymentHistoryDiv className="bg-white">
          <div className="title">
            <Headline4 kind="textDark">Payment history</Headline4>
          </div>
          <div className="table-body">
            <div className="header">
              <Body2 kind="textBody">Date</Body2>
              <Body2 kind="textBody">Status</Body2>
              <Body2 kind="textBody">Amount</Body2>
              <Body2 kind="textBody">Receipt</Body2>
            </div>
            {billingSubscriptions?.results?.map(
              (billingSubscription: any, idx: number) => (
                <div className="content" key={billingSubscription?.id}>
                  <Body2 kind="textBody">
                    {dayjs(billingSubscription?.createdAt).format(
                      'MMMM DD, YYYY',
                    )}
                  </Body2>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                    }}>
                    <CustomColorIcon
                      color={
                        billingSubscription?.status === 'active' ||
                        billingSubscription?.status === 'completed'
                          ? '#1A9E68'
                          : '#D52A2A'
                      }
                      width="8px"
                      height="8px"
                      margin={3}
                    />
                    <FlexRow>
                      <Body2 kind="textBody" style={{marginLeft: 8}}>
                        {billingSubscription?.status === 'active' ||
                        billingSubscription?.status === 'completed'
                          ? 'Successful'
                          : 'Failed'}{' '}
                      </Body2>

                      {idx === 0 && isCurrentSubscriptionPending && (
                        <>
                          <HorizontalSideRule
                            size="20px"
                            style={{margin: '0 8px'}}
                          />
                          {retrying ? (
                            <RotatingLines width="16" strokeColor="#585ADF" />
                          ) : (
                            <NoBorderButton onClick={retrySubscription}>
                              <Body2 kind="purple300" weight="semibold">
                                Retry payment
                              </Body2>
                            </NoBorderButton>
                          )}
                        </>
                      )}
                    </FlexRow>
                  </div>
                  <Body2 kind="textBody">
                    {parseCurrencyValue(billingSubscription?.amount?.currency)}
                    {billingSubscription?.amount?.cost}
                  </Body2>
                  {billingSubscription?.paymentInfo?.receiptUrl ? (
                    <Link
                      to="#"
                      style={{
                        fontWeight: 500,
                      }}
                      onClick={() => {
                        window.location.href =
                          billingSubscription?.paymentInfo?.receiptUrl;
                      }}>
                      Download
                    </Link>
                  ) : (
                    <Body2 kind="textMuted" weight="semibold">
                      Download
                    </Body2>
                  )}
                </div>
              ),
            )}
          </div>
        </PaymentHistoryDiv>

        <Modal open={!!showCancelModal} onClose={() => setShowCancelModal('')}>
          <ModalDiv>
            <div style={{width: '100%'}}>
              <BackAction
                width="md"
                icon={<CancelIcon />}
                onClick={() => {
                  setShowCancelModal('');
                }}
                title="Close"
              />
            </div>

            <VerticalSpacer size="16px" />
            <ModalCard align="center" title={``}>
              <div>
                <VerticalSpacer size="20px" />

                <span>
                  <Headline2 align="center">
                    Cancel {isCancelFreeTrial ? 'trial' : 'subcription'}?
                  </Headline2>
                </span>
                <VerticalSpacer size="20px" />

                <Body1 align="center" kind="textBody">
                  {isCancelFreeTrial
                    ? 'Are you sure you want to cancel your 14-days free trial? Once complete, you’d be downgraded to the free plan'
                    : 'Please let us know your reason.'}
                </Body1>
                <VerticalSpacer size={!isCancelFreeTrial ? '8px' : '34px'} />
                {!isCancelFreeTrial && (
                  <>
                    <CancelReason>
                      <div className="info">
                        <div>
                          {cancelSubscriptionReason.map((reason, idx) => (
                            <FlexRow style={{marginBottom: '20px'}} key={idx}>
                              <Checkbox
                                onChange={(event) => {
                                  handleUpdate(
                                    idx,
                                    event.target.checked,
                                    reason,
                                  );
                                }}
                              />
                              <Body2
                                kind="textBody"
                                style={{marginLeft: '10px'}}>
                                {reason}
                              </Body2>
                            </FlexRow>
                          ))}
                        </div>
                      </div>
                    </CancelReason>
                    <VerticalSpacer size="40px" />
                  </>
                )}

                <Button
                  kind="secondary"
                  isLoading={loading}
                  width="full"
                  disabled={!cancellationReasons.length && !isCancelFreeTrial}
                  style={{
                    padding: '12px 24px',
                  }}
                  onClick={cancelSubscription}>
                  Cancel {!isCancelFreeTrial && 'subscription'}
                </Button>
              </div>
            </ModalCard>
          </ModalDiv>
        </Modal>
        <ElementsConsumer>
          {({stripe, elements}) => (
            <UpdateCard
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              stripe={stripe}
              elements={elements}
            />
          )}
        </ElementsConsumer>
        <AnnualModal
          open={openUpgradeSub}
          type={modalAction}
          billingPlans={billingPlans}
          billingType={activeSubscriptionPlan?.plan}
          currency={nextCycle?.currency || ''}
          seats={nextCycle?.seats || 0}
          loadBillingSubscriptions={loadBillingSubscriptions}
          nextStartDate={nextCycle?.startDate}
          monthlyCost={nextCycle?.amount}
          onClose={() => setOpenUpgradeSub(false)}
        />
      </Wrapper>
    </>
  );
});
