import {memo, ReactElement, useMemo, useRef} from 'react';
import {Colors, InputState} from '../../../interface';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {useState} from 'react';
import {ReactSortable} from 'react-sortablejs';
import {Label} from '../../../atoms/label';
import {HorizontalSpacer, VerticalSpacer} from '../../../atoms/spacer';
import {IAPIUserFormat, useMultiField} from '../../../../hooks';
import {PlusSVG} from '../../../atoms/svg/plus';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  InputWrapper,
  AddButton,
  AddButton2,
  HelperWrapper,
} from './styles';
import {CTA} from '../../../atoms/typography';
import {Field} from '@ui/atoms/field/field';
import nextId from 'react-id-generator';

export interface MultiFieldProps {
  label: string;
  id: string;
  value: {
    text?: string;
    completed?: boolean;
    assignee?: IAPIUserFormat;
  }[];
  userCanReorder?: boolean;
  updateValue: (
    value?: Array<any>,
    action?: 're-sort' | '',
    key?: string,
  ) => void;
  state?: InputState;
  helper?: string | ReactElement;
  checkedIndexes?: number[];
  handleUserSelect?: (user: string, field: string) => void;
  editor?: boolean;
  colorString?: Colors;
  removeAddToList?: boolean;
  updateValueInstantly?: boolean;
  onCreateNewItem?: () => void;
  onCompleteItem?: (data: any) => void;
  addAvatar?: boolean;
  background?: Colors;
  placeholder?: string;
  returnValueKey?: boolean;
  editorOptions?: {name: ''; id: ''}[];
  fontWeight?: number;
  margin?: boolean;
  errors?: Array<{
    key?: string;
    error?: string;
  }>;
  disableCheckbox?: boolean;
  maxFields?: number;
  showCheckbox?: boolean;
  userId?: string;
  assigneeOptions?: {value: string; label: string}[];
  variant?: string;
  disabled?: boolean;
  excludeDefaultUser?: boolean;
  readonly?: boolean;
  addFieldPlaceholder?: string;
  dropdownComponent?: any;
  footerComponent?: any;
  ref?: any;
}

export const MultiField = memo(
  ({
    label,
    value,
    updateValue,
    state,
    helper,
    checkedIndexes,
    assigneeOptions,
    showCheckbox,
    disabled,
    editor,
    returnValueKey,
    colorString,
    fontWeight,
    excludeDefaultUser,
    removeAddToList,
    handleUserSelect,
    addFieldPlaceholder,
    userId,
    userCanReorder,
    background,
    margin,
    disableCheckbox,
    editorOptions,
    addAvatar,
    maxFields,
    errors,
    placeholder,
    updateValueInstantly,
    onCreateNewItem,
    onCompleteItem,
    dropdownComponent,
    footerComponent,
    readonly,
    ref,
  }: MultiFieldProps) => {
    const checkable = !!checkedIndexes;

    const getValue = useMemo(() => value, [value]);

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const {
      shouldShowRemove,
      handleChange,
      handleCheck,
      handleRemove,
      values,
      handleAddItem,
    } = useMultiField(
      getValue,
      updateValue,
      () => {},
      checkable,
      maxFields,
      returnValueKey,
      addAvatar,
      updateValueInstantly,
      excludeDefaultUser,
      onCreateNewItem,
      onCompleteItem,
    );

    const addItem = (index?: number) => {
      if (!readonly) {
        handleAddItem();

        if (index) {
          if (inputRefs.current[index + 1]) {
            inputRefs.current[index + 1]?.focus();
          }
        }
      }
    };

    const [sortedLists, setSortedLists] = useState<any[]>([]);

    const arrayedValues = useMemo(() => {
      const updatedValues = [...Array.from(values)];

      return updatedValues;
    }, [values]);

    const drop = (
      newValues: {
        id: any;
        saved?: boolean;
        chosen?: boolean;
        text?: string;
        selected?: boolean;
      }[],
    ) => {
      const computeValues = newValues
        .filter((_value) => !!_value?.text)
        .map((value) => {
          delete value?.saved;
          delete value?.chosen;
          delete value?.selected;

          return {
            key: value.id,
            val: value,
          };
        });

      setSortedLists(computeValues);
    };

    const findError = (key: string) => {
      return errors?.find((error) => error.key === key)?.error;
    };

    return (
      <FieldWrapper margin={margin} ref={ref}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
        </Wrapper>
        {checkedIndexes && <VerticalSpacer size="8px" />}
        <InputWrapper>
          <ReactSortable
            id={nextId('sortable')}
            className={`flex flex-col gap-4`}
            group={'shared'}
            onAdd={(event, sortable) => {
              updateValue(sortedLists, 're-sort');
            }}
            onEnd={() => {
              updateValue(sortedLists, 're-sort');
            }}
            list={
              arrayedValues.map(([key, value], index) => ({
                id: key,
                ...value,
              })) as any
            }
            setList={(lists) => drop(lists)}
            handle=".filter">
            {arrayedValues.map(([key, value], index) => (
              <Field
                value={value?.text}
                checked={value?.completed}
                user={value?.assignee}
                name={key}
                handleUserSelect={handleUserSelect}
                state={state}
                fieldId={key}
                assigneeOptions={assigneeOptions}
                handleChange={handleChange}
                disabled={value?.disabled || disabled}
                fieldLength={arrayedValues.length}
                showAvatar={addAvatar}
                ref={(input: any) => (inputRefs.current[index] = input)}
                addItem={addItem}
                indexOfFieldId={index}
                disableCheckbox={
                  disableCheckbox ||
                  ![value.assignee?.id, value?.user, value?.updatedBy].includes(
                    userId,
                  )
                }
                useCompletedAsDisabled={value?.completed}
                handleCheck={handleCheck}
                editorOptions={editorOptions}
                footerComponent={footerComponent}
                userCanReorder={userCanReorder}
                handleRemove={(key) => handleRemove(key)}
                saveValues={async () => {}}
                error={errors && findError(key)}
                editor={editor}
                showCheckbox={showCheckbox}
                placeholder={placeholder}
                showRemove={shouldShowRemove(index)}
                checkable={!!checkedIndexes ? true : false}
                colorString={colorString}
                dropdownComponent={dropdownComponent}
                fontWeight={fontWeight}
                background={background}
                readonly={value?.disabled || readonly}
              />
            ))}
          </ReactSortable>

          {!checkedIndexes && (
            <AddButton
              tabIndex={-1}
              type="button"
              onClick={() => {
                if (!readonly) {
                  handleAddItem();
                }
              }}>
              <PlusSVG />
            </AddButton>
          )}
        </InputWrapper>

        <div>
          {!removeAddToList && (
            <>
              <VerticalSpacer size="16px" />
              <div
                className={`flex items-center  ${
                  readonly ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
                style={{
                  marginLeft: userCanReorder ? '25px' : '0px',
                }}
                onClick={() => {
                  if (readonly || disabled) {
                    return;
                  }

                  addItem();
                }}>
                <AddButton2 tabIndex={-1} type="button">
                  <PlusSVG />
                </AddButton2>
                <HorizontalSpacer size="8px" />
                <CTA
                  kind={
                    (disabled && editor) || readonly ? 'textMuted' : 'textDark'
                  }
                  style={{fontWeight: 600}}>
                  {editor
                    ? 'Add an item'
                    : addFieldPlaceholder || 'Add to list'}
                </CTA>
              </div>
            </>
          )}
        </div>

        <HelperWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </HelperWrapper>
        <VerticalSpacer size="8px" />
      </FieldWrapper>
    );
  },
);
