import {MeasurementType} from '@hooks';
import {Body2} from '@ui/atoms/typography';
import {ItemLoader} from '../item-loader';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import {
  SecondWrapperHeader,
  LineColor,
  SecondWrapperStackGoals,
} from './styles';
import {FlexRowCenter, FlexRowEnd, FlexRow} from '@ui/style/styles';
import {useState} from 'react';

import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {ProgressType, GoalKind, UserAvatar} from '@ui/interface';
import {StackViewContainer} from '@ui/molecules/stack-view/stack-view-container';
import {cfv} from '@utils/framework';
import {cloudinary} from '@assets/images/cloudinary';
import {FC} from 'react';

const {emptyGoals: emptyGoal} = cloudinary;

export interface Metric {
  [x: string]: any;
  title: string;
  slug: string;
  user: {
    name: string;
    avatar: string;
    id: string;
  };
  state: string;
  measurement: {
    unit: MeasurementType;
    symbol?: string;
  };
  percent: number;
  id: string;
}

export interface StackViewGoal {
  name: string;
  type: GoalKind;
  managerEdit?: boolean;
  variantType?: string;
  goalType: string;
  endDate: string;
  assignee: {
    id: string;
    name: string;
  };
  createdBy: string;
  goal?: any;
  totalUsers: number;
  isOpen: boolean;
  alignedGoals: StackViewGoal[];
  upcomingGoals?: boolean;
  goals?: any;
  group?: string;
  setIsOpen: any;
  users: UserAvatar[];
  disabled?: boolean;
  measurement: {
    unit: MeasurementType;
    symbol?: string;
  };
  showObjectiveProgress?: boolean;
  currencySymbol?: any;
  percentageProgress: number;
  progress: {
    state: string;
    type: ProgressType;
    percent: number;
    due: string;
    status: string;
    endDate: string;
  };
  id: string;
  keyResults: Metric[];
  currentValue?: number;
  targetValue?: number;
  className?: string;
  status: string;
  kind?: string;
  variant?: string;
}

interface IStackView {
  goals: StackViewGoal[];
  isFetching?: boolean;
  allGoals: StackViewGoal[];
}

const UserGoalHighlight = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoal,
    style: {height: '50%', width: '50%'},
  },
  subtitle: `See stacked ${
    cfv(undefined, true).g_oals
  } once they are available.`,
  title: `No stacked ${cfv(undefined, true).g_oals} .`,
};

const StackView: FC<IStackView> = ({isFetching, goals = [], allGoals}) => {
  const [isOpen, setIsOpen] = useState<any>(null);

  return (
    <div>
      <SecondWrapperHeader style={{padding: '24px 16px'}}>
        <FlexRowEnd>
          <FlexRow>
            <LineColor bg="#1A9E68" />
            <HorizontalSpacer size="8px" />
            <Body2 weight="bold" style={{fontSize: '12px'}}>
              Aligned objectives
            </Body2>
          </FlexRow>
          <FlexRow style={{marginLeft: '16px'}}>
            <LineColor bg="#084B8A" />
            <HorizontalSpacer size="8px" />

            <Body2 weight="bold" style={{fontSize: '12px'}}>
              Cascaded key results
            </Body2>
          </FlexRow>
        </FlexRowEnd>
      </SecondWrapperHeader>
      <SecondWrapperStackGoals>
        {isFetching && (
          <FlexRowCenter>
            <ItemLoader />
          </FlexRowCenter>
        )}
        {!isFetching && !goals.length && (
          <div
            style={{
              textAlign: 'center',
            }}>
            <EmptyPlaceholder {...UserGoalHighlight} />
          </div>
        )}
        <div style={{overflowX: 'auto'}}>
          {!isFetching &&
            goals &&
            goals.map((goal, index) => (
              <StackViewContainer
                goal={goal}
                index={goal.id}
                // isExpand={isExpand}
                key={index}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                allGoals={allGoals}
                // keyResults={keyResults}
              />
            ))}
        </div>
      </SecondWrapperStackGoals>
    </div>
  );
};

export default StackView;
