import styled from 'styled-components';
import {Body2} from '@ui/atoms/typography';
import {SearchBox2} from '@ui/molecules/search-box';
import {useMemo, useEffect, useState} from 'react';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {FlexRow} from '@ui/style/styles';
import {cloudinary} from '@assets/images/cloudinary';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {Pagination} from '@ui/molecules/pagination/pagination';
import {YouHightLight} from '../../you/you-components/you-hightligts';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react';
import {ItemLoader} from '@ui/organisms/item-loader';
import {useKpisHook} from './kpis-hook';
import ObjectiveFilter from '../objective-filter';
import {useDebouncedCallback} from 'use-debounce';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {UsersGoalsLayout} from '@ui/layouts/users-goals';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {GoalFilter} from './kpi-filter';
import {SortByFilter} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/status-component/sort-by-filter';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';

const {emptyGoals: emptyGoal} = cloudinary;

const Wrapper = styled.div`
  .nested {
    border-top: 1px solid #ededf2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .filter-wrapper {
    border: 1px solid #ededf2;
    border-radius: 10px;
    width: 100%;

    .pd {
      width: 100%;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .s-b-wrapper {
      border-top: 1px solid #ededf2;

      padding: 16px 24px;
    }
  }
  .g-container {
    border: 1px solid #ededf2;
    border-radius: 10px;
    background: white;
    margin-top: 24px;
  }

  .body-wrapper {
    padding: 16px 24px;
  }

  .goal-wrapper {
    padding: 16px;
    border-top: 1px solid #ededf2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media (max-width: 500px) {
      padding: 16px 12px;
    }
  }
`;

const transformMap = (data: any) => {
  const res = new Map();

  data.forEach((result: any) => {
    res.set(result.id, result);
  });
  return res;
};

export const KpisPage = observer(() => {
  const {
    analyticsStore: {selectedDateFilterValue, updateDateValue},
    storeAllObjectives: {
      youGoalsPaginator,
      youGoalsPages,
      handleYouGoalsPaginatorPageClick,
      handleYouGoalsPaginatorNextbtn,
      revertChanges,
      handleYouGoalsPaginatorPrevbtn,
      saveUpdate,
      goals_: data,
      youGoalsFilter,
      modifyYouGoalsPaginator,
    },
  } = useStoreContext();

  const {
    loading,
    clearSelection,
    setClearSelection,
    searchValue,
    setGoalState,
    setSelectedUsers,
    selectedUsers,
    state,
    groupBy,
    setGroupBy,
    setSortBy,
    refetch,
    sortBy,
    setSearchValue,
    dateRange,
    handleUpdateDateFilter,
  } = useKpisHook('');

  const groupedGoals = data?.reduce(
    (acc: any, goal: any) => {
      const {meta = {}} = goal;
      const {tags = []} = meta;

      if (tags.length === 0) {
        acc.noTags.push(goal);
      } else {
        tags.forEach((_tag: string) => {
          const tag = _tag.toLowerCase().trim();

          if (!acc.tagged[tag]) {
            acc.tagged[tag] = [];
          }
          acc.tagged[tag].push(goal);
        });
      }
      return acc;
    },
    {tagged: {}, noTags: []},
  );

  const [searchBoxValue, setSearchBoxValue] = useState('');

  const updateSearchValue = useDebouncedCallback((value) => {
    setSearchValue(value);
  }, 1000);

  const disableFilter = ['active', 'upcoming', 'archived'].includes(
    youGoalsFilter,
  );

  const EmptyHighlight = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoal,
      style: {height: '50%', width: '50%'},
    },
    subtitle: 'Create an objective from scratch or choose from our templates.',
    title: `You  have no ${youGoalsFilter} KPIs.`,
  };

  const EmptySearchHighlight = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoal,
      style: {height: '50%', width: '50%'},
    },
    subtitle: '',
    title: `No KPI found.`,
  };

  const getData = useMemo(() => data, [data]);

  const taggedGoals = groupedGoals.tagged
    ? Object.entries(groupedGoals.tagged)
    : [];

  const noTaggedGoals = groupedGoals.noTags;

  const allTaggedGoals = [...taggedGoals, ['No Tags', noTaggedGoals]];

  useEffect(() => {
    return () => {
      if (selectedDateFilterValue === 'custom') {
        updateDateValue('');
      }
    };
  }, [updateDateValue, selectedDateFilterValue]);

  return (
    <>
      <Wrapper>
        <div className="g-container ">
          <div className="body-wrapper">
            <div className="flex flex-row items-center sm:flex-col justify-between w-full">
              <Body2 weight="bold">
                Summary ({youGoalsPaginator.totalResults || data?.length})
              </Body2>

              <div className="flex flex-row items-end justify-end sm:w-full w-fit mt-0 sm:mt-2">
                <ObjectiveFilter
                  showDateRange
                  filterOnlyPeople
                  removeUserFilter
                  filterOnlyTeams
                  variant={'objectives'}
                  clearSelection={clearSelection}
                  disabled={disableFilter}
                  setClearSelection={setClearSelection}
                  setDateRange={handleUpdateDateFilter}
                  dateRange={dateRange}
                />
              </div>
            </div>

            <div className=" flex flex-row sm:flex-col justify-between  border-t border-t-[#EDEDF2] mt-4 pt-4">
              <div>
                <GoalFilter
                  isKpi
                  value={{users: selectedUsers, state}}
                  onChange={(state, users) => {
                    setSelectedUsers(users);
                    setGoalState(state as any);
                    handleYouGoalsPaginatorPageClick(1);
                  }}
                />
              </div>
              <FlexRow className="sm:mt-3 mt-0">
                <SortByFilter
                  value={sortBy}
                  onChange={(value) => {
                    setSortBy(value);
                  }}
                />{' '}
                <div className="mx-2">
                  <HorizontalSideRule size="22px" />
                </div>
                <DropdownCustom
                  collapseOnClick={true}
                  menu={(handleClose: () => void) => (
                    <div>
                      <DropdownItem
                        onClick={() => {
                          setGroupBy('');
                          handleClose();
                        }}>
                        None
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setGroupBy('tag');
                          handleClose();
                        }}>
                        KPI tags
                      </DropdownItem>
                    </div>
                  )}
                  customComponent={(
                    handleOpen: (event: any) => void,
                    open: boolean,
                  ) => (
                    <button
                      onClick={handleOpen}
                      className="flex flex-row items-center">
                      <Body2 weight="bold" kind="purple300">
                        Grouping{groupBy === 'tag' ? ': KPI tags' : ''}
                      </Body2>

                      <ArrowHeadDownIcon
                        style={{
                          marginLeft: 6,
                          right: 0,
                          stroke: '#585ADF',
                          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </button>
                  )}
                  customDropdownWrapperStyles={{
                    top: '130%',
                    right: '0px',
                    width: '150px',
                  }}
                />
              </FlexRow>
            </div>

            <div className="s-b-wrapper mt-3">
              <SearchBox2
                placeholder={`Search goals`}
                inputStyle={{height: '56px', borderRadius: '10px'}}
                onChange={(e) => {
                  const text = e.target.value;
                  updateSearchValue(text);
                  setSearchBoxValue(text);
                }}
                value={searchBoxValue}
                handleCancel={() => {
                  setSearchValue('');
                  setSearchBoxValue('');
                }}
              />
            </div>
          </div>

          {!groupBy && (
            <div className={'goal-wrapper bg-white'}>
              <>
                {loading && <ItemLoader />}

                {!loading && data?.length > 0 && (
                  <>
                    <YouHightLight
                      goals={transformMap(getData)}
                      isFetching={loading}
                      onUpdateReverted={async (id) => {
                        await revertChanges(id).then(() => refetch());
                      }}
                      onGoalUpdate={async (id) => {
                        await saveUpdate(id).then(() => refetch());
                      }}
                    />
                  </>
                )}

                {!loading && getData.length === 0 && searchValue && (
                  <EmptyPlaceholder {...EmptySearchHighlight} />
                )}

                {!loading && getData.length === 0 && !searchValue && (
                  <EmptyPlaceholder {...EmptyHighlight} />
                )}
              </>
            </div>
          )}
        </div>

        {groupBy && (
          <div>
            {allTaggedGoals.map(([title, goals]) => (
              <div className="mt-4">
                <UsersGoalsLayout
                  title={`${title} (${goals.length})`}
                  goals={goals}
                  tooltip={true}
                  view="flat"
                  isFetching={loading}
                />
              </div>
            ))}
          </div>
        )}
      </Wrapper>

      {youGoalsPaginator.totalResults > 10 && !groupBy ? (
        <Pagination
          totalPages={youGoalsPaginator.totalPages}
          page={youGoalsPaginator.page}
          totalResults={youGoalsPaginator.totalResults}
          maxPageNumberLimit={youGoalsPaginator.maxPageNumberLimit}
          limit={youGoalsPaginator.limit}
          updateLimit={(value) => modifyYouGoalsPaginator('limit', value)}
          minPageNumberLimit={youGoalsPaginator.minPageNumberLimit}
          goToPage={handleYouGoalsPaginatorPageClick}
          pages={youGoalsPages(
            youGoalsPaginator.totalResults,
            youGoalsPaginator.limit,
          )}
          nextBtn={handleYouGoalsPaginatorNextbtn}
          prevBtn={handleYouGoalsPaginatorPrevbtn}
        />
      ) : null}
    </>
  );
});
