import {IMeetingResponse} from '@hooks';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {Button} from '@ui/atoms/button';
import {SelectField} from '@ui/molecules/select/selectfield';
import {DatePicker} from '@ui/molecules/date-picker';
import {
  computeReminderOptions,
  FREQUENCY_OPTIONS,
} from '../create-meetings/options';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import dayjs from 'dayjs';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {Body2, Headline3, CTA} from '@ui/atoms/typography';
import {useState, useCallback} from 'react';
import {Label} from '@ui/atoms/label';
import {MeetingRequestImpl} from '../meeting.request';
import {MeetingController} from '../meeting-controller';
import {useGCalenderHook} from '@hooks/gcalender';
import {authStore} from '@store/stores/auth-store';
import {useStoreContext} from '@store/store-context';
import {useMeetingTags} from '../useMeetingTags';
import {TimeSelect} from '../create-meetings/create-meeting-form';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import nextId from 'react-id-generator';
import {useDebouncedCallback} from 'use-debounce';

interface RescheduleProps {
  meeting: IMeetingResponse;
  open: boolean;
  onClose: () => void;
  onUpdated?: () => void;
}

export const RescheduleMeeting = ({
  open,
  meeting,
  onUpdated,
  onClose,
}: RescheduleProps) => {
  const computeParticipant = meeting.participant.map((participant: any) =>
    typeof participant === 'string' ? participant : participant.id,
  );
  const owner = meeting.user?.id;

  const [values, setValues] = useState({
    startDate: meeting.startDate,
    endTime: meeting?.endDate ? dayjs(meeting?.endDate).format('hh:mm a') : '',
    frequency: meeting.frequency,
    title: meeting?.title || '',
    label: meeting.label,
    participant: computeParticipant,
    meetingLink: meeting?.meetingLink || '',
    addBeamAI: !!meeting.meta?.botId,
    time: meeting.time,
    reminder: meeting.reminder,
  });

  const [isUpdated, setIsUpdated] = useState(false);

  const getTime = (time: string) => {
    const formattedTime = time.toLowerCase();

    const splitTime = formattedTime.split(':');

    const hour = splitTime[0];

    const getMinute = splitTime?.[1]?.split(' ')[0] || '';

    return {
      hour,
      minute: getMinute,
      period: formattedTime.includes('pm') ? 'pm' : 'am',
    };
  };

  const computedStartTime = getTime(meeting?.time);

  const computedEndTime = getTime(values.endTime);

  const [selectedTime, setSelectedTime] = useState({
    starts: {
      time: `${computedStartTime.hour ? computedStartTime.hour + ':' : ''}${
        computedStartTime.minute?.padStart(2, '00') ?? ''
      }`,

      period: computedStartTime.period || 'AM',
      error: '',
    },
    ends: {
      time: `${computedEndTime.hour ? computedEndTime.hour + ':' : ''}${
        computedEndTime.minute || ''
      }`,
      period: computedStartTime.period || 'AM',
      error: '',
    },
  });

  const {tags} = useMeetingTags();

  const enableNextButton =
    values.time &&
    values.startDate &&
    values.frequency &&
    isUpdated &&
    !selectedTime.ends.error &&
    !selectedTime.starts.error &&
    values.title;

  const updateSelectedTime = (
    value: string,
    field: keyof typeof selectedTime['starts'],
    type: 'starts' | 'ends',
  ) => {
    if (field === 'error') {
      return setSelectedTime((prev) => ({
        ...prev,
        [type]: {...prev[type], error: value},
      }));
    } else {
      setSelectedTime((prev) => ({
        ...prev,
        [type]: {...prev[type], [field]: value, error: ''},
      }));
    }

    const getSelectedTime = {
      ...selectedTime,
      [type]: {...selectedTime[type], [field]: value},
    };

    // if (canTimeUpdate) {
    handleFormValueChange(
      `${getSelectedTime[type].time} ${getSelectedTime[type].period}`,
      type === 'starts' ? 'time' : 'endTime',
    );
    // }
  };

  const handleFormValueChange = useCallback(
    async (value: string | null | string[] | boolean, name: string) => {
      setValues((prev) => ({...prev, [name]: value}));
      setIsUpdated(true);
    },
    [],
  );
  const {isSigned, updateEvent, getEvent} = useGCalenderHook();

  const [loading, setLoading] = useState(false);

  const computeTimeWithDate = dayjs(values.startDate)
    .set('hour', Number(getTime(selectedTime.starts.time).hour))
    .set('minute', Number(getTime(selectedTime.starts.time).minute));

  const computeEndTimeWithDate = dayjs(values.startDate)
    .set('hour', Number(getTime(selectedTime.ends.time).hour))
    .set('minute', Number(getTime(selectedTime.ends.time).minute));

  const replacePeriod = (date: string, period: string) => {
    return date.replace(date.slice(date.length - 2), period);
  };

  const updatedDate = dayjs(
    replacePeriod(
      computeTimeWithDate.format(`YYYY-MM-DD hh:mm a`),
      selectedTime.starts.period,
    ),
    'YYYY-MM-DD hh:mm a',
  ).format();

  const updatedEndDate = dayjs(
    replacePeriod(
      computeEndTimeWithDate.format(`YYYY-MM-DD hh:mm a`),
      selectedTime.ends.period,
    ),
    'YYYY-MM-DD hh:mm a',
  ).format();

  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const EventId = meeting?.meta?.calendarEventId || '';

  const fetchMeetingLink = async () => {
    getEvent(EventId).then(
      function (resp: any) {
        const response = resp.result;

        handleFormValueChange(response?.hangoutLink, 'meetingLink');
      },
      function (reason: any) {},
    );
  };

  const debounceMeetingLinkFetch = useDebouncedCallback(
    () => fetchMeetingLink(),
    20000,
  );

  const isBeforeMeetingEnd = dayjs().isBefore(
    dayjs(meeting.startDate).add(1, 'hour'),
  );

  const canEditOthers = owner === auth.user.id && isBeforeMeetingEnd;

  const MEETING_WITH = () => {
    const computeValue = (user: {
      firstName: string;
      lastName: string;
      email: string;
      avatar: {url: string};
      id: string;
    }) => ({
      label:
        user && user.firstName
          ? user.firstName + ' ' + user.lastName
          : user.email + ' (pending invitation)',
      value: user.id,
      disabled: meeting.participant.includes(user.id),
    });

    return users
      .filter((user) => user.id !== auth.user.id)
      .map((user) => {
        return computeValue(user);
      });
  };

  const onSubmit = async () => {
    const request = new MeetingRequestImpl();

    const controller = new MeetingController(request);

    setLoading(true);

    const _data = {
      ...values,
      endDate: dayjs(updatedEndDate).isValid() ? updatedEndDate : undefined,
      endTime: undefined,
      meetingLink: values.meetingLink || undefined,
      id: undefined,
    };

    const response = await controller.patchMeeting(_data, meeting.id);

    if (response) {
      activateNotification({
        content: 'Meeting updated successfully.',
        title: 'Success',
        kind: 'success',
      });

      onUpdated && onUpdated();

      if (isSigned) {
        updateEvent(
          {
            start: {
              dateTime: updatedDate,
              timeZone: authStore.auth.user.timezone,
            },
            end: {
              dateTime: updatedEndDate,
              timeZone: authStore.auth.user.timezone,
            },
          },
          EventId,
          'primary',
          'all',
        ).then(
          function (resp: any) {},
          function (reason: any) {},
        );
      }
      setLoading(false);

      onClose();
    }
    setLoading(false);
  };

  const disableUpdateBeamAI = !meeting?.id || !EventId || !isBeforeMeetingEnd;

  return (
    <DrawerModal open={open} onClose={onClose} widthType="quarter">
      <Headline3>Meeting settings</Headline3>
      <VerticalSpacer size="32px" />

      <Label>Meeting topic</Label>
      <VerticalSpacer size="8px" />

      <TextField
        inputStyle={{borderRadius: '10px'}}
        value={values.title}
        placeholder="e.g. Weekly catch up "
        onChange={(event) => {
          handleFormValueChange(event.target.value, 'title');
        }}
      />

      <div className="relative">
        <Label>
          Tags <span className="text-[#5F5F8C] font-normal">(optional)</span>
        </Label>
        <VerticalSpacer size="8px" />

        <SelectField
          placeholder="# - add tag"
          performance
          type="dropdownOnly"
          borderRadius="10px"
          excludeSearchBox
          showCancelIcon={false}
          defaultValue={values.label?.[0]}
          noOptionMessage={'No option Found'}
          groupDisplayLength={3}
          options={
            tags
              ? tags.map((tag) => ({
                  value: tag.tag,
                  label: tag.tag,
                }))
              : []
          }
          onChange={(data: {value: string; label: any | null}) => {
            handleFormValueChange(data?.value ? [data?.value] : [], 'label');
          }}
        />
      </div>

      <Label>Auto record</Label>

      <Body2 kind="textBody" className="mt-1">
        Set the BeamAI to join, record and summarise your events automatically.
      </Body2>

      <div className="border border-[#CECEDE] mt-1 py-[14px] px-4 rounded-[10px] gap-2 flex-row flex">
        <ToggleSwitch
          checked={values.addBeamAI}
          onChange={() => {
            const botValue = values.addBeamAI;

            handleFormValueChange(!botValue, 'addBeamAI');

            debounceMeetingLinkFetch();
          }}
          switchId={meeting?.id || (nextId() as any)}
          disabled={disableUpdateBeamAI}
        />
        <CTA kind={disableUpdateBeamAI ? 'textMuted' : 'textDark'}>
          Invite BeamAI to join
        </CTA>
      </div>
      <VerticalSpacer size="24px" />

      <Label>Add/remove members from this meeting</Label>

      <MultiOptionsSelectField
        name="participant"
        defaultValue={values.participant.map((participant: string) => ({
          value: participant,
        }))}
        onChange={(data: {value: string}) => {
          if (Array.isArray(data)) {
            handleFormValueChange(
              data?.map((option) => option.value),
              'participant',
            );

            return;
          }
          handleFormValueChange(data?.value, 'participant');
        }}
        placeholder={`Select members`}
        searchPlaceholder={'Search'}
        options={MEETING_WITH()}
        fieldNotFoundPlaceHolder={(searchTerm?: string) =>
          `Oops! Seems there is no ${searchTerm} found in this workspace`
        }
      />

      {canEditOthers && (
        <>
          <Label>Date</Label>
          <VerticalSpacer size="8px" />

          <DatePicker
            label={'Date'}
            placeholder={values.startDate ? '' : 'Choose'}
            dateAlign="left"
            onChange={(date) => handleFormValueChange(date, 'startDate')}
            value={values.startDate}
            // disabledAfter={overallTimeline?.ends}
            disabledBefore={dayjs().subtract(1, 'day').format()}
          />

          <VerticalSpacer size="24px" />
          <Label>Time</Label>

          <>
            <div className="flex sm:flex-col flex-row  gap-3">
              <div>
                <TimeSelect
                  selectedTime={selectedTime.starts}
                  type="starts"
                  addEndTime={!selectedTime?.ends.time}
                  updateSelectedTime={updateSelectedTime}
                />
                {selectedTime.starts.error ? (
                  <Body2
                    kind="red400"
                    style={{fontSize: '12px'}}
                    weight="semibold">
                    {selectedTime.starts.error}
                  </Body2>
                ) : null}
              </div>
              <div>
                <TimeSelect
                  selectedTime={selectedTime.ends}
                  type="ends"
                  updateSelectedTime={updateSelectedTime}
                />
                {selectedTime.ends.error ? (
                  <Body2
                    kind="red400"
                    style={{fontSize: '12px'}}
                    weight="semibold">
                    {selectedTime.ends.error}
                  </Body2>
                ) : null}
              </div>
            </div>
          </>

          <VerticalSpacer size="24px" />
          <Label>Meeting frequency</Label>
          <VerticalSpacer size="8px" />

          <SelectField
            name="user"
            defaultValue={values.frequency}
            excludeSearchBox
            onChange={(data: {value: string}) => {
              handleFormValueChange(data?.value, 'frequency');
            }}
            borderRadius="10px"
            placeholder="Select frequency"
            options={FREQUENCY_OPTIONS}
            fieldNotFoundPlaceHolder={(searchTerm?: string) =>
              `Oops! Seems there is no ${searchTerm} found in this workspace`
            }
          />

          <Label>
            Meeting reminder <span className="#5F5F8C">(Optional)</span>
          </Label>

          <MultiOptionsSelectField
            placeholder="Select when"
            value={values.reminder}
            type="dropdownOnly"
            excludeSearchBox
            disabled={!values.startDate}
            // reviewer={true}
            showCancelIcon={false}
            defaultValue={values.reminder?.map((member: any) => {
              return {value: member};
            })}
            noOptionMessage={'No option Found'}
            // reportingStyling={true}
            groupDisplayLength={4}
            options={computeReminderOptions(values.startDate)}
            onChange={(data: {value: string; label: any | null}[]) => {
              handleFormValueChange(
                data?.map((time) => time.value),
                'reminder',
              );
            }}
          />
        </>
      )}

      <Button
        width="full"
        onClick={onSubmit}
        isLoading={loading}
        disabled={!enableNextButton}>
        Save
      </Button>
    </DrawerModal>
  );
};
