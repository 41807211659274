import {Modal} from '@ui/molecules/modal';
import {BackAction} from '@ui/molecules/back-action';
import {Headline2, CTA, Body2} from '@ui/atoms/typography';
import {CancelIcon} from '@ui/atoms/icons';
import styled from 'styled-components';
import {parseCurrencyValue} from '@constants';
import dayjs from 'dayjs';
import {AdminBillingRequestImpl} from '../../../admin-billing.request';
import {AdminBillingController} from '../../../admin-billing.controller';
import {Flex} from '@ui/style/styles';
import {Button} from '@ui/atoms/button';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {VerticalSpacer, HorizontalSpacer} from '@ui/atoms/spacer';
import {ThickCheckIcon} from '@ui/atoms/icons/check';
import {getColorFromTheme} from '@ui/ui-utils';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {ModalCard} from '@ui/layouts/modal-card';

import {useState} from 'react';
import {setFirebaseData} from '@utils/firebase-handler';

const BillingDetails = styled.div`
  background: #f6f6f8;
  border-radius: 10px;
  padding: 24px;
  width: 100%;
`;

const ModalDiv = styled.div`
  max-width: 500px;
  margin: auto;
  @media (max-width: 500px) {
    margin: 0px 16px;
  }
`;

export const PriceHelperInfoDiv = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(237, 237, 242, 1)' stroke-width='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  padding: 24px;
  border-radius: 12px;
  div.info {
    display: flex;
    span.price {
      font-weight: 500;
      color: ${getColorFromTheme('textDark')};
    }
  }
`;

interface IAnnual {
  open: boolean;
  onClose: () => void;
  billingPlans: any;
  seats: number;
  currency: string;
  billingType?: string;
  type?: '' | 'downgrade' | 'upgrade';
  nextStartDate: string;
  loadBillingSubscriptions: () => void;
  monthlyCost: number;
}

export const AnnualModal = ({
  open,
  onClose,
  billingPlans,
  currency,
  billingType,
  type,
  seats,
  loadBillingSubscriptions,
  nextStartDate,
  monthlyCost,
}: IAnnual) => {
  // Make update to this
  const getPlanType = () => {
    if (type === 'upgrade') return 'standard';

    if (billingType?.includes('standard') && !type) return 'standard';

    return 'growth';
  };

  const getBillingType = billingType?.includes('monthly')
    ? 'monthly'
    : 'yearly';

  const getPlanTypeName = () => {
    if (getPlanType().includes('standard')) return 'standard_';
    return '';
  };

  const monthlyUnitCost = (billingPlans as any)?.[getPlanType()]?.[
    getPlanTypeName() + 'monthly'
  ].unit_cost;

  const yearlyLumpsumCost = !type
    ? monthlyCost * 12
    : getBillingType === 'monthly'
    ? monthlyUnitCost * (seats as number)
    : monthlyUnitCost * 12 * (seats as number);

  const yearlyDiscountPercent = (billingPlans as any)?.[getPlanType()]?.[
    getPlanTypeName() + 'yearly'
  ]?.yearly_discount_percent;

  const annualDiscountPercent = Math.floor(
    (yearlyDiscountPercent / 100) * yearlyLumpsumCost,
  );

  const total =
    getBillingType === 'monthly'
      ? yearlyLumpsumCost
      : yearlyLumpsumCost - annualDiscountPercent;

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const request = new AdminBillingRequestImpl();
    const controller = new AdminBillingController(request);
    const response = await controller.switchSubscription(
      !type ? 'yearly' : getPlanTypeName() + getBillingType,
    );
    if (response) {
      !!response &&
        setFirebaseData(`updates/settings`, generateRandomDigits(16));

      loadBillingSubscriptions();

      onClose && onClose();

      activateNotification({
        title: 'Success',
        content: !type
          ? 'Your billing cycle was switched to annually.'
          : 'Your billing cycle updated successfully.',
        kind: 'success',
      });
    }
    setLoading(false);
  };
  const modalTitle =
    type === 'upgrade'
      ? 'Upgrade to Standard?'
      : type === 'downgrade'
      ? 'Downgrade to Essentials?'
      : 'Switch to Annual?';
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDiv>
        <div style={{width: '100%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={onClose}
            title="Close"
          />
        </div>

        <VerticalSpacer size="16px" />

        <ModalCard align="center" title={``}>
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <span>
              <Headline2 align="center">{modalTitle}</Headline2>
            </span>

            <VerticalSpacer size="24px" />
            <BillingDetails>
              <CTA kind="green400" style={{fontSize: 12}}>
                {!!type
                  ? 'Plan Info'
                  : `Active plan - ${
                      billingType?.includes('standard')
                        ? 'Standard'
                        : 'Essentials'
                    }`}
              </CTA>
              <VerticalSpacer size="16px" />
              <Headline2 kind="textDark">
                {parseCurrencyValue(currency)}
                {total}
                <span style={{fontSize: 14, fontWeight: 400, color: '#5F5F8C'}}>
                  / {!type ? 'Annually' : getBillingType}
                </span>
              </Headline2>
              <VerticalSpacer size="16px" />
              <Body2 kind="textBody">
                Seats:{' '}
                <span style={{color: '#1E1E2F', fontWeight: 600}}>
                  {seats} seats
                </span>
              </Body2>
            </BillingDetails>
            <VerticalSpacer size="24px" />
            <PriceHelperInfoDiv>
              <div className="info">
                <span>
                  <ThickCheckIcon style={{stroke: '#585ADF'}} />
                </span>
                <HorizontalSpacer size="5px" />
                <Body2 kind="textBody">
                  When your plan renews by{' '}
                  {dayjs(nextStartDate).format('MMMM DD')}, your annual plan
                  will begin and you will be charged for the new billing cycle
                  until when cancelled.
                </Body2>
              </div>

              <VerticalSpacer size="16px" />
              <div className="info">
                <span>
                  <ThickCheckIcon style={{stroke: '#585ADF'}} />
                </span>
                <HorizontalSpacer size="5px" />
                <Body2 kind="textBody">
                  If a new member is added, you will be charged the pro-rated
                  amount of the current billing period, plus the cost for the
                  new billing period in your next billing on{' '}
                  {dayjs(nextStartDate).format('MMMM DD YYYY')}.
                </Body2>
              </div>
              <VerticalSpacer size="16px" />
              <div className="info">
                <span>
                  <ThickCheckIcon style={{stroke: '#585ADF'}} />
                </span>
                <HorizontalSpacer size="5px" />
                <Body2 kind="textBody">
                  Your acount will be instantly updated. You can upgrade,
                  downgrade at anytime.
                </Body2>
              </div>
            </PriceHelperInfoDiv>
            <VerticalSpacer size="24px" />

            <Button
              kind="primary"
              isLoading={loading}
              style={{
                padding: '12px 24px',
                width: '340px',
              }}
              onClick={handleSubmit}>
              Confirm
            </Button>
          </Flex>
        </ModalCard>
      </ModalDiv>
    </Modal>
  );
};
