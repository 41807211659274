import {Flex} from '@ui/style/styles';
import {Button} from '@ui/atoms/button';
import {Headline3, Body2} from '@ui/atoms/typography';
import {useBreakpoints} from '@utils/use-breakpoints';
import {down} from 'styled-breakpoints';
import {VerticalSpacer} from '@ui/atoms/spacer';
import styled from 'styled-components';
import {ReactElement} from 'react';

interface BodyProps {
  image: ReactElement;
  title: string;
  description: string;
  customButton?: () => void;
  dropDown?: ReactElement;
}

const ImageWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const FlexWrapper = styled.div`
  width: 70%;
  ${down('sm')} {
    width: 100%;
  }
`;
export const IntegrationBody = ({
  image,
  title,
  description,
  dropDown,
  customButton,
}: BodyProps) => {
  const {isXs} = useBreakpoints();

  const CustomButton = customButton ? (
    customButton()
  ) : (
    <Button
      kind="primary"
      disabled
      style={{
        width: '118px',
        height: '38px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      Coming Soon
    </Button>
  );

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      style={{margin: '16px'}}>
      <FlexWrapper>
        <Flex
          alignItems="center"
          justifyContent={isXs ? 'space-between' : 'flex-start'}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <ImageWrapper>{image}</ImageWrapper>
            <Headline3 style={{marginLeft: '5px'}}>{title}</Headline3>
          </div>
          {isXs && CustomButton}
        </Flex>
        <VerticalSpacer size="10px" />
        <Body2 kind="textBody">{description}</Body2>
      </FlexWrapper>

      {!isXs && (
        <Flex alignItems="center">
          {CustomButton}
          <div style={{marginLeft: '8px'}}>{dropDown}</div>
        </Flex>
      )}
    </Flex>
  );
};
export const Wrapper = styled.div<{state?: string}>`
  border: ${({state}) =>
    state === 'error' ? '1px solid #D52A2A' : '1px solid #ededf2'};
  border-radius: 10px;
  margin-top: 10px;
`;
