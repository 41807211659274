import {makeAutoObservable, observable, action} from 'mobx';
import {IBillingPlans} from '../../pages/dashboard/admin/admin-components/admin-workspace/admin-billing-setup/admin-billing.interface';

export class BillingsStore {
  @observable loader: any = {};
  @observable billingPlans: IBillingPlans | null = null;
  @observable billingSubscriptions: any = null;
  @observable billingMethods: any = null;
  @observable numSeats: number | null = null;
  @observable selectedBillingPlan: string = 'monthly';
  @observable chosenBillingPlanType: string = 'growth';
  @observable validCoupons: any = null;
  @observable showNonActiveBilling = false;

  showFreeTierModals: any = {
    date_filter: '',
    invite_user: false,
  };

  @observable nextCycle: any = null;
  constructor() {
    makeAutoObservable(this);
  }

  @action updateShowNonActiveBilling = (status: boolean) => {
    this.showNonActiveBilling = status;
  };

  @action updateShowFreeTierModals = (
    field: 'date_filter' | 'invite_user',
    value: boolean | string,
  ) => {
    this.showFreeTierModals[field] = value;
  };

  @action
  setLoader = (field: string, loading: boolean) => {
    this.loader = {...this.loader, [field]: loading};
  };

  @action
  setBillingPlans = (data: any) => {
    this.billingPlans = data;
  };

  @action
  setNumSeats = (num: number) => {
    this.numSeats = num;
  };
  @action
  setSelectedBillingPlan = (plan: string) => {
    this.selectedBillingPlan = plan;
  };
  @action
  setChosenBillingPlanType = (plan: string) => {
    this.chosenBillingPlanType = plan;
  };
  @action
  setBillingSubscriptions = (data: any) => {
    this.billingSubscriptions = data;
  };
  @action
  setBillingMethods = (data: any) => {
    this.billingMethods = data;
  };
  @action
  setValidCoupons = (data: any) => {
    this.validCoupons = data;
  };
  @action
  setNextCycle = (data: any) => {
    this.nextCycle = data;
  };
}

export const billingsStore = new BillingsStore();
