import {useStoreContext} from '@store/store-context';
import {useQuery} from 'react-query';
import {useDebouncedCallback} from 'use-debounce';
import {MemberProfileRequestImpl} from '../../member-profile-request';
import {MemberProfileController} from '../../member-profile-controller';
import {IntegrationsController} from '@pages/dashboard/admin/admin-components/admin-integrations/components/app.controller';
import {useMemo, useCallback, useState} from 'react';
import {capitalize} from '@utils';
import {checkinName} from '@utils/checkin-name';

export const useNotification = () => {
  const {
    authStore: {auth, setInitResponseAsAuth, setInitResponse},
  } = useStoreContext();

  const emailCategoryNames = useMemo(
    () => [
      'Task list',
      'Hubspot',
      '1:1 meeting reminder',
      `${capitalize(checkinName())} submissions`,
      'OKR & KPIs updates',
      'OKR & KPIs edit',
    ],
    [],
  );
  const [emailParameters, setEmailParameters] = useState(
    auth.user.notifications.email.parameters,
  );

  const [isEmailNotificationEnabled, setIsEmailNotificationEnabled] = useState(
    auth.user.notifications.email.enabled,
  );

  const [slackParameters, setSlackParameters] = useState(
    auth.user.notifications.slack.parameters,
  );

  const [isSlackNotificationEnabled, setIsSlackNotificationEnabled] = useState(
    auth.user.notifications.slack.enabled,
  );

  const slackCategoryNames = useMemo(
    () => [
      'Task list',
      'Hubspot',
      '1:1 meeting reminder',
      `${capitalize(checkinName())} submissions`,
      'OKR & KPIs edit',
    ],
    [],
  );

  const handleSettings = async () => {
    const request = new MemberProfileRequestImpl();
    const controller = new MemberProfileController(request);
    const {
      id: _,
      rights,
      avatar,
      emailVerified,
      status,
      isReviewer,
      createdAt,
      updatedAt,
      googleId,
      workspace,
      ...rest
    } = auth.user;

    const profileData = {
      ...rest,

      reviewer: auth.user.reviewer?.id || auth.user.reviewer,
      notifications: {
        email: {
          enabled: isEmailNotificationEnabled,
          parameters: emailParameters,
        },
        slack: {
          enabled: isSlackNotificationEnabled,
          parameters: slackParameters,
        },
      },
    };

    const response: any = await controller.newMemberProfileForm(
      profileData,
      auth.tokens.access.token,
      true,
    );

    if (response) {
      const storedAuthObject = JSON.parse(localStorage.auth);

      storedAuthObject.user.notifications = response?.notifications;

      localStorage.setItem('auth', JSON.stringify(storedAuthObject));

      setInitResponse(storedAuthObject);

      setInitResponseAsAuth();
    }
  };

  const updateSettings = (type: 'slack' | 'email') => {
    if (type === 'email') {
      setIsEmailNotificationEnabled(!isEmailNotificationEnabled);

      return delaySettings();
    }

    if (type === 'slack') {
      setIsSlackNotificationEnabled(!isSlackNotificationEnabled);

      return delaySettings();
    }
  };

  const delaySettings = useDebouncedCallback(() => handleSettings(), 1000);

  const updateParameters = (type: 'slack' | 'email', value: string) => {
    if (type === 'email') {
      if (emailParameters.includes(value)) {
        const formatParams = emailParameters.filter(
          (params: string) => params !== value,
        );
        setEmailParameters(formatParams);

        return delaySettings();
      }
      setEmailParameters((prev: string) => [...prev, value]);

      return delaySettings();
    }
    if (type === 'slack') {
      if (slackParameters.includes(value)) {
        const formatParams = slackParameters.filter(
          (params: string) => params !== value,
        );
        setSlackParameters(formatParams);
        return delaySettings();
      }
      setSlackParameters((prev: string) => [...prev, value]);
      return delaySettings();
    }
  };

  const getActiveIntegrations = useCallback(async () => {
    const controller = new IntegrationsController();
    const response = await controller.getIntegrations();

    return response;
  }, []);

  const {data, isLoading} = useQuery('activeApp', () =>
    getActiveIntegrations(),
  );

  const options = useMemo(() => {
    const categoryExist = (category: string) =>
      emailParameters.includes(category);
    const slackCategoryExist = (category: string) =>
      slackParameters.includes(category);

    const opt = [
      {
        title: 'Email notification',
        type: 'email',
        subTitle: 'Automatically manage beam actions',
        isChecked: isEmailNotificationEnabled,
        subCategory: emailCategoryNames.map((categoryName) => ({
          name: categoryName,
          isChecked: categoryExist(categoryName),
        })),
      },
    ];
    if (data) {
      if (
        data?.find(
          (apps: {app: string; status: string}) =>
            apps.app === 'Slack' && apps.status === 'connected',
        )
      ) {
        opt.push({
          title: 'Slack notification ',
          type: 'slack',
          subTitle: 'Automatically manage beam actions',
          isChecked: isSlackNotificationEnabled,
          subCategory: slackCategoryNames.map((categoryName) => ({
            name: categoryName,
            isChecked: slackCategoryExist(categoryName),
          })),
        });
      }
    }

    return opt;
  }, [
    data,
    emailCategoryNames,
    slackParameters,
    slackCategoryNames,
    isEmailNotificationEnabled,
    isSlackNotificationEnabled,
    emailParameters,
  ]);

  return {options, isLoading, updateParameters, updateSettings};
};
