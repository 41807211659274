import {useCallback, useMemo, useState} from 'react';
import {MeetingRequestImpl} from '../meeting.request';
import {authStore} from '@store/stores/auth-store';
import {IAPIUserFormat} from '@hooks';
import {get} from 'idb-keyval';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {MeetingController} from '../meeting-controller';
import {IMeetingResponse} from '@hooks';
import {beamMasteryTasks} from '@utils/firebase-request';
import {usePostHogHook} from '@hooks/post-hog';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import dayjs from 'dayjs';
import {removeEmptyStringFromObject} from '@utils/data-structure-algos';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {useActionItems} from '@hooks/features-action-items';
import {useGCalenderHook} from '@hooks/gcalender';

export interface PatchMeetingFormData {
  participant: string;
  [x: string]: any;
  shareHostAgenda: boolean;
  hostAgenda: {text: string; completed: boolean}[];
  startDate: string;
  frequency: string;
  time: string;
  reminder: string[];
}

export const useViewMeetingHook = () => {
  const request = useMemo(() => new MeetingRequestImpl(), []);
  const controller = useMemo(() => new MeetingController(request), [request]);

  const [agendas, setAgendas] = useState({host: [], participant: []});

  const {getCalendarEventById, isSigned} = useGCalenderHook();

  const fetchMeetingDetails = useCallback(
    async (
      id: string,
      startMeeting?: boolean,
    ): Promise<IMeetingResponse | false> => {
      const users = await get(`${authStore.auth.user.workspace.id}/users`);

      const response = await controller.getMeeting(id, startMeeting);
      if (response) {
        const _response = {
          ...response,
          user: users.find((user: IAPIUserFormat) => user.id === response.user),
          participant: response.participant,
          time: !response.time?.toLowerCase().includes('invalid')
            ? response.time
            : '',
        };

        if (response?.meta?.calendarEventId && isSigned) {
          await getCalendarEventById(
            response?.meta?.calendarEventId,
            'primary',
          ).then(
            async function (resp: any) {
              const event = resp.result;

              if (event) {
                const allUsersEmail = users.map(
                  (user: {email: string}) => user.email,
                );

                const isExistingParticipant = allUsersEmail.filter(
                  (user: string) => response.participant.includes(user),
                );
                const attendees = event?.attendees?.filter(
                  (attendee: {email: string}) =>
                    !isExistingParticipant.includes(attendee.email),
                );

                attendees.forEach((attendee: {email: string}) => {
                  if (allUsersEmail.includes(attendee.email)) {
                    const userId = users.find(
                      (user: {email: string}) => user.email === attendee.email,
                    )?.id;

                    _response.participant.push(userId);
                  }
                });

                _response.extraParticipant = attendees;

                _response.meetingLink = event?.hangoutLink;
              }
            },
            function (reason: any) {},
          );
        }

        setAgendas({
          host: response.hostAgenda,
          participant: response.participantAgenda,
        });

        return _response;
      }
      return false;
    },
    [controller, getCalendarEventById, isSigned],
  );

  const handleUpdateMeeting = useCallback(
    async (id: string, data: PatchMeetingFormData): Promise<any> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);
      const _data = {
        ...data,
      };

      const response = await controller.patchMeeting(
        removeEmptyStringFromObject(_data),
        id,
      );

      if (response) {
        setAgendas({
          host: response.hostAgenda,
          participant: response.participantAgenda,
        });
      }
      return response;
    },
    [],
  );

  const handleAddTopic = useCallback(
    async (title: string, isPrivate: boolean, id: string) => {
      const body: any = {
        title,
        isPrivate,
        id: generateRandomDigits(24),
      };

      writeFirebaseData(`meeting/${id}/topics`, body);
      return;
    },
    [],
  );

  const [submitting, setSubmitting] = useState(false);

  const [searchParams] = useSearchParams();

  const isMeetingNotes = searchParams.get('type') === 'meeting-notes';

  const {postHogCapture} = usePostHogHook();

  const markAsCompleted = useCallback(
    async (
      id: string,
      name: string,
      feedbackValues: {
        feedbackValue: string;
        impression: string;
        feedbackSharingType: string;
        values: string[];
      },
      userId: string,
    ) => {
      const request = new FeedbackRequestImpl();
      const controller = new FeedbackController(request);
      const {
        feedbackValue,
        impression,
        feedbackSharingType,
        values,
      } = feedbackValues;

      setSubmitting(true);

      const submitMeeting = async (feedbackId?: string) => {
        const request = new MeetingRequestImpl();
        const controller = new MeetingController(request);
        const _data = {
          status: 'completed',
          feedback: feedbackId,
        };

        const meetingResponse = await controller.patchMeeting(_data, id);
        beamMasteryTasks('complete_meeting', true);

        setSubmitting(false);

        postHogCapture('frontend - 1:1 meeting conclusion');

        return meetingResponse;
      };
      setSubmitting(false);

      if (feedbackValue && impression && feedbackSharingType) {
        const response = await controller.postFeedback(
          {
            feedback: feedbackValue,
            impression: impression,
            subjectType: '1:1',
            subjectName: `1:1 with ${name}`,
            subjectId: id,
            visibility: feedbackSharingType,
            values: values,
          },
          userId,
        );
        if (response) {
          postHogCapture('frontend - feedback submission');

          return submitMeeting(response.id);
        }
      }
      return submitMeeting();
    },
    [postHogCapture],
  );
  const {id} = useParams();

  const submitMeetingNotes = useCallback(
    () =>
      markAsCompleted(
        id,
        '',
        {
          feedbackSharingType: '',
          values: [],
          impression: '',
          feedbackValue: '',
        },
        '',
      ),
    [id, markAsCompleted],
  );

  const [loadingFeedback, setLoadingFeedback] = useState(false);

  const getUserFeedBack = useCallback(async (id: string, user: string) => {
    try {
      const request = new FeedbackRequestImpl();
      const controller = new FeedbackController(request);

      setLoadingFeedback(true);
      const response = await controller.getFeedback(
        {subjectId: id, subjectType: '1:1'},
        user,
      );
      setLoadingFeedback(false);
      return response;
    } catch {}
  }, []);

  const {
    isPerformanceActive,
    isEngagementActive,
    isCheckinsEnabled,
    isFeedBackEnabled,
  } = useWorkspaceUseCase();

  const managerViewUserOptions = useMemo(() => {
    const options = [];

    if (isPerformanceActive) {
      options.unshift({
        value: 'objective',
        label: 'Goals',
      });
    }

    if (isEngagementActive && isCheckinsEnabled) {
      options.push({
        value: 'check-in',
        label: 'Updates',
      });
    }
    if (isEngagementActive && isFeedBackEnabled) {
      options.push({
        value: 'feedback',
        label: 'Feedback',
      });
    }
    options.push({
      value: '1:1',
      label: '1:1s',
    });
    return options;
  }, [
    isPerformanceActive,
    isEngagementActive,
    isFeedBackEnabled,
    isCheckinsEnabled,
  ]);

  const notesPath = `meeting/${id}/notes`;

  const topicsPath = `meeting/${id}/topics`;

  const {data: notes, isLoading: loadingNotes} = useFirebaseFetch(notesPath);

  const {data: topics} = useFirebaseFetch(topicsPath);

  const handleEditTopic = async (key: string, body: any) => {
    updateFirebaseData(`meeting/${id}/topics/${key}`, body);
  };

  const handleDeleteTopic = async (key: string) => {
    removeFirebaseData(`meeting/${id}/topics/${key}`);
  };

  const computedTopics = useMemo((): {
    title: string;
    id: string;
    key: string;
    isPrivate: boolean;
  }[] => {
    if (topics) {
      const _topics: any = Object.entries(topics).map(([key, value]: any) => ({
        ...value,
        key: key,
      }));
      return _topics;
    }
    return [];
  }, [topics]);

  const computeTopicNotes = useMemo(() => {
    if (notes) {
      let _groupByNotes: any = {};

      Object.entries(notes).forEach(([key, note]: any) => {
        if (!note.topic) return;

        const groupNotesExists = _groupByNotes?.[note.topic]
          ? _groupByNotes[note.topic]
          : [];

        _groupByNotes[note.topic] = [
          ...groupNotesExists,
          {
            ...note,
            firebaseId: key,
            shareHostNote: note.shareNote,
          },
        ];
      });

      return _groupByNotes;
    }
  }, [notes]);

  const computedNotes = useMemo(() => {
    if (notes) {
      let hostNote;
      let participantNote;

      for (let value in notes) {
        const note = notes[value];
        if (!!note.topic) return;

        if (note.author === 'hostNote') {
          hostNote = {
            ...note,
            firebaseId: value,
            shareHostNote: note.shareNote,
          };
        }
        if (note.author === 'participantNote') {
          participantNote = {
            ...notes[value],
            firebaseId: value,
            shareParticipantNote: note.shareParticipantNote,
          };
        }
      }
      return {
        hostNote,
        participantNote,
      };
    }
  }, [notes]);

  const computedTeamNotes = useMemo(() => {
    if (notes) {
      let _notes: any[] = [];

      Object.entries(notes).forEach(([key, note]: any) => {
        if (!!note.topic) return;

        const groupNotesExists = _notes;

        if (!note.shareNote && note.updatedBy !== authStore.auth.user.id)
          return;

        _notes = [
          ...groupNotesExists,
          {
            ...note,
            firebaseId: key,
            shareHostNote: note.shareNote,
          },
        ];
      });

      return _notes;
    }
  }, [notes]);

  const handleUpdateNotes = useCallback(
    async (
      value: string,
      _id: string,
      field: string,
      shareNote?: boolean,
      prevData?: {
        createdBy: string;
        createdAt: string;
        firebaseId: string;
      },
      topic?: string,
    ) => {
      const firebaseId = prevData?.firebaseId;

      const body: any = {
        updatedBy: authStore.auth.user.id,
        createdBy: prevData?.createdBy || authStore.auth.user.id,
        createdAt: prevData?.createdAt || dayjs().format(),
        updatedAt: dayjs().format(),
        author: field,
        topic: topic || '',
        note: value,
        shareNote: shareNote || false,
      };

      if (!firebaseId) {
        writeFirebaseData(`meeting/${id}/notes`, body);
        return;
      }

      updateFirebaseData(`meeting/${id}/notes/${firebaseId}`, body);
    },
    [id],
  );

  const {tasks} = useActionItems({
    source: 'meeting',
    userId: '',
    itemId: '',
    orderBy: {
      value: 'meeting',
      key: 'source',
    },
  });

  const handleCopyPreviousActionItems = (
    nextSteps: {text: string; completed: boolean}[],
    id: string,
    currentSteps: {text: string; completed: boolean}[],
  ) => {
    // Create a Set of currentSteps texts for quick lookup
    const currentStepsSet = new Set(currentSteps.map((step) => step.text));

    // Filter nextSteps to include only those not present in currentSteps
    const filteredNextSteps = nextSteps.filter(
      (step) => !currentStepsSet.has(step.text),
    );

    const getPreviousData = tasks.filter((task) =>
      filteredNextSteps.some(
        (step) => step.text === task.text && step.completed === task.completed,
      ),
    );

    // Copy the filtered steps to Firebase
    getPreviousData
      .filter((step) => !step.completed)
      .forEach((step) => {
        writeFirebaseData('action_items', {
          ...step,
          source: 'meeting',
          sourceId: id,
        });
      });
  };

  return {
    fetchMeetingDetails,
    handleUpdateMeeting,
    markAsCompleted,
    handleUpdateNotes,
    loadingFeedback,
    submitMeetingNotes,
    controller,
    computeTopicNotes,
    computedTopics,
    isMeetingNotes,
    handleCopyPreviousActionItems,
    loadingNotes,
    agendas,
    id,
    computedTeamNotes,
    managerViewUserOptions,
    handleEditTopic,
    handleDeleteTopic,
    computedNotes,
    getUserFeedBack,
    handleAddTopic,
    submitting,
  };
};
