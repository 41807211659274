import {DropdownVerticalSecondary} from '../../../../../../../../ui/molecules/dropdown-vertical';
import {Button} from '../../../../../../../../ui/atoms/button';
import {Body2} from '../../../../../../../../ui/atoms/typography/body2';
import {Body1} from '../../../../../../../../ui/atoms/typography/body1';
import {Headline2} from '../../../../../../../../ui/atoms/typography/headline2';
import {TextTag2} from '../../../../../../../../ui/atoms/tag';
import {UserListCard} from '../../../../../../../../ui/molecules/user/list-card';
import React, {useCallback, useMemo} from 'react';
import {UsersIcon} from '../../../../../../../../ui/atoms/icons/users';
import {UserIcon} from '../../../../../../../../ui/atoms/icons/user';
import axios from 'axios';
import {authStore} from '../../../../../../../../store/stores/auth-store';
import {useApiCalls} from '@hooks/init-calls';
import {useNavigate} from 'react-router';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {HorizontalDots} from '@ui/atoms/icons/horizontal-dots';
import {
  VerticalSpacer,
  HorizontalSpacer,
} from '../../../../../../../../ui/atoms/spacer';
import {Modal} from '../../../../../../../../ui/molecules/modal';
import {UserAPIRequestImpl} from '../../../../../../../../api/user-api/user-api-request';
import {useQuery} from 'react-query';
import {observer} from 'mobx-react-lite';
import {UserAPIController} from '../../../../../../../../api/user-api/user-api-controller';
import {DropdownCard} from '../../../../../../../../ui/molecules/dropdown-card';
import {useState} from 'react';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {ModalCard} from '../../../../../../../../ui/layouts/modal-card';
import {ThickCheckIcon} from '@ui/atoms/icons/check';
import {BackAction} from '../../../../../../../../ui/molecules/back-action';
import {
  ArrowHeadLeftIcon,
  ArrowHeadRightIcon,
  CancelIcon,
} from '../../../../../../../../ui/atoms/icons';
import {ItemLoaderLine} from '../../../../../../../../ui/organisms/item-loader-line';
import {activateNotification} from '../../../../../../../../ui/molecules/notification/activate-notification';
import {Checkbox} from '../../../../../../../../ui/atoms/checkbox';
import {Tooltip} from '../../../../../../../../ui/molecules/tooltip';
import {BaseURL} from '../../../../../../../../configs/request';
import {
  PaginationLi,
  TableContainer,
  HeadlineWrapperContainer1,
  HeadlineWrapper1,
  ItemWrapperContainer1,
  ItemWrapper1,
  SecondRowWrapper,
  TableWrapper,
  HeadlineWrapperContainer,
  HeadlineWrapper,
  ItemWrapperContainer,
  ItemWrapper,
  JobTitleWrapper,
  EmailWrapper,
  TagWrapper,
  DropdownWrapper,
  ChangeWrapper,
  DropdownItem,
  ShowingWrapper,
  ItemLoaderWrapper,
  HelperInfoDiv,
  PaginationUl,
  PaginationButton,
} from './active.styles';
import {useActiveHook} from './active.hooks';
import {parseErrorContent} from '../../../../../../../../utils/error-handling';
import {FlexRow, ResponsiveFlexRow} from '@ui/style/styles';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {DesktopWrapper} from '@ui/molecules/workspace/logo-toggle/logo-toggle';
import {checkinName} from '@utils/checkin-name';
import {setFirebaseData} from '@utils/firebase-handler';

interface IConfirmedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  reviewer: {
    firstName: string;
    lastName: string;
  };
  jobTitle: string;
  avatar: {
    url: string;
  };
}

const defaultUsers: IConfirmedUser[] = [];

export const Active = observer(() => {
  const [users, setUsers]: [
    IConfirmedUser[],
    (users: IConfirmedUser[]) => void,
  ] = React.useState(defaultUsers);
  const {changeRole, isSettingRole} = useActiveHook();

  const [limit, setLimit] = useState<number>(0);
  const [, setAllUsersClick] = useState(false);
  const [page, setPage] = useState<any>(1);
  const [allData, setAllData]: any = useState([]);
  const [totalPages, setTotalPages] = useState<any>(1);

  const [changeGroup, setChangeGroup] = useState(false);
  const [updateDone, setUpdateDone] = useState(false);
  const [changeReviewer, setChangeReviewer] = useState(false);
  const [isCheck, setIsCheck]: any = useState<any>([]);
  const [usersGroup, setUsersGroup] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const [isCheckGroup, setIsCheckGroup] = useState<any>([]);
  const [loadingSubmitGroup, setLoadingSubmitGroup] = useState(false);

  const [search, setSearch] = useState('');
  const [check, setCheckBoxChecked] = useState('');
  const [totalResults, setTotalResults] = useState<any>(0);
  const [pageNumberLimit] = useState(2);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(2);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const {
    groupStore: {groups: group},
    groupTypeStore: {groupType},
    authStore: {setInitResponse, setInitResponseAsAuth},
    usersStore: {getUsers, users: allUsers},
  } = useStoreContext();
  const pages = [];
  for (let i = 1; i <= Math.ceil(totalResults / limit); i++) {
    pages.push(i);
  }

  const handlePageClick = (event: any) => {
    setPage(Number(event.target.id));
  };
  const {initGroupMembers, initUsers} = useApiCalls();

  const renderPageNumbers = pages.map((number: any) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <PaginationLi
          key={number}
          id={number}
          onClick={handlePageClick}
          className={page === number ? 'active' : undefined}>
          {number}
        </PaginationLi>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setPage(page + 1);

    if (page + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handleSelectAll = (e: any) => {
    setIsCheckAll(false);
    if (isCheck.length > 1) {
      setIsCheck([]);
      setUsersGroup([]);
    } else {
      setIsCheck(allData?.map((user: any) => user.id));
      const res = allData?.map((items: any) => {
        let item = [items?.id, {group: items?.group}];
        return item;
      });
      setUsersGroup(res);
      if (isCheckAll) {
        setIsCheck([]);
        setUsersGroup([]);
      }
    }
  };

  const groupTypesWithGroups = () => {
    const res = new Map();
    group.forEach((result: any) => {
      if (res.has(result.groupType)) {
        res.set(result.groupType, [...res.get(result.groupType), result]);
      } else res.set(result.groupType, [result]);
    });
    const groupTypes = groupType.map((grpType) => {
      const getGroups = res.get(grpType.id);
      return {
        ...grpType,
        groups: getGroups,
      };
    });
    return groupTypes;
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const handlePrevbtn = () => {
    setPage(page - 1);

    if ((page - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const res = allUsers?.filter((its: any) => {
    return !isCheck.includes(its.id);
  });

  const handleUsers = () => {
    const resd = res?.filter((user: any) => {
      if (search !== '') {
        const firstName =
          user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          user.lastName.toLowerCase().includes(search.toLowerCase()) ||
          user.email.toLowerCase().includes(search.toLowerCase());
        return firstName;
      } else {
        return res;
      }
    });

    return resd;
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <PaginationLi onClick={handleNextbtn}> ... </PaginationLi>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <PaginationLi onClick={handlePrevbtn}> ... </PaginationLi>
    );
  }

  const handleCheckboxClick = (e: any) => {
    const {id, checked} = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };
  const handleCheckboxClickGroups = (id: string) => {
    // const { checked} = e.target;
    setIsCheckGroup([...isCheckGroup, id]);
    if (isCheckGroup.includes(id)) {
      setIsCheckGroup(isCheckGroup.filter((item: any) => item !== id));
    }
  };

  const onChangeAttribute = (value: string) => {
    setCheckBoxChecked(value);
  };

  const fetchActiveUsers = useCallback(async () => {
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const res = await controller.fetchPaginatedConfirmedUsers(page);

    return res;
  }, [page]);

  const {isLoading, data, refetch: userRefetch} = useQuery(
    ['activeUsers', `${page}, ${updateDone}`],
    () => fetchActiveUsers(),
  );
  const {data: groupMembers, refetch: groupMembersRefetch} = useQuery(
    ['initGroupMembers'],
    () => initGroupMembers(),
  );

  const getUsersGroup = useCallback(async () => {
    const res = groupMembers;
    if (res) {
      const includeGroup = users.map((user) => {
        const userGroup = res.get(user.id);
        const getGroupIds = userGroup?.map((grp: any) => grp.group);
        const getUserGroups =
          group.length > 0
            ? group?.filter((grp: any) => getGroupIds?.includes(grp.id))
            : [];
        return {
          ...user,
          group: getUserGroups,
        };
      });

      setAllData(includeGroup);
      return includeGroup;
    }
  }, [users, group, groupMembers]);

  useMemo(() => {
    if (data) {
      setLimit(data.limit);
      getUsersGroup();
      setTotalPages(data.totalPages);
      setTotalResults(data.totalResults);
      setUsers(data.results);
      setUpdateDone(false);
    }
  }, [data, getUsersGroup]);

  const navigate = useNavigate();

  const handleGroupChange = async () => {
    setLoadingSubmitGroup(true);
    const changeUserGroups = async (id: any) => {
      return await axios.post(
        `${BaseURL}/groups/${id}/member`,
        {
          add: {
            users: isCheck,
            type: 'add',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
            mode: 'cors',
          },
        },
      );
    };

    const removeExistingUsersGroup = async (item: any) => {
      item[1].group?.length > 0 &&
        item[1].group.map((grp: any) => {
          return axios
            .post(
              `${BaseURL}/groups/${grp?.id}/member`,
              {
                remove: {
                  users: [item[0]],
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
                  mode: 'cors',
                },
              },
            )
            .then(() => {
              setUsersGroup([]);
            });
        });
    };
    const removeUserGroupData = async () => {
      return Promise.all(
        usersGroup.map((item: any) => removeExistingUsersGroup(item)),
      );
    };

    removeUserGroupData();
    const getData = async () => {
      return Promise.all(
        isCheckGroup.map((item: any) => changeUserGroups(item)),
      );
    };

    getData().then(() => {
      setUpdateDone(true);
      getUsers();

      setFirebaseData(`updates/user`, generateRandomDigits(16));

      groupMembersRefetch();
      setChangeGroup(false);
      setIsCheck([]);
      setIsCheckGroup([]);
      activateNotification({
        title: 'Success',
        content: 'User`s group successfully updated',
        kind: 'success',
      });
    });
    setLoadingSubmitGroup(false);
  };

  const handleUsersReviewer = async () => {
    setLoadingSubmitGroup(true);

    setUpdateDone(false);

    try {
      const res = await axios.patch(
        `${BaseURL}/users/update-users`,
        {
          userIds: isCheck,
          reviewer: check,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
            mode: 'cors',
          },
        },
      );
      if (
        res &&
        (isCheck?.includes(authStore?.auth?.user?.id) ||
          check === authStore?.auth?.user?.id)
      ) {
        const storedAuthObject = JSON.parse(localStorage.auth);

        storedAuthObject.user.reviewer = check;
        localStorage.setItem('auth', JSON.stringify(storedAuthObject));

        setInitResponse(storedAuthObject);

        setInitResponseAsAuth();

        navigate('/');
        navigate('/manage-people');
      }
      if (res) {
        setFirebaseData(`updates/user`, generateRandomDigits(16));
      }
      res && (await getUsers());
      res && setLoadingSubmitGroup(true);
      res && setChangeReviewer(false);
      res && initUsers();
      res && userRefetch();
      res &&
        activateNotification({
          title: 'Success',
          content: 'Manager updated',
          kind: 'success',
        });
      res && setUsers([]);
      res && setUpdateDone(true);
      res && setLoadingSubmitGroup(false);
      res && setIsCheck([]);
      res && setUsersGroup([]);
    } catch (error) {
      setLoadingSubmitGroup(false);
      activateNotification({
        title: 'Error',
        content: parseErrorContent(error),
        kind: 'error',
      });
    }
  };

  //prevent button submissiopagen refresh
  const noReload = (e: any) => {
    e.preventDefault();
  };

  // deactivate user function
  const deactivateUser = async (
    id: string,
    firstName: string,
    lastName: string,
    email: string,
  ) => {
    await fetch(`${BaseURL}/users/${id}/deactivate`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        activateNotification({
          title: 'Success',
          content: ` "${firstName} ${lastName}" was suspended  `,
          kind: 'success',
        });
        navigate('/', {replace: true});
        navigate('/manage-people', {replace: true});
      });
  };
  const [modalLoading, setModalLoading] = useState(false);

  // deactivate user function
  const deleteUser = async (
    id: string,
    firstName: string,
    lastName: string,
    email: string,
  ) => {
    setModalLoading(true);
    const request = new UserAPIRequestImpl();
    const controller = new UserAPIController(request);
    const response = await controller.deleteUser(id);
    if (response) {
      activateNotification({
        title: 'Success',
        content: `"${firstName} ${lastName}"  was deleted`,
        kind: 'success',
      });
      navigate('/', {replace: true});
      navigate('/manage-people', {replace: true});
    }
    setModalLoading(false);
  };

  // reset password function
  const passwordReset = useCallback(
    (email: string) => {
      let passwordResetData = {email: email};
      setTimeout(() => {
        fetch(`${BaseURL}/auth/forgot-password`, {
          mode: 'cors',
          method: 'POST',
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
          },
          body: JSON.stringify(passwordResetData),
        })
          .then((res) => {
            if (res.ok) {
              activateNotification({
                title: 'Password Reset Sent',
                content: `"${email}" password has been reset`,
                kind: 'success',
              });
            } else {
              activateNotification({
                title: 'Error',
                content: res.statusText,
                kind: 'error',
              });
            }

            return res.json();
          })
          .then((data) => {
            navigate('/', {replace: true});
            navigate('/admin', {replace: true});
          })
          .catch((err: any) => {
            activateNotification({
              title: 'Error',
              content: parseErrorContent(err),
              kind: 'error',
            });
          });
      }, 1000);
    },
    [navigate],
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalFName, setModalFName] = useState('');
  const [modalLName, setModalLName] = useState('');
  const [modalEmail, setModalEmail] = useState('');
  const [modalId, setModalId] = useState('');

  //open and close modal with user data being passed to modal screen
  const openModal = (
    firstName: string,
    lastName: string,
    id: string,
    email: string,
    deleteModal?: boolean,
  ) => {
    if (deleteModal) {
      setModalDeleteOpen(true);
    } else {
      setModalIsOpen(true);
    }
    setModalFName(firstName);
    setModalLName(lastName);
    setModalEmail(email);
    setModalId(id);
  };

  const hideModal = () => {
    setModalIsOpen(false);
    setModalDeleteOpen(false);
  };

  //convert role text to titlecase
  const titleCase = function (string: string) {
    const sentence = string.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  };

  const handleClick = useCallback(
    (id: string) => {
      navigate(`/edit-profile/${id}`);
    },
    [navigate],
  );
  return (
    <>
      <TableContainer
        style={{
          marginBottom: changeReviewer
            ? '200px'
            : changeGroup
            ? '400px'
            : '0px',
        }}>
        <ResponsiveFlexRow>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              padding: '16px 12px',
            }}>
            {isCheck?.length < 2 ? (
              <>
                <Checkbox
                  kind="import"
                  onChange={(e) => {
                    handleSelectAll(e);
                    setAllUsersClick(true);
                  }}
                  checked={isCheckAll}
                />
                <Body2 kind="textDark" style={{marginLeft: '5px'}}>
                  Select all
                </Body2>
              </>
            ) : (
              <>
                <Checkbox
                  kind="indeterminate"
                  state="error"
                  onChange={(e) => {
                    handleSelectAll(e);
                    setAllUsersClick(false);
                  }}
                  checked={isCheck?.length > 1}
                />
                <Body2 kind="textDark" style={{marginLeft: '5px'}}>
                  Deselect
                </Body2>
              </>
            )}
          </div>
          <div style={{position: 'relative'}}>
            {isCheck.length > 0 ? (
              <>
                <ChangeWrapper
                  style={{
                    position: 'relative',
                  }}>
                  <DesktopWrapper>
                    <HorizontalSideRule
                      size="20px"
                      style={{margin: '0px 10px'}}
                    />
                  </DesktopWrapper>

                  <div style={{position: 'relative'}}>
                    <Body2
                      onClick={() => {
                        setChangeGroup(false);
                        setChangeReviewer(!changeReviewer);
                      }}
                      style={{
                        color: 'rgba(88, 90, 223, 1)',
                        fontWeight: 500,
                        display: 'flex',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <span
                        style={{
                          marginRight: '5px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <UserIcon />
                      </span>
                      Change manager
                    </Body2>
                    {changeReviewer && (
                      <div style={{position: 'absolute', paddingTop: '10px'}}>
                        <DropdownCard
                          title="Change Manager"
                          loading={loadingSubmitGroup}
                          onSubmit={handleUsersReviewer}
                          onClickCancel={() => {
                            setChangeReviewer(false);
                            setCheckBoxChecked('');
                            setSearch('');
                          }}
                          handleSearch={(e: any) => handleSearch(e)}
                          height={428}
                          disabled={check === ''}
                          placeholder="Search manager">
                          {' '}
                          <div style={{padding: '0px 10px'}}>
                            <Body2 style={{fontWeight: 500}}>Reviewers</Body2>
                            {handleUsers().length === 0 ? (
                              <>
                                <Body2
                                  kind="textBody"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingTop: '8px',
                                    alignItems: 'center',
                                  }}>
                                  No user found
                                </Body2>
                              </>
                            ) : (
                              <>
                                {handleUsers().map((user, key) => (
                                  <>
                                    <div
                                      key={key}
                                      onClick={() =>
                                        onChangeAttribute(user?.id)
                                      }
                                      style={{
                                        border:
                                          '1px solid rgba(206, 206, 222, 1)',
                                        borderRadius: '6px',
                                        display: 'flex',
                                        cursor: 'pointer',
                                        padding: ' 16px',
                                        marginTop: '8px',
                                      }}>
                                      <Checkbox
                                        kind="import"
                                        name={user?.id}
                                        id={user?.id}
                                        checked={check === user?.id}
                                      />
                                      {check === user?.id ? (
                                        <Body2
                                          kind="textDark"
                                          style={{
                                            marginLeft: '5px',
                                            fontWeight: 500,
                                          }}>
                                          {user.firstName
                                            ? `${user.firstName} ${user.lastName}`
                                            : user.email}
                                        </Body2>
                                      ) : (
                                        <Body2
                                          kind="textBody"
                                          style={{marginLeft: '5px'}}>
                                          {user.firstName
                                            ? `${user.firstName} ${user.lastName}`
                                            : user.email}
                                        </Body2>
                                      )}
                                    </div>
                                  </>
                                ))}
                              </>
                            )}
                          </div>
                        </DropdownCard>
                      </div>
                    )}
                  </div>
                  <HorizontalSideRule
                    size="20px"
                    style={{margin: '0px 10px'}}
                  />

                  <div style={{position: 'relative', zIndex: 5}}>
                    <Body2
                      onClick={() => {
                        setChangeGroup(!changeGroup);
                        setChangeReviewer(false);
                      }}
                      style={{
                        color: 'rgba(88, 90, 223, 1)',
                        fontWeight: 500,
                        display: 'flex',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <span
                        style={{
                          marginRight: '5px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        {' '}
                        <UsersIcon />
                      </span>
                      Change group
                    </Body2>
                    {changeGroup && (
                      <div style={{position: 'absolute', paddingTop: '10px'}}>
                        <DropdownCard
                          title="Change Group"
                          onSubmit={handleGroupChange}
                          loading={loadingSubmitGroup}
                          onClickCancel={() => {
                            setChangeGroup(false);
                            setSearch('');
                            setCheckBoxChecked('');
                          }}
                          handleSearch={(e: any) => handleSearch(e)}
                          height={622}
                          placeholder="Search group">
                          {' '}
                          <div
                            style={{
                              padding: '0px 16px',
                            }}>
                            {groupTypesWithGroups()?.length === 0 ? (
                              <>
                                <Body2
                                  kind="textBody"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingTop: '8px',
                                    alignItems: 'center',
                                  }}>
                                  No group type found
                                </Body2>
                              </>
                            ) : (
                              <>
                                {groupTypesWithGroups()?.map(
                                  (grps: any, key: any) => (
                                    <>
                                      {grps?.groups && grps?.groups.length > 0 && (
                                        <>
                                          <VerticalSpacer size="16px" />
                                          <Body2
                                            style={{fontWeight: 500}}
                                            key={key}>
                                            {grps.name}
                                          </Body2>
                                        </>
                                      )}
                                      {grps?.groups
                                        ?.filter((grp: any) =>
                                          search !== ' '
                                            ? grp.name
                                                .toLowerCase()
                                                .includes(search.toLowerCase())
                                            : grp,
                                        )
                                        .map((grp: any, key: any) => (
                                          <>
                                            <div
                                              key={key}
                                              onClick={() =>
                                                handleCheckboxClickGroups(
                                                  grp?.id,
                                                )
                                              }
                                              style={{
                                                border:
                                                  '1px solid rgba(206, 206, 222, 1)',
                                                borderRadius: '6px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                padding: ' 16px',
                                                marginTop: '8px',
                                              }}>
                                              <Checkbox
                                                kind="import"
                                                name={grp?.id}
                                                id={grp?.id}
                                                checked={isCheckGroup.includes(
                                                  grp?.id,
                                                )}
                                              />
                                              <Body2
                                                kind="textBody"
                                                style={{marginLeft: '5px'}}>
                                                {grp.name}
                                              </Body2>
                                            </div>
                                          </>
                                        ))}
                                    </>
                                  ),
                                )}
                              </>
                            )}
                          </div>
                          <div
                            style={{
                              borderBottom: '1px solid rgba(237, 237, 242, 1)',
                              height: '20px',
                              margin: '5px 16px',
                            }}></div>
                          <div
                            style={{
                              padding: '5px 16px',
                              borderBottom: '1px solid rgba(237, 237, 242, 1) ',
                            }}></div>
                        </DropdownCard>
                      </div>
                    )}
                  </div>
                </ChangeWrapper>
              </>
            ) : (
              <>
                <ShowingWrapper>
                  <DesktopWrapper>
                    <HorizontalSideRule
                      size="20px"
                      style={{margin: '0px 10px'}}
                    />
                  </DesktopWrapper>

                  <FlexRow
                    style={{
                      whiteSpace: 'nowrap',
                    }}>
                    <Body2 kind="textBody">
                      Showing {totalResults} of {totalResults}
                    </Body2>
                  </FlexRow>
                </ShowingWrapper>
              </>
            )}
          </div>
        </ResponsiveFlexRow>

        <TableWrapper>
          <div>
            <HeadlineWrapperContainer1>
              <HeadlineWrapper1>
                <div>
                  <Body2 kind="textDark" weight={'semibold'}>
                    Name
                  </Body2>
                </div>
              </HeadlineWrapper1>
            </HeadlineWrapperContainer1>
            {!isLoading &&
              allData.map((user: any, key: any) => (
                <ItemWrapperContainer1 key={key}>
                  <ItemWrapper1 className="tooltip_container-active">
                    <div style={{margin: '0px 2px 0px 5px'}}>
                      <Checkbox
                        kind="import"
                        onChange={(e: any) => {
                          handleCheckboxClick(e);
                          if (!isCheck.includes(user?.id)) {
                            let item = [user?.id, {group: user?.group}];
                            setUsersGroup([...usersGroup, item]);
                          } else {
                            setUsersGroup(
                              usersGroup.filter(
                                (item: any) => item[0] !== user?.id,
                              ),
                            );
                          }
                        }}
                        id={user?.id}
                        checked={isCheck.includes(user?.id)}
                      />
                    </div>

                    <UserListCard
                      tooltip={true}
                      userId={user?.id}
                      type="secondary"
                      reviewer={
                        user?.reviewer?.id !== undefined
                          ? user?.reviewer?.id
                          : user?.reviewer !== undefined
                          ? user?.reviewer
                          : null
                      }
                      avatar={user.avatar ? user.avatar.url : ''}
                      name={
                        user.firstName && user.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : user.email
                      }
                    />

                    {user.firstName &&
                      `${user?.firstName} ${user?.lastName}`.length > 20 && (
                        <span style={{marginTop: '-70px'}} className="tooltip">
                          {`${user?.firstName} ${user?.lastName}`}
                        </span>
                      )}
                  </ItemWrapper1>
                </ItemWrapperContainer1>
              ))}
          </div>

          <SecondRowWrapper>
            <HeadlineWrapperContainer>
              <HeadlineWrapper>
                <div style={{maxWidth: '150px', minWidth: '100px'}}>
                  <Body2 kind="textDark" weight={'semibold'}>
                    Job Title
                  </Body2>
                </div>
                <div style={{maxWidth: '250px', minWidth: '250px'}}>
                  <Body2 kind="textDark" weight={'semibold'}>
                    Groups
                  </Body2>
                </div>
                <div style={{maxWidth: '250px', minWidth: '250px'}}>
                  <Body2 kind="textDark" weight={'semibold'}>
                    Manager
                  </Body2>
                </div>
                <div style={{maxWidth: '100px', minWidth: '100px'}}>
                  <Body2 kind="textDark" weight={'semibold'}>
                    Role
                  </Body2>
                </div>
                <div style={{maxWidth: '100px', minWidth: '100px'}}>
                  <Body2 kind="textDark" weight={'semibold'}>
                    Action
                  </Body2>
                </div>
              </HeadlineWrapper>
            </HeadlineWrapperContainer>
            {allData.map((user: any, key: any) => (
              <ItemWrapperContainer key={key}>
                <ItemWrapper>
                  <JobTitleWrapper>
                    <Body2
                      style={{
                        marginRight: '14px',
                      }}>
                      {user.jobTitle ? (
                        user?.jobTitle?.length > 20 ? (
                          <Tooltip
                            text={`${user?.jobTitle}`}
                            maxLength={20}
                            tooltipBody={<span>{user?.jobTitle}</span>}
                          />
                        ) : (
                          user?.jobTitle
                        )
                      ) : (
                        '----'
                      )}
                    </Body2>
                  </JobTitleWrapper>
                  <EmailWrapper>
                    {user.group.length === 0 ? (
                      <>
                        <Body2 kind="textBody">--- ---</Body2>
                      </>
                    ) : (
                      <>
                        {user?.group.length === 1
                          ? user?.group.map((grp: any) => (
                              <Body2
                                kind="textBody"
                                style={{overflow: 'clip', marginRight: '10px'}}>
                                {grp?.name}
                              </Body2>
                            ))
                          : user?.group
                              .filter((grp: any, idx: any) => idx < 1)
                              .map((grp: any) => (
                                <div style={{display: 'flex'}}>
                                  <div
                                    style={{
                                      padding: '4px 8px',
                                      background: '#F6F6F8',
                                      color: '#5F5F8C',
                                      display: 'flex',
                                      fontSize: '14px',
                                      alignItems: 'center',
                                      borderRadius: '6px',
                                    }}>
                                    {grp.name && `${grp.name}`.length > 6 ? (
                                      <>
                                        <Tooltip
                                          text={`${grp?.name}  `}
                                          maxLength={10}
                                          tooltipBody={<span>{grp?.name}</span>}
                                        />
                                      </>
                                    ) : (
                                      `${grp.name}`
                                    )}
                                  </div>
                                  <span
                                    style={{
                                      padding: '4px 8px',
                                      background: '#F6F6F8',
                                      color: '#585ADF',
                                      display: 'flex',
                                      fontSize: '14px',
                                      marginLeft: '4px',
                                      fontWeight: 500,
                                      alignItems: 'center',
                                      borderRadius: '6px',
                                    }}>
                                    <Tooltip
                                      text={`+${user?.group.length - 1}`}
                                      BodyTextNodeType="p"
                                      bodyStyle={{
                                        color: 'rgb(88, 90, 223)',
                                        fontWeight: 500,
                                      }}
                                      tooltipBody={
                                        <span>
                                          {user?.group.map(
                                            (group: any, index: number) => (
                                              <span>{`${group.name}${
                                                index === user?.group.length - 1
                                                  ? ''
                                                  : ', '
                                              }`}</span>
                                            ),
                                          )}
                                        </span>
                                      }
                                    />
                                  </span>
                                </div>
                              ))}
                      </>
                    )}
                  </EmailWrapper>
                  <Body2>
                    {user?.reviewer === undefined
                      ? '--- ---'
                      : user?.reviewer?.firstName
                      ? `${user?.reviewer?.firstName} ${user?.reviewer?.lastName}`
                      : user?.reviewer?.email
                      ? user?.reviewer?.email
                      : '---- ---'}
                  </Body2>
                  <TextTag2 style={{width: '68px'}}>
                    {isSettingRole.hasOwnProperty(user.id) &&
                    isSettingRole[user.id] ? (
                      <TagWrapper>...</TagWrapper>
                    ) : (
                      titleCase(user.role)
                    )}
                  </TextTag2>
                  <DropdownWrapper>
                    <Button
                      style={{padding: '8px 16px', marginRight: '8px'}}
                      kind="secondary"
                      onClick={() => handleClick(user.id)}>
                      Edit
                    </Button>
                    {authStore?.auth?.user?.id !== user.id && (
                      <DropdownVerticalSecondary
                        collapseOnClick={true}
                        customIcon={
                          <div>
                            <HorizontalDots />
                          </div>
                        }
                        menu={(handleClose: () => void) => (
                          <div>
                            {/* <Link to='edit-profile/:user.id'> */}

                            {/* </Link> */}
                            <form onSubmit={noReload}>
                              <DropdownItem
                                onClick={() => {
                                  passwordReset(user.email);
                                  handleClose();
                                }}>
                                Send Password reset
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  openModal(
                                    user.firstName,
                                    user.lastName,
                                    user.id,
                                    user.email,
                                  );
                                  handleClose();
                                }}>
                                Suspend user
                              </DropdownItem>
                            </form>

                            <DropdownItem
                              onClick={() => {
                                const isAdmin =
                                  user.role.toLowerCase() === 'admin'
                                    ? true
                                    : false;
                                const callback = (role: string) => {
                                  user.role = role;
                                  users[key] = user;
                                  setUsers(users);
                                };
                                changeRole(
                                  isAdmin ? 'user' : 'admin',
                                  user.id,
                                  callback,
                                );
                                handleClose();
                              }}>
                              Change role to{' '}
                              {user.role === 'admin' ? 'user' : 'admin'}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                openModal(
                                  user.firstName,
                                  user.lastName,
                                  user.id,
                                  user.email,
                                  true,
                                );
                                handleClose();
                              }}>
                              Delete user
                            </DropdownItem>
                          </div>
                        )}
                      />
                    )}
                  </DropdownWrapper>
                </ItemWrapper>
              </ItemWrapperContainer>
            ))}
          </SecondRowWrapper>
        </TableWrapper>
        {isLoading && (
          <ItemWrapperContainer>
            <ItemLoaderWrapper>
              <ItemLoaderLine />
            </ItemLoaderWrapper>
          </ItemWrapperContainer>
        )}
      </TableContainer>
      <Modal open={modalDeleteOpen} onClose={() => {}}>
        <div style={{maxWidth: '500px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={hideModal}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <form onSubmit={noReload}>
              <Headline2 align="center">
                Delete{' '}
                {!modalFName ? modalEmail : modalFName + ' ' + modalLName}
              </Headline2>

              <VerticalSpacer size="16px" />
              <Body1 kind="textBody" align="center">
                Once deleted, the user can't access their account and any data
                will be deleted after 30 days. User’s license will also become
                available.
              </Body1>

              <VerticalSpacer size="32px" />

              <Button
                onClick={() => {
                  deleteUser(modalId, modalFName, modalLName, modalEmail);
                }}
                isLoading={modalLoading}
                kind="secondary"
                width="full">
                Delete
              </Button>
              <VerticalSpacer size="30px" />
            </form>
          </ModalCard>
        </div>
      </Modal>
      <Modal open={modalIsOpen} onClose={() => {}}>
        <div style={{maxWidth: '500px', margin: 'auto', marginTop: '10%'}}>
          <BackAction
            width="md"
            icon={<CancelIcon />}
            onClick={hideModal}
            title="Close"
          />
          <VerticalSpacer size="16px" />
          <ModalCard align="center" title="">
            <form onSubmit={noReload}>
              <Headline2 align="center">
                Suspend{' '}
                {!modalFName ? modalEmail : modalFName + ' ' + modalLName}
              </Headline2>

              <VerticalSpacer size="16px" />
              <Body1 kind="textBody" align="center">
                Some things to note:
              </Body1>
              <VerticalSpacer size="16px" />
              <HelperInfoDiv>
                <>
                  <div className="info">
                    <span>
                      <ThickCheckIcon style={{stroke: '#585ADF'}} />
                    </span>
                    <HorizontalSpacer size="5px" />
                    <Body2 kind="textBody">
                      User billing fees still apply to the suspended users.
                    </Body2>
                  </div>
                  <VerticalSpacer size="16px" />

                  <div className="info">
                    <span>
                      <ThickCheckIcon style={{stroke: '#585ADF'}} />
                    </span>
                    <HorizontalSpacer size="5px" />
                    <Body2 kind="textBody">
                      User's goals, {checkinName()}s, and feedback will be
                      temporarily removed from the workspace during the
                      suspension period.
                    </Body2>
                  </div>
                  <VerticalSpacer size="16px" />
                  <div className="info">
                    <span>
                      <ThickCheckIcon style={{stroke: '#585ADF'}} />
                    </span>
                    <HorizontalSpacer size="5px" />
                    <Body2 kind="textBody">
                      User’s data will be kept, but they won’t be available to
                      use in any feature. But will be restored when unsuspended.
                    </Body2>
                  </div>
                  <VerticalSpacer size="16px" />

                  <div className="info">
                    <span>
                      <ThickCheckIcon style={{stroke: '#585ADF'}} />
                    </span>
                    <HorizontalSpacer size="5px" />
                    <Body2 kind="textBody">
                      Suspended users can be restored as long as they're not
                      deleted.
                    </Body2>
                  </div>
                </>
              </HelperInfoDiv>
              <VerticalSpacer size="32px" />

              <Button
                onClick={() => {
                  deactivateUser(modalId, modalFName, modalLName, modalEmail);
                }}
                width="full">
                Suspend
              </Button>
              <VerticalSpacer size="30px" />
            </form>
          </ModalCard>
        </div>
      </Modal>
      {totalPages > 1 ? (
        <PaginationUl>
          <PaginationLi>
            <PaginationButton
              onClick={handlePrevbtn}
              disabled={page === 1 ? true : false}>
              <ArrowHeadLeftIcon />
            </PaginationButton>
          </PaginationLi>
          {pageDecrementBtn}
          {renderPageNumbers}
          {pageIncrementBtn}
          <PaginationLi>
            <PaginationButton
              onClick={handleNextbtn}
              disabled={page === totalPages ? true : false}>
              <ArrowHeadRightIcon />
            </PaginationButton>
          </PaginationLi>
        </PaginationUl>
      ) : null}
    </>
  );
});
