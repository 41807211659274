import {useCallback} from 'react';
import {
  FIREBASE_BASE_URL,
  database,
  updateGoogleTokens,
} from '@utils/firebase-request';
import {onValue, ref} from 'firebase/database';
import {authStore} from '@store/stores/auth-store';
import ApiCalendar from './google-calender';
import axios from 'axios';
import {useStoreContext} from '@store/store-context';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_CALENDAR || '';
const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET || '';
const config = {
  clientId,
  clientSecret,
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  scope: 'https://www.googleapis.com/auth/calendar',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
};

const apiCalendar = new ApiCalendar(config);

export const useGCalenderHook = () => {
  const userId = authStore.auth.user.id;

  const firebasePath = `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/users/${userId}/integration`;

  const {
    integrationStore: {
      googleTokenLoader: loader,
      setLoader,
      googleCalenderIsSigned: isSigned,
      setSignStatus: setIsSigned,
    },
  } = useStoreContext();

  const fetchGoogleToken = useCallback(async () => {
    onValue(ref(database, firebasePath), (snapshot: any) => {
      const data = snapshot?.val();
      if (data) {
        try {
          axios
            .post('https://oauth2.googleapis.com/token', {
              client_id: clientId,
              client_secret: clientSecret,
              refresh_token: data.refresh_token,
              grant_type: 'refresh_token',
            })
            .then((response) => {
              const updateToken = () =>
                apiCalendar.setToken({
                  ...data,
                  access_token: response.data.access_token,
                });
              apiCalendar.onLoad(updateToken);
            });
        } catch (error) {}
        setLoader(false);
        setIsSigned(true);
      } else {
        setLoader(false);
        setIsSigned(false);
      }
    });
  }, [firebasePath, setLoader, setIsSigned]);

  const handleLogOut = () => {
    apiCalendar.handleSignoutClick();
    updateGoogleTokens(userId, null, true);
  };

  return {
    ...apiCalendar,
    handleAuthClick: apiCalendar.handleAuthClick,
    deleteEvent: apiCalendar.deleteEvent,
    createEventWithVideoConference: apiCalendar.createEventWithVideoConference,
    createEvent: apiCalendar.createEvent,
    getCalendarEventById: apiCalendar.getEvent,
    updateEvent: apiCalendar.updateEvent,
    listCalendarEvents: apiCalendar.listUpcomingEvents,
    getEvent: apiCalendar.getEvent,
    handleLogOut,
    loader,
    isSigned,
    fetchGoogleToken,
  };
};
