import {observer} from 'mobx-react';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Controller} from 'react-hook-form';
import {weekdays} from '../../../../../../../../constants';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {Button} from '../../../../../../../../ui/atoms/button';
import {CancelIcon} from '../../../../../../../../ui/atoms/icons';
import {VerticalSpacer} from '../../../../../../../../ui/atoms/spacer';
import {
  Body1,
  Body2,
  CTA,
  Headline4,
} from '../../../../../../../../ui/atoms/typography';
import {useBreakpoints} from '@utils/use-breakpoints';
import {SectionCard} from '@ui/layouts/section-card';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';
import {SelectField} from '@ui/molecules/select/selectfield';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {capitalize} from '@utils';
import {useAdminCheckinsHook} from '../admin-checkins.hooks';
import {
  EditCheckinsInnerWrapper,
  EditCheckinsOuterWrapper,
  BreadcrumbWrapper,
  SectionComponentWrapper,
} from '../admin-checkins.styles';
import {useNavigate} from 'react-router';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';

import {checkinName} from '@utils/checkin-name';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';

interface IEditCheckins {
  setIsConfiguring: Dispatch<SetStateAction<boolean>>;
}

interface ISettingsCardProps {
  title: string;
  message: string;
  checked: boolean;
  handleChecked: (value: boolean) => void;
}

const SettingToggleCard = ({
  title,
  message,
  checked,
  handleChecked,
}: ISettingsCardProps) => {
  return (
    <div className="p-6 flex items-center justify-between rounded-[10px] border border-borderLight">
      <FlexRow>
        <Body1 weight="bold">{title}</Body1>
      </FlexRow>
      <SwitchToggle
        checked={checked}
        onChange={() => handleChecked(!checked)}
      />
    </div>
  );
};

export const EditCheckins = observer(({setIsConfiguring}: IEditCheckins) => {
  const {
    errors,
    control,
    handleFormValueChange,
    isEditingCheckinsFrequency,
    handleSubmit,
    isSubmitting,
    handleEditCheckinsFrequency,
    handleSaveCheckinsFrequency,
    disabled,
    submitForm,
    loading,
  } = useAdminCheckinsHook();

  const {
    groupStore: {groups},
    groupTypeStore: {groupType},
    checkinsStore,
  } = useStoreContext();

  const navigate = useNavigate();

  const [showInfoBlock, setShowInfoBlock] = useState(true);

  const breadcrumbNav = useMemo(() => {
    const nav = [
      {
        title: 'Engagement',
        href: '#',
        id: '1',
        onClick: () => {
          setIsConfiguring(false);
          navigate(-1);
        },
      },
      {
        title: 'Updates',
        href: '#',
        id: '2',
        isCurrent: true,
        onClick: () => {
          setIsConfiguring(true);
        },
      },
    ];
    return nav;
  }, [setIsConfiguring, navigate]);

  const isEditing = Object.keys(checkinsStore.configuration).length > 0;

  const [groupOptions, setgroupOptions] = useState<any[]>([]);
  const [loadingGroups, setLoadingGroups] = useState<any>(false);
  const fetchGroup = useCallback(
    async (groupTypeId: string, name: string) => {
      try {
        setLoadingGroups(true);
        if (groups) {
          const _groups = groups.filter((grp) => grp.groupType === groupTypeId);

          setgroupOptions((prev) => [
            ...prev,
            {
              name: name,
              data:
                _groups &&
                _groups.map((group: any) => ({
                  label: group.name,
                  value: group.id,
                })),
            },
          ]);
        }
        setLoadingGroups(false);
      } catch (error) {
        setLoadingGroups(false);
      }
    },
    [groups],
  );

  useEffect(() => {
    groupType.map((itm) => fetchGroup(itm?.id, itm.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupType]);
  const {isXs} = useBreakpoints();
  return (
    <EditCheckinsOuterWrapper
      method={isEditing ? 'patch' : 'post'}
      onSubmit={handleSubmit(submitForm)}>
      <BreadcrumbWrapper>
        <SecondaryBreadcrumb
          wrapperStyles={{padding: '24px 32px 12px', marginBottom: 0}}
          navigation={breadcrumbNav}
        />
      </BreadcrumbWrapper>
      <EditCheckinsInnerWrapper>
        {
          <>
            {loading ? (
              <ItemLoaderLine />
            ) : (
              <>
                {showInfoBlock && (
                  <div className="bg-[#FAFAFA] px-6 py-4 rounded-[10px] mb-6">
                    <FlexRowSpaceBetween>
                      <Body1 weight="bold">
                        {capitalize(checkinName())} tips
                      </Body1>
                      <PlainButton onClick={() => setShowInfoBlock(false)}>
                        <CancelIcon />
                      </PlainButton>
                    </FlexRowSpaceBetween>
                    <Body2 kind="textBody" className="mt-2">
                      {capitalize(checkinName())}s are set to be submitted
                      weekly by default, starting on Sundays.
                    </Body2>
                  </div>
                )}

                <SectionCard
                  title="Due day"
                  headerPadding={isXs ? 'small' : undefined}
                  action={
                    <Button
                      kind={
                        isEditingCheckinsFrequency ? 'primary' : 'secondary'
                      }
                      style={{padding: '8px 16px'}}
                      onClick={
                        isEditingCheckinsFrequency
                          ? handleSaveCheckinsFrequency
                          : handleEditCheckinsFrequency
                      }
                      type="button">
                      {isEditingCheckinsFrequency ? 'Save' : 'Edit'}
                    </Button>
                  }
                  contentStyling={{
                    padding: isXs ? '0px' : '0px 0px 10px 0px',
                  }}
                  CustomHeaderTitle={
                    <div className="flex items-center">
                      <Headline4 kind="textDark">Due day </Headline4>
                    </div>
                  }>
                  <SectionComponentWrapper>
                    <>
                      <CTA kind="textDark" className="mb-1">
                        What day of the week should {checkinName()}s be due?
                      </CTA>
                      <Controller
                        control={control}
                        name={`frequency.dueDay`}
                        render={({onBlur, value}) => (
                          <SelectField
                            onBlur={onBlur}
                            margin
                            value={value}
                            helper={(errors?.frequency as any)?.message || ''}
                            state={
                              !!(errors as any)?.frequency ? 'error' : 'default'
                            }
                            onChange={(data: {value: string}) => {
                              handleFormValueChange(
                                data?.value,
                                `frequency.dueDay`,
                              );
                            }}
                            placeholder={'Friday'}
                            options={weekdays.map((day) => ({
                              ...day,
                              value: capitalize(day?.value),
                            }))}
                            disabled={!isEditingCheckinsFrequency}
                          />
                        )}
                      />
                    </>
                  </SectionComponentWrapper>
                </SectionCard>
                <VerticalSpacer size="24px" />
                <Controller
                  control={control}
                  name={`frequency.priorities`}
                  render={({onBlur, value}) => (
                    <SettingToggleCard
                      title={`Add priorities to ${checkinName()}s`}
                      message=""
                      checked={value !== 'never'}
                      handleChecked={(value) => {
                        handleFormValueChange(
                          value ? 'weekly' : 'never',
                          `frequency.priorities`,
                        );
                      }}
                    />
                  )}
                />
                <VerticalSpacer size="24px" />
                <Controller
                  control={control}
                  name={`frequency.questions`}
                  render={({onBlur, value}) => (
                    <SettingToggleCard
                      title={`Add questions to ${checkinName()}s`}
                      message=""
                      checked={value !== 'never'}
                      handleChecked={(value) => {
                        handleFormValueChange(
                          value ? 'weekly' : 'never',
                          `frequency.questions`,
                        );
                      }}
                    />
                  )}
                />
                <VerticalSpacer size="24px" />
                <SectionCard
                  title="Exclude groups"
                  headerPadding={isXs ? 'small' : undefined}
                  contentStyling={{
                    padding: isXs ? '0px' : '0px 0px 10px 0px',
                  }}
                  CustomHeaderTitle={
                    <div>
                      <Headline4 kind="textDark">
                        Exclude groups from {checkinName()}s submission
                      </Headline4>
                      <Body2 kind="textBody">
                        Select which group should be exempted from{' '}
                        {checkinName()}s
                      </Body2>
                    </div>
                  }>
                  <SectionComponentWrapper>
                    {loadingGroups && <ItemLoaderLine />}
                    {!loadingGroups && (
                      <>
                        <CTA kind="textDark">Groups</CTA>
                        <Controller
                          control={control}
                          name={`excludedGroups`}
                          render={({onBlur, value}) => (
                            <MultiOptionsSelectFieldGroup
                              options={groupOptions}
                              performance={true}
                              searchPlaceholder="Search group"
                              groupDisplayLength={5}
                              noOptionsMessage="Group not found"
                              placeholder="Select Groups"
                              defaultValue={value?.map((val?: string) => ({
                                label: groups?.find(
                                  (value) => value?.id === val,
                                )?.name,
                                value: val,
                              }))}
                              onChange={(data: any) => {
                                handleFormValueChange(
                                  data.map(
                                    (group: {label: string; value: string}) =>
                                      group.value,
                                  ),
                                  'excludedGroups',
                                );
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </SectionComponentWrapper>
                </SectionCard>
                <VerticalSpacer size="24px" />
                <VerticalSpacer size="16px" />
                <VerticalSpacer size="24px" />
                <Button
                  width="full"
                  type="submit"
                  data-form-action={true}
                  isLoading={isSubmitting}
                  disabled={isEditingCheckinsFrequency || disabled}
                  aria-busy={isSubmitting}>
                  Save
                </Button>
              </>
            )}
          </>
        }
      </EditCheckinsInnerWrapper>
    </EditCheckinsOuterWrapper>
  );
});
