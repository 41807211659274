import {IAPIUserFormat} from '@hooks';

const teamLabel = (user: {
  firstName: string;
  email: string;
  lastName: string;
}) => (user.firstName ? `${user.firstName} ${user.lastName}` : `${user.email}`);

export const groupUsers = (
  currentUser: IAPIUserFormat,
  users: IAPIUserFormat[],
) => {
  const userId = currentUser.id;

  const isUserReviewer = currentUser.isReviewer;

  const currentUserReviewer = currentUser.reviewer?.id || currentUser.reviewer;

  const options = [
    {
      name: 'My Team',
      groups: users
        .filter(
          (user) =>
            (user.reviewer?.id || user.reviewer) === currentUserReviewer,
        )
        .map((user) => ({id: user.id, name: teamLabel(user)})),
    },
    {
      name: 'Members',
      groups: users.map((user) => ({id: user.id, name: teamLabel(user)})),
    },
  ];

  if (isUserReviewer) {
    options.unshift({
      name: 'Direct Reports',
      groups: users
        .filter((user) => (user.reviewer?.id || user.reviewer) === userId)
        .map((user) => ({id: user.id, name: teamLabel(user)})),
    });
  }

  return options;
};
