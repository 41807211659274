import {IAPIUserFormat, IMeetingResponse} from '@hooks';
import {useGCalenderHook} from '@hooks/gcalender';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {Body1, Body2, CTA, Headline3} from '@ui/atoms/typography';
import {Calendar} from '@ui/molecules/calendar';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import dayjs from 'dayjs';
import {cloudinary} from '@assets/images/cloudinary';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {useUpcomingMeetingHook} from '../individual-meetings/individual-hook';
import {useQuery} from 'react-query';
import {Tooltip} from '@ui/molecules/tooltip';
import {ArrowRightIcon, CalendarIcon} from '@ui/atoms/icons';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {Button} from '@ui/atoms/button';
import styled from 'styled-components';
import {useNavigate} from 'react-router';
import {userName} from '@utils/user-name';
import {authStore} from '@store/stores/auth-store';
import {PlusSVG} from '@ui/atoms/svg';
import {useViewMeetingHook} from '../view-meetings/view-meeting-hook';
import {useStoreContext} from '@store/store-context';
import {ItemLoader} from '@ui/organisms/item-loader';
import {groupMeetingsByDate} from '@utils/group-meetings';
import {RotatingLines} from 'react-loader-spinner';
import {observer} from 'mobx-react';
import {get} from 'idb-keyval';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import nextId from 'react-id-generator';
import {capitalize} from '@utils';
import {Image} from '@ui/atoms/image';
import {getTime} from '@utils/date';
import {timezones} from '@constants';
const {emptyGoals} = cloudinary;

interface ICalendarView {
  open: boolean;
  onClose: () => void;
  type?: string;
}
export const CalendarIconWrapper = styled.span`
  stroke: white;
`;

export const PlusIconWrapper = styled.button`
  svg {
    width: 20px;
    height: 20px;

    circle {
      fill: #cecede;
    }

    path {
      stroke: white;
    }

    &:hover {
      circle {
        fill: #47b881;

        &:disabled {
          fill: #cecede;
        }
      }
    }
  }
`;

export const CalendarView = observer(({open, onClose, type}: ICalendarView) => {
  const {listCalendarEvents, isSigned} = useGCalenderHook();

  const navigate = useNavigate();

  const [events, setEvents] = useState<IMeetingResponse[]>([]);

  const {
    authStore: {auth},
  } = useStoreContext();

  const [calendarValue, setCalendarValue] = useState(
    dayjs().format('MM/DD/YYYY'),
  );

  const {getMeetings} = useUpcomingMeetingHook();

  const [dateRange, setDateRange] = useState({
    starts: dayjs(calendarValue).format('MM/DD/YYYY'),
    ends: dayjs(calendarValue).add(1, 'week').format('MM/DD/YYYY'),
  });

  const filter = useMemo(
    () => ({
      startDate: dateRange.starts,
      paginate: false,
      endDate: dateRange.ends,
    }),
    [dateRange],
  );

  const handleGetMeetings = useCallback(async (): Promise<
    IMeetingResponse[]
  > => {
    const response: any = await getMeetings(filter);

    if (response) {
      const users: IAPIUserFormat[] | undefined = await get(
        `${auth.user.workspace.id}/users`,
      );

      return response.map((meeting: any) => ({
        ...meeting,
        participant: users?.filter((user) =>
          meeting.participant.includes(user.id),
        ),
        user: users?.find((user) => user.id === meeting.user),
      }));
    }
    return [];
  }, [auth.user.workspace.id, filter, getMeetings]);

  const {data = [], refetch, isLoading} = useQuery(
    ['calendar-view-meetings', dateRange.starts, dateRange.ends],
    () => handleGetMeetings(),
  );

  const getCalenderEvents = useCallback(async () => {
    let results: any[] = [];

    listCalendarEvents(
      100,
      dayjs(calendarValue).format(),
      dayjs(calendarValue).add(1, 'week').format(),
      'primary',
    ).then(
      async function (resp: any) {
        const allGoogleEvents = resp.result.items;

        if (allGoogleEvents) {
          const users: IAPIUserFormat[] | undefined = await get(
            `${auth.user.workspace.id}/users`,
          );

          const computeEvents = allGoogleEvents
            .filter((event: {summary: any; id: string}) => !!event.summary)
            .map(
              (event: {
                id: string;
                attendees: any;
                creator: any;
                summary: any;
                hangoutLink?: string;
                start: {dateTime: dayjs.ConfigType | undefined};
                end: {dateTime: dayjs.ConfigType | undefined};
              }) => ({
                title: event.summary,
                time: dayjs(event.start?.dateTime).format('hh:mm a'),
                nextSteps: [],
                meetingLink: event?.hangoutLink,
                googleEvent: true,
                eventId: event.id,
                user: event.creator,
                participant:
                  users?.filter((user) =>
                    event?.attendees
                      ?.map((attendee: {email: string}) => attendee.email)
                      .includes(user.email),
                  ) || [],
                label: [],
                startDate: dayjs(event.start?.dateTime).format(),
                endDate: dayjs(event.end?.dateTime).format(),
                reminder: [],
              }),
            );

          setEvents(computeEvents);
        }
      },
      function (reason: any) {},
    );
    return results;
  }, [auth.user.workspace.id, calendarValue, listCalendarEvents]);

  const {
    refetch: googleEventsRefetch,
    isLoading: googleEventLoading,
  } = useQuery(['google-events', dateRange.ends, dateRange.starts], () =>
    getCalenderEvents(),
  );

  const showLoader = googleEventLoading || isLoading;

  const groupedEvents = useMemo(() => {
    const meetingCalendarEvents = data
      ? data.map((meeting) => meeting.meta?.calendarEventId || '')
      : [];

    return data || events
      ? Object.entries(
          groupMeetingsByDate(
            [
              ...data,
              ...events.filter(
                (event) => !meetingCalendarEvents.includes(event.eventId),
              ),
            ],
            calendarValue,
          ),
        )
      : [];
  }, [calendarValue, data, events]);

  return (
    <div>
      <DrawerModal
        open={open}
        onClose={onClose}
        widthType={'quarter'}
        header={
          !isSigned && (
            <div className="px-4 pt-2 pb-3 bg-[#585ADF] w-full flex items-center justify-between mb-4 ">
              <CalendarIconWrapper className="">
                <CalendarIcon />
              </CalendarIconWrapper>
              <Body2
                weight="semibold"
                kind="white"
                className=" whitespace-nowrap">
                Sync with your calendar
              </Body2>
              <Button
                style={{padding: '4px 16px'}}
                kind="secondary"
                onClick={() => navigate('/apps')}>
                Connect
              </Button>
            </div>
          )
        }
        title="">
        <div>
          <FlexRowSpaceBetween className="mb-3">
            <FlexRow className="gap-2">
              <Headline3>Calendar</Headline3>
              <button
                className="py-[2px] px-2 rounded-lg border border-[#CECEDE]"
                onClick={() => {
                  const today = dayjs().format('MM/DD/YYYY');
                  setCalendarValue(today);

                  setDateRange({
                    starts: today,
                    ends: dayjs(today).add(1, 'week').format('MM/DD/YYYY'),
                  });
                }}>
                <Body2>Today</Body2>
              </button>
            </FlexRow>
            <PlainButton
              onClick={() => {
                refetch();
                googleEventsRefetch();
              }}>
              <Body2 weight="bold" kind="purple300">
                Refresh
              </Body2>
            </PlainButton>
          </FlexRowSpaceBetween>

          <Calendar
            style={{width: '100%'}}
            value={calendarValue}
            useValue
            onChange={(value) => {
              const date = dayjs(value).format('MM/DD/YYYY');

              setCalendarValue(date);
              setDateRange({
                starts: date,
                ends: dayjs(value).add(1, 'week').format('MM/DD/YYYY'),
              });
            }}
          />

          <div className="border-t border-t-[#EDEDF2] mt-4 pt-4 overflow-auto">
            <div className="max-h-[400px]">
              {showLoader && (
                <div className="w-full h-full min-h-[200px] flex items-center justify-center">
                  <ItemLoader />
                </div>
              )}
              {groupedEvents &&
                !showLoader &&
                groupedEvents.map(([key, meetings]) => (
                  <MeetingView
                    key={key}
                    title={key}
                    calendarEvents={events}
                    meetings={meetings as any}
                    refetch={refetch}
                  />
                ))}
            </div>
          </div>
        </div>
      </DrawerModal>
    </div>
  );
});

interface MeetingViewProps {
  title: string;
  meetings: IMeetingResponse[];
  calendarEvents: {eventId: string; meetingLink: string}[];
  refetch: () => void;
}
const MeetingView = memo(
  ({title, meetings, refetch, calendarEvents}: MeetingViewProps) => {
    const emptyPlaceholder = {
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: emptyGoals,
        pictureStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        style: {
          height: '60px',
          width: '60px',
        },
      },
      imports: true,
      // action: <
      subtitle: 'No Meetings!',
      title: '',
    };

    return (
      <div className="p-4 rounded-[10px] border border-borderLight  mb-4 ">
        <Body1 weight="bold">{title}</Body1>
        <div className="">
          {meetings.length ? (
            meetings.map((meeting) => (
              <MeetingCard
                meeting={meeting}
                key={meeting.id}
                meetingLink={
                  calendarEvents.find(
                    (event) => event.eventId === meeting?.meta?.calendarEventId,
                  )?.meetingLink
                }
                refetch={refetch}
              />
            ))
          ) : (
            <div>
              <FlexRowCenter
                className="min-h-[170px] "
                style={{flexDirection: 'column'}}>
                <FlexRowCenter>
                  <Image {...emptyPlaceholder.image} />
                </FlexRowCenter>
                <Body2 kind="textBody">No Meetings!</Body2>
              </FlexRowCenter>
            </div>
          )}
        </div>
      </div>
    );
  },
);

interface Meeting {
  meeting: IMeetingResponse;
  meetingLink?: string;
  refetch: () => void;
}

const MeetingCard = memo(({meeting, refetch, meetingLink}: Meeting) => {
  const name = meeting.participant?.some(
    (_participant: {id: string}) => _participant.id === authStore.auth.user.id,
  )
    ? userName(meeting.user)
    : userName(meeting.participant[0]);

  const [isBotChecked, setIsBotChecked] = useState(!!meeting.meta?.botId);

  const [isCreating, setIsCreating] = useState(false);

  const {targetHour, targetMinute} = getTime(meeting?.time || '');

  const getTimeZone = timezones.find(
    (timezone) => timezone.value === authStore.auth.user.timezone,
  );

  const updatedDate = dayjs(meeting?.startDate)
    .set('hour', targetHour)
    .set('minute', targetMinute)
    .tz(getTimeZone?.label || '', true);

  const isDisabled = dayjs().isAfter(dayjs(updatedDate), 'day');

  const meeting_link = meeting?.meetingLink || meetingLink;

  const disableUpdateBeamAI = !meeting?.id || isDisabled || !meeting_link;

  const {handleUpdateMeeting, controller} = useViewMeetingHook();

  const createMeeting = async () => {
    setIsCreating(true);

    const response = await controller.postMeeting({
      participant: meeting.participant.map(
        (participant: {id: string}) => participant.id,
      ),

      title: meeting.title,
      label: [],
      hostAgenda: [],
      startDate: updatedDate,
      endDate: meeting.endDate,
      time: meeting.time,
      frequency: 'once',
      reminder: [],
    });

    if (response) {
      await controller.patchMeeting(
        {
          meetingLink: meeting.meetingLink,
          meta: {
            calendarEventId: meeting.eventId,
          },
        },
        response.id,
      );
      refetch();
    }
    setIsCreating(false);
  };

  const navigate = useNavigate();

  return (
    <div
      key={meeting?.id}
      className="rounded-[10px] p-4 border border-borderLight mt-4 flex items-center justify-between">
      <div>
        <FlexRow>
          <Body2 weight="semibold" kind="textBody" className="lowercase">
            {' '}
            {meeting.time}{' '}
            {meeting?.endDate
              ? `- ${dayjs(meeting.endDate).format('hh:mm a')}`
              : null}
          </Body2>

          {meeting.label?.length > 0 && (
            <div className="flex items-center gap-2 ">
              <HorizontalSideRule size="16px" />
              {meeting.label.slice(0, 3).map((_label) => (
                <div
                  className="bg-[#F6F6F8] py-1 px-2 rounded-lg flex gap-1 items-center cursor-pointer"
                  onClick={() => {}}>
                  <Tooltip
                    text={`${_label}`}
                    bodyStyle={{fontWeight: 600, fontSize: '12px'}}
                    withEllipsis
                    BodyTextNodeType={Body2}
                    bodyTextNodeKind="textBody"
                    maxLength={11}
                    tooltipBody={<span>{_label}</span>}
                  />

                  <ArrowRightIcon style={{stroke: '#5F5F8C'}} />
                </div>
              ))}
            </div>
          )}
        </FlexRow>

        <div className="mt-3">
          <Tooltip
            text={capitalize(
              `${meeting.title || `1:1 with ${capitalize(name || '--')}`}`,
            )}
            bodyStyle={{fontWeight: 600}}
            BodyTextNodeType={Body1}
            withEllipsis
            maxLength={20}
            tooltipBody={
              <span>{meeting.title || `1:1 with ${name || '--'}`}</span>
            }
          />
        </div>

        <FlexRow className="gap-2 mt-3">
          <ToggleSwitch
            checked={isBotChecked}
            onChange={() => {
              const botValue = isBotChecked;

              setIsBotChecked((prev) => !prev);

              handleUpdateMeeting(meeting.id, {
                addBeamAI: !botValue,
                meetingLink: meeting_link,
              } as any).then((response) => {
                if (response) {
                  refetch();
                } else {
                  setIsBotChecked(botValue);
                }
              });
            }}
            switchId={meeting?.id || (nextId() as any)}
            disabled={disableUpdateBeamAI}
          />
          <CTA kind={disableUpdateBeamAI ? 'textMuted' : 'textDark'}>
            Invite BeamAI to join
          </CTA>
        </FlexRow>
      </div>

      {meeting.googleEvent ? (
        isCreating ? (
          <RotatingLines width="16" strokeColor="#47B881" />
        ) : (
          <PlusIconWrapper
            className={` ${
              isDisabled
                ? 'cursor-not-allowed fill-[#cecede]'
                : 'cursor-pointer'
            }`}
            disabled={isDisabled}
            onClick={() => {
              createMeeting();
            }}>
            <PlusSVG />
          </PlusIconWrapper>
        )
      ) : (
        <Button
          kind="secondary"
          style={{padding: '8px 16px'}}
          onClick={() => {
            navigate(`/view-meetings/${meeting.id}`);
          }}>
          View
        </Button>
      )}
    </div>
  );
});
