import {Body1, Body2, Headline3} from '@ui/atoms/typography';

import {Card, Value} from './styles';
import {Image} from '@ui/atoms/image';
import {emojiList} from '@ui/assets/emojis';

interface PulseSummaryProps {
  updatesSubmission?: {
    count: number;
    percent: number;
  };
  pulseSubmissionCount: {
    count: number;
    percent: number;
  };
  avgPulse: string;
  view?: string;
}
export const PulseSummary = ({
  updatesSubmission,
  pulseSubmissionCount,
  avgPulse,
  view,
}: PulseSummaryProps) => {
  const moods = [
    {
      emoji: emojiList.drained,
      text: 'Drained',
    },
    {
      emoji: emojiList.sad,
      text: 'Sad',
    },
    {
      emoji: emojiList.meh,
      text: 'Meh!',
    },
    {
      emoji: emojiList.happy,
      text: 'Happy',
    },
    {
      emoji: emojiList.elated,
      text: 'Elated',
    },
  ];

  return (
    <div className={view === 'home' ? 'flex flex-row  gap-3 ' : ''}>
      <Card bg={'#F6F6FE'} className="mb-2">
        <div className="card-wrapper">
          <Body1 weight={'semibold'} kind={'textDark'}>
            Average Pulse
          </Body1>
          <div>
            {avgPulse ? (
              <Image
                {...moods.find((mood) => mood.text.toLowerCase() === avgPulse)
                  ?.emoji}
                width="40px"
                height="40px"
              />
            ) : (
              <Body2>-- --</Body2>
            )}
          </div>
        </div>
      </Card>

      <div className="flex w-full flex-row gap-2 sm:flex-col items-center justify-between">
        {updatesSubmission && (
          <Card bg={'#F6F6FE'}>
            <div className="card-wrapper">
              <Body1 weight={'semibold'} kind={'textDark'}>
                Update submission
              </Body1>
              <div>
                <Value bg={'#F6F6FE'}>
                  <Headline3 kind={'textDark'}>
                    {updatesSubmission.count}{' '}
                    <span className="text-[16px] text-[#5F5F8C] font-normal">
                      ({updatesSubmission.percent}%)
                    </span>
                  </Headline3>
                </Value>
              </div>
            </div>
          </Card>
        )}

        <Card bg={'#F6F6FE'}>
          <div className="card-wrapper">
            <Body1 weight={'semibold'} kind={'textDark'}>
              Pulse submission
            </Body1>
            <div>
              <Value bg={'#F6F6FE'}>
                <Headline3 kind={'textDark'}>
                  {pulseSubmissionCount.count}{' '}
                  <span className="text-[16px] text-[#5F5F8C] font-normal">
                    ({pulseSubmissionCount.percent}%)
                  </span>
                </Headline3>
              </Value>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
