import {memo} from 'react';

export const GlassesToggleIcon = memo(() => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.667969" width="26" height="26" rx="13" fill="#585ADF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.90909 15.3029C9.34308 15.3029 9.7593 15.1305 10.0662 14.8236C10.3731 14.5167 10.5455 14.1005 10.5455 13.6665C10.5455 13.2325 10.3731 12.8163 10.0662 12.5094C9.7593 12.2025 9.34308 12.0301 8.90909 12.0301C8.4751 12.0301 8.05888 12.2025 7.75201 12.5094C7.44513 12.8163 7.27273 13.2325 7.27273 13.6665C7.27273 14.1005 7.44513 14.5167 7.75201 14.8236C8.05888 15.1305 8.4751 15.3029 8.90909 15.3029ZM11.7441 12.0301C11.4189 11.4675 10.9336 11.0145 10.3501 10.7285C9.76654 10.4426 9.11112 10.3368 8.46725 10.4245C7.82338 10.5122 7.22018 10.7896 6.73442 11.2212C6.24865 11.6528 5.90231 12.2192 5.73945 12.8483H4.81818C4.60119 12.8483 4.39308 12.9345 4.23964 13.0879C4.0862 13.2414 4 13.4495 4 13.6665C4 13.8835 4.0862 14.0916 4.23964 14.245C4.39308 14.3985 4.60119 14.4847 4.81818 14.4847H5.73945C5.94051 15.2542 6.41475 15.9242 7.07364 16.3696C7.73253 16.815 8.53101 17.0054 9.32 16.9052C10.109 16.805 10.8345 16.4211 11.3612 15.8251C11.8878 15.2291 12.1795 14.4618 12.1818 13.6665H13.8182C13.8174 14.4636 14.1076 15.2336 14.6342 15.832C15.1609 16.4304 15.8878 16.8159 16.6786 16.9164C17.4694 17.0168 18.2696 16.8252 18.9291 16.3774C19.5886 15.9297 20.0621 15.2567 20.2605 14.4847H21.1818C21.3988 14.4847 21.6069 14.3985 21.7604 14.245C21.9138 14.0916 22 13.8835 22 13.6665C22 13.4495 21.9138 13.2414 21.7604 13.0879C21.6069 12.9345 21.3988 12.8483 21.1818 12.8483H20.2605C20.0977 12.2192 19.7513 11.6528 19.2656 11.2212C18.7798 10.7896 18.1766 10.5122 17.5327 10.4245C16.8889 10.3368 16.2335 10.4426 15.6499 10.7285C15.0664 11.0145 14.5811 11.4675 14.2559 12.0301H11.7441ZM15.4545 13.6665C15.4545 14.1005 15.6269 14.5167 15.9338 14.8236C16.2407 15.1305 16.6569 15.3029 17.0909 15.3029C17.5249 15.3029 17.9411 15.1305 18.248 14.8236C18.5549 14.5167 18.7273 14.1005 18.7273 13.6665C18.7273 13.2325 18.5549 12.8163 18.248 12.5094C17.9411 12.2025 17.5249 12.0301 17.0909 12.0301C16.6569 12.0301 16.2407 12.2025 15.9338 12.5094C15.6269 12.8163 15.4545 13.2325 15.4545 13.6665Z"
        fill="white"
      />
    </svg>
  );
});
