import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo, useCallback, useState, useEffect} from 'react';
import {useForm, Resolver} from 'react-hook-form';

import {WorkspaceAPIController} from '../../../../../../../api/workspaces-api/workspaces-api-controller';
import {WorkspaceAPIRequestImpl} from '../../../../../../../api/workspaces-api/workspaces-api-request';
import {useStoreContext} from '../../../../../../../store/store-context';
import {AnalyticsController} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {WorkspaceCheckinsSettingsController} from './admin-checkins.controller';
import {WorkspaceCheckinsSettingsForm} from './admin-checkins.interface';
import {WorkspaceCheckinsSettingsRequestImpl} from './admin-checkins.request';
import {AdminConfigureCheckinsValidator} from './admin-checkins.validator';
import {useNavigate} from 'react-router';

export function useAdminCheckinsHook() {
  // 01. General ---- Shared functions and constants
  const {
    analyticsStore: {setFilterGroup},
    authStore: {auth, setAuthResponse},
    checkinsStore: {configuration, setCheckinsConfiguration},
  } = useStoreContext();

  // --- UI
  const [loading, setLoading] = useState(false);
  const [isEditingCheckinsFrequency, setIsEditingCheckinsFrequency] = useState(
    false,
  );
  const [disabled, setDisabled] = useState(true);
  const handleEditCheckinsFrequency = () => {
    setIsEditingCheckinsFrequency(true);
  };
  const handleSaveCheckinsFrequency = () => {
    setIsEditingCheckinsFrequency(false);
  };
  const navigate = useNavigate();

  // Visibility settings
  const [selectedVisibility, setSelectedVisibility] = useState('full');
  //
  const workspaceAPIRequest = useMemo(() => new WorkspaceAPIRequestImpl(), []);
  const workspaceAPIController = useMemo(
    () => new WorkspaceAPIController(workspaceAPIRequest),
    [workspaceAPIRequest],
  );
  const workspaceCheckinsRequest = useMemo(
    () => new WorkspaceCheckinsSettingsRequestImpl(),
    [],
  );
  const workspaceCheckinsController = useMemo(
    () => new WorkspaceCheckinsSettingsController(workspaceCheckinsRequest),
    [workspaceCheckinsRequest],
  );

  const editableCheckinsConfig = Object.keys(configuration).length > 0;
  const [editableConfigData, setEditableConfigData] = useState(configuration);

  // 03. Update workspace setting to enable/disable checkins
  const [isConfiguring, setIsConfiguring] = useState(false);

  const isCheckinsEnabled =
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const firstTimeEnable =
    Object.keys(configuration)?.length === 0 &&
    auth?.user?.workspace?.settings?.checkInStatus === 'enabled';

  const enableCheckins = useCallback(async () => {
    const response = await workspaceAPIController.updateWorkspace(
      {
        settings: {
          checkInStatus: 'enabled',
        },
      },
      true,
    );
    response &&
      setAuthResponse({
        ...auth,
        user: {
          ...auth?.user,
          workspace: {
            ...auth?.user?.workspace,
            settings: {
              ...auth?.user?.workspace?.settings,
              checkInStatus: 'enabled',
            },
          },
        },
      });
  }, [auth, setAuthResponse, workspaceAPIController]);
  // 04. Configure Checkins Form
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    reset,
  } = useForm<WorkspaceCheckinsSettingsForm>({
    resolver: yupResolver(AdminConfigureCheckinsValidator) as Resolver<
      WorkspaceCheckinsSettingsForm,
      object
    >,
    mode: 'all',
    defaultValues: {
      excludedGroups: editableCheckinsConfig
        ? configuration?.excludedGroups
        : [],
      frequency: {
        goals: editableCheckinsConfig ? configuration?.frequency?.goals : '',
        pulse: editableCheckinsConfig ? configuration?.frequency?.pulse : '',
        priorities: editableCheckinsConfig
          ? configuration?.frequency?.priorities
          : '',
        questions: editableCheckinsConfig
          ? configuration?.frequency?.questions
          : '',
      },
      activeCustomFrequency: editableCheckinsConfig
        ? configuration?.activeCustomFrequency
        : false,
      visibility: editableCheckinsConfig ? configuration?.visibility : 'full',
      managersCanAddQuestions: editableCheckinsConfig
        ? configuration?.managersCanAddQuestions
        : true,
      customFrequency: editableCheckinsConfig
        ? configuration?.customFrequency
        : undefined,
    },
  });

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState.isSubmitting]);

  const handleFormValueChange = useCallback(
    (value: any, field: string) => {
      setDisabled((prev) => prev === true && false);
      if (value) {
        setValue(field, value);
      } else {
        setValue(field, null);
      }
    },
    [setValue],
  );

  ////
  const {activeCustomFrequency} = watch();
  // UI 2.0
  /// Custom Frequency
  const [
    isEditingCustomCheckinsFrequency,
    setIsEditingCustomCheckinsFrequency,
  ] = useState(false);
  const [togglCustomFrequency, setTogglCustomFrequency] = useState(
    activeCustomFrequency,
  );

  const [advancedSettingsExpanded, setAdvancedSettingsExpanded] = useState(
    true,
  );
  useEffect(() => {
    if (activeCustomFrequency !== togglCustomFrequency) {
      setTogglCustomFrequency(activeCustomFrequency);
    }
  }, [activeCustomFrequency, togglCustomFrequency]);
  // 02. Initializers
  //a. Init Checkins Config Data
  const getCheckinsConfigData = useCallback(async () => {
    setLoading(true);
    const response = await workspaceCheckinsController.getCheckinsSettings();
    response && setCheckinsConfiguration(response);
    setLoading(false);
  }, [setCheckinsConfiguration, workspaceCheckinsController]);

  useEffect(() => {
    getCheckinsConfigData();
  }, [getCheckinsConfigData]);

  //b. Init editable data
  useEffect(() => {
    setEditableConfigData(configuration);
  }, [configuration]);
  const insertDefaultData = useCallback(async () => {
    reset({
      excludedGroups: editableConfigData?.excludedGroups,
      frequency: editableConfigData?.frequency,
      activeCustomFrequency: editableConfigData?.activeCustomFrequency,
      visibility: editableConfigData?.visibility,
      managersCanAddQuestions: editableConfigData?.managersCanAddQuestions,
      customFrequency: editableConfigData?.customFrequency,
    });
  }, [
    editableConfigData?.activeCustomFrequency,
    editableConfigData?.customFrequency,
    editableConfigData?.excludedGroups,
    editableConfigData?.frequency,
    editableConfigData?.managersCanAddQuestions,
    editableConfigData?.visibility,
    reset,
  ]);
  useEffect(() => {
    insertDefaultData();
  }, [configuration, editableConfigData, insertDefaultData]);

  const submitForm = useCallback(
    async (data: WorkspaceCheckinsSettingsForm) => {
      enableCheckins();

      setDisabled(true);
      const data__ = {
        ...data,
        frequency: {
          goals:
            data?.frequency?.goals !== '' && data?.frequency?.goals !== null
              ? data?.frequency?.goals
              : 'weekly',
          pulse:
            data?.frequency?.pulse !== '' && data?.frequency?.pulse !== null
              ? data?.frequency?.pulse
              : 'weekly',
          priorities:
            data?.frequency?.priorities !== '' &&
            data?.frequency?.priorities !== null
              ? data?.frequency?.priorities
              : 'weekly',
          questions:
            data?.frequency?.questions !== '' &&
            data?.frequency?.questions !== null
              ? data?.frequency?.questions
              : 'weekly',
          dueDay:
            data?.frequency?.dueDay !== '' && data?.frequency?.dueDay !== null
              ? data?.frequency?.dueDay
              : 'weekly',
        },
        customFrequency:
          data?.customFrequency &&
          data?.customFrequency.groups &&
          data?.customFrequency.goals &&
          data?.customFrequency.priorities &&
          data?.customFrequency.pulse &&
          data?.customFrequency.questions
            ? data?.customFrequency
            : undefined,
        managersCanAddQuestions: !!data?.managersCanAddQuestions ? true : false,
        activeCustomFrequency: !!data?.activeCustomFrequency ? true : false,
      };

      const response = await workspaceCheckinsController.updateCheckinsSettings(
        data__,
      );

      response && setCheckinsConfiguration(data__);
      response && setEditableConfigData(data__);
    },
    [enableCheckins, setCheckinsConfiguration, workspaceCheckinsController],
  );
  const submitEditedForm = useCallback(
    (data: WorkspaceCheckinsSettingsForm) => {},
    [],
  );
  const editCheckins = () => {
    setIsConfiguring(true);
    navigate('checkins-configure');
  };

  const handleToggleCheckins = async (e: any, status: string) => {
    const response = await workspaceAPIController.updateWorkspace({
      settings: {
        checkInStatus: status,
      },
    });
    response &&
      setAuthResponse({
        ...auth,
        user: {
          ...auth?.user,
          workspace: {
            ...auth?.user?.workspace,
            settings: {
              ...auth?.user?.workspace?.settings,
              checkInStatus: status,
            },
          },
        },
      });
  };

  //
  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const grpRequest = new AnalyticsRequestImpl();
    const grpController = new AnalyticsController(grpRequest);
    const groupsResponse = await grpController.fetchGroups();

    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    isSubmitting,
    handleFormValueChange,
    isEditingCheckinsFrequency,
    setIsEditingCheckinsFrequency,
    handleEditCheckinsFrequency,
    handleSaveCheckinsFrequency,
    advancedSettingsExpanded,
    setAdvancedSettingsExpanded,
    togglCustomFrequency,
    setTogglCustomFrequency,
    selectedVisibility,
    disabled,
    setSelectedVisibility,
    isConfiguring,
    firstTimeEnable,
    isCheckinsEnabled,
    setIsConfiguring,
    enableCheckins,
    editCheckins,
    handleToggleCheckins,
    submitForm,
    submitEditedForm,
    isEditingCustomCheckinsFrequency,
    setIsEditingCustomCheckinsFrequency,
    loading,
  };
}
