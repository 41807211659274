import {observer} from 'mobx-react';
import {Button} from '../../../../../../../ui/atoms/button';

import {Body1} from '../../../../../../../ui/atoms/typography';
import {useAdminWorkspaceHook} from '../../admin-workspace-hooks';
import {ContentBox, FeaturesLayout} from '../../admin-workspace-styled';
import {FlexRowEnd} from '@ui/style/styles';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {EditCheckins} from './components/admin-edit-checkins';
import {Routes, Route} from 'react-router';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {useAdminCheckinsHook} from './admin-checkins.hooks';
import {useEffect, useState} from 'react';

export const AdminCheckins = observer(({subTab}: {subTab?: string}) => {
  const {loader} = useAdminWorkspaceHook();
  const isSubmitting: boolean = loader?.goalFramework;
  const {
    isCheckinsEnabled,
    editCheckins,
    handleToggleCheckins,

    setIsConfiguring,
  } = useAdminCheckinsHook();
  const {isEngagementActive} = useWorkspaceUseCase();

  //
  const [checkinsChecked, setCheckinsChecked] = useState(isCheckinsEnabled);

  useEffect(() => {
    if (subTab && subTab === 'configure') {
      editCheckins();
    }
  }, [editCheckins, subTab]);

  return (
    <>
      <Routes>
        <Route path="">
          <ContentBox>
            <div className="flex">
              <div className="column">
                <Body1 kind="textDark" weight="bold">
                  Updates
                </Body1>
              </div>
              <FlexRowEnd className="">
                <SwitchToggle
                  disabled={!isEngagementActive}
                  onChange={(e: any) => {
                    if (isCheckinsEnabled) {
                      setCheckinsChecked(false);
                    } else {
                      setCheckinsChecked(true);
                    }
                    handleToggleCheckins(
                      e,
                      isCheckinsEnabled ? 'disabled' : 'enabled',
                    );
                  }}
                  checked={checkinsChecked && isEngagementActive}
                  defaultChecked={isCheckinsEnabled}
                />
                <div style={{margin: '0px 10px'}}>
                  <HorizontalSideRule size="32px" />
                </div>
                <Button
                  width="sm"
                  kind="secondary"
                  aria-busy={isSubmitting}
                  isLoading={isSubmitting}
                  style={{padding: '8px 16px'}}
                  disabled={!isCheckinsEnabled || !isEngagementActive}
                  onClick={editCheckins}>
                  View
                </Button>
              </FlexRowEnd>
            </div>
          </ContentBox>
        </Route>
        <Route path="checkins-configure">
          <FeaturesLayout>
            <EditCheckins setIsConfiguring={setIsConfiguring} />
          </FeaturesLayout>
        </Route>
      </Routes>
    </>
  );
});
