import {down} from 'styled-breakpoints';
import styled from 'styled-components';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {getColorFromTheme} from '../../../../../../../../ui/ui-utils';

export const Wrapper = styled.div`
  margin-top: 12px;
  width: 79vw;
  @media (max-width: 1000px) {
    width: 100vw;
  }
  @media (max-width: 500px) {
    width: 92vw;
  }
`;
export const FreeTrialNotificationDiv = styled.div`
  width: 100%;
  background: ${getColorFromTheme('purple300')};
  color: ${getColorFromTheme('white')};
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const PlansSection = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;

  @media (max-width: 500px) {
    align-items: flex-start;
    width: 100%;
  }
`;
export const PlansDiv = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;

    justify-content: flex-start;
  }
`;
export const PlanDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  background-color: ${getColorFromTheme('white')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 16px;
  min-height: 583px;
  margin-right: 16px;
  min-width: 362px;
  padding: 40px;

  @media (max-width: 500px) {
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  p.paragraphMicroscopy {
    font-size: 12px;
    color: ${getColorFromTheme('green400')};
    font-weight: 600;
  }
  span.monthlyCostSpan {
    font-weight: 600;
    font-size: 31.25px;
    line-height: 34px;
    color: ${getColorFromTheme('textDark')};
  }
  div.divider {
    border-bottom: 1px solid ${getColorFromTheme('borderLight')};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  div.featureLine {
    display: flex;
    margin-bottom: 16px;
  }
`;

export const SeatsDiv = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const SeatDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  width: 316px;
  div.header {
    padding: 18px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${getColorFromTheme('borderLight')};
    span {
      margin-right: 10px;
      svg {
        stroke: ${getColorFromTheme('textBody')};
      }
      cursor: pointer;
      &:hover {
        stroke: ${getColorFromTheme('purple300')};
      }
    }
  }
  div.content {
    padding: 64px 24px 117px;
  }
`;

// Billing Cycle Styles
export const BillingCycleOuterWrapper = styled.form`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
export const BillingCycleInnerWrapper = styled.div`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 50% 50%;
  padding: 32px 200px 100px;
  ${down('lg')} {
    display: grid;
    padding: 32px 200px 100px;
  }

  ${down('md')} {
    display: block;
    padding: 16px 70px 50px;
  }
  ${down('sm')} {
    display: flex;
    flex-direction: column-reverse;

    padding: 16px 16px 25px;
  }
  .address {
    padding: 24px;
    border: 1px solid #ededf2;
    border-radius: 10px;
  }
`;
export const MultiRadioSelectDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderDark')};
  border-radius: 12px;
  display: grid;
  grid-template-columns: 50% 50%;
  div.firstDiv {
    padding: 16px;
    border-right: 1px solid ${getColorFromTheme('borderDark')};
    display: flex;
    justify-content: space-between;
    height: 56px;
    align-items: center;
  }
  div.secondDiv {
    padding: 16px;
    display: flex;
    height: 56px;
    justify-content: space-between;

    align-items: center;
  }
`;
export const PriceBoxDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  div.top {
    background: ${getColorFromTheme('backgroundDark')};
    padding: 16px 24px;
    border-bottom: 1px solid ${getColorFromTheme('borderLight')};
  }
  div.bottom {
    padding: 16px 24px;
    div.items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;
    }
    .hr {
      border-bottom: 1px solid ${getColorFromTheme('borderLight')};
      margin-bottom: 26px;
    }
  }
`;
export const PriceHelperInfoDiv = styled.div`
  border: 1px dashed ${getColorFromTheme('borderLight')};
  padding: 24px;
  border-radius: 12px;
  div.info {
    display: flex;
    span.price {
      font-weight: 500;
      color: ${getColorFromTheme('textDark')};
    }
  }
`;

export const CardInfoDiv = styled.div`
  div.expired_cvc {
    display: grid;
    grid-gap: 4%;
    grid-template-columns: 62% 34%;
  }
  div.stripeCard {
    padding: 16px;
    border-radius: 7px;
    border: 1px solid ${getColorFromTheme('borderDark')};
  }
`;

export const ResponsiveFlexRowBetween = styled(FlexRowSpaceBetween)`
  .first-div {
    width: 48%;
  }
  .s-div {
    width: 48%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    .first-div {
      width: 100%;
    }
    .s-div {
      width: 100%;
    }
  }
`;
