import {AxiosResponse} from 'axios';
import {BaseRequest} from '../../../../../configs/request';
import {authStore} from '../../../../../store/stores/auth-store';
import {
  ECheckinsFrequencyType,
  EQuestionType,
  ICheckinsCalendarWeekListResponse,
  ICheckinsListResponse,
  ICheckinsPrioritiesResponse,
  ICheckinsPulseResponse,
  ICheckinsPulseResponseData,
  ICheckinsQuestionResponse,
  ICheckinsQuestionResponseData,
  ICheckinsQuestionsTemplatesResponse,
  ICreateQuestionForm,
  ICreateQuestionResponse,
  ICurrentCheckinsInfoResponse,
  ISpecificCheckinsWithoutResponse,
  ISpecificCheckinsWithResponse,
} from './company-checkins.interface';

export interface CompanyCheckinsRequest extends BaseRequest {
  url: string;
  createCheckinsQuestion(
    data: ICreateQuestionForm,
  ): Promise<ICreateQuestionResponse>;
  getCheckinsQuestions(queryFilters?: {
    type?: EQuestionType;
    status?: string;
    frequency?: ECheckinsFrequencyType;
    userQuestions?: boolean;
  }): Promise<ICreateQuestionResponse['data']>;
  getSpecificCheckinsQuestion(
    questionId: string,
  ): Promise<ICreateQuestionResponse>;
  updateCheckinQuestion(
    data: ICreateQuestionForm,
    questionId: string,
  ): Promise<ICreateQuestionResponse>;
  deleteCheckinsQuestion(questionId: string): Promise<ICreateQuestionResponse>;
  getSpecificCheckinsBatch(batchId: string): Promise<ICreateQuestionResponse>;
  updateCheckinBatch(
    data: ICreateQuestionForm,
    batchId: string,
  ): Promise<ICreateQuestionResponse>;
  deleteCheckinsBatch(batchId: string): Promise<ICreateQuestionResponse>;
  getCurrentCheckinsInfo(filter?: {
    weekOfYear?: number;
    year?: number;
  }): Promise<ICurrentCheckinsInfoResponse>;
  getCurrentPulseInfo(filter?: {
    weekOfYear?: number;
    year?: number;
  }): Promise<ICurrentCheckinsInfoResponse>;
  getCheckinsQuestionsResponse(
    questionId: string,
  ): Promise<ICheckinsQuestionResponse>;
  updateCheckinsQuestionsResponse(
    data: ICheckinsQuestionResponseData,
    questionId: string,
  ): Promise<ICheckinsQuestionResponse>;
  getCheckinsPulseResponse(pulseId: string): Promise<ICheckinsPulseResponse>;
  updateCheckinsPulseResponse(
    data: ICheckinsPulseResponseData,
    pulseId: string,
  ): Promise<ICheckinsPulseResponse>;
  getCheckinsPrioritiesResponse(
    prioritiesId: string,
  ): Promise<ICheckinsPrioritiesResponse>;
  updateCheckinsPrioritiesResponse<ICheckinsPrioritiesResponseData>(
    data: ICheckinsPrioritiesResponseData,
    prioritiesId: string,
  ): Promise<ICheckinsPrioritiesResponse>;
  getListOfCheckins(filter?: {
    weekOfYear?: number;
    year?: number;
    isActive?: boolean;
    isSubmitted?: boolean;
    isReviewed?: boolean;
    reviewer?: string;
    manager?: string;
    user?: string;
    page?: number;
  }): Promise<ICheckinsListResponse>;
  getPulses(filter?: {
    weekOfYear?: number;
    year?: number;
    isActive?: boolean;
    isSubmitted?: boolean;
    isReviewed?: boolean;
    reviewer?: string;
    manager?: string;
    user?: string;
    page?: number;
  }): Promise<any>;
  getCheckinById(checkinId: string): Promise<ISpecificCheckinsWithoutResponse>;
  updateCheckinById<ISpecificCheckinsWithoutResponseData>(
    data: ISpecificCheckinsWithoutResponseData,
    checkinId: string,
  ): Promise<ISpecificCheckinsWithoutResponse>;
  getCheckinByIdAndFetchResponses(
    checkinId: string,
  ): Promise<ISpecificCheckinsWithResponse>;
  submitSpecificCheckin<ISpecificCheckinsWithoutResponseData>(
    data: ISpecificCheckinsWithoutResponseData,
    checkinId: string,
  ): Promise<ISpecificCheckinsWithoutResponse>;
  getCheckinsCalendarWeekList(filter?: {
    manager?: string;
    user?: string;
  }): Promise<ICheckinsCalendarWeekListResponse>;
  getCheckinsQuestionsTemplates(filter?: {
    type?: string;
    getTypes?: string;
  }): Promise<ICheckinsQuestionsTemplatesResponse>;
}

export class CompanyCheckinsRequestImpl
  extends BaseRequest
  implements CompanyCheckinsRequest {
  url: string = '/check-in';

  // 01. CREATE a Checkin Question
  async createCheckinsQuestion<ICreateQuestionResponse>(
    data: ICreateQuestionForm,
  ) {
    const {data: responseData} = await this.request.post<
      ICreateQuestionForm,
      AxiosResponse<ICreateQuestionResponse>
    >(`${this.url}/questions`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 02. GET Checkin Questions
  async getCheckinsQuestions(queryFilters?: {
    type?: EQuestionType;
    status?: string;
    frequency?: ECheckinsFrequencyType;
    userQuestions?: boolean;
  }) {
    const {data: responseData} = await this.request.get(
      `${this.url}/questions${
        queryFilters && Object.keys(queryFilters).length > 0 ? '?' : ''
      }${
        queryFilters && queryFilters?.type ? 'type=' + queryFilters?.type : ''
      }${
        queryFilters && queryFilters?.frequency
          ? '&frequency=' + queryFilters?.frequency
          : ''
      }${
        queryFilters && queryFilters?.status
          ? '&status=' + queryFilters?.status
          : ''
      }${
        queryFilters && queryFilters?.userQuestions
          ? '&userQuestions=' + queryFilters?.userQuestions
          : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 03. GET specific checkin question
  async getSpecificCheckinsQuestion(questionId: string) {
    const {data: responseData} = await this.request.get(
      `${this.url}/questions/${questionId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 04. UPDATE a checkins question
  async updateCheckinQuestion<ICreateQuestionResponse>(
    data: ICreateQuestionForm,
    questionId: string,
  ) {
    const {data: responseData} = await this.request.patch<
      ICreateQuestionForm,
      AxiosResponse<ICreateQuestionResponse>
    >(`${this.url}/questions/${questionId}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 05. DELETE a checkins question
  async deleteCheckinsQuestion(questionId: string) {
    const {data: responseData} = await this.request.delete(
      `${this.url}/questions/${questionId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 06. GET specific checkin batch
  async getSpecificCheckinsBatch(batchId: string) {
    const {data: responseData} = await this.request.get(
      `${this.url}/questions/update/${batchId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 07. UPDATE a checkins batch
  async updateCheckinBatch<ICreateQuestionResponse>(
    data: ICreateQuestionForm,
    batchId: string,
  ) {
    const {data: responseData} = await this.request.patch<
      ICreateQuestionForm,
      AxiosResponse<ICreateQuestionResponse>
    >(`${this.url}/questions/update/${batchId}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 08. DELETE a checkins batch
  async deleteCheckinsBatch(batchId: string) {
    const {data: responseData} = await this.request.delete(
      `${this.url}/questions/update/${batchId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 09. GET checkins info for workspace
  async getCurrentCheckinsInfo(filter?: {weekOfYear?: number; year?: number}) {
    const parseQuery = (filter?: {weekOfYear?: number; year?: number}) => {
      if (filter && Object.keys(filter).length > 0) {
        let arrQueries = [];
        if (filter?.weekOfYear) {
          arrQueries.push(`weekOfYear=${filter?.weekOfYear}`);
        }
        if (filter?.year) {
          arrQueries.push(`year=${filter?.year}`);
        }
        return `?${arrQueries.join('&')}`;
      }

      return '';
    };

    const {data: responseData} = await this.request.get(
      `${this.url}/current${parseQuery(filter)}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        useCache: false,
      },
    );
    return responseData;
  }

  // 10. GET checkins info for workspace

  async getCurrentPulseInfo(filter?: {weekOfYear?: number; year?: number}) {
    const parseQuery = (filter?: {weekOfYear?: number; year?: number}) => {
      if (filter && Object.keys(filter).length > 0) {
        let arrQueries = [];
        if (filter?.weekOfYear) {
          arrQueries.push(`weekOfYear=${filter?.weekOfYear}`);
        }
        if (filter?.year) {
          arrQueries.push(`year=${filter?.year}`);
        }
        return `?${arrQueries.join('&')}`;
      }

      return '';
    };

    const {data: responseData} = await this.request.get(
      `${this.url}/current-pulse${parseQuery(filter)}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        useCache: false,
      },
    );
    return responseData;
  }

  // 11. GET checkins questions response
  async getCheckinsQuestionsResponse(questionId: string) {
    const {data: responseData} = await this.request.get(
      `${this.url}/questions-response/${questionId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 12. UPDATE checkins questions response
  async updateCheckinsQuestionsResponse<ICheckinsQuestionResponseData>(
    data: ICheckinsQuestionResponseData,
    questionId: string,
  ) {
    const {data: responseData} = await this.request.patch<
      ICheckinsQuestionResponseData,
      AxiosResponse<ICheckinsQuestionResponse>
    >(`${this.url}/questions-response/${questionId}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 13. GET checkins pulse response
  async getCheckinsPulseResponse(pulseId: string) {
    const {data: responseData} = await this.request.get(
      `${this.url}/pulse-response/${pulseId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 14. UPDATE checkins pulse response
  async updateCheckinsPulseResponse<ICheckinsPulseResponseData>(
    data: ICheckinsPulseResponseData,
    pulseId: string,
  ) {
    const {data: responseData} = await this.request.patch<
      ICheckinsPulseResponseData,
      AxiosResponse<ICheckinsPulseResponse>
    >(`${this.url}/pulse-response/${pulseId}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 14. GET checkins priorities response
  async getCheckinsPrioritiesResponse(prioritiesId: string) {
    const {data: responseData} = await this.request.get(
      `${this.url}/priorities-response/${prioritiesId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 15. UPDATE checkins pulse response
  async updateCheckinsPrioritiesResponse<ICheckinsPrioritiesResponseData>(
    data: ICheckinsPrioritiesResponseData,
    prioritiesId: string,
  ) {
    const {data: responseData} = await this.request.patch<
      ICheckinsPrioritiesResponseData,
      AxiosResponse<ICheckinsPulseResponse>
    >(`${this.url}/priorities-response/${prioritiesId}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 16. GET list of past and present checkins for workspace
  async getListOfCheckins(filter?: {
    weekOfYear?: number;
    year?: number;
    isActive?: boolean;
    isSubmitted?: boolean;
    isReviewed?: boolean;
    reviewer?: string;
    manager?: string;
    user?: string;
    page?: number;
  }) {
    const parseQuery = (filter?: {
      weekOfYear?: number;
      year?: number;
      isActive?: boolean;
      isSubmitted?: boolean;
      isReviewed?: boolean;
      reviewer?: string;
      manager?: string;
      user?: string;
      page?: number;
    }) => {
      if (filter && Object.keys(filter).length > 0) {
        let arrQueries = [];
        if (filter?.weekOfYear) {
          arrQueries.push(`weekOfYear=${filter?.weekOfYear}`);
        }
        if (filter?.year) {
          arrQueries.push(`year=${filter?.year}`);
        }
        if (filter?.isActive) {
          arrQueries.push(`isActive=${filter?.isActive}`);
        }
        if (filter?.isSubmitted !== undefined) {
          arrQueries.push(`isSubmitted=${filter?.isSubmitted}`);
        }
        if (filter?.isReviewed) {
          arrQueries.push(`isReviewed=${filter?.isReviewed}`);
        }
        if (filter?.reviewer) {
          arrQueries.push(`reviewer=${filter?.reviewer}`);
        }
        if (filter?.manager) {
          arrQueries.push(`manager=${filter?.manager}`);
        }
        if (filter?.user) {
          arrQueries.push(`user=${filter?.user}`);
        }
        if (filter?.page) {
          arrQueries.push(`page=${filter?.page}`);
        }
        return `&${arrQueries.join('&')}`;
      }

      return '';
    };
    const {data: responseData} = await this.request.get(
      `${this.url}/list?include=user${parseQuery(
        filter,
      )}&sortBy=createdAt:desc`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }
  async getPulses(filter?: {
    weekOfYear?: number;
    year?: number;
    isActive?: boolean;
    isSubmitted?: boolean;
    isReviewed?: boolean;
    reviewer?: string;
    manager?: string;
    user?: string;
    page?: number;
  }) {
    const parseQuery = (filter?: {
      weekOfYear?: number;
      year?: number;
      isActive?: boolean;
      isSubmitted?: boolean;
      isReviewed?: boolean;
      reviewer?: string;
      paginate?: boolean;
      manager?: string;
      user?: string;
      page?: number;
    }) => {
      if (filter && Object.keys(filter).length > 0) {
        let arrQueries = [];
        if (filter?.weekOfYear) {
          arrQueries.push(`weekOfYear=${filter?.weekOfYear}`);
        }
        if (filter?.year) {
          arrQueries.push(`year=${filter?.year}`);
        }
        if (filter?.isActive) {
          arrQueries.push(`isActive=${filter?.isActive}`);
        }
        if (filter?.isSubmitted !== undefined) {
          arrQueries.push(`isSubmitted=${filter?.isSubmitted}`);
        }
        if (filter?.isReviewed) {
          arrQueries.push(`isReviewed=${filter?.isReviewed}`);
        }
        if (filter?.reviewer) {
          arrQueries.push(`reviewer=${filter?.reviewer}`);
        }
        if (filter?.manager) {
          arrQueries.push(`manager=${filter?.manager}`);
        }
        if (filter?.user) {
          arrQueries.push(`user=${filter?.user}`);
        }
        if (filter?.page) {
          arrQueries.push(`page=${filter?.page}`);
        }
        if (filter?.paginate !== undefined) {
          arrQueries.push(`paginate=${filter?.paginate}`);
        }
        return `&${arrQueries.join('&')}`;
      }

      return '';
    };
    const {data: responseData} = await this.request.get(
      `${this.url}/pulses?include=user${parseQuery(
        filter,
      )}&sortBy=createdAt:desc`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 17. GET checkin by id
  async getCheckinById(checkinId: string) {
    const {data: responseData} = await this.request.get(
      `${this.url}/${checkinId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 18. UPDATE checkin by id
  async updateCheckinById<ISpecificCheckinsWithoutResponseData>(
    data: ISpecificCheckinsWithoutResponseData,
    checkinId: string,
  ) {
    const {data: responseData} = await this.request.patch<
      ISpecificCheckinsWithoutResponseData,
      AxiosResponse<ISpecificCheckinsWithoutResponse>
    >(`${this.url}/${checkinId}`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 19. GET checkin by id and fetch all responses
  async getCheckinByIdAndFetchResponses(checkinId: string) {
    const {data: responseData} = await this.request.get(
      `${this.url}/${checkinId}/response`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        useCache: false,
      },
    );
    return responseData;
  }

  // 20. SUBMIT specific checkin
  async submitSpecificCheckin<ISpecificCheckinsWithoutResponseData>(
    data: ISpecificCheckinsWithoutResponseData,
    checkinId: string,
  ) {
    const {data: responseData} = await this.request.post<
      ISpecificCheckinsWithoutResponseData,
      AxiosResponse<ISpecificCheckinsWithoutResponse>
    >(`${this.url}/${checkinId}/submit`, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return responseData;
  }

  // 21. Checkins Calendar API
  async getCheckinsCalendarWeekList(filter?: {
    manager?: string;
    user?: string;
  }) {
    const parseQuery = (filter?: {manager?: string; user?: string}) => {
      if (filter && Object.keys(filter).length > 0) {
        let arrQueries = [];

        if (filter?.manager) {
          arrQueries.push(`manager=${filter?.manager}`);
        }
        if (filter?.user) {
          arrQueries.push(`user=${filter?.user}`);
        }
        return `?${arrQueries.join('&')}`;
      }

      return '';
    };
    const {data: responseData} = await this.request.get(
      `${this.url}/week-list${parseQuery(filter)}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }

  // 22. Checkins Questions Templates API
  async getCheckinsQuestionsTemplates(filter?: {
    type?: string;
    getTypes?: string;
  }) {
    const parseQuery = (filter?: {type?: string; getType?: string}) => {
      if (filter && Object.keys(filter).length > 0) {
        let arrQueries = [];

        if (filter?.type) {
          arrQueries.push(`type=${filter?.type}`);
        }
        if (filter?.getType) {
          arrQueries.push(`getType=${filter?.getType}`);
        }
        return `?${arrQueries.join('&')}`;
      }

      return '';
    };
    const {data: responseData} = await this.request.get(
      `${this.url}/questions-templates${parseQuery(filter)}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return responseData;
  }
}
