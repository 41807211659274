import {useEffect, useMemo, useState} from 'react';
import {
  Wrapper,
  OverviewWrapper,
  FilterContainer,
  DropdownWrapper,
} from './styles';
import {Button} from '@ui/atoms/button';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {useQuery} from 'react-query';
import {ItemLoader} from '@ui/organisms/item-loader';
import {Body1, Body2, CTA, Headline4} from '@ui/atoms/typography';
import ObjectiveFilter from '@pages/dashboard/goals/objective-filter';
import {useNavigate} from 'react-router';
import {Image} from '@ui/atoms/image';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {useLocation} from 'react-router';
import {CheckinsTeamSummary} from './direct-reports-summary';
import dayjs from 'dayjs';
import {getWeekRangeWorkDays} from '@utils/date';
import {emojiList} from '@ui/assets/emojis';
import {useCompanyCheckinsHook} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.hooks';
import {useStoreContext} from '@store/store-context';
import {capitalize} from '@utils';
import {observer} from 'mobx-react-lite';
import {useCheckinsCalendar} from '@hooks/checkins-calendar';
import {YouCalendar} from '@pages/dashboard/company-legacy/shared-components/calendars/calendar-you-variant';
import {getWeekOfYear, getMonthFromWeek} from '@utils/date';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';

export const CheckinsDirectReports = observer(() => {
  const {
    fetchListOfCheckins,
    userFilter,
    setUserFilter,
    clearSelection,
    loading,
    setClearSelection,
    clearPagination,
  } = useCompanyCheckinsHook(true);

  const {
    checkinsStore: {
      usersCheckins,
      checkinsDates,
      usersCheckinsPaginator,
      monthCheckinsFilter,
      configuration,
      setCheckinsDate,
      calendarWeeksDirectReports,
    },
    authStore: {setCalendarView},
  } = useStoreContext();

  const location = useLocation();

  const [updateDate, setUpdateDate] = useState(false);

  const calendarData = useCheckinsCalendar(
    checkinsDates?.year,
    checkinsDates?.month,
    checkinsDates?.date,
  );

  const moods = useMemo(
    () => [
      {
        emoji: emojiList.drained,
        text: 'Drained',
      },
      {
        emoji: emojiList.sad,
        text: 'Sad',
      },
      {
        emoji: emojiList.meh,
        text: 'Meh!',
      },
      {
        emoji: emojiList.happy,
        text: 'Happy',
      },
      {
        emoji: emojiList.elated,
        text: 'Elated',
      },
    ],
    [],
  );

  const week = Object.fromEntries(new URLSearchParams(location.search as any));

  const filters = useMemo(() => {
    return {
      year: checkinsDates?.year,
      weekOfYear: monthCheckinsFilter
        ? monthCheckinsFilter
        : week?.week
        ? week?.week
        : checkinsDates?.weekOfYear,
      user:
        userFilter?.members.length > 0
          ? userFilter?.members
              .map((user: {value: string}) => user.value)
              .join(',')
          : undefined,
      manager:
        userFilter?.members.length === 0 &&
        (userFilter.selectedTeam.value || undefined),

      page: usersCheckinsPaginator?.page,
    };
  }, [
    checkinsDates?.weekOfYear,
    checkinsDates?.year,
    monthCheckinsFilter,
    week?.week,
    userFilter.selectedTeam.value,
    userFilter?.members,

    usersCheckinsPaginator?.page,
  ]);

  const {isLoading: loader} = useQuery(
    ['checkinsDirectReport', filters],
    () => fetchListOfCheckins(filters),
    {
      enabled: updateDate,
    },
  );

  useEffect(() => {
    if (
      (week.week && week.week !== checkinsDates.weekOfYear) ||
      (week.year && week.year !== checkinsDates.year)
    ) {
      const getWeek = calendarData.currentMonthWeeks.find(
        (_week) => _week.weekOfYear === Number(week?.week),
      );

      setCheckinsDate({
        starts: getWeek?.dateStart,
        ends: getWeek?.dateEnd,
        weekOfYear: Number(week.week),
        month: getMonthFromWeek(
          Number(week.year || new Date().getFullYear()),
          Number(week.week),
        ),
        year: Number(week.year) || new Date().getFullYear(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkinsDates.weekOfYear,
    checkinsDates.year,

    week.week,
    week.year,
    setCheckinsDate,
  ]);

  useEffect(() => {
    const week = Object.fromEntries(
      new URLSearchParams(location.search as any),
    );
    if (calendarWeeksDirectReports.length > 0 && !week.week) {
      setCheckinsDate({
        starts: dayjs(
          getWeekRangeWorkDays(getWeekOfYear(), new Date().getFullYear())
            .starts,
        ).format('MM/DD/YYYY'),
        ends: dayjs(
          getWeekRangeWorkDays(getWeekOfYear(), new Date().getFullYear()).ends,
        ).format('MM/DD/YYYY'),
        weekOfYear:
          calendarWeeksDirectReports[calendarWeeksDirectReports.length - 1]
            .weekOfYear,
        month: getMonthFromWeek(
          calendarWeeksDirectReports[calendarWeeksDirectReports.length - 1]
            .year,
          calendarWeeksDirectReports[calendarWeeksDirectReports.length - 1]
            .weekOfYear,
        ),
        year:
          calendarWeeksDirectReports[calendarWeeksDirectReports.length - 1]
            .year,
      });
      setUpdateDate(true);
    } else {
      setUpdateDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCheckinsDate, calendarWeeksDirectReports]);

  useEffect(() => {
    return clearPagination;
  }, [clearPagination]);

  const navigate = useNavigate();

  const months = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'Jun',
    'Jul.',
    'Aug.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];
  const activeCalWeeks = useMemo(() => {
    return calendarData.currentMonthWeeks
      .filter(
        (week) =>
          (week.month === checkinsDates?.month ||
            week.monthEnd === checkinsDates?.month) &&
          new Date(week.dateStart) <= new Date(Date.now()),
      )
      .map((week) => {
        if (
          calendarWeeksDirectReports
            ?.map((calWk: any) => calWk.year)
            .includes(week?.year) &&
          calendarWeeksDirectReports
            ?.map((calWk: any) => calWk.weekOfYear)
            .includes(week?.weekOfYear)
        ) {
          return {...week, isCheckins: true};
        } else {
          return week;
        }
      })
      .filter((week: any) => week?.isCheckins === true);
  }, [
    calendarData.currentMonthWeeks,
    calendarWeeksDirectReports,
    checkinsDates?.month,
  ]);

  if (loading) {
    return <ItemLoader />;
  }

  return (
    <Wrapper>
      <FilterContainer className="bg-white">
        <FlexRow>
          <div>
            <ObjectiveFilter
              userFilter={userFilter}
              setUserFilter={setUserFilter}
              directReportFilter
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </div>
        </FlexRow>
      </FilterContainer>
      <VerticalSpacer size="24px" />
      <OverviewWrapper className="bg-white">
        <FlexRowSpaceBetween>
          <Body1 weight="bold">Overview</Body1>
          <DropdownCustom
            collapseOnClick={true}
            menu={(handleClose: () => void) => (
              <>
                <DropdownWrapper>
                  <YouCalendar
                    historicalCheckinsCloseModal={handleClose}
                    startYear={
                      configuration?.createdAt
                        ? new Date(configuration?.createdAt).getFullYear()
                        : new Date().getFullYear()
                    }
                    variant="directReports"
                    onClickWeek={(date) => {
                      if (location.search?.length > 0)
                        navigate(location.pathname);

                      setCheckinsDate({
                        starts: date.start,
                        ends: date.end,
                        weekOfYear: date.weekOfYear,
                      });
                    }}
                  />
                </DropdownWrapper>
              </>
            )}
            shouldNotIncludeWrapper={true}
            customComponent={(
              handleOpen: (event: any) => void,
              open: boolean,
            ) => (
              <>
                <Button
                  kind="secondary"
                  onClick={(event) => {
                    setCalendarView('year');
                    handleOpen(event as any);
                  }}
                  style={{padding: '8px 16px'}}>
                  <FlexRowSpaceBetween style={{width: '100%'}}>
                    <Body2 weight="bold">
                      {' '}
                      {months[checkinsDates.month]} {' ' + checkinsDates.year}
                    </Body2>

                    <ArrowHeadDownIcon
                      style={{
                        marginLeft: 14,
                        stroke: 'black',
                        transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                      }}
                    />
                  </FlexRowSpaceBetween>
                </Button>
              </>
            )}
            customDropdownWrapperStyles={{
              top: '130%',
              boxShadow: '0px',
              right: 0,
              padding: 0,
              overflowY: 'auto',
              background: 'none',
              border: 0,
            }}
          />
        </FlexRowSpaceBetween>
        <VerticalSpacer size="10px" />
        {!loader &&
          !!usersCheckins?.summary &&
          Object.keys(usersCheckins?.summary).length > 0 && (
            <div>
              <div className="summary">
                <div className="box">
                  <div>
                    <Headline4 kind="textDark" style={{fontWeight: 500}}>
                      Average pulse
                    </Headline4>
                    {!!usersCheckins?.summary?.averagePulse ? (
                      <CTA kind="green400">
                        {capitalize(usersCheckins?.summary?.averagePulse)}
                      </CTA>
                    ) : (
                      <CTA kind="textMuted">--/--</CTA>
                    )}
                  </div>
                  <div>
                    {!!usersCheckins?.summary?.averagePulse && (
                      <Image
                        {...moods.filter(
                          (mood) =>
                            mood.text ===
                            capitalize(usersCheckins?.summary?.averagePulse),
                        )[0]?.emoji}
                        width="60px"
                        height="60px"
                      />
                    )}
                  </div>
                </div>
                <div className="box">
                  <div>
                    <Headline4 kind="textDark" style={{fontWeight: 500}}>
                      Submitted
                    </Headline4>
                  </div>
                  <div className="numbers">
                    {usersCheckins?.summary?.submitted}
                  </div>
                </div>
                <div className="box">
                  <div>
                    <Headline4 kind="textDark" style={{fontWeight: 500}}>
                      Reviewed
                    </Headline4>
                  </div>
                  <div className="numbers">
                    {usersCheckins?.summary?.reviewed}
                  </div>
                </div>
              </div>
            </div>
          )}
      </OverviewWrapper>
      <VerticalSpacer size="16px" />
      <CheckinsTeamSummary
        loader={loader}
        loading={loading}
        activeWeeks={activeCalWeeks}
      />
    </Wrapper>
  );
});
