import {useStoreContext} from '@store/store-context';
import {useMemo, useState} from 'react';
import {useNavigate} from 'react-router';
import {WorkspaceCheckinsSettingsRequestImpl} from '../admin-checkins/admin-checkins.request';
import {WorkspaceCheckinsSettingsController} from '../admin-checkins/admin-checkins.controller';
import {activateNotification} from '@ui/molecules/notification/activate-notification';

export const useAdminPulseHook = () => {
  const {
    checkinsStore: {configuration, setCheckinsConfiguration},
  } = useStoreContext();

  const workspaceCheckinsRequest = useMemo(
    () => new WorkspaceCheckinsSettingsRequestImpl(),
    [],
  );

  const workspaceCheckinsController = useMemo(
    () => new WorkspaceCheckinsSettingsController(workspaceCheckinsRequest),
    [workspaceCheckinsRequest],
  );

  const isPulseEnabled = configuration?.frequency?.pulse !== 'never';

  const navigate = useNavigate();

  const [, setIsConfiguring] = useState(false);

  const handleTogglePulse = async (enable?: boolean) => {
    const value = enable ? 'weekly' : 'never';

    const response = await workspaceCheckinsController.updateCheckinsSettings(
      {
        frequency: {
          pulse: enable ? 'weekly' : 'never',
        },
      },
      true,
    );

    response &&
      setCheckinsConfiguration({
        ...configuration,
        frequency: {
          ...configuration.frequency,
          pulse: value,
        },
      });
    response &&
      activateNotification({
        title: 'Success',

        content: `Pulse  settings updated`,
        kind: 'success',
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const response = await workspaceCheckinsController.updateCheckinsSettings(
      {
        frequency: {
          pulse: formData.frequency,
        },
        excludedPulseGroups: formData.excludedGroups,
      },
      true,
    );

    response &&
      setCheckinsConfiguration({
        ...configuration,
        frequency: {
          ...configuration.frequency,
          pulse: formData.frequency,
        },
        excludedPulseGroups: formData.excludedGroups,
      });
    response &&
      activateNotification({
        title: 'Success',

        content: `Pulse  settings updated`,
        kind: 'success',
      });
    setIsLoading(false);
  };

  const editPulse = () => {
    setIsConfiguring(true);
    navigate('pulse-configure');
  };

  const [isEditingPulseFrequency, setIsEditingPulseFrequency] = useState(false);

  const [formData, updateFormData] = useState<{
    frequency: string;
    excludedGroups?: string[];
  }>({
    frequency: configuration.frequency?.pulse || '',
    excludedGroups: configuration.excludedPulseGroups || [],
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const handleFormChange = (
    field: keyof typeof formData,
    value: string | string[],
  ) => {
    updateFormData((prev) => ({...prev, [field]: value}));
    setIsDisabled(false);
  };

  return {
    isPulseEnabled,
    handleTogglePulse,
    editPulse,
    isEditingPulseFrequency,
    setIsEditingPulseFrequency,
    isDisabled,
    handleFormChange,
    formData,
    handleSubmit,
    isLoading,
  };
};
