import React from 'react';

export const PlayIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#585ADF" />
      <path
        d="M28.504 20.6626C29.6914 19.8708 29.6913 18.1259 28.5037 17.3344L16.1093 9.07256C14.7802 8.18661 13 9.13941 13 10.7367V27.2627C13 28.8601 14.7804 29.8129 16.1095 28.9267L28.504 20.6626Z"
        fill="white"
      />
    </svg>
  );
};

export const SecondaryPlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none">
      <path
        d="M10.4751 8.71402C11.7698 7.93714 11.7696 6.06078 10.4749 5.28405L3.02888 0.817072C1.69583 0.0173552 0 0.977589 0 2.53212V11.4674C0 13.022 1.69605 13.9822 3.0291 13.1823L10.4751 8.71402Z"
        fill="#585ADF"
      />
    </svg>
  );
};
