import {ResponsiveBarChart} from '@ui/atoms/bar-chart';
import {FC, memo, useMemo} from 'react';
import {Insight} from '../interface';
import {Body1} from '@ui/atoms/typography';
import {FlexRowEnd} from '@ui/style/styles';
import {formatConfigDate, comparisonConfigDate} from '../insight-options';
import {useInsightActionItemHook} from './action-items-insight-hook';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {getInitialAdditionalFilters} from '../edit-insight/edit-insight-filter';
import {VerticalInsightConfig} from '../../dashboard/insight-config';

interface ActionItemsDistributionProps extends Insight {
  showRangeType?: boolean;
  handleEditLayout?: (id: string) => void;
}

export const ActionItemDistributionPerIndividual: FC<ActionItemsDistributionProps> = memo(
  ({showRangeType, name, handleEditLayout, config, ...rest}) => {
    const formatDate = useMemo(() => formatConfigDate(config), [config]);

    const additionalFilters = getInitialAdditionalFilters(
      config.list,
      config.users,
    );

    const comparisonDate = useMemo(
      () => comparisonConfigDate(config, formatDate.startDate),
      [config, formatDate.startDate],
    );

    const {filteredData} = useInsightActionItemHook({
      members: config.users || [],
      sections: config.list || [],
      date: formatDate,
      comparisonDate: comparisonDate,
    });

    const hasComparison = !!config.comparison.value;

    return (
      <div className="bg-white shadow-sm py-6 px-4 border rounded-[10px] border-borderLight  min-h-[150px] w-full ">
        <Body1 weight="bold" className={!showRangeType ? 'pb-6' : ''}>
          {name}
        </Body1>

        {showRangeType && (
          <VerticalInsightConfig
            config={config}
            additionalFilters={additionalFilters}
          />
        )}

        <ResponsiveBarChart
          hasComparison={hasComparison}
          data={filteredData.map((value) => ({
            value: value.current.completionRate,
            count: value.current.completedonDueDate,
            comparedValue: value.comparison.completionRate,
            comparedCount: value.comparison.completedonDueDate,
            name: value.name,
          }))}
        />
        {showRangeType && (
          <FlexRowEnd className="mt-6">
            <button
              onClick={() => {
                if (rest.firebaseId && handleEditLayout)
                  handleEditLayout(rest.firebaseId);
              }}>
              <HoverTooltip tooltipText="Edit insight">
                <CandleVIcon />
              </HoverTooltip>
            </button>
          </FlexRowEnd>
        )}
      </div>
    );
  },
);
