import {useGCalenderHook} from '@hooks/gcalender';
import {IntegrationBody, Wrapper} from '../../components/app-body';
import {Button} from '@ui/atoms/button';
import {GoogleCalenderIcon} from '@ui/atoms/icons/apps/google-calender';
import {SuccessIcon} from '@ui/atoms/svg/success-icon';
import {Dropdown} from '@ui/molecules/dropdown';
import {ItemLoader} from '@ui/organisms/item-loader';
import {observer} from 'mobx-react';

export const GoogleCalender = observer(() => {
  const Body = {
    image: <GoogleCalenderIcon />,
    title: 'Google Calendar',
    description:
      'Auto-add and manage 1:1 meetings – keep your calendar clutter-free and conflicts at bay.',
  };

  const {handleAuthClick, isSigned, handleLogOut, loader} = useGCalenderHook();

  const CustomButton = () => {
    if (loader) {
      return <ItemLoader />;
    }
    if (isSigned) {
      return (
        <Button
          kind="secondary"
          style={{padding: '6px 16px', columnGap: '5px'}}
          className="flex items-center">
          <SuccessIcon />
          Connected
        </Button>
      );
    }

    return (
      <Button
        kind="secondary"
        className="min-w-[118px] transform transa"
        style={{padding: '6px 16px'}}
        onClick={() => handleAuthClick()}>
        Connect
      </Button>
    );
  };
  const ConnectedDropdown = () => {
    return (
      <Dropdown
        dropdownItemStyle={{whiteSpace: 'nowrap'}}
        menu={[
          {
            content: `Disconnect`,
            id: 'disconnect',
          },
        ]}
        onClick={() => handleLogOut()}
      />
    );
  };

  return (
    <Wrapper>
      <IntegrationBody
        {...Body}
        dropDown={isSigned ? <ConnectedDropdown /> : undefined}
        customButton={CustomButton}
      />
    </Wrapper>
  );
});
