import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {memo, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {
  DesktopWrapper,
  FormWrapper,
  MagicPenIconWrapper,
  MobileWrapper,
  ModalWrapper,
} from './styles';
import {ViewMeetings} from './view-meetings';
import {Modal} from '@ui/molecules/modal';
import {useBreakpoints} from '@utils/use-breakpoints';
import {ModalCard} from '@ui/layouts/modal-card';
import {Templates} from '@ui/organisms/templates';
import {MeetingRequestImpl} from '../meeting.request';
import {MeetingController} from '../meeting-controller';
import {useQuery} from 'react-query';
import {useViewMeetingHook} from './view-meeting-hook';
import {authStore} from '@store/stores/auth-store';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {FlexRow} from '@ui/style/styles';
import {ArrowLeftIcon, MagicPenIcon} from '@ui/atoms/icons';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';
import {useSearchParams} from 'react-router-dom';
import {PageSideLayout} from '@ui/layouts/page-size';
import {SideNavigation} from '@ui/molecules/side-nav';
import AiSummaryPage from './ai-summary';

export const ViewMeetingsPage = memo(() => {
  const navigate = useNavigate();

  const [showTemplates, setShowTemplates] = useState(false);

  const {id} = useParams();

  const {isSm, isMd, isXs} = useBreakpoints();

  const [searchParams] = useSearchParams();

  const [tab, setTab] = useState({
    note: true,
    summary: false,
  });

  const {refetch, data: meeting} = useQuery(
    ['view-meeting', id],
    () => fetchMeetingDetails(id),
    {
      enabled: false,
    },
  );

  const {data: botResponse} = useQuery(
    ['view-meeting-bot-response', id],
    () => controller.getMeetingBotResponse(id),
    {
      enabled: meeting && !!meeting?.meta?.botId,
    },
  );

  const {data: botData} = useQuery(
    ['view-meeting-bot-data', id],
    () => controller.getMeetingBotData(id),
    {
      enabled: meeting && !!meeting?.meta?.botId,
    },
  );

  const summaryInProgress = botData
    ? botData.status_changes[botData.status_changes.length - 1]?.code ===
      'in_call_recording'
    : false;

  const navigations: any = useMemo(() => {
    const COLORS = {
      active: '#585ADF',
      inactive: '#5f5f8c',
      summaryInProgress: '#E87F16',
      default: '#BFBFD4',
    };

    const getIconColor = (
      summaryInProgress: boolean,
      tabSummary: boolean,
      botResponse: boolean,
    ) => {
      if (summaryInProgress) return COLORS.summaryInProgress;
      if (!tabSummary) return COLORS.inactive;
      return botResponse ? COLORS.active : COLORS.default;
    };

    // Function to determine the text color
    const getTextColor = (
      summaryInProgress: boolean,
      tabSummary: boolean,
      botResponse: boolean,
    ) => {
      if (summaryInProgress) return 'orange300';
      if (!tabSummary) return 'textBody';
      return botResponse ? 'purple300' : 'textMuted';
    };

    // Function to determine the text weight
    const getTextWeight = (tabSummary: boolean, summaryInProgress: boolean) => {
      return !tabSummary && !summaryInProgress ? 'regular' : 'bold';
    };

    const nav = [
      {
        active: !!tab.note,
        href: '',
        id: '1',
        type: 'status',
        onClick: () => {
          setTab({note: true, summary: false});
        },
        icon: <></>,
        iconPosition: '',
        title: `Meeting Notes`,
      },
      {
        active: !!tab.summary,
        href: '',
        id: '1',
        type: 'status',
        onClick: () => {
          setTab({note: false, summary: true});
        },
        icon: (
          <MagicPenIconWrapper
            color={getIconColor(summaryInProgress, tab.summary, !!botResponse)}
            disabled={!botResponse}>
            <MagicPenIcon />
          </MagicPenIconWrapper>
        ),
        iconPosition: 'left',
        title: (
          <Body2
            kind={getTextColor(summaryInProgress, tab.summary, !!botResponse)}
            weight={getTextWeight(tab.summary, summaryInProgress)}>
            Meeting Recap
          </Body2>
        ),
      },
    ];

    return nav;
  }, [botResponse, tab.note, tab.summary, summaryInProgress]);

  const viewMode = searchParams.get('view');

  const [showGoBack] = useState(!!viewMode);

  const request = new MeetingRequestImpl();
  const controller = new MeetingController(request);

  const [limit, setLimit] = useState(10);

  const {data, isLoading} = useQuery(['agenda-templates', limit], () =>
    controller.getAgendaTemplates(limit),
  );

  const {handleUpdateMeeting, fetchMeetingDetails} = useViewMeetingHook();

  const userIsHost = meeting && meeting.user?.id === authStore.auth.user.id;

  const TemplatesComponents = () => (
    <Templates
      title="Browse meeting templates"
      onClose={() => setShowTemplates(false)}
      isLoading={isLoading}
      templateName="Agenda"
      totalResults={data?.totalResults || 10}
      handleClick={(data) => {
        refetch().then((response) => {
          if (response.data) {
            const isHost = response.data?.user?.id === authStore.auth.user.id;

            const prevHostAgenda = response.data.hostAgenda;
            const prevParticipantAgenda = response.data.participantAgenda;

            const agendas = data.children.map((agenda) => ({
              text: agenda,
              completed: false,
              participant: isHost ? undefined : authStore.auth.user.id,
            }));

            handleUpdateMeeting(
              id,
              isHost
                ? {hostAgenda: [...prevHostAgenda, ...agendas]}
                : ({
                    participantAgenda: [...prevParticipantAgenda, ...agendas],
                  } as any),
            ).then(() => {
              refetch();
            });
          }
        });

        setShowTemplates(false);
      }}
      templates={
        data?.results.map((result) => ({
          children: result.agenda,
          id: result.id,
          title: result.title,
        })) || []
      }
      templatesType={[
        {
          value: '1:1',
          label: '1-on-1s',
        },
      ]}
      limit={limit}
      updateLimit={(value) => setLimit(value)}
    />
  );

  return (
    <PageLayoutTemplate
      onClick={() => {
        navigate('/meetings/');
      }}
      leftAction={
        !showGoBack ? null : (
          <PlainButton onClick={() => navigate(tab.summary ? -2 : -1)}>
            <FlexRow>
              <ArrowLeftIcon />
              <HorizontalSpacer size="10px" />
              <Body2 weight="semibold">Go back</Body2>
            </FlexRow>
          </PlainButton>
        )
      }
      titleMargin={showGoBack ? '1.1%' : '6.7%'}
      title={'Meetings'}
      bodyStyle={{margin: 0}}>
      <div className="mt-[75px]">
        <PageSideLayout
          content={
            <>
              {tab.summary && meeting && (
                <FormWrapper>
                  <div className="form">
                    <AiSummaryPage
                      host={meeting.user?.id || ''}
                      inProgress={summaryInProgress}
                      agendaType={
                        userIsHost ? 'hostAgenda' : 'participantAgenda'
                      }
                      agenda={
                        userIsHost
                          ? meeting.hostAgenda
                          : meeting.participantAgenda
                      }
                      meetingTitle={meeting.title || `1:1 with`}
                      meetingId={meeting.id}
                      participants={meeting.participant}
                    />
                  </div>
                </FormWrapper>
              )}
              {tab.note && (
                <FormWrapper split={showTemplates}>
                  <div className={showTemplates ? 'split-form' : 'form'}>
                    <ViewMeetings
                      handleUseTemplates={() => setShowTemplates(true)}
                    />
                  </div>
                  {showTemplates && (
                    <>
                      <DesktopWrapper>
                        <TemplatesComponents />
                      </DesktopWrapper>

                      <MobileWrapper>
                        <Modal
                          open={!!isSm || !!isMd || !!isXs}
                          onClose={() => null}>
                          <ModalWrapper>
                            <ModalCard title="">
                              <TemplatesComponents />
                            </ModalCard>
                          </ModalWrapper>
                        </Modal>
                      </MobileWrapper>
                    </>
                  )}
                </FormWrapper>
              )}
            </>
          }
          navigation={
            <>
              {meeting && meeting?.meta?.botId && (
                <SideNavigation
                  navigations={navigations}
                  iconSpacing="flex-start"
                />
              )}
            </>
          }
        />
      </div>
    </PageLayoutTemplate>
  );
});
