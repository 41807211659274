import {Body2} from '@ui/atoms/typography';
import {Flex, FlexRowSpaceBetween} from '@ui/style/styles';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import styled from 'styled-components';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownWrapper} from '../../../../../you/you-page.styles';
import {Button} from '@ui/atoms/button';
import dayjs from 'dayjs';
import {capitalize} from '@utils';
import {GoalActivity} from './goal-activity';
import {DesktopWrapper, MobileWrapper} from '../../insights';
import {cfv} from '@utils/framework';
import {useMemo, useState} from 'react';
import {AlignedObjectives} from './aligned-objectives';
import {FiChevronDown} from 'react-icons/fi';
import {DropdownItem} from '../../../../../you/you-page.styles';
import MultiLineChart from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/Graph';
import {goalDueDate} from '@utils/date';
import {IGoalResult} from '@hooks';

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #ededf2;
  margin-top: 20px;
`;

const GraphContainerWrapper = styled.div`
  .heading {
    padding: 16px;
  }
  border-bottom: 1px solid #ededf2;
  @media (max-width: 800px) {
    .heading {
      padding: 16px;
      justify-content: space-between;
    }
    padding: 0px;
    border-bottom: 0px;
  }
`;

const CustomButton = styled(Button)`
  min-width: 120px;
  height: 38px;
  padding: 0px 8px 0px 16px;
  display: flex;
  align-items: center;
  z-index: 0;
  overflow: hidden;
`;

const DateContainer = styled.div`
  padding: 10px;

  @media (max-width: 800px) {
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #ededf2;
  }

  .wrapper {
    background: #f6f6fe;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      padding: 8px;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
`;
interface IDate {
  startDate: string;
  endDate: string;
  type?: string;
}

const DateWrapper = ({startDate, endDate, type}: IDate) => {
  return (
    <DateContainer>
      <div className="wrapper">
        <div>
          <Body2 weight="bold">Start {type === 'mobile' ? '' : 'date'}:</Body2>
          <Body2 style={{marginLeft: '8px'}}>
            {dayjs(startDate).format('DD MMM YYYY')}
          </Body2>
        </div>
        <div>
          <Body2 weight="bold">End {type === 'mobile' ? '' : 'date'}:</Body2>
          <Body2 style={{marginLeft: '8px'}}>
            {dayjs(endDate).format('DD MMM YYYY')}
          </Body2>
        </div>
      </div>
    </DateContainer>
  );
};
const GraphDiv = styled.div`
  width: 100%;

  @media (max: width: 1240px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    border-top: 1px solid #ededf2;
    width: 100%;
    padding: 0px;
  }
`;
const GraphActivity = ({goal, krValue, isKeyResult, data}: any) => {
  return (
    <>
      <GraphDiv>
        <MultiLineChart
          data={data}
          completedAt={dayjs(goal?.completedAt || goal?.updatedAt).format(
            'MM/DD/YYYY',
          )}
          lastUpdated={goal.status}
          KRtargetValue={krValue}
          isKeyResult={isKeyResult}
          isKpi={goal?.isKpi}
          useCurrentValue={goal.isKpi}
          dueDate={dayjs(goal.endDate).format('MM/DD/YYYY')}
          todayDate={dayjs().format('MM/DD/YYYY')}
          performance={
            goalDueDate(goal?.endDate) === 'Past due'
              ? 'at_risk'
              : (goal?.performance as any)
          }
          margin={{top: 20, right: 25, bottom: 20, left: 45}}
        />
      </GraphDiv>
    </>
  );
};

const OverflowWrapper = styled.div`
  margin-right: 8px;
  @media (max-width: 800px) {
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 2em;
      border-top: 1px solid #ededf2;
      height: 2.1em;
    }

    &::-webkit-scrollbar-track {
      background: #f6f8fa;
      border: 0.8em solid rgba(0, 0, 0, 0);
      background-clip: padding-box;

      -webkit-border-radius: 1.5em;

      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      height: 10em;
      border: 0.8em solid rgba(0, 0, 0, 0);
      background: #e5ebf1;
      border-radius: 10px;
      background-clip: padding-box;
      -webkit-border-radius: 1.5em;

      -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
`;

const GraphContainer = ({
  goal,
  setSelectedValue: updateSelectedValue,
  updateTimeRange,
  timeRange,
}: {
  timeRange: string;
  updateTimeRange: (value: string) => void;
  setSelectedValue: any;
  goal: IGoalResult;
}) => {
  const [selectedKeyResult, setSelectedKeyResult] = useState('');

  const [selectedValue, setSelectedValue] = useState(
    `${capitalize(cfv(undefined, true).g_oal)}`,
  );

  const [krValue, setKrValue] = useState(0);

  const timeRangeOption = [
    {
      label: 'Last 7 days',
      value: '7',
    },
    {
      label: 'Last 14 days',
      value: '14',
    },
    {
      label: 'Last 28 days',
      value: '28',
    },
    {
      label: 'All time',
      value: '',
    },
  ];

  const selectedTime = timeRangeOption.find(
    (option) => option.value === timeRange,
  )?.label;

  const activities = useMemo(() => {
    if (selectedKeyResult) {
      return goal.keyResults.find(
        (keyResult) => keyResult.id === selectedKeyResult,
      )?.activities;
    }
    return goal.activities;
  }, [goal.activities, goal.keyResults, selectedKeyResult]);

  return (
    <GraphContainerWrapper>
      <FlexRowSpaceBetween className="mt-2">
        {!goal?.isKpi ? (
          <Flex alignItems="center" className="heading">
            <Body2 weight="bold">Showing trend for</Body2>
            <HorizontalSpacer size="10px" />
            <DropdownWrapper>
              <DropdownCustom
                collapseOnClick={true}
                menu={(handleClose: () => void) => (
                  <div style={{width: '180px'}}>
                    <DropdownItem
                      onClick={() => {
                        setSelectedKeyResult('');

                        handleClose();

                        updateSelectedValue({
                          id: '',
                          name: `${cfv(undefined, true).g_oal} Activity`,
                        });

                        setSelectedValue(
                          `${capitalize(cfv(undefined, true).g_oal)}`,
                        );
                      }}>
                      {' '}
                      {capitalize(cfv(undefined, true).g_oal)}
                    </DropdownItem>
                    {goal.keyResults.map((keyResult: any, index: number) => (
                      <DropdownItem
                        onClick={() => {
                          setSelectedKeyResult(keyResult.id);
                          handleClose();

                          updateSelectedValue({
                            id: keyResult.id,
                            name: `${cfv(undefined, true).k_r} ${
                              index + 1
                            } Activity`,
                          });
                          setKrValue(keyResult.targetValue);
                          setSelectedValue(`Key result ${index + 1}`);
                        }}>{`${capitalize(cfv(undefined, true).k_rs)} ${
                        index + 1
                      }`}</DropdownItem>
                    ))}
                  </div>
                )}
                customComponent={(
                  handleOpen: (event: any) => void,
                  open: boolean,
                ) => (
                  <>
                    <CustomButton kind="secondary" onClick={handleOpen}>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        style={{width: '100%'}}>
                        <Body2 weight="bold">{selectedValue}</Body2>
                        <FiChevronDown
                          style={{margin: '0px 5px 0px 0px'}}
                          aria-hidden="true"
                        />
                      </Flex>
                    </CustomButton>
                  </>
                )}
                customDropdownWrapperStyles={{top: '130%', right: 0}}
              />
            </DropdownWrapper>
          </Flex>
        ) : (
          <div className="heading"></div>
        )}
        {goal?.isKpi && (
          <div className="mr-4">
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <div style={{width: '180px'}}>
                  {timeRangeOption.map((option) => (
                    <DropdownItem
                      key={option.value}
                      onClick={() => {
                        updateTimeRange(option.value);
                        handleClose();
                      }}>
                      {option.label}
                    </DropdownItem>
                  ))}
                </div>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <>
                  <CustomButton kind="secondary" onClick={handleOpen}>
                    <FlexRowSpaceBetween>
                      <Body2 weight="semibold">{selectedTime || ''}</Body2>
                      <FiChevronDown
                        style={{margin: '0px 5px 0px 5px'}}
                        aria-hidden="true"
                      />
                    </FlexRowSpaceBetween>
                  </CustomButton>
                </>
              )}
              customDropdownWrapperStyles={{top: '130%', right: 0}}
            />
          </div>
        )}
      </FlexRowSpaceBetween>

      <OverflowWrapper>
        <GraphActivity
          goal={goal}
          isKeyResult={!!selectedKeyResult}
          data={activities}
          krValue={krValue}
        />
      </OverflowWrapper>
    </GraphContainerWrapper>
  );
};

const GraphWrapper = ({
  goal,
  setSelectedValue,
  timeRange,
  updateTimeRange,
}: {
  timeRange: string;
  updateTimeRange: (value: string) => void;
  goal: IGoalResult;
  setSelectedValue: any;
}) => {
  return (
    <>
      <Wrapper>
        <GraphContainer
          goal={goal}
          updateTimeRange={updateTimeRange}
          timeRange={timeRange}
          setSelectedValue={setSelectedValue}
        />
        <DesktopWrapper>
          <DateWrapper startDate={goal.startDate} endDate={goal.endDate} />
        </DesktopWrapper>
      </Wrapper>
      <MobileWrapper>
        <DateWrapper
          startDate={goal.startDate}
          endDate={goal.endDate}
          type="mobile"
        />
      </MobileWrapper>
    </>
  );
};

export const InsightTab = ({
  goal,
  updateTimeRange,
  timeRange,
}: {
  goal: IGoalResult;
  updateTimeRange: (value: string) => void;
  timeRange: string;
}) => {
  const [selectedValue, setSelectedValue] = useState({
    id: '',
    name: `All activity`,
  });

  const showAlignment =
    (!goal?.isKpi && goal?.alignedGoals?.length > 0) ||
    goal.goalAlignment ||
    goal.keyResults.some(
      (kr: any) =>
        kr.alignedKeyResults?.length > 0 || kr.keyResultAlignment?.keyResult,
    );

  return (
    <>
      <GraphWrapper
        goal={goal}
        setSelectedValue={setSelectedValue}
        updateTimeRange={updateTimeRange}
        timeRange={timeRange}
      />

      {showAlignment && (
        <AlignedObjectives
          childGoals={
            goal.goalAlignment
              ? [goal, ...goal.alignedGoals]
              : goal.alignedGoals
          }
          goalAlignment={
            goal?.alignedGoals.length > 0 && !goal.goalAlignment
              ? goal
              : goal.goalAlignment
          }
          goal={goal}
        />
      )}

      <GoalActivity
        id={goal.id}
        createdBy={goal.createdBy}
        selectedValue={selectedValue}
        isGoalKpi={goal.isKpi || false}
        updatedAt={goal.createdAt}
      />
    </>
  );
};
