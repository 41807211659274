import {observer} from 'mobx-react';
import {lazy, useState} from 'react';
import {useYouHook} from '@pages/dashboard/you/you-hook';
import {useStoreContext} from '../../store/store-context';
import {beamMasteryTasks, INSIGHT_PATH} from '../../utils/firebase-request';
import {CreateGoalController} from './create-goal-controller';
import {CreateGoalRequestImpl} from './create-goal-request';
import {usePostHogHook} from '@hooks/post-hog';
import {useTags} from '@hooks/tags';
import {updateFirebaseData, writeFirebaseData} from '@utils/firebase-handler';
import dayjs from 'dayjs';
import {DEFAULT_KPI_CONFIG} from '@pages/dashboard/reporting/insights/edit-insight/edit-insight-hook';

const CreateGoalPage = lazy(() =>
  import('./create-goal-page').then((module) => ({
    default: module.CreateGoalPage,
  })),
);

const CreateGoalForm = lazy(() =>
  import('./create-goal-form').then((module) => ({
    default: module.CreateGoalForm,
  })),
);

export const installCreateGoalPage = () => {
  const request = new CreateGoalRequestImpl();
  const controller = new CreateGoalController(request);

  const FormComponent = observer(() => {
    const {
      goalsState: {setShowGoalForm},
    } = useStoreContext();

    const [loading, setLoading] = useState(false);

    const {refetch: goalsRefetch} = useYouHook();

    const {tags} = useTags(`goal_tags`);
    const formattedTags = tags.filter((tag) => !!tag.tag?.name);

    const {postHogCapture} = usePostHogHook();

    const onSubmit = async (data: any) => {
      setLoading(true);

      const data_ = data;

      const response = await controller.createGoal(data_);
      setLoading(false);

      if (!data?.isKpi) {
        response && beamMasteryTasks('create_kpi', true);
      } else {
        if (response) {
          beamMasteryTasks('create_objective', true);

          writeFirebaseData(INSIGHT_PATH, {
            name: data.name,
            id: response.id,
            createdBy: '',
            updatedAt: dayjs().format(),
            createdAt: dayjs().format(),
            updatedBy: '',
            config: DEFAULT_KPI_CONFIG,
            type: 'kpi',
          });
        }
      }

      postHogCapture('frontend - Goal creation', undefined, {
        goalName: data.name,
        isKpi: data?.isKpi,
      });

      if (response && data.meta?.tags) {
        formattedTags.forEach((tag) => {
          if (data?.meta.tags.includes(tag?.tag?.name)) {
            updateFirebaseData(`goal_tags/${tag.key}`, {
              name: tag?.tag.name,
              goals: tag?.tag?.goals
                ? [...tag?.tag?.goals, response?.id]
                : [response?.id],
            });
          }
        });
      }

      response && setShowGoalForm(false);

      response && goalsRefetch();
    };

    return <CreateGoalForm onSubmit={onSubmit} loading={loading} />;
  });

  const Page = observer(({exploreTemplates}: {exploreTemplates?: boolean}) => (
    <CreateGoalPage
      FormComponent={FormComponent}
      exploreTemplates={exploreTemplates}
    />
  ));

  return {
    Page,
  };
};
