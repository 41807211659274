import styled from 'styled-components';
import {memo, useEffect, useMemo, useState} from 'react';
import {motion} from 'framer-motion';
import {ProgressField} from '../../field/progress-field';
import {BinarySwitchField} from '../../field/binary-switch';
import {VerticalSpacer} from '../../../atoms/spacer';
import nextId from 'react-id-generator';
import {useBreakpoints} from '../../../../utils/use-breakpoints';
import {MeasurementType, ProgressType} from '../../../interface';
import {CancelIcon} from '@ui/atoms/icons';
import {SecondaryNumberInput} from '@ui/atoms/range/number';
import {PercentageUpdateField} from '../../field/percentage-update';
import {RangeUpdateField} from '../../field/range-update-field';
import {useFirebaseFetch} from '@hooks/query-hook';
import {Button} from '../../../atoms/button';
import {Body2} from '@ui/atoms/typography';
import {Input as TextArea} from '@ui/atoms/textArea';
import {useStoreContext} from '../../../../store/store-context';
import {computeFrameworkVars} from '../../../../utils/framework';
import dayjs from 'dayjs';
import {ifProp} from 'styled-tools';
import {FlexRow} from '@ui/style/styles';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';

const ProvideContext = styled.div`
  margin-top: 10px;
`;

const MobileWrapper = styled.div<{isVisible?: boolean}>`
  display: ${ifProp('isVisible', 'block', 'none')};

  @media (max-width: 1360px) {
    display: block;
  }
`;

const UpdateWrapper = styled(motion.div)`
  display: flex;
  align-items: flex-end;
  margin-top: 8px;

  .meterics {
    flex-grow: 1;
    display: flex;

    align-items: center;

    > div {
      &:not(:last-of-type) {
        margin-right: 24px;
      }

      &:last-of-type {
        @media (max-width: 600px) {
          margin-top: 16px;
          margin-right: 2px;
        }
      }
    }

    @media (max-width: 720px) {
      flex-wrap: wrap;

      justify-content: space-between;
    }
  }

  .endgoal {
    display: inline-block;
    margin-right: 35px;

    @media (max-width: 600px) {
      margin-top: 16px;
      display: flex;
      width: 100%;
      margin-right: 0px;

      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;
    > div {
      margin-bottom: 12px;
    }
  }
`;

const UpdateKpi = styled.div`
  padding: 16px;
  margin-top: 16px;
  background: #fafafa;

  border: 1px solid #ededf2;
  border-radius: 0px 0px 10px 10px;
`;

const SmallButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  overflow: hidden;
  font-weight: 600;
  padding: 8px 16px;
`;

export interface UpdateGoalProps {
  kind: 'goal' | 'metric';
  name: string;
  measurement: MeasurementType;
  endGoal?: () => void;
  type?: ProgressType;
  percent?: number;
  goalId: string;
  tabKind?: string;
  storeCurrentValue?: number;
  saveChanges?: (e?: any, type?: string) => void;
  currentValue?: number;
  isKpi?: boolean;
  targetValue?: number;
  handleRevertChanges?: () => void;
  youGoalsFilter?: string;
  currencySymbol?: any;
  setIsUpdated?: any;
}

export const UpdateGoal = memo(function ({
  kind,
  name,
  measurement,
  type,
  goalId,
  tabKind,
  handleRevertChanges,
  isKpi,
  saveChanges,
  youGoalsFilter,
  setIsUpdated,
}: UpdateGoalProps) {
  const {updatePerformance} = useStoreContext().storeYouGoals;

  const {
    authStore: {auth},
    storeYouGoals: {fetchedGoals},
    storeAllObjectives: {
      fetchedGoals: fetchedKPIgoals,
      updateGoalContextValue,
      updateGoalFields: addGoalFields,
    },
    checkinsStore: {
      updatePerformanceCheckins,
      userGoals = new Map(),
      updateGoalFieldCheckins,
      updateGoalContextCheckins,
    },
  } = useStoreContext();

  const PATH = `goals/${goalId}`;

  const {
    data = {
      updatedAt: '',
    },
  } = useFirebaseFetch(PATH);

  const lastUpdatedMoreThan24H = useMemo(
    () => dayjs().diff(data?.updatedAt, 'hour') > 24,
    [data?.updatedAt],
  );
  const lastKPIUpdatedMoreThan24H = useMemo(
    () => dayjs().diff(data?.meta?.lastUpdate, 'hour') > 24,
    [data?.meta?.lastUpdate],
  );

  const goal_ =
    fetchedGoals.get(goalId) ||
    fetchedKPIgoals?.get(goalId) ||
    userGoals?.get(goalId);

  const [lastUpdatedValue] = useState(goal_?.value);

  const [prevGoalUpdate, setPrevGoalUpdate] = useState(
    Number(goal_.currentValue) === 0 && !goal_.context,
  );

  const [updateKpiValue, setUpdateKpiValue] = useState(
    prevGoalUpdate ? 'new-value' : '',
  );

  useEffect(() => {
    if (lastUpdatedMoreThan24H && !prevGoalUpdate && !updateKpiValue) {
      setPrevGoalUpdate(true);
      setUpdateKpiValue('new-value');
    }
  }, [lastUpdatedMoreThan24H, prevGoalUpdate, updateKpiValue]);

  const [initGoalValue] = useState({
    currentValue: goal_?.value,
    performance: goal_.performance,
    context: goal_?.context,
  });

  const goalFramework = isKpi
    ? 'KPI'
    : computeFrameworkVars(auth?.user?.workspace?.framework).g_oal;

  const progressTitle = useMemo(() => {
    switch (kind) {
      case 'goal':
        return `What's the status of this ${goalFramework}?`;
      case 'metric':
        return `What’s the status of this ${
          computeFrameworkVars(auth?.user?.workspace?.framework)?.k_r
        }?`;
      // no-default
    }
  }, [auth?.user?.workspace?.framework, kind, goalFramework]);

  const resetGoalFields = () => {
    const inputElement = document.querySelector(
      'input[name="kpiValue"]',
    ) as HTMLInputElement;
    inputElement.value = '';
  };

  return (
    <div>
      <UpdateWrapper
        initial={{height: '0px', opacity: 0}}
        animate={{height: 'auto', opacity: 1}}
        exit={{height: '0px', opacity: 0}}
        transition={{
          duration: 0.3,
        }}
        key={`${kind}-content`}>
        <div className="meterics">
          {kind === 'goal' && !isKpi && (
            <div style={{width: '100%'}}>
              <ProgressField
                disabled={!!youGoalsFilter && youGoalsFilter !== 'active'}
                defaultValue={
                  tabKind === 'youcheckins' && !userGoals.get(goalId)?.updated
                    ? undefined
                    : type
                }
                label={progressTitle}
                name={`${name}-progress`}
                setValue={(value) => {
                  if (tabKind === 'youcheckins') {
                    updatePerformanceCheckins(goalId, 'type', value);
                  } else {
                    updatePerformance(goalId, 'type', value);
                  }
                  setIsUpdated(true);
                  saveChanges && saveChanges();
                }}
              />
            </div>
          )}
        </div>

        {saveChanges && isKpi && (
          <MobileWrapper
            style={{width: '100%'}}
            isVisible={tabKind === 'youcheckins'}>
            <VerticalSpacer size="20px" />
            <Button
              kind={'primary'}
              aria-label="update goal status"
              title="Update progress"
              width="full"
              type="button"
              style={{
                padding: '8px 16px',
                height: '38px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
              onClick={(e) => {
                setIsUpdated(true);

                saveChanges(e, 'kpi');
              }}>
              Save Changes
            </Button>
          </MobileWrapper>
        )}
      </UpdateWrapper>
      {measurement !== MeasurementType.BINARY && (
        <UpdateKpi>
          <VerticalSpacer size="16px" />
          {!lastKPIUpdatedMoreThan24H && lastUpdatedValue ? (
            <FlexRow className="gap-2 mb-2">
              <Body2 weight="semibold" kind="textBody">
                Most recent update:{' '}
                {goal_?.valueUpdated ? lastUpdatedValue : goal_.value}
              </Body2>
              <HorizontalSideRule size="22px" />
              <button
                onClick={() => handleRevertChanges && handleRevertChanges()}>
                <Body2 weight="semibold" kind="purple300">
                  Undo
                </Body2>
              </button>
            </FlexRow>
          ) : null}

          <div className="flex sm:flex-col flex-row">
            <div style={{width: '225px'}}>
              <SecondaryNumberInput
                label=""
                name="kpiValue"
                onChange={(e) => {
                  addGoalFields(goalId, 'value', Number(e.target.value));
                  addGoalFields(goalId, 'valueUpdated', true);
                  updateGoalFieldCheckins(
                    goalId,
                    Number(e.target.value),
                    'value',
                  );
                  updateGoalFieldCheckins(goalId, true, 'valueUpdated');
                  setIsUpdated(e.target.value ? true : false);
                }}
                id="kpiValue"
                placeholder="KPI value"
              />
            </div>

            {initGoalValue.currentValue !== goal_.value && (
              <div className="w-full  flex items-end justify-end mt-0 sm:mt-2">
                <div>
                  <SmallButton
                    width="sm"
                    kind="secondary"
                    onClick={() => {
                      resetGoalFields();
                      goal_.value = initGoalValue.currentValue;

                      if (goal_.performance === initGoalValue.performance)
                        setIsUpdated(false);
                    }}>
                    Cancel <CancelIcon />
                  </SmallButton>
                </div>
              </div>
            )}
          </div>
          <VerticalSpacer size="10px" />

          <ProvideContext>
            <Body2 weight="bold">
              Give more context about the update?{' '}
              <span style={{color: '#5F5F8C', fontWeight: 400}}>
                (optional)
              </span>
            </Body2>
            <VerticalSpacer size="10px" />
            <TextArea
              id="textfield"
              style={{
                borderRadius: '10px',
                height: '98px',
                background: 'white',
              }}
              onChange={(e) => {
                updateGoalContextValue(goal_.id, e.target.value);

                updateGoalContextCheckins(
                  goalId,
                  goalId,
                  'text',
                  e.target.value,
                );
                setIsUpdated(goal_?.value && e.target.value ? true : false);
              }}
            />
          </ProvideContext>
        </UpdateKpi>
      )}
    </div>
  );
});

UpdateGoal.displayName = 'UpdateGoal';

export const UpdateMetricGoal = memo(function ({
  kind,
  name,
  measurement,
  endGoal,
  type,
  goalId,
  storeCurrentValue,
  saveChanges,
  currentValue,
  targetValue,
  currencySymbol,
}: UpdateGoalProps) {
  const [initialCurrentValue] = useState(storeCurrentValue || currentValue);

  const {
    authStore: {auth},
    checkinsStore: {
      updateGoalValueCheckins,
      updatePerformanceCheckins,
      updateGoalContextCheckins,
    },
    storeYouGoals: {updateKeyResultContext, currentValues},
  } = useStoreContext();

  const _currentValue = currentValues.get(name) || currentValue;

  const {updateGoalValue, updatePerformance} = useStoreContext().storeYouGoals;

  const progressTitle = useMemo(() => {
    switch (kind) {
      case 'goal':
        return `What's the status of this ${
          computeFrameworkVars(auth?.user?.workspace?.framework)?.g_oal
        }?`;
      case 'metric':
        return `What’s the status of this ${
          computeFrameworkVars(auth?.user?.workspace?.framework)?.k_r
        }?`;
      // no-default
    }
  }, [auth?.user?.workspace?.framework, kind]);

  const renderValueUpdater = useMemo(() => {
    switch (measurement) {
      case MeasurementType.BINARY:
        return (
          <BinarySwitchField
            label="Toggle to update?"
            name={`${name}-binary`}
            currentValue={currentValue}
            setValue={(value) => {
              if (value === 'COMPLETED') {
                updateGoalValue(goalId, name, 'binary', 1);
                updateGoalValueCheckins(goalId, name, 'binary', 1);
              } else {
                updateGoalValue(goalId, name, 'binary', 0);
                updateGoalValueCheckins(goalId, name, 'binary', 0);
              }
            }}
          />
        );
      case MeasurementType.PERCENTAGE:
        return (
          <PercentageUpdateField
            defaultValue={currentValue}
            label="What’s the new value?"
            name={`${name}-percentage`}
            id={nextId('percentage-id')}
            placeholder={`of ${targetValue}`}
            max={`${targetValue}`}
            setValue={(value) => {
              updateGoalValue(goalId, name, 'percent', value);
              updateGoalValueCheckins(goalId, name, 'percent', value);
            }}
          />
        );
      case MeasurementType.CURRENCY:
        return (
          <RangeUpdateField
            defaultValue={currentValue}
            label="What’s the new value?"
            name={`${name}-currency-value`}
            id={nextId('currency-id')}
            kind="currency"
            currencySymbol={currencySymbol}
            max={`${targetValue}`}
            setValue={(value) => {
              updateGoalValue(goalId, name, 'currency', value);
              updateGoalValueCheckins(goalId, name, 'currency', value);
            }}
          />
        );
      case MeasurementType.NUMERIC:
        return (
          <RangeUpdateField
            defaultValue={currentValue}
            label="What’s the new value?"
            id={nextId('numeric-id')}
            name={`${name}-numeric-value`}
            kind="number"
            // value="4"
            max={`${targetValue}`}
            setValue={(value) => {
              updateGoalValue(goalId, name, 'numeric', value);
              updateGoalValueCheckins(goalId, name, 'numeric', value);
            }}
          />
        );
      // no-default
    }
  }, [
    measurement,
    name,
    currentValue,
    updateGoalValueCheckins,
    targetValue,
    currencySymbol,
    updateGoalValue,
    goalId,
  ]);
  const {isSm} = useBreakpoints();

  const context = useMemo(() => {
    return (
      <ProvideContext>
        <Body2 weight="bold">
          Give more context about the update?{' '}
          <span style={{color: '#5F5F8C', fontWeight: 400}}>(optional)</span>
        </Body2>
        <VerticalSpacer size="10px" />
        <TextArea
          style={{borderRadius: '10px', height: '98px'}}
          onChange={(e) => {
            updateKeyResultContext(goalId, name, e.target.value);
            updateGoalContextCheckins(goalId, name, 'text', e.target.value);
          }}
        />
      </ProvideContext>
    );
  }, [goalId, name, updateKeyResultContext, updateGoalContextCheckins]);

  return (
    <>
      <UpdateWrapper
        initial={{height: '0px', opacity: 0}}
        animate={{height: 'auto', opacity: 1}}
        exit={{height: '0px', opacity: 0}}
        transition={{
          duration: 0.3,
        }}
        key={`${kind}-content`}>
        <div className="meterics">
          <div style={{width: '50%'}}>{renderValueUpdater}</div>
          {kind === 'goal' && (
            <div>
              <ProgressField
                defaultValue={type}
                label={progressTitle}
                name={`${name}-progress`}
                setValue={(value) => {
                  updatePerformance(goalId, 'type', value);
                  updatePerformanceCheckins(goalId, 'type', value);
                }}
              />
            </div>
          )}
        </div>
        {saveChanges && Number(initialCurrentValue) !== Number(_currentValue) && (
          <Button
            kind={'primary'}
            aria-label="update goal status"
            title="Update progress"
            width={isSm ? 'full' : 'sm'}
            type="button"
            style={{
              padding: '8px 16px',
              height: '38px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
            onClick={(e) => {
              saveChanges(e);
            }}>
            Save Changes
          </Button>
        )}
        {kind === 'goal' && (
          <div className="endgoal">
            <Button width="sm" kind="secondary" onClick={endGoal}>
              End goal
            </Button>
          </div>
        )}
      </UpdateWrapper>

      {Number(initialCurrentValue) !== Number(_currentValue) && context}
    </>
  );
});

UpdateMetricGoal.displayName = 'UpdateMetricGoal';
