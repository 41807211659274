import {BaseRequest} from '../../configs/request';
import {authStore} from '../../store/stores/auth-store';

export interface IUserAPIRequest extends BaseRequest {
  fetchAllUsers(): Promise<any>;
  fetchConfirmedUsers(): Promise<any>;
  fetchPaginatedConfirmedUsers(page: number): Promise<any>;
  fetchUserByID(id: string, query?: string): Promise<any>;
  fetchCurrentUser(): Promise<any>;
  fetchInvitedUsers(): Promise<any>;
  activateUser(userId: string): Promise<any>;
  fetchPaginatedInvitedUsers(page: number): Promise<any>;
  fetchCurrentUserGroups(): Promise<any>;
  updateUser(userData: any, userId: string): Promise<any>;
  fetchDeactivatedUsers(): Promise<any>;
  fetchPaginatedDeactivatedUsers(page: number): Promise<any>;
  deleteUser(userId: string): Promise<any>;
  fetchUserGroups(id: string): Promise<any>;
  fetchSpecificUserGroups(id: string): Promise<any>;
}

export class UserAPIRequestImpl extends BaseRequest implements IUserAPIRequest {
  fetchUsersEndpoint: string = '/users';

  async fetchAllUsers() {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}?paginate=false&include=reviewer`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async activateUser(userId: string) {
    const {data: response} = await this.request.post(
      `${this.fetchUsersEndpoint}/${userId}/activate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async fetchConfirmedUsers() {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/confirmed`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchInvitedUsers() {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/invited?paginate=false&include=reviewer`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchPaginatedConfirmedUsers(page: number) {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/confirmed?page=${page}&include=reviewer`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchPaginatedInvitedUsers(page: number) {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/invited?include=reviewer&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchUserByID(id: string, query?: string) {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/${id}${query ? `?${query}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchCurrentUser() {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/me?include=[reviewer]`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        useCache: false,
      },
    );
    return response;
  }

  async fetchCurrentUserGroups() {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/${authStore.auth?.user.id}/group`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchSpecificUserGroups(id: string) {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/${id}/group`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchUserGroups(id: string) {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/${id}/group`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async updateUser(userData: any, userId: string) {
    const {data: response} = await this.request.patch(
      `${this.fetchUsersEndpoint}/${userId}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );

    return response;
  }
  async fetchDeactivatedUsers() {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/deactivated?paginate=false`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async deleteUser(userId: string) {
    const {data: response} = await this.request.delete(
      `${this.fetchUsersEndpoint}/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchPaginatedDeactivatedUsers(page: number) {
    const {data: response} = await this.request.get(
      `${this.fetchUsersEndpoint}/deactivated?page=${page}&include=reviewer`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
}
