import {useQuery} from 'react-query';
import {onValue, ref, query} from 'firebase/database';
import {FIREBASE_BASE_URL, database} from '@utils/firebase-request';
import {useCallback, useEffect, useState} from 'react';
import {authStore} from '@store/stores/auth-store';

export const useFetchHook = (
  description: string | Array<any>,
  callback: any,
  options?: any,
) => {
  // const FIVE_MINUTES_STALE_TIME = 300000;

  return useQuery(description, callback, {
    ...options,
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
    // staleTime: FIVE_MINUTES_STALE_TIME,
  });
};

export const useFirebaseFetch = (path: string, filter?: any) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const databaseRef = ref(
    database,
    `${FIREBASE_BASE_URL}/${authStore.auth.user.workspace.id}/${path}`,
  );

  const fetchData = useCallback(() => {
    const filteredQuery = filter?.equalTo
      ? query(databaseRef, filter)
      : databaseRef;

    setIsLoading(true);
    onValue(filteredQuery, (snapshot: any) => {
      setData(snapshot?.val());
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return {data, isLoading, refetch: fetchData};
};
