import {
  TableContainer,
  SecondRowWrapper,
  HeadlineWrapperContainer,
  SubmittedHeadlineWrapper,
  ItemWrapperContainer,
  HeadlineItemWrapper,
  ItemLoaderWrapper,
  PaginationLi,
  SummaryWrapper,
  MentionIndicator,
  PaginationWrapper,
  Status,
  LinkButtonWrapper,
  PaginationUl,
  PaginationButton,
} from './styles';
import {UserListCard} from '@ui/molecules/user/list-card';
import {parseName} from '../my_checkins/my_checkins';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {useNavigate} from 'react-router';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {useMemo} from 'react';
import dayjs from 'dayjs';
import {useLocation} from 'react-router';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {Button} from '@ui/atoms/button';
import {cloudinary} from '@assets/images/cloudinary';
import {ArrowHeadRightIcon} from '@ui/atoms/icons';
import {useCheckinsCalendar} from '@hooks/checkins-calendar';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {ArrowHeadLeftIcon} from '@ui/atoms/icons';
import {useStoreContext} from '@store/store-context';
import {useCheckinsTeamsMention} from './checkins-mention-hook';
import {Body2, CTA} from '@ui/atoms/typography';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {observer} from 'mobx-react';

interface SummaryWeeksProps {
  activeWeeks?: any[];
}
export interface IAppProps extends SummaryWeeksProps {
  loader: boolean;
  loading: boolean;
}

export const CheckinsTeamSummary = observer(
  ({loader, loading, activeWeeks}: IAppProps) => {
    const {
      checkinsStore: {
        usersCheckinsPaginator,
        handleUsersCheckinsPaginatorNextbtn,
        handleUsersCheckinsPaginatorPageClick,
        checkinsDates,
        handleUsersCheckinsPaginatorPrevbtn,
        usersCheckinsPages,
        setCheckinsDate,
        monthCheckinsFilter,
        setMonthCheckinsFilter,
      },
    } = useStoreContext();

    const {getPendingReviews} = useCheckinsTeamsMention();
    const location = useLocation();

    const renderPageNumbers = usersCheckinsPages(
      usersCheckinsPaginator.totalResults,
      usersCheckinsPaginator.limit,
    ).map((number: any) => {
      if (
        number < usersCheckinsPaginator.maxPageNumberLimit + 1 &&
        number > usersCheckinsPaginator.minPageNumberLimit
      ) {
        return (
          <PaginationLi
            key={number}
            id={number}
            onClick={handleUsersCheckinsPaginatorPageClick}
            className={
              usersCheckinsPaginator.page === number ? 'active' : undefined
            }>
            {number}
          </PaginationLi>
        );
      } else {
        return null;
      }
    });

    const navigate = useNavigate();

    return (
      <div className="bg-white">
        <SummaryWrapper>
          <div className="table-summary">
            <div className="flex gap-1 items-center ">
              <Body2 weight="bold">Summary </Body2>
              {getPendingReviews?.length > 0 && (
                <MentionIndicator>{getPendingReviews?.length}</MentionIndicator>
              )}
            </div>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <>
                  <DropdownItem
                    onClick={() => {
                      handleClose();
                      activeWeeks &&
                        setMonthCheckinsFilter(
                          activeWeeks
                            ?.map((week) => week.weekOfYear)
                            ?.join(','),
                        );
                    }}>
                    All
                  </DropdownItem>

                  {activeWeeks &&
                    activeWeeks.map((week) => (
                      <DropdownItem
                        onClick={() => {
                          if (location.search?.length > 0)
                            navigate(location.pathname);

                          handleClose();
                          setCheckinsDate({
                            weekOfYear: week.weekOfYear,
                            starts: week.dateStart,
                            ends: week.dateEnd,
                          });
                          setMonthCheckinsFilter('');
                        }}>
                        {week.title}
                      </DropdownItem>
                    ))}
                </>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <>
                  <Button
                    kind="secondary"
                    onClick={handleOpen}
                    style={{padding: '8px 16px'}}>
                    <FlexRowSpaceBetween style={{width: '100%'}}>
                      <Body2 weight="bold">
                        {' '}
                        {monthCheckinsFilter
                          ? 'All'
                          : dayjs(checkinsDates?.starts).format('MMM. DD') +
                            ' - ' +
                            dayjs(checkinsDates?.ends).format('MMM. DD')}
                      </Body2>

                      <ArrowHeadDownIcon
                        style={{
                          marginLeft: 14,
                          stroke: 'black',
                          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </FlexRowSpaceBetween>
                  </Button>
                </>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                width: '200px',
                zIndex: 99999,
                right: 0,
              }}
            />
          </div>

          <TableContainer>
            <TeamCheckins
              loader={loader}
              loading={loading}
              activeWeeks={activeWeeks}
            />

            {(loader || loading) && (
              <ItemWrapperContainer>
                <ItemLoaderWrapper>
                  <ItemLoaderLine />
                </ItemLoaderWrapper>
              </ItemWrapperContainer>
            )}
          </TableContainer>
          {usersCheckinsPaginator.totalResults > 10 ? (
            <PaginationWrapper>
              <PaginationUl>
                {usersCheckinsPaginator.minPageNumberLimit > 0 && (
                  <PaginationLi>
                    <PaginationButton
                      onClick={handleUsersCheckinsPaginatorPrevbtn}
                      disabled={
                        usersCheckinsPaginator.page === 1 ? true : false
                      }>
                      <ArrowHeadLeftIcon />
                    </PaginationButton>
                  </PaginationLi>
                )}

                {renderPageNumbers}

                {usersCheckinsPages(
                  usersCheckinsPaginator.totalResults,
                  usersCheckinsPaginator.limit,
                ).length !== usersCheckinsPaginator.maxPageNumberLimit && (
                  <PaginationLi>
                    <PaginationButton
                      onClick={handleUsersCheckinsPaginatorNextbtn}
                      disabled={
                        usersCheckinsPaginator.page ===
                        usersCheckinsPaginator.totalPages
                          ? true
                          : false
                      }>
                      <ArrowHeadRightIcon />
                    </PaginationButton>
                  </PaginationLi>
                )}
              </PaginationUl>
            </PaginationWrapper>
          ) : null}
        </SummaryWrapper>
      </div>
    );
  },
);

const TeamCheckins = observer(({loader, loading, activeWeeks}: IAppProps) => {
  const {
    checkinsStore: {usersCheckins},
  } = useStoreContext();

  const EmptyGroupQuestions = useMemo(
    () => ({
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: cloudinary.emptyGoals,
        style: {height: '50%', width: '50%'},
      },
      subtitle: '',
      title: `You have no  reports for this time period`,
    }),
    [],
  );
  const checkinsData =
    usersCheckins?.data?.length > 0 ? usersCheckins?.data : [];

  return (
    <>
      <div style={{overflowX: 'scroll'}}>
        <SecondRowWrapper>
          <HeadlineWrapperContainer className="header">
            <SubmittedHeadlineWrapper>
              <Body2 kind="textBody">Name</Body2>
              <Body2 kind="textBody">Manager</Body2>
              <Body2 kind="textBody">Status</Body2>
              <Body2 kind="textBody">Actions</Body2>
            </SubmittedHeadlineWrapper>
          </HeadlineWrapperContainer>
          {!loader &&
            !loading &&
            checkinsData.map((report: any) => (
              <CheckinRow
                activeWeeks={activeWeeks}
                report={report}
                key={report?.id}
              />
            ))}
          {!loader && !loading && usersCheckins?.data?.length === 0 && (
            <div
              style={{
                textAlign: 'center',
              }}>
              <EmptyPlaceholder {...EmptyGroupQuestions} />
            </div>
          )}
        </SecondRowWrapper>
      </div>
    </>
  );
});

interface RowProps extends SummaryWeeksProps {
  report: {
    id: string;
    weekOfYear: number;
    user: any;
    isReviewed?: boolean;
    isSubmitted?: boolean;
  };
}
const CheckinRow = ({report}: RowProps) => {
  const {
    usersStore: {users},
    checkinsStore: {setCheckinsDate},
  } = useStoreContext();
  const navigate = useNavigate();

  const calendarData = useCheckinsCalendar();

  const handleView = () => {
    const getSelectedWeek = calendarData.currentMonthWeeks.find(
      (week) => week.weekOfYear === report.weekOfYear,
    );

    if (getSelectedWeek) {
      setCheckinsDate({
        weekOfYear: report.weekOfYear,
        starts: getSelectedWeek.dateStart,
        ends: getSelectedWeek.dateEnd,
        month: getSelectedWeek.month,
      });
    }
  };

  return (
    <>
      <ItemWrapperContainer>
        <HeadlineItemWrapper>
          <div>
            <UserListCard
              tooltip={true}
              userId={report?.user?.id}
              type="secondary"
              reviewer={parseName(
                users?.filter((user) => user?.id === report?.user?.reviewer),
              )}
              avatar={report?.user?.avatar?.url}
              name={parseName(report?.user)}
              TextComponent={CTA}
              textKind="textDark"
            />
          </div>
          <div>
            {users
              ?.filter((user) => user?.id === report?.user?.reviewer)
              .map((manager) => (
                <UserListCard
                  tooltip={true}
                  userId={manager?.id}
                  type="secondary"
                  reviewer={parseName(
                    users?.filter((user) => user?.id === manager?.reviewer),
                  )}
                  avatar={manager?.avatar?.url}
                  name={parseName(manager)}
                  TextComponent={CTA}
                  textKind="textDark"
                />
              ))}
          </div>

          <div>
            <Status>
              <Body2 weight="semibold" size="12px" kind="textBody">
                {!report.isSubmitted
                  ? 'Not submitted'
                  : report?.isReviewed
                  ? 'Reviewed'
                  : 'Pending review'}
              </Body2>
            </Status>
          </div>

          <LinkButtonWrapper>
            <Button
              disabled={!report.isSubmitted}
              onClick={() => {
                handleView();
                navigate(
                  {pathname: `/view-checkins/${report?.id}/direct`},
                  {state: {user: report?.user?.id}},
                );
              }}
              kind={
                !report?.isReviewed && report?.isSubmitted
                  ? 'primary'
                  : 'secondary'
              }
              style={{
                padding: `8px 16px`,
              }}>
              {!report?.isReviewed && report?.isSubmitted ? 'Review' : 'View'}
            </Button>
          </LinkButtonWrapper>
        </HeadlineItemWrapper>
      </ItemWrapperContainer>
    </>
  );
};
