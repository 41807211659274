import React, {FC, memo, useCallback, useMemo} from 'react';
import {Insight} from '../interface';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body2, Headline2} from '@ui/atoms/typography';
import {cfv} from '@utils/framework';
import {AnalyticsController} from '../../reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../reporting-pages/admin-analytics/admin-analytics.request';
import {useQuery} from 'react-query';
import {formatConfigDate} from '../insight-options';
import {IKeyResult, MeasurementType} from '@hooks';
import {VerticalInsightConfig} from '../../dashboard/insight-config';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {goalDueDate} from '@utils/date';
import {ProgressBar} from '@ui/molecules/progress-bar';
import {ItemLoader} from '@ui/organisms/item-loader';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';

let formatter = Intl.NumberFormat('en', {notation: 'compact'});

interface KeyResultPerformanceProps extends Insight {
  showRangeType?: boolean;
  handleEditLayout?: (id: string) => void;
}

const formatTextColor = (state: string, endDate?: string) => {
  if (endDate === 'Past due') return 'red300';

  switch (state) {
    case 'at_risk':
      return 'red300';
    case 'on_track':
      return 'green300';
    case 'behind':
      return 'orange300';
    default:
      return 'red300';
  }
};
export const KeyResultPerformance: FC<KeyResultPerformanceProps> = memo(
  ({config, showRangeType, handleEditLayout, name, ...rest}) => {
    const hasSelectedKeyResult = !!config.key_result;

    const dateRange = useMemo(() => formatConfigDate(config), [config]);

    const fetchKeyResult = useCallback(
      async (filters: any) => {
        const request = new AnalyticsRequestImpl();
        const controller = new AnalyticsController(request);
        const keyresult = await controller.fetchSpecifiedKpis(filters, true);
        if (keyresult) {
          return keyresult.find(
            (keyresult: {id: string}) => keyresult.id === config.key_result,
          ) as IKeyResult;
        }
      },
      [config.key_result],
    );

    const {data, isLoading} = useQuery(
      ['key-result', hasSelectedKeyResult, config],
      () =>
        fetchKeyResult({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }),
      {
        refetchOnWindowFocus: false,
        enabled: !!hasSelectedKeyResult,
        refetchOnMount: false,
        staleTime: 5000,
      },
    );

    if (!hasSelectedKeyResult) {
      return (
        <FlexRowCenter className="min-h-[180px]">
          <div className="bg-backgroundDark px-6 py-2 rounded-[10px] ">
            <Body2 kind="textBody">
              Select a {cfv().k_r} to show performance insights.
            </Body2>
          </div>
        </FlexRowCenter>
      );
    }

    const isBinary =
      data?.measurement.unit.toUpperCase() === MeasurementType.BINARY;

    const formatBinary = (value: number) => {
      return value === 0 ? 'Incomplete' : 'Complete';
    };

    return (
      <div className="bg-white flex  flex-col items-start shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] p-6 border h-full rounded-[10px] border-borderLight  min-h-[150px]">
        <FlexRowSpaceBetween>
          {showRangeType && (
            <>
              <VerticalSpacer size="7px" />
              <VerticalInsightConfig config={config} additionalFilters={[]} />
            </>
          )}

          {showRangeType && (
            <button
              onClick={() => {
                if (rest.firebaseId && handleEditLayout)
                  handleEditLayout(rest.firebaseId);
              }}>
              <HoverTooltip tooltipText="Edit insight">
                <CandleVIcon />
              </HoverTooltip>
            </button>
          )}
        </FlexRowSpaceBetween>

        {data && !isLoading && (
          <>
            <FlexRowSpaceBetween className="mt-4">
              <Headline2
                kind={formatTextColor(
                  data.performance,
                  goalDueDate(data.goalInfo.endDate || ''),
                )}>
                {data.percentageProgress}%
              </Headline2>
              <Body2 className="text-[12px]" kind="textBody">
                {goalDueDate(data.goalInfo.endDate || '')}
              </Body2>
            </FlexRowSpaceBetween>

            <Body2 kind="textBody" className="mt-4">
              {name}
            </Body2>

            <div className="w-full mt-4">
              <FlexRowSpaceBetween>
                <Body2 weight="bold">
                  {isBinary
                    ? formatBinary(data.currentValue)
                    : formatter.format(data.currentValue)}
                </Body2>
                <Body2 weight="bold" kind="textBody">
                  {isBinary
                    ? formatBinary(data.targetValue)
                    : formatter.format(data.targetValue)}
                </Body2>
              </FlexRowSpaceBetween>

              <ProgressBar
                type="admin-plus"
                wStyle={{gridTemplateColumns: '100% auto', width: '100%'}}
                percent={data.percentageProgress}
                state=""
                pStyle={{width: '100%'}}
                measurement={data.measurement.unit}
              />
            </div>
          </>
        )}

        {isLoading && (
          <FlexRowCenter className="w-full pt-6">
            <ItemLoader />
          </FlexRowCenter>
        )}
      </div>
    );
  },
);
