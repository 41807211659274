import {array, boolean, object, string} from 'yup';

export const AdminConfigureCheckinsValidator = object().shape({
  frequency: object().shape({
    goal: string().nullable(),
    // .required('Select frequency'),
    pulse: string().nullable(),
    // .required('Select frequency for pulse'),
    priorities: string().nullable(),
    // .required('Select frequency for priorities'),
    questions: string().nullable(),
    // .required('Select frequency for questions'),
  }),
  customFrequency: object().shape({
    groups: array().of(string()),
    goal: string(),
    pulse: string(),
    priorities: string(),
    questions: string(),
    defaultFrequency: string(),
  }),
  excludedGroups: array().of(string()),
  activeCustomFrequency: boolean().nullable(),
  visibility: string(),
  // .required('Select preferred visibility'),
  managersCanAddQuestions: boolean().nullable(),
  groups: object(),
});
